import React, { Component } from "react";
import { SUBSCRIPTION_STATUS } from "../../constants/Enum";

class StatusBadge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.status,
    };
  }
  componentWillReceiveProps(nextProp) {
    this.setState({
      status: nextProp.status,
    });
  }
  render() {
    return this.getStatusBadge(this.state.status);
  }

  getStatusBadge(status) {
    let border = " border-red";
    let textColor = " text-orange";
    // let bgColor = " bg-chip-orange";
    if (status === SUBSCRIPTION_STATUS.ACTIVE) {
      textColor = " text-green";
      // bgColor = " bg-chip-green";
      border = " border-green";
    } else if (
      status === SUBSCRIPTION_STATUS.EXPIRED ||
      status === SUBSCRIPTION_STATUS.TRIAL_ENDED ||
      status === SUBSCRIPTION_STATUS.CANCELLED
    ) {
      textColor = " text-red";
      // bgColor = " bg-chip-red";
      border = " border-red";
    } else if (status === SUBSCRIPTION_STATUS.IN_TRIAL) {
      textColor = " text-blue";
      // bgColor = " bg-chip-blue";
      border = " border-blue";
    }
    return (
      <div
        className={
          " border-radius-r pl-s pr-s pt-xs pb-xs fw-m text-wrap-none " +
          this.props.className +
          border +
          textColor
        }
      >
        {status}
      </div>
    );
  }
}

export default StatusBadge;
