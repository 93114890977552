import React, { Component } from "react";

import { DKLabel } from "deskera-ui-library";

import ReleaseNoteManager from "../../managers/ReleaseNoteManager";
import Utility from "../../utility/Utility";
import RouteManager from "../../managers/RouteManager";
import { t } from "i18next";
class Updates extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateClicked = (e) => {
    const url = e.currentTarget.dataset.div_id;
    if (Utility.isValidURL(url)) {
      window.open(url, "_blank");
    } else {
      RouteManager.navigateToPage(url);
    }
  };

  render() {
    const updates = ReleaseNoteManager.getUpdated();
    return (
      <div className="mb-xl pl-r pr-r pt-r pb-r border-m border-radius-s parent-width column bg-deskera-secondary shadow-ms">
        <DKLabel text={t("WHAT'S_NEW")} className="fw-b" />

        <DKLabel
          text={t("RELEASED_ON") + `${updates.date}`}
          className="fw-r mt-xs mb-m"
        />

        {updates.items &&
          updates.items.length !== 0 &&
          updates.items.map((obj, index) => {
            return <div key={index}>{this.getItem(obj)}</div>;
          })}
        <div className="mb-r" />
      </div>
    );
  }

  getItem(data) {
    return (
      <div
        className="row align-items-start mt-r cursor-hand"
        data-div_id={data.url}
        onClick={this.updateClicked}
      >
        <DKLabel text="•" className="fw-b" />
        <div className="column ml-s">
          <DKLabel className="under-line" text={data.title} />
        </div>
      </div>
    );
  }
}

export default Updates;
