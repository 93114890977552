import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { LanguageMenuList } from "../components/menu/LabelList/LanguageMenuList";
import { LANGUAGES_CODE, COUNTRY } from "../constants/Constant";
import { TENANT, TenantService } from "../services/tenant";

import en from "./translations/en.json";
import en_GB from "./translations/en_GB.json";
import en_IN from "./translations/en_IN.json";

export const LANG = "language";

const DEFAULT_COUNTRY_LANG = {
  US: LANGUAGES_CODE.ENGLISH_US,
  IN: LANGUAGES_CODE.ENGLISH_IN,
  IN_MH: LANGUAGES_CODE.ENGLISH_IN,
  IN_UP: LANGUAGES_CODE.ENGLISH_IN,
};

const resources = {
  en: {
    translation: en, // English
  },
  en_GB: {
    translation: en_GB, // English UK
  },
  en_IN: {
    translation: en_IN, // English IN
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: LANGUAGES_CODE.ENGLISH_US,
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;

export function getDefaultLangCode(country) {
  // if (localStorage.getItem(LANG)) {
  //     return localStorage.getItem(LANG);
  // }

  let lang;
  if (country === COUNTRY.IN) {
    const state = TenantService.get(TENANT.STATE_CODE);
    if (DEFAULT_COUNTRY_LANG[`${country}_${state}`]) {
      lang = DEFAULT_COUNTRY_LANG[`${country}_${state}`];
    } else {
      lang = DEFAULT_COUNTRY_LANG[country];
    }
  } else {
    lang = DEFAULT_COUNTRY_LANG[country] || LANGUAGES_CODE.ENGLISH_UK;
  }

  return lang;
}

export function getDefaultLangTitle() {
  const country = TenantService.get(TENANT.COUNTRY_CODE);
  const defaultLang = LanguageMenuList.find(
    (l) => l.code === getDefaultLangCode(country)
  );
  return defaultLang.title;
}
