import ApiConstants from "../constants/ApiConstants";
import http from "../http";

export default class StripeApiManager {
  static getIntent() {
    return http
      .post(ApiConstants.URL.STRIPE.GET_INTENT, {})
      .then(
        (res) => {
          if (res.errorMessage) Promise.resolve(res);
          return Promise.resolve(res);
        },
        (err) => Promise.resolve(err)
      )
      .catch((err) => Promise.resolve(err));
  }

  static setPaymentMethod(PaymentMethodID, tenantID = null, userID = null) {
    let url;
    if(tenantID !== null && tenantID.length > 0 && userID !== null && userID.length > 0){
      url = ApiConstants.URL.STRIPE.SET_PAYMENT + "?UserID="+userID+"&TenantID="+tenantID
    } else {
      url = ApiConstants.URL.STRIPE.SET_PAYMENT
    }
    return http
      .post(url, { PaymentMethodID })
      .then(
        (res) => {
          if (res.errorMessage) Promise.resolve(res);
          return Promise.resolve(res);
        },
        (err) => Promise.resolve(err)
      )
      .catch((err) => Promise.resolve(err));
  }
}
