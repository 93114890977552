import React, { Component } from "react";

import { DKLabel } from "deskera-ui-library";

import { PRODUCTS } from "../../constants/Enum";
import ProductIcon from "./ProductIcon";
import PlanManager from "../../managers/PlanManager";
import ApiConstants from "../../constants/ApiConstants";

import MobileAppPopup from "./MobileAppPopup";
import UserManager from "../../managers/UserManager";
import SubscriptionManager from "../../managers/SubscriptionManager";
import { isComplianceCountry } from "./CommonUtils";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needMobilePopup: false,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className=" mt-l  pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width column">
        <DKLabel className="fw-m fs-m" text={t("EXPLORE_DESKERA_PRODUCTS")} />
        <DKLabel className="text-gray mt-xs" text={t("BEST_CUSTOMER_EXP")} />
        {this.getProductsRow()}
      </div>
    );
  }

  getProductsRow() {
    let products = [
      // {
      //   product: PRODUCTS.ERP,
      //   description: "Online invoicing, accounting & inventory",
      //   url: ApiConstants.PRODUCT_URL_ERP,
      // },
      // {
      //   product: PRODUCTS.CRM,
      //   description: "Deals, sales pipelines, Email campaign & more",
      //   url: ApiConstants.PRODUCT_URL_CRM,
      // },
      // {
      //   product: PRODUCTS.PEOPLE,
      //   description: "Optimize HR processes to manage workforce",
      //   url: ApiConstants.PRODUCT_URL_PEOPLE,
      // },
      {
        id: "product_mrp",
        product: PRODUCTS.MRP,
        description: "Plan & manage material, resources & more",
        url: ApiConstants.PRODUCT_URL_MRP,
      },
      {
        id: "product_books_plus",
        product: PRODUCTS.BOOKS_PLUS,
        description: t("BOOKS_PLUS"),
        url: isComplianceCountry(PRODUCTS.BOOKS_PLUS)
          ? ApiConstants.PRODUCT_URL_ERP_PLUS
          : ApiConstants.PRODUCT_URL_ERP,
      },
      {
        id: "product_crm_plus",
        product: PRODUCTS.CRM_PLUS,
        description: t("CRM_PLUS"),
        url: ApiConstants.PRODUCT_URL_CRM_PLUS,
      },
      {
        id: "product_people_plus",
        product: PRODUCTS.PEOPLE_PLUS,
        description: t("PEOPLE_PLUS"),
        url: isComplianceCountry(PRODUCTS.PEOPLE_PLUS)
          ? ApiConstants.PRODUCT_URL_PEOPLE_PLUS
          : ApiConstants.PRODUCT_URL_PEOPLE,
      },
      // {
      //   product: PRODUCTS.SHOP,
      //   description: "Build your own shop and start selling",
      //   url: ApiConstants.PRODUCT_URL_SHOP,
      // },
      {
        id: "product_doc_builder",
        product: PRODUCTS.DOCUMENT_BUILDER,
        description: t("DOCUMENT_BUILDER"),
        url: ApiConstants.PRODUCT_URL_DOCUMENT_BUILDER,
      },
      {
        id: "product_report_builder",
        product: PRODUCTS.REPORT_BUILDER,
        description: "Customize all your data into a single report",
        url: ApiConstants.PRODUCT_URL_REPORT_BUILDER,
      },
      // {
      //   product: PRODUCTS.WORKFLOW_ENGINE,
      //   description: "Design your business processes & automate",
      //   url: ApiConstants.PRODUCT_URL_WORKFLOW_ENGINE,
      // },
      // {
      //   product: PRODUCTS.DOCUMENT_SCANNER,
      //   description: "Scan documents quickly with our mobile app",
      //   url: null,
      // },
    ];

    if (SubscriptionManager.isProductEnabled(PRODUCTS.AI)) {
      products.unshift({
        id: "product_ai",
        product: PRODUCTS.AI,
        description: "Instant Insights, Exceptional Experiences",
        url: ApiConstants.PRODUCT_URL_AI,
      });
    }

    if (UserManager.getUserDetails()?.tenantName?.toLowerCase() === "klarra") {
      products.push({
        id: "product_shop",
        product: PRODUCTS.SHOP,
        description: t("SHOP"),
        url: ApiConstants.PRODUCT_URL_SHOP,
      });
    }

    if (!SubscriptionManager.isMRPEnabled()) {
      products = products?.filter((productRow) => {
        return productRow.id !== "product_mrp";
      });
    }

    return (
      <div
        className="row align-items-start mt-xxl mb-m justify-content-center flex-wrap"
        style={{ gap: 30 }}
      >
        {this.state.needMobilePopup && (
          <MobileAppPopup onClose={this.hideMobilePopup} />
        )}
        {products.map((obj) => {
          return (
            <div
              className=" column align-items-center cursor-hand "
              style={{ width: 120 }}
              onClick={() => {
                if (obj.url) {
                  window.open(obj.url, "_blank");
                } else {
                  this.showMobilePopup();
                }
              }}
            >
              <ProductIcon product={obj.product} className="ic-m" />
              <div className="text-wrap fs-r mt-r fw-m">
                {PlanManager.getProductDisplayName(obj.product)}
              </div>
              <div className="text-wrap fs-s mt-xs text-gray">
                {obj.description}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  showMobilePopup = () => {
    this.setState({ needMobilePopup: true });
  };
  hideMobilePopup = () => {
    this.setState({ needMobilePopup: false });
  };
}

export default withTranslation()(ProductList);
