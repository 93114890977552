import AppManager from "../managers/AppManager";
import ApiConstants from "../constants/ApiConstants";
import UserManager from "./UserManager";
import CompanyDetailManager from "./CompanyDetailManager";
import TimeoutManager from "./TimeoutManager";

export default class ApiManager {
  static commonError = "Server error occured. Please try again later.";

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static getApiRequestOptions(method, body = null) {
    var requestOptions = {
      method: method,
      credentials: "include",
      withCredentials: true,
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        accept: "*/*",
        // "x-access-token": ApiConstants.ACCESS_TOKEN,
      },
    };
    if (method === "POST" || method === "PUT") {
      body = JSON.stringify(body);
      requestOptions = { ...requestOptions, body };
    }
    return requestOptions;
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////

  static async checkIfUserLoggedIn(onSuccess, onFail, showAlertPopup = false) {
    return fetch(ApiConstants.URL.BASE + ApiConstants.URL.IAM.STATUS, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.accessToken) {
          ApiManager.tokenReceived(data.accessToken, data.emailVerified);
          onSuccess();
          return Promise.resolve("Authenticated User");
        } else {
          AppManager.gotoLoginPage(showAlertPopup);
          return Promise.reject("Failed to authenticate user");
        }
      })
      .catch((error) => {
        AppManager.gotoLoginPage(showAlertPopup);
        /* Rejecting with no message to avoid showing app ui */
        return Promise.reject("");
      });
  }

  static tokenReceived(accessToken, emailVerified) {
    let token = ApiManager.parseJwt(accessToken);
    ApiConstants.ACCESS_TOKEN = accessToken;

    // Set token expiry time
    TimeoutManager.setTokenExpiryTime(token.exp);

    let user = {
      imID: token.iamUserId,
      id: token.userId,
      tenantID: token.tenantId,
      name: token.name,
      email: token.email,
      phone: token.phone_number,
      website: token.website,
      tenantName: token.website,
      country: token.taxResidency,
      currency: token.currency,
      isOrgSet: token.isOrgSet,
      emailVerified: emailVerified,
    };
    UserManager.setUserDetails(user);
    CompanyDetailManager.setCompanyDetails(user);
    AppManager.userLoggedIn();
  }

  ////////////////////////////////////////////
  ////////////////////////////////////////////
}
