import React, { useEffect } from "react";
import { DKLabel, DKIcon, showAlert } from "deskera-ui-library";
import { LanguageMenuList } from "./LabelList/LanguageMenuList";
import i18n, { LANG } from "./../../i18n/i18n";
import RouteManager from "../../managers/RouteManager";

const LanguageList = (props) => {
  const changeLanguage = (data) => {
    i18n.changeLanguage(data.code);
    localStorage.setItem(LANG, data.code);
    props.onSelect(data);
    props.onClose();
  };

  useEffect(() => {
    const onMouseUp = (e) => {
      if (typeof props.onClose !== "undefined") {
        const target = e.target;
        const editingContainer = target.closest(
          "#language-list-popup, #language-list-popup-btn"
        );

        if (!editingContainer) {
          props.onClose();
        }
      }
    };

    if (typeof props.onClose !== "undefined") {
      document.addEventListener("mouseup", onMouseUp);
    }
    return () => {
      if (typeof props.onClose !== "undefined") {
        document.removeEventListener("mouseup", onMouseUp);
      }
    };
  }, []);

  return (
    <div
      className="column bg-white border-radius-s border-m shadow-m position-absolute pb-s overflow-auto"
      style={{ top: 35, right: -5, width: 180, zIndex: 999999, maxHeight: 390 }}
      id="language-list-popup"
    >
      <DKLabel text="Change Language" className="fw-m fs-m pt-r pb-s p-h-s" />
      {LanguageMenuList.map((obj, index) => {
        return (
          <div
            key={index}
            className="row listPickerBG cursor-hand parent-width border-box"
            style={{ padding: 12 }}
            onClick={() => changeLanguage(obj)}
          >
            <DKIcon src={obj.icon} className="ic-r circle" />
            <DKLabel text={obj.title} className="ml-r" />
          </div>
        );
      })}
    </div>
  );
};

export default LanguageList;
