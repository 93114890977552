import { CURRENCYLIST, COUNTRY_CODE } from "../constants/Enum";
import Utility from "../utility/Utility";

export default class CurrencyManager {
  // static currentCurrency = getCountryCodeFromLocation();
  static loactionDetails = null;

  static setCurrentCurrency(currency) {
    CurrencyManager.currentCurrency = currency;
  }
  static setCurrentCountry(country) {
    CurrencyManager.currentCountry = country;
  }

  static getCurrentCurrency() {
    return CurrencyManager.currentCurrency;
  }

  static getCurrentCountry() {
    return CurrencyManager.currentCountry;
  }
  
  static getAllCurrencies() {
    let currencies = [];
    for (const curr in CURRENCYLIST) {
      currencies.push(CURRENCYLIST[curr]);
    }
    return currencies;
  }

}
