import ApiConstants from "../constants/ApiConstants";
import { TRACK_EVENT } from "../constants/Enum";

import http from "../http";
import { trackEventAC } from "../managers/ActiveCampaignWrapper";
import MixPanelManager from "../managers/common/MIxpanelManger";
import CompanyDetailManager from "../managers/CompanyDetailManager";
import UserManager from "../managers/UserManager";
import { localizationText } from "../utility/Localization";
import Utility from "../utility/Utility";
import { removeLoader, showLoader } from "deskera-ui-library";
export const TENANT = {
  COUNTRY_CODE: "COUNTRY_CODE",
  STATE_CODE: "STATE_CODE",
};
export class TenantService {
  static tenantData = {
    COUNTRY_CODE: "",
    STATE_CODE: "",
  };
}
class Tenant {
  static getTenantDetails(params) {
    return http.get(ApiConstants.URL.TENANT.GET_DETAILS, { params }).then(
      (res) => {
        UserManager.updateTenantName(res.name);
        UserManager.updateTenantCreatedDate(res.tenantCreatedDate);
        CompanyDetailManager.updateCompanyName(res.name);
        CompanyDetailManager.setCompanyDetails(res);
        CompanyDetailManager.setCountry(res?.country);
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getClientDetails(params) {
    return http.get(ApiConstants.URL.TENANT.CLIENT_DETAILS, { params }).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static setCompanyName(params) {
    showLoader("Updating company name...");
    return http
      .post(
        ApiConstants.URL.TENANT.COMPANY_NAME.replace("TENANT_ID", params.id),
        {
          newName: params.name,
        }
      )
      .then(
        (res) => {
          UserManager.updateTenantName(params.name);
          CompanyDetailManager.updateCompanyName(params.name);
          removeLoader();
          return Promise.resolve(res);
        },
        (err) => {
          removeLoader();
          return Promise.reject(err);
        }
      );
  }

  static resetCompany() {
    showLoader("Resetting company...");
    return http.delete(ApiConstants.URL.TENANT.RESET_COMPANY).then(
      (res) => {
        removeLoader();
        MixPanelManager.resetCompany();
        Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }

  static deleteCompany(params, callback) {
    showLoader("Deleting company...");
    return http.delete(ApiConstants.URL.TENANT.DELETE_COMPANY + params.id).then(
      (res) => {
        removeLoader();
        MixPanelManager.deleteCompany();
        trackEventAC(
          {
            event: TRACK_EVENT.DELETE_COMPANY,
            eventData: "Company deleted successfully",
            email: UserManager.getUserEmail(),
          },
          (clbk) => {
            if (clbk) {
              callback(true);
            }
          }
        );
      },
      (err) => {
        removeLoader();
        callback(false);
      }
    );
  }

  static getTenantPayment(params) {
    return http.get(ApiConstants.URL.TENANT.PAYMENT, { params }).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getOrgWithoutSetup(params) {
    if (!Utility.isEmpty(params.tenantName)) {
      showLoader(`Creating new ${"organisation"}...`);
      return http
        .post(ApiConstants.URL.TENANT.ORG_WITHOUT_SETUP, {
          tenantName: params.tenantName.trim(),
          isBookkeeper: params.isBookkeeper,
          refreshToken: params.refreshToken,
        })
        .then(
          (res) => {
            removeLoader();
            return Promise.resolve(res);
          },
          (err) => {
            removeLoader();
            return Promise.reject(err);
          }
        );
    } else return;
  }

  static getUsertenants(params) {
    return http.get(ApiConstants.URL.TENANT.TENANTS, { params }).then(
      (res) => {
        CompanyDetailManager.setAllOrgs(res);
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
}

export default Tenant;
