export default class CRMPlusForms {
  static signup_form_data = {
    DEV: {
      form_id: "AHAekPARWZ8pgEbW",
      form_field_first_name: "4xNT",
      form_field_last_name: "J76t",
      form_field_name: "n0dN",
      form_field_email: "Zm0u",
      form_field_phone: "jFRe",
      form_field_organization: "GCEX",
      form_field_no_of_employees: "pFHB",
      form_field_industry_type: "b7kH",
      form_field_job_title: "6Gpr",
      form_field_utm_campaign: "UpAb",
      form_field_gclid: "54aX",
      form_field_utm_medium: "Ld7C",
      form_field_utm_source: "OkGO",
      form_field_source_url: "lKVr",
      form_field_plan_name: "CORR",
      form_field_plan_type: "2yRb",
      form_field_onboarding_status: "FI6T",
      form_field_product: "pRxz",
      form_field_discount_prepaid_code: "diVw",
      form_field_country: "TR6o",
      form_field_singup_process: "h9T7",
      form_field_default_key_a: "XetH",
      form_field_default_key_b: "DGfk",
      form_field_default_key_c: "8TGT",
    },
    QA: {
      form_id: "SwH6WqIzjJSopw4x",
      form_field_first_name: "sDJv",
      form_field_last_name: "6PRd",
      form_field_name: "",
      form_field_email: "XNOM",
      form_field_phone: "QHHB",
      form_field_organization: "Rxiz",
      form_field_no_of_employees: "pFHB",
      form_field_industry_type: "b7kH",
      form_field_job_title: "6Gpr",
      form_field_utm_campaign: "Srz1",
      form_field_gclid: "X8VU",
      form_field_utm_medium: "JYwC",
      form_field_utm_source: "S9iX",
      form_field_source_url: "lKVr",
      form_field_plan_name: "YtRD",
      form_field_plan_type: "ry7n",
      form_field_onboarding_status: "FI6T",
      form_field_product: "9gDw",
      form_field_discount_prepaid_code: "diVw",
      form_field_country: "W0oH",
      form_field_singup_process: "h9T7",
      form_field_default_key_a: "CZa4",
      form_field_default_key_b: "V8bM",
      form_field_default_key_c: "8TGT",
    },
    STAGING: {
      form_id: "r8MhpzQgEnSEpqC5",
      form_field_first_name: "s5kc",
      form_field_last_name: "zsFx",
      form_field_name: "IYRL",
      form_field_email: "hbkE",
      form_field_phone: "tKhD",
      form_field_organization: "3J6J",
      form_field_no_of_employees: "uMtq",
      form_field_industry_type: "EoDn",
      form_field_job_title: "BKNO",
      form_field_utm_campaign: "6AOx",
      form_field_gclid: "jMKA",
      form_field_utm_medium: "ymDT",
      form_field_utm_source: "z34E",
      form_field_source_url: "ZwSI",
      form_field_plan_name: "wNRw",
      form_field_plan_type: "zqaH",
      form_field_onboarding_status: "FI6T",
      form_field_product: "L9wW",
      form_field_discount_prepaid_code: "nmOp",
      form_field_country: "0YBy",
      form_field_singup_process: "eh0G",
      form_field_default_key_a: "qN4y",
      form_field_default_key_b: "LV5G",
      form_field_default_key_c: "0BB0",
    },
    PROD: {
      form_id: "33BUbka6OynIAn61",
      form_field_first_name: "rMbv",
      form_field_last_name: "F7wy",
      form_field_name: "rmsb",
      form_field_email: "hTrl",
      form_field_phone: "5vsq",
      form_field_organization: "j1Oz",
      form_field_no_of_employees: "",
      form_field_industry_type: "",
      form_field_job_title: "",
      form_field_utm_campaign: "OxJU",
      form_field_gclid: "0o7H",
      form_field_utm_medium: "d0c4",
      form_field_utm_source: "3CGy",
      form_field_source_url: "1gRh",
      form_field_plan_name: "JmfO",
      form_field_plan_type: "sL66",
      form_field_onboarding_status: "FI6T",
      form_field_product: "DJ5j",
      form_field_discount_prepaid_code: "uegR",
      form_field_country: "W0oH",
      form_field_singup_process: "1uqX",
      form_field_default_key_a: "8XCi",
      form_field_default_key_b: "L4wV",
      form_field_default_key_c: "6MZL",
    },
  };
  static signup_form_data_new = {
    DEV: {
      form_id: "AHAekPARWZ8pgEbW",
      form_field_first_name: "4xNT",
      form_field_last_name: "J76t",
      form_field_name: "n0dN",
      form_field_email: "Zm0u",
      form_field_phone: "jFRe",
      form_field_organization: "GCEX",
      form_field_no_of_employees: "pFHB",
      form_field_industry_type: "b7kH",
      form_field_job_title: "6Gpr",
      form_field_utm_campaign: "UpAb",
      form_field_gclid: "54aX",
      form_field_utm_medium: "Ld7C",
      form_field_utm_source: "OkGO",
      form_field_source_url: "lKVr",
      form_field_plan_name: "CORR",
      form_field_plan_type: "2yRb",
      form_field_onboarding_status: "FI6T",
      form_field_product: "pRxz",
      form_field_discount_prepaid_code: "diVw",
      form_field_country: "TR6o",
      form_field_singup_process: "h9T7",
      form_field_default_key_a: "XetH",
      form_field_default_key_b: "DGfk",
      form_field_default_key_c: "8TGT",
    },
    QA: {
      form_id: "SwH6WqIzjJSopw4x",
      form_field_first_name: "sDJv",
      form_field_last_name: "6PRd",
      form_field_name: "",
      form_field_email: "XNOM",
      form_field_phone: "QHHB",
      form_field_organization: "Rxiz",
      form_field_no_of_employees: "pFHB",
      form_field_industry_type: "b7kH",
      form_field_job_title: "6Gpr",
      form_field_utm_campaign: "Srz1",
      form_field_gclid: "X8VU",
      form_field_utm_medium: "JYwC",
      form_field_utm_source: "S9iX",
      form_field_source_url: "lKVr",
      form_field_plan_name: "YtRD",
      form_field_plan_type: "ry7n",
      form_field_onboarding_status: "FI6T",
      form_field_product: "9gDw",
      form_field_discount_prepaid_code: "diVw",
      form_field_country: "W0oH",
      form_field_singup_process: "h9T7",
      form_field_default_key_a: "CZa4",
      form_field_default_key_b: "V8bM",
      form_field_default_key_c: "8TGT",
    },
    STAGING: {
      form_id: "r8MhpzQgEnSEpqC5",
      form_field_first_name: "s5kc",
      form_field_last_name: "zsFx",
      form_field_name: "IYRL",
      form_field_email: "hbkE",
      form_field_phone: "tKhD",
      form_field_organization: "3J6J",
      form_field_no_of_employees: "uMtq",
      form_field_industry_type: "EoDn",
      form_field_job_title: "BKNO",
      form_field_utm_campaign: "6AOx",
      form_field_gclid: "jMKA",
      form_field_utm_medium: "ymDT",
      form_field_utm_source: "z34E",
      form_field_source_url: "ZwSI",
      form_field_plan_name: "wNRw",
      form_field_plan_type: "zqaH",
      form_field_onboarding_status: "FI6T",
      form_field_product: "L9wW",
      form_field_discount_prepaid_code: "nmOp",
      form_field_country: "0YBy",
      form_field_singup_process: "eh0G",
      form_field_default_key_a: "qN4y",
      form_field_default_key_b: "LV5G",
      form_field_default_key_c: "0BB0",
    },
    PROD: {
      form_id: "fXrU5mwPfAhkQNNA",
      form_field_first_name: "6NPe",
      form_field_last_name: "y1Lp",
      form_field_name: "YHhZ",
      form_field_email: "NYbH",
      form_field_phone: "TS1b",
      form_field_organization: "mhdU",
      form_field_no_of_employees: "",
      form_field_industry_type: "",
      form_field_job_title: "",
      form_field_utm_campaign: "CPMC",
      form_field_gclid: "d8R2",
      form_field_utm_medium: "Mxx1",
      form_field_utm_source: "UTCy",
      form_field_source_url: "lI8g",
      form_field_plan_name: "w90x",
      form_field_plan_type: "AohR",
      form_field_onboarding_status: "FI6T",
      form_field_product: "IFPM",
      form_field_discount_prepaid_code: "gg5i",
      form_field_country: "Idm3",
      form_field_singup_process: "XwWC",
      form_field_default_key_a: "TYoo",
      form_field_default_key_b: "VAaM",
      form_field_default_key_c: "isqD",
    },
  };
  static signup_form_data_ind = {
    DEV: {
      form_id: "AHAekPARWZ8pgEbW",
      form_field_first_name: "4xNT",
      form_field_last_name: "J76t",
      form_field_name: "n0dN",
      form_field_email: "Zm0u",
      form_field_phone: "jFRe",
      form_field_organization: "GCEX",
      form_field_no_of_employees: "pFHB",
      form_field_industry_type: "b7kH",
      form_field_job_title: "6Gpr",
      form_field_utm_campaign: "UpAb",
      form_field_gclid: "54aX",
      form_field_utm_medium: "Ld7C",
      form_field_utm_source: "OkGO",
      form_field_source_url: "lKVr",
      form_field_plan_name: "CORR",
      form_field_plan_type: "2yRb",
      form_field_onboarding_status: "FI6T",
      form_field_product: "pRxz",
      form_field_discount_prepaid_code: "diVw",
      form_field_country: "TR6o",
      form_field_singup_process: "h9T7",
      form_field_default_key_a: "XetH",
      form_field_default_key_b: "DGfk",
      form_field_default_key_c: "8TGT",
    },
    QA: {
      form_id: "SwH6WqIzjJSopw4x",
      form_field_first_name: "sDJv",
      form_field_last_name: "6PRd",
      form_field_name: "",
      form_field_email: "XNOM",
      form_field_phone: "QHHB",
      form_field_organization: "Rxiz",
      form_field_no_of_employees: "pFHB",
      form_field_industry_type: "b7kH",
      form_field_job_title: "6Gpr",
      form_field_utm_campaign: "Srz1",
      form_field_gclid: "X8VU",
      form_field_utm_medium: "JYwC",
      form_field_utm_source: "S9iX",
      form_field_source_url: "lKVr",
      form_field_plan_name: "YtRD",
      form_field_plan_type: "ry7n",
      form_field_onboarding_status: "FI6T",
      form_field_product: "9gDw",
      form_field_discount_prepaid_code: "diVw",
      form_field_country: "W0oH",
      form_field_singup_process: "h9T7",
      form_field_default_key_a: "CZa4",
      form_field_default_key_b: "V8bM",
      form_field_default_key_c: "8TGT",
    },
    STAGING: {
      form_id: "r8MhpzQgEnSEpqC5",
      form_field_first_name: "s5kc",
      form_field_last_name: "zsFx",
      form_field_name: "IYRL",
      form_field_email: "hbkE",
      form_field_phone: "tKhD",
      form_field_organization: "3J6J",
      form_field_no_of_employees: "uMtq",
      form_field_industry_type: "EoDn",
      form_field_job_title: "BKNO",
      form_field_utm_campaign: "6AOx",
      form_field_gclid: "jMKA",
      form_field_utm_medium: "ymDT",
      form_field_utm_source: "z34E",
      form_field_source_url: "ZwSI",
      form_field_plan_name: "wNRw",
      form_field_plan_type: "zqaH",
      form_field_onboarding_status: "FI6T",
      form_field_product: "L9wW",
      form_field_discount_prepaid_code: "nmOp",
      form_field_country: "0YBy",
      form_field_singup_process: "eh0G",
      form_field_default_key_a: "qN4y",
      form_field_default_key_b: "LV5G",
      form_field_default_key_c: "0BB0",
    },
    PROD: {
      form_id: "guhl5vRS0M12cuWx",
      form_field_first_name: "dydn",
      form_field_last_name: "31jo",
      form_field_name: "D0eS",
      form_field_email: "ncE9",
      form_field_phone: "MucM",
      form_field_organization: "KJSj",
      form_field_no_of_employees: "",
      form_field_industry_type: "",
      form_field_job_title: "",
      form_field_utm_campaign: "HtsK",
      form_field_gclid: "4tSG",
      form_field_utm_medium: "W5QD",
      form_field_utm_source: "hUCJ",
      form_field_source_url: "FYM9",
      form_field_plan_name: "z5oY",
      form_field_plan_type: "7Gor",
      form_field_onboarding_status: "55uD",
      form_field_product: "VPM3",
      form_field_discount_prepaid_code: "lDkZ",
      form_field_country: "MgmL",
      form_field_singup_process: "72Sr",
      form_field_default_key_a: "8W8r",
      form_field_default_key_b: "AxnL",
      form_field_default_key_c: "XOwV",
    },
  };
}
