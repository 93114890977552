import React from "react";

import { DKButton, DKLabel, DKInput } from "deskera-ui-library";

import IAM from "../../services/iam";
import Tenant from "../../services/tenant";
import { getCapitalized } from "../../utility/Utility";
import UserManager from "../../managers/UserManager";
import { localizationText } from "../../utility/Localization";

class AddOrgPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orgName: "",
      saveOrgTapped: false,
    };
  }

  cancelTapped = () => {
    this.setState(
      {
        orgName: "",
      },
      () => {
        this.props.onCancel();
      }
    );
  };

  addTapped = () => {
    this.setState({ saveOrgTapped: true });
    if (this.state.orgName.trim() === "") {
      return;
    }

    Tenant.getOrgWithoutSetup({
      tenantName: this.state.orgName,
      isBookkeeper: false,
      refreshToken: "refreshToken",
    }).then((res) => {
      IAM.switchTenant({ tenantId: res.tenantId }).then((res) => {
        window.location.reload();
      });
    });
  };

  render() {
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ maxWidth: 400 }}>
          <div className="row justify-content-between">
            <DKLabel text={`Add ${"organisation"}`} className="fw-m fs-l" />
            <div>
              <div className="row">
                {UserManager.isOrgSet() && (
                  <DKButton
                    title="Cancel"
                    className="bg-gray1 border-m fw-m"
                    onClick={this.cancelTapped}
                  />
                )}
                <DKButton
                  title="Add"
                  className="bg-blue ml-r text-white fw-m"
                  onClick={this.addTapped}
                />
              </div>
            </div>
          </div>

          <div className="column mt-l parent-width">
            <div
              className="row parent-width"
              style={{ alignItems: "flex-start" }}
            >
              <DKInput
                name="Company name"
                placeholder="Company name"
                required
                value={this.state.orgName}
                onChange={(value) => {
                  this.setState({ orgName: getCapitalized(value) });
                }}
                canValidate={this.state.saveOrgTapped}
                invalid={
                  this.state.saveOrgTapped && this.state.orgName.trim() === ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddOrgPopup;
