export const MIX_PANEL_EVENTS = {
    GO_OPEN: `Opened Go`,
    PLAN_TRIAL_START: `Trial Started`,
    PLAN_BUY: `Plan Buy`,
    PLAN_CHANGE: `Plan changed`,
    PLAN_CANCEL: `Cancel Plan`,
    PLAN_BUY_FAILED: `Plan Buy Fail`,
    INVOICE_VIEW: `Invoice view`,
    CARD_SAVE: `Card Add`,
    MFA_ENABLED: `Multi Factor Authentication Enabled`,
    MFA_DISABLED: `Multi Factor Authentication Disabled`,
    USER_ADD: `User Added`,
    USER_UPDATE: `User Update`,
    USER_DELETE: `User Delete`,
    RESET_ORG: `Organization Reset`,
    DELETE_COMPANY: `Company Deleted`,
    USER_PERMISSION_CHANGED: `Role Chang`,
    USER_APP_ACCESS_CHANGED: `App Access Chang`,
    PASSWORD_CHANGE: `Password Chang`,
}