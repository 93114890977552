import {isViewportLarge} from "../utility/ViewportSizeUtils";
import Utility from "../utility/Utility";

class SideBarService {
    static sideBarExpandedWidth = 230;
    static sideBarCollapsedWidth = 0;
    static isSideBarExpanded = true;

    static setIsSideBarExpanded() {
        if(isViewportLarge()) {
            SideBarService.isSideBarExpanded = true;
        } else {
            SideBarService.isSideBarExpanded = (Utility.getPersistentValue('isSideBarExpanded')?.toString() === 'true') || false;
        }
    }

    static triggerSidebarChangeEvent() {
        dispatchEvent(new CustomEvent('onSideBarChange', { detail: { isExpanded: SideBarService.isSideBarExpanded } }));
    }

    static toggleSideBar = () => {
        SideBarService.isSideBarExpanded = !SideBarService.isSideBarExpanded;
        SideBarService.triggerSidebarChangeEvent();
        Utility.setPersistentValue(SideBarService.isSideBarExpanded,'isSideBarExpanded');
    }

    static getContainerWidth = (): number => {
        if(isViewportLarge()) {
            return window.innerWidth - SideBarService.sideBarExpandedWidth - 48;
        } else {
            return window.innerWidth * 0.92;    /* as 4% padding present on mainContainer */
        }
    }

    static getCurrentMenuWidth = (): number => {
        return SideBarService.isSideBarExpanded ? SideBarService.sideBarExpandedWidth : SideBarService.sideBarCollapsedWidth;
    }
}

export default SideBarService;
