import Utility from "../utility/Utility";
import DataParser from "../Helper/DataParser";

class CouponsManager {
  static discountCoupons = [];
  static prepaidCoupons = [];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  static setDiscountCoupons(discountCoupons) {
    this.discountCoupons = discountCoupons;
  }
  static getDiscountCoupons() {
    return this.discountCoupons;
  }
  static getAppliedDiscountCoupons() {
    return this.discountCoupons.find(
      (coupon) =>
        coupon.Apply && coupon.MaxApplyCount > coupon.CurrentApplyCount
    );
  }
  static isDiscountCouponApplied() {
    return !Utility.isEmpty(this.getAppliedDiscountCoupons());
  }

  static getDiscountedPrice(actualPrice, product, interval) {
    let discountedPrice = actualPrice;
    if (CouponsManager.isDiscountCouponApplied()) {
      let couponDetails = CouponsManager.getAppliedDiscountCoupons();

      let isProductValid =
        couponDetails.ProductApplicable === "" ||
        couponDetails.ProductApplicable.toLowerCase() === product.toLowerCase();
      let isIntervalValid =
        couponDetails.PlanIntervalApplicable === "" ||
        couponDetails.PlanIntervalApplicable.toLowerCase() ===
          interval.toLowerCase();

      if (isProductValid && isIntervalValid) {
        let percentOff = couponDetails.PercentOff;
        let amountOff = couponDetails.AmountOff;
        if (percentOff > 0) {
          discountedPrice = actualPrice - (actualPrice * percentOff) / 100;
        } else {
          if (amountOff > 0) {
            discountedPrice = actualPrice - amountOff;
          }
        }
      }
    }
    return discountedPrice;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  static setPrepaidCoupons(prepaidCoupons) {
    this.prepaidCoupons = prepaidCoupons;
  }
  static getPrepaidCoupons() {
    return this.prepaidCoupons;
  }
  static getAppliedPrepaidCoupons() {
    return this.prepaidCoupons.find((coupon) => coupon.Apply);
  }
  static isPrepaidCouponApplied() {
    return !Utility.isEmpty(this.getAppliedPrepaidCoupons());
  }

  static isVendorPrepaidCouponApplied() {
    return (
      CouponsManager.isPrepaidCouponApplied() &&
      CouponsManager.getAppliedPrepaidCoupons().PaymentDisallowed
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  static getCouponListForEachPlan(nextChargeDate, prepaidList, plan){
    let erpArrayDate= [];
    let crmArrayDate= [];
    let pplArrayDate= [];
    let aoiArrayDate = [];
    erpArrayDate.push(new Date(nextChargeDate));
    crmArrayDate.push(new Date(nextChargeDate));
    pplArrayDate.push(new Date(nextChargeDate));
    aoiArrayDate.push(new Date(nextChargeDate));

    let erpDuration = [];
    let crmDuration = [];
    let pplDuration = [];
    let aioDuration = [];

    for (var i = 0; i < prepaidList.length; i++) {
      for(var x = 0; x < prepaidList[i].Products.length; x++) {
        if(prepaidList[i].Products[x].Name === "ERP") {
          let newArray = this.getNewDateArray(new Date(prepaidList[i].RedeemedDate), prepaidList[i].PrepaidDuration);
          erpDuration.push(prepaidList[i].PrepaidDuration)
          erpArrayDate.push(newArray);
        } else if(prepaidList[i].Products[x].Name === "CRM"){
          let newArray = this.getNewDateArray(new Date(prepaidList[i].RedeemedDate), prepaidList[i].PrepaidDuration);
          crmDuration.push(prepaidList[i].PrepaidDuration)
          crmArrayDate.push(newArray);
        } else if(prepaidList[i].Products[x].Name === "PEOPLE") {
          let newArray = this.getNewDateArray(new Date(prepaidList[i].RedeemedDate), prepaidList[i].PrepaidDuration);
          pplDuration.push(prepaidList[i].PrepaidDuration)
          pplArrayDate.push(newArray);
        } else {
          let newArray = this.getNewDateArray(new Date(prepaidList[i].RedeemedDate), prepaidList[i].PrepaidDuration);
          aioDuration.push(prepaidList[i].PrepaidDuration)
          aoiArrayDate.push(newArray);
        }
      }
    }
    if (plan === "erp") {
      if(Math.max.apply(null, erpDuration) >= 1200) {
        return "Lifetime"
      } else {
        return this.getMaxDate(erpArrayDate)
      }
    } else if (plan === "crm") {
      if(Math.max.apply(null, crmDuration) >= 1200) {
        return "Lifetime"
      } else {
        return this.getMaxDate(crmArrayDate)
      }
    } else if (plan === "people") {
      if(Math.max.apply(null, pplDuration) >= 1200) {
        return "Lifetime"
      } else {
        return this.getMaxDate(pplArrayDate)
      }
    } else {
      if(Math.max.apply(null, aioDuration) >= 1200) {
        return "Lifetime"
      } else {
        return this.getMaxDate(aoiArrayDate)
      }
    }
  }

  static getNewDateArray(redeemDate, prepaidDuration) {
    let arrayDate= [];

    let prepaidDate = DataParser.getNextChargedDate(redeemDate, prepaidDuration, true)
    arrayDate.push(prepaidDate);
    return prepaidDate;
  }

  static getMaxDate(date) {
    var maximumDate=Math.max.apply(null, date);
    return DataParser.getBillDateFormat(new Date(maximumDate)) 
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
}

export default CouponsManager;
