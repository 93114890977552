export const planDetails = {
  discount: 50,
  erp: {
    startup: {
      description:
        "Ideal for startups. Send invoices, track bills and expenses, and generate financial statements.",
      features: [
        "Startup",
        "Create 30 invoices & bills",
        "Create recurring invoices",
        "Collect payment online",
        "Connect your bank",
        "Invite your accountant",
        "Add free users",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 49, monthly: 59 },
        INR: { yearly: 599, monthly: 699 },
        IDR: { yearly: 110000, monthly: 130000 },
        MYR: { yearly: 15, monthly: 20 },
      },
      planID: {
        yearly: process.env.REACT_APP_ERP_YEARLY_PLAN_S,
        monthly: process.env.REACT_APP_ERP_MONTHLY_PLAN_S,
      },
    },
    essential: {
      description:
        "Ideal for growing businesses and SMBs. Send more invoices, fulfill orders, track inventory.",
      features: [
        "Unlimited invoices and bills",
        "Create recurring invoices",
        "Collect payment online",
        "Connect your bank",
        "Multi Currency",
        "Invite your accountant",
        "Add free users",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 99, monthly: 119 },
        INR: { yearly: 1699, monthly: 1999 },
        IDR: { yearly: 199000, monthly: 240000 },
        MYR: { yearly: 35, monthly: 45 },
      },
      planID: {
        yearly: process.env.REACT_APP_ERP_YEARLY_PLAN_E,
        monthly: process.env.REACT_APP_ERP_MONTHLY_PLAN_E,
      },
    },
    professional: {
      description:
        "A powerful solution for advanced users! Use custom fields to track data and create reports.",
      features: [
        "Unlimited invoices and bills",
        "Create recurring invoices",
        "Collect payment online",
        "Connect your bank",
        "Multi Currency",
        "Add your own custom fields",
        "Get complete inventory management",
        "Invite your accountant",
        "Add free users",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 219, monthly: 259 },
        INR: { yearly: 3499, monthly: 3999 },
        IDR: { yearly: 299000, monthly: 360000 },
        MYR: { yearly: 70, monthly: 85 },
      },
      planID: {
        yearly: process.env.REACT_APP_ERP_YEARLY_PLAN_P,
        monthly: process.env.REACT_APP_ERP_MONTHLY_PLAN_P,
      },
    },
  },
  crm: {
    startup: {
      description:
        "Ideal for startups. Add contacts, close deals, and build sales pipelines.",
      features: [
        "Add contacts and deals",
        "Manage all sales conversation",
        "Create your own sales pipelines",
        "Track sales activities",
        "Add free users",
        "Run Email Marketing campaigns",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 39, monthly: 49 },
        INR: { yearly: 499, monthly: 599 },
        IDR: { yearly: 99000, monthly: 120000 },
        MYR: { yearly: 15, monthly: 20 },
      },
      planID: {
        yearly: process.env.REACT_APP_CRM_YEARLY_PLAN_S,
        monthly: process.env.REACT_APP_CRM_MONTHLY_PLAN_S,
      },
    },
    essential: {
      description:
        "Ideal for growing businesses and SMBs. Get the power of custom fields.",
      features: [
        "Add contacts and deals",
        "Manage all sales conversation",
        "Create your sales pipelines",
        "Track sales activities",
        "Run email campaigns",
        "Add free users",
        "Custom Fields",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 79, monthly: 99 },
        INR: { yearly: 999, monthly: 1099 },
        IDR: { yearly: 150000, monthly: 180000 },
        MYR: { yearly: 35, monthly: 45 },
      },
      planID: {
        yearly: process.env.REACT_APP_CRM_YEARLY_PLAN_E,
        monthly: process.env.REACT_APP_CRM_MONTHLY_PLAN_E,
      },
    },
    professional: {
      description:
        "For email marketing at scale. This plan allows you to expand your contact list and the number of emails sent.",
      features: [
        "Add contacts and deals",
        "Manage all sales conversation",
        "Create your sales pipelines",
        "Track sales activities",
        "Run email campaigns",
        "Setup your personalized helpdesk",
        "Add free users",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 199, monthly: 229 },
        INR: { yearly: 1499, monthly: 1999 },
        IDR: { yearly: 199000, monthly: 240000 },
        MYR: { yearly: 70, monthly: 85 },
      },
      planID: {
        yearly: process.env.REACT_APP_CRM_YEARLY_PLAN_P,
        monthly: process.env.REACT_APP_CRM_MONTHLY_PLAN_P,
      },
    },
  },
  people: {
    startup: {
      description:
        "Ideal for startups. Automate payroll processing, set up pay schedule, invite employees, and send e-Payslips.",
      features: [
        "Add up to 5 employees for free",
        "Process weekly, bi-Weekly & monthly payroll",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 39, monthly: 49 },
        INR: { yearly: 499, monthly: 599 },
        IDR: { yearly: 99000, monthly: 120000 },
        MYR: { yearly: 35, monthly: 45 },
      },
      planID: {
        yearly: process.env.REACT_APP_PEOPLE_YEARLY_PLAN_S,
        monthly: process.env.REACT_APP_PEOPLE_MONTHLY_PLAN_S,
      },
    },
    essential: {
      description:
        "Ideal for growing businesses and SMBs. Run payroll for more employees. Setup your leave rules.",
      features: [
        "Add up to 10 employees for free",
        "Process weekly, bi-Weekly & monthly payroll",
        "Design your payslip",
        "Apply and approve leaves",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 79, monthly: 99 },
        INR: { yearly: 999, monthly: 1099 },
        IDR: { yearly: 150000, monthly: 180000 },
        MYR: { yearly: 50, monthly: 60 },
      },
      planID: {
        yearly: process.env.REACT_APP_PEOPLE_YEARLY_PLAN_E,
        monthly: process.env.REACT_APP_PEOPLE_MONTHLY_PLAN_E,
      },
    },
    professional: {
      description:
        "Run payroll, track leave & attendance, and integrated expense reporting.",
      features: [
        "Add up to 15 employees for free",
        "Process weekly, bi-Weekly & monthly payroll",
        "Design your payslip",
        "Apply and approve leaves",
        "Submit and approve expenses",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 199, monthly: 229 },
        INR: { yearly: 1499, monthly: 1999 },
        IDR: { yearly: 199000, monthly: 240000 },
        MYR: { yearly: 90, monthly: 110 },
      },
      planID: {
        yearly: process.env.REACT_APP_PEOPLE_YEARLY_PLAN_P,
        monthly: process.env.REACT_APP_PEOPLE_MONTHLY_PLAN_P,
      },
    },
  },
  aio: {
    startup: {
      description:
        "The perfect choice for your new business. Setup integrated Accounting, CRM and Payroll in minutes.",
      features: [
        "All In One Dashboard",
        "Create 30 invoices & bills",
        "Connect your banks",
        "Run Payroll for 5 employees",
        "Create your own sales pipelines",
        "Run Email Marketing campaigns",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 99, monthly: 109 },
        INR: { yearly: 1199, monthly: 1499 },
        IDR: { yearly: 220000, monthly: 265000 },
        MYR: { yearly: 50, monthly: 60 },
      },
      planID: {
        yearly: process.env.REACT_APP_AIO_YEARLY_PLAN_S,
        monthly: process.env.REACT_APP_AIO_MONTHLY_PLAN_S,
      },
    },
    essential: {
      description:
        "The most popular choice for SMBs and growing companies. Suitable for growing businesses.",
      features: [
        "All In One Dashboard",
        "Unlimited invoices and bills",
        "Connect your banks",
        "Multi currency",
        "Run payroll for 10 employees",
        "Apply and approve leaves",
        "Create your own sales pipelines",
        "Run email campaigns",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 199, monthly: 249 },
        INR: { yearly: 2999, monthly: 3499 },
        IDR: { yearly: 349000, monthly: 420000 },
        MYR: { yearly: 90, monthly: 110 },
      },
      planID: {
        yearly: process.env.REACT_APP_AIO_YEARLY_PLAN_E,
        monthly: process.env.REACT_APP_AIO_MONTHLY_PLAN_E,
      },
    },
    professional: {
      description:
        "The ideal choice for companies that need advanced features and custom reporting.",
      features: [
        "All In One Dashboard",
        "Unlimited invoices and bills",
        "Connect your banks",
        "Multi currency",
        "Add your own custom fields",
        "Get complete inventory management",
        "Run payroll for 15 employees",
        "Apply and approve leaves & expenses",
        "Create your own sales pipelines",
        "Run email campaigns",
        "Setup your personalized helpdesk",
        "Email & chat support",
        "Access to Helpcentre & Deskera Academy",
      ],
      pricing: {
        USD: { yearly: 499, monthly: 599 },
        INR: { yearly: 5999, monthly: 6599 },
        IDR: { yearly: 499000, monthly: 599000 },
        MYR: { yearly: 180, monthly: 220 },
      },
      planID: {
        yearly: process.env.REACT_APP_AIO_YEARLY_PLAN_P,
        monthly: process.env.REACT_APP_AIO_MONTHLY_PLAN_P,
      },
    },
  },
  erp_v2: {
    startup: {
      description: "Ideal for businesses with 0-15 employees",
      descriptionObj: {
        IN: "Ideal for businesses with 0-15 employees",
        US: "Ideal for businesses with 0-5 employees",
      },
      billingInfo: "per month billed annually",
      users: {
        IN: "1",
        US: "2",
      },
      features: [],
      pricing: {
        INR: { yearly: 2900 },
        USD: { yearly: 250 },
      },
      planID: {
        IN: {
          yearly: process.env.REACT_APP_ERP_SMALL_BUSINESS_IN,
        },
        US: {
          yearly: process.env.REACT_APP_ERP_SMALL_BUSINESS_US,
        },
      },
    },
    essential: {
      description: "Ideal for businesses with 16-50 employees",
      descriptionObj: {
        IN: "Ideal for businesses with 16-50 employees",
        US: "Ideal for businesses with 5-20 employees",
      },
      billingInfo: "per month billed annually",
      users: {
        IN: "2",
        US: "5",
      },
      features: [],
      pricing: {
        INR: { yearly: 9500 },
        USD: { yearly: 1000 },
      },
      planID: {
        IN: {
          yearly: process.env.REACT_APP_ERP_GROWTH_IN,
        },
        US: {
          yearly: process.env.REACT_APP_ERP_GROWTH_US,
        },
      },
    },
    professional: {
      description: "Ideal for businesses with 51-100 employees",
      descriptionObj: {
        IN: "Ideal for businesses with 51-100 employees",
        US: "Ideal for businesses with 20-50 employees",
      },
      billingInfo: "per month billed annually",
      users: {
        IN: "5",
        US: "10",
      },
      features: [],
      pricing: {
        INR: { yearly: 19500 },
        USD: { yearly: 2000 },
      },
      planID: {
        IN: {
          yearly: process.env.REACT_APP_ERP_MID_MARKET_IN,
        },
        US: {
          yearly: process.env.REACT_APP_ERP_MID_MARKET_US,
        },
      },
    },
  },
  mrp: {
    startup: {
      description: "",
      descriptionObj: {
        IN: "",
        US: "",
      },
      billingInfo: "per month billed annually",
      users: {
        IN: "",
        US: "",
      },
      features: [],
      pricing: {
        INR: { yearly: 0 },
        USD: { yearly: 0 },
      },
      planID: {
        IN: {
          yearly: process.env.REACT_APP_ERP_SMALL_BUSINESS_IN,
        },
        US: {
          yearly: process.env.REACT_APP_ERP_SMALL_BUSINESS_US,
        },
      },
    },
    essential: {
      description: "",
      descriptionObj: {
        IN: "",
        US: "Ideal for businesses with 5-20 employees",
      },
      billingInfo: "per month billed annually",
      users: {
        IN: "",
        US: "5",
      },
      features: [],
      pricing: {
        INR: { yearly: 0 },
        USD: { yearly: 1800 },
      },
      planID: {
        IN: {
          yearly: process.env.REACT_APP_ERP_GROWTH_IN,
        },
        US: {
          yearly: process.env.REACT_APP_ERP_GROWTH_US,
        },
      },
    },
    professional: {
      description: "",
      descriptionObj: {
        IN: "Ideal for businesses with 5-50 employees",
        US: "Ideal for businesses with 20-50 employees",
      },
      billingInfo: "per month billed annually",
      users: {
        IN: "5",
        US: "10",
      },
      features: [],
      pricing: {
        INR: { yearly: 35000 },
        USD: { yearly: 2900 },
      },
      planID: {
        IN: {
          yearly: process.env.REACT_APP_ERP_MID_MARKET_IN,
        },
        US: {
          yearly: process.env.REACT_APP_ERP_MID_MARKET_US,
        },
      },
    },
  },
};
export const planDetailsV2 = {
  erp: {
    description: {},
    pricing: {
      in: 599,
      us: 99,
    },
  },
  erp_plus: {
    description: {},
    pricing: {
      in: 1699,
      us: 199,
    },
  },
  mrp_plus: {
    description: {},
    pricing: {
      in: 6999,
      us: 299,
    },
  },
};
