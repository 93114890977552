import { IntercomAPI } from "react-intercom";

export default class IntercomManager {
  static trackEvent(name, value) {
    if (window.Intercom) {
      IntercomAPI("trackEvent", name, value);
      // console.log(
      //   "Intercom event - " + name + " - Value - " + JSON.stringify(value)
      // );
    }
  }
}
