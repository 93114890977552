import Activecampaign from "../services/activecampaign";

const instanceEventTrack = new Activecampaign();

export const trackEventAC = (data, callback) => {
  const trackEventData = {
    event: data.event,
    eventData: data.eventData,
    email: data.email,
  };
  instanceEventTrack.eventTracking(trackEventData, callback);
};

export const createOrUpdateContact = (data) => {
  const createContactData = {
    email: data.contact.email,
    firstName: data.contact.firstName,
    lastName: data.contact.lastName,
    phone: data.contact.phone,
    fieldValues: data.contact.fieldValues,
  };
  instanceEventTrack.createOrUpdateContact(createContactData);
};
