import {
	DKDataGrid,
	DKLabel,
	INPUT_TYPE,
	DKIcon,
	DKIcons,
} from 'deskera-ui-library';
import SideBarService from '../../services/sidebar';

const BillingHistory = ({ creditData }) => {
	const getColumnConfig = () => {
		return [
			...COLUMN_CONFIG,
			{
				options: [],
				key: 'actions',
				name: '',
				type: INPUT_TYPE.BUTTON,
				width: 150,
			},
		];
	};

	const onRowUpdate = (data) => {};

	const getDataGrid = () => {
		return (
			<DKDataGrid
				title="Credit History"
				rows={creditData ?? []}
				width={SideBarService.getContainerWidth() - 50}
				columns={getColumnConfig()}
				buttons={[]}
				onRowUpdate={onRowUpdate}
				allowBulkOperation={false}
				allowColumnEdit={false}
				needShadow={false}
				needBorder={true}
				needTrailingColumn={true}
				needColumnIcons={false}
				allowColumnSort={false}
			/>
		);
	};

	const getBlankGridView = () => {
		return (
			<div className="column parent-size justify-content-center align-items-center mb-l">
				<DKLabel text="No data found" className="fw-m text-gray" />
			</div>
		);
	};

	return (
		<div className="column parent-width flex-1 p-r border-m border-radius-m mt-l bg-white">
			{getDataGrid()}
			{creditData?.length === 0 && getBlankGridView()}
		</div>
	);
};

const COLUMN_CONFIG = [
	{
		id: 'Code',
		key: 'Code',
		name: 'Code',
		type: INPUT_TYPE.TEXT,
		width: 250,
		systemField: true,
		editable: false,
		hidden: false,
		uiVisible: true,
	},
	{
		id: 'AppliedDate',
		key: 'AppliedDate',
		name: 'Applied Date',
		type: INPUT_TYPE.DATE,
		width: 150,
		systemField: true,
		editable: false,
		hidden: false,
		uiVisible: true,
	},
	{
		id: 'CreditsAvailable',
		key: 'CreditsAvailable',
		name: 'Credits Available',
		type: INPUT_TYPE.NUMBER,
		width: 180,
		systemField: true,
		editable: false,
		hidden: false,
		uiVisible: true,
	},
];

export default BillingHistory;
