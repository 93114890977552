import React, { Component } from "react";
import ic_logout from "../../assets/icons/ic_logout.png";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import Tenant from "../../services/tenant";
import CompanyDetailManager from "../../managers/CompanyDetailManager";
import UserManager from "../../managers/UserManager";
import Utility, { getCapitalized } from "../../utility/Utility";
import AddOrgPopup from "../common/AddOrgPopup";
import AppManager from "../../managers/AppManager";
import OrgList from "../common/OrgList";
import Subscription from "../../services/subscription";
import BillingManager from "../../managers/BillingManager";
import CreditCardView from "../common/CreditCardView";
import CouponsManager from "../../managers/CouponsManager";
import PermissionManager from "../../managers/PermissionManager";
import CurrencyManager from "../../managers/CurrencyManager";
import AdyenCardView from "../common/AddAdyenCardUI/AdyenCardView";
import CashfreeView from "../common/CashfreeUI/CashfreeView";
import { isMobileAppWebView } from "../../utility/ViewportSizeUtils";
import { MOBILE_APP_ACTIONS } from "../../constants/Constant";
import ic_home from "../../assets/icons/white/ic_home.png";
import { BOOKKEEPER_FLOW, URL_PARAMS } from "../../constants/Enum";
import { localizationText } from "../../utility/Localization";
import i18n, { LANG } from "../../i18n/i18n";
import {
  DKButton,
  DKLabel,
  removeLoader,
  showLoader,
  DKIcon,
  DKIcons,
} from "deskera-ui-library";
import SideBarService from "../../services/sidebar";
import LanguageList from "./LanguageList";
import { LanguageMenuList } from "./LabelList/LanguageMenuList";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

/*
- isOrgAvailable
*/

class TopMenu extends Component {
  constructor(props) {
    const defaultLanguage = LanguageMenuList.find(
      (lang) => lang.code === props.defaultLangCode
    );
    super(props);
    this.state = {
      needOrgListPicker: false,
      allOrgs: [],
      tenantIds: [],
      allTenantResReceived: false,
      orgBtnTapped: false,
      isCardAdded: true,
      needAddCardView: false,
      pathName: null,
      isBookkeeper: false,
      needLanguageList: false,
      selectedFlag: defaultLanguage,
      currentLanguage: "",
    };

    setTimeout(() => {
      if (!UserManager.isOrgSet()) {
        this.addOrgTapped();
      }
    }, 200);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let userID = !Utility.isEmpty(urlParams.get(URL_PARAMS.USER_ID))
      ? urlParams.get(URL_PARAMS.USER_ID)
      : "";

    let tenantID = !Utility.isEmpty(urlParams.get(URL_PARAMS.TENANT_ID))
      ? urlParams.get(URL_PARAMS.TENANT_ID)
      : "";

    localStorage.setItem(BOOKKEEPER_FLOW.TENANT_ID, tenantID);
    localStorage.setItem(BOOKKEEPER_FLOW.USER_ID, userID);

    this.setState({
      isBookkeeper: tenantID.length > 0 && userID.length > 0 ? true : false,
    });

    if (UserManager.isOrgSet()) {
      Tenant.getUsertenants().then(
        (res) => {
          let orgNames = [],
            ids = [];
          let orgs = CompanyDetailManager.getAllOrgs();
          for (let index = 0; index < orgs.length; index++) {
            orgNames.push(orgs[index].tenantName);
            ids.push(orgs[index].tenantId);
          }

          this.setState(
            {
              allOrgs: orgNames,
              tenantIds: ids,
              allTenantResReceived: true,
            },
            () => {
              if (this.state.orgBtnTapped) {
                this.showListPicker();
                removeLoader();
              }
            }
          );
        },
        (err) => {
          if (this.state.orgBtnTapped) {
            removeLoader();
          }
        }
      );
    }
    if (window && !localStorage.getItem("language")) {
      Subscription.getCountryFromRequest().then((data) => {
        if (data?.country?.iso_code) {
          let currentLanguageTemp = this.getCurrentLanguageBasedOnLocation(
            data?.country?.iso_code
          );
          i18n.changeLanguage(currentLanguageTemp);
          localStorage.setItem("language", currentLanguageTemp);
          this.setState({
            currentLanguage: currentLanguageTemp,
          });
        }
      });
    }
    if (window && localStorage.getItem("language")) {
      i18n.changeLanguage(localStorage.getItem("language"));
    }
    this.setPathName();
    PermissionManager.isSubscriptionAccessible() && this.getCardDetails();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isBookkeeper:
        localStorage.getItem(BOOKKEEPER_FLOW.TENANT_ID).length > 0 &&
        localStorage.getItem(BOOKKEEPER_FLOW.USER_ID).length > 0
          ? true
          : false,
    });
    this.setPathName();
  }
  getCurrentLanguageBasedOnLocation(countryCode) {
    switch (countryCode) {
      case "IN":
        return "en_IN";
      case "MY":
        return "en_GB";
      case "SG":
        return "en_GB";
      case "GB":
        return "en_GB";
      default:
        return "en";
    }
  }
  setPathName() {
    this.setState({ pathName: window.location.pathname });
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.hideListPicker();
    }
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const { t } = this.props;
    return (
      <div
        className={`header-wrapper pt-m pb-m row justify-content-between pr-r pl-r shadow-s z-index-3
      ${isMobileAppWebView() ? " bg-purple " : " bg-white "}`}
      >
        <div className="column">
          <div
            className="dk-sidebar-toggle p-v-s expandable-button cursor-hand z-index-4 column justify-content-center display-only-mobile"
            onClick={() => SideBarService.toggleSideBar()}
          >
            <div className="">
              <DKIcon src={DKIcons.ic_menu} className={`ic-s shadow-s-2`} />
              {isMobileAppWebView() && this.getTenantNameView()}
            </div>
          </div>
        </div>
        {!isMobileAppWebView() &&
          this.state.needOrgListPicker &&
          this.renderListPicker()}
        {this.state.showAddNewOrgPopup && this.showAddNewOrgPopup()}
        <div className="banner-mobile column">{this.getWelcomeUserView()}</div>
        <div className="column" style={{ width: "75%" }}>
          <div className="row justify-content-end">
            {!this.state.isCardAdded &&
              this.state.pathName !== PAGE_ROUTES.BILLING &&
              this.state.pathName !== PAGE_ROUTES.CHECKOUT &&
              !CouponsManager.isPrepaidCouponApplied() &&
              !CouponsManager.isVendorPrepaidCouponApplied() &&
              !BillingManager.isCardDetailsAdded() &&
              !this.state.isBookkeeper &&
              this.getNoCardView()}
            {/* <DKButton
              title="Add Organisation"
              className="bg-blue mr-m text-white display-only-web"
              icon={ic_add}
              onClick={this.addOrgTapped}
            /> */}
            {/* {(UserManager.isUserOwner() || UserManager.isUserAdmin()) && (
              <DKButton
                title="Settings"
                className="bg-gray2 border-m mr-m display-only-web"
                icon={ic_settings}
                onClick={this.settingsTapped}
              />
            )} */}
            {isMobileAppWebView() && this.getMobileAppActions()}
            {/* {!isMobileAppWebView() && (
              <div className="position-relative mr-m">
                <div
                  onClick={this.toggleLanguageList}
                  className="flex items-center cursor-pointer"
                  style={{ width: 30, height: 30 }}
                  id="language-list-popup-btn"
                >
                  <DKIcon
                    src={this.state.selectedFlag.icon}
                    className="circle cursor-hand"
                    style={{ height: 28, width: 28 }}
                  />{" "}
                  <p
                    className="mr-m font-semibold"
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  ></p>
                </div>
                {this.state.needLanguageList && this.getLanguageList()}
              </div>
            )} */}
            {!isMobileAppWebView() && !this.state.isBookkeeper && (
              <DKButton
                title="Log-out"
                className="bg-gray2 border-m"
                icon={ic_logout}
                onClick={this.logoutTapped}
              />
            )}
          </div>
        </div>
        {this.state.needAddCardView && this.getAddCardView()}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  getWelcomeUserView() {
    return (
      <div className="row justify-content-start">
        <DKLabel
          text={`<span class="fs-l">👋</span> Welcome <span class="welcome-message fw-b">${UserManager.getUserName()}</span>`}
        />
      </div>
    );
  }
  getNoCardView() {
    let helloKeywords = [
      "Hi",
      "Hello",
      "Hey",
      "Hola",
      "Bonjour",
      "Salve",
      "Nǐn hǎo",
      "Namaste",
    ];
    let hey = helloKeywords[Math.floor(Math.random() * helloKeywords.length)];
    let message = `👋 ${hey}${t("PAYMENT_DETAILS_ACCESS")} 🙂`;
    if (
      CouponsManager.isDiscountCouponApplied() ||
      CouponsManager.isPrepaidCouponApplied()
    ) {
      message = `👋 ${hey}${t("PAYMENT_DETAILS_UPGRADES")} 🙂`;
    }

    return (
      <div className="mr-m display-only-web pl-r ">
        <div className="row">
          <div className="bg-deskera-secondary border-m border-radius-m">
            <DKLabel className="p-v-s p-h-s" text={message} />
          </div>
          <DKButton
            title={`${
              BillingManager.showCashfreePayment()
                ? t("APP_PAYMENT_DETAILS")
                : t("ADD_CARD")
            }`}
            className="ml-s bg-blue text-white"
            onClick={() => {
              if (
                !CouponsManager.isVendorPrepaidCouponApplied() &&
                BillingManager.showCashfreePayment()
              ) {
                RouteManager.navigateToPage(PAGE_ROUTES.BILLING);
              } else {
                this.showAddCardView();
              }
            }}
          />
        </div>
      </div>
    );
  }

  getAddCardView() {
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ padding: 0 }}>
          {(BillingManager.showStripePayment() ||
            !BillingManager.isDefaultPaymentGateway()) && (
            <CreditCardView
              isPopup={true}
              onCancel={this.hideAddCardView}
              onCardAdded={this.cardAdded}
            />
          )}

          {BillingManager.showAdyenPayment() && (
            <AdyenCardView
              isPopup={true}
              onCancel={this.hideAddCardView}
              onCardAdded={this.cardAdded}
            />
          )}
          {!CouponsManager.isVendorPrepaidCouponApplied() &&
            BillingManager.showCashfreePayment() &&
            // <CashfreeView isPopup={true} onCancel={this.hideAddCardView} />
            RouteManager.navigateToPage(PAGE_ROUTES.BILLING)}
        </div>
      </div>
    );
  }

  cardAdded = () => {
    this.hideAddCardView();
    this.setState({ isCardAdded: true });
  };

  showAddCardView = () => {
    this.setState({ needAddCardView: true });
  };
  hideAddCardView = () => {
    this.setState({ needAddCardView: false });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getCardDetails = () => {
    Subscription.getPaymentMethods()
      .then((res) => {
        let cardInfo = BillingManager.getDefaultPaymentCard();

        if (cardInfo === null || cardInfo === undefined) {
          this.setState({ isCardAdded: false });
        }
      })
      .catch((err) => {});
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  addOrgTapped = () => {
    this.setState({
      showAddNewOrgPopup: true,
    });
  };

  cancelTapped = () => {
    this.setState({
      showAddNewOrgPopup: false,
    });
  };

  showAddNewOrgPopup = () => {
    return <AddOrgPopup onCancel={this.cancelTapped} />;
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  renderListPicker() {
    const { allOrgs, tenantIds } = this.state;
    return (
      <div
        ref={this.setWrapperRef}
        className="position-absolute"
        style={{ top: 46, left: 15 }}
      >
        <OrgList
          allOrgs={allOrgs}
          tenantIds={tenantIds}
          onAddOrgClick={
            PermissionManager.canAddOrg() ? this.addOrgTapped : null
          }
          onClose={this.hideListPicker}
        />
      </div>
    );
  }

  toggleListPicker = () => {
    this.setState({ orgBtnTapped: true });
    if (!this.state.allTenantResReceived) {
      showLoader(`Fetching ${t("ORGANISATIONS")}...`);
      return;
    }
    this.setState({
      needOrgListPicker: !this.state.needOrgListPicker,
    });
  };
  showListPicker = () => {
    this.setState({
      needOrgListPicker: true,
    });
  };
  hideListPicker = () => {
    this.setState({
      needOrgListPicker: false,
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  settingsTapped = () => {
    RouteManager.navigateToPage(PAGE_ROUTES.SETTINGS);
  };

  logoutTapped = () => {
    AppManager.logout();
  };

  getMobileAppActions() {
    return (
      <div
        className="row position-relative ml-m mb-s mt-s mr-s"
        onClick={() => Utility.postMobileAppActions(MOBILE_APP_ACTIONS.HOME)}
      >
        <DKIcon src={ic_home} className="ic-s-2" />
      </div>
    );
  }

  getLanguageList = () => {
    return (
      <LanguageList
        onSelect={this.changeLanguageSelection}
        onClose={this.hideLanguageList}
      />
    );
  };

  changeLanguageSelection = (selected) => {
    this.setState({
      selectedFlag: selected,
      needLanguageList: false,
    });

    // LeftMenuUpdate.sendMessage(true);
  };

  toggleLanguageList = () => {
    this.setState({
      needLanguageList: !this.state.needLanguageList,
    });
  };

  hideLanguageList = () => {
    this.setState({
      needLanguageList: false,
    });
  };
}
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////

export default withTranslation()(TopMenu);
