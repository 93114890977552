/**
 * PROPS:
 *
 *  className: '',
 *  style: {},
 *  accentColor: '#b40030',
 *  titleColor: '#b40030',
 *  icon: '',
 *  header: '',
 *  subHeader: ''
 */

import React from "react";
import { COLORS } from "../../constants/Colors";
import { PRODUCTS } from "../../constants/Enum";
import SVGIcon from "../../constants/SVGIcon";
import { SVGICON } from "../../constants/SVGIconName";
import { DKLabel } from "deskera-ui-library";

export default function PricingHeroTabs({
  product,
  className,
  gridCards,
  style,
  onClick,
  isCenterBadge,
}) {
  const renderCard = (card, index) => (
    <div
      role="presentation"
      className={
        `column flex-1 position-relative business-function-cards cursor-hand ${
          isCenterBadge ? `align-items-center` : ``
        }` + card.className
      }
      style={{
        maxWidth: 230,
        marginLeft: 15,
        marginRight: 15,
        paddingBottom: 30,
      }}
      onClick={() => onClick?.(index)}
      onKeyDown={() => onClick?.(index)}
    >
      <div className="row">
        <SVGIcon
          name={
            card.icon.name
              ? card.icon.name
              : product === PRODUCTS.MRP
              ? SVGICON.MENU.MRP.ICON
              : SVGICON.MENU.ERP.ICON
          }
          color={
            card.accentColor
              ? card.accentColor
              : product === PRODUCTS.MRP
              ? COLORS.mrp
              : COLORS.erp
          }
          className="pricing-tabs-icon "
        />
        {card.header ? (
          <DKLabel
            text={card.header}
            className={`max-width-90 ${
              isCenterBadge ? `text-align-center` : ``
            } fs-xxxl header-neue line-height-19 ml-xl pricing-tabs-text`}
            style={{
              color: card.titleColor || card.accentColor || `#181818`,
              fontWeight: `900`,
              lineHeight: `63px`,
            }}
          />
        ) : null}
      </div>

      {/* {card.subHeader ? (
                    <DKLabel text={card.subHeader} className="max-width-90 mobile-text-center fw-s mt-s" style={{ fontSize: 11 }} />
                ) : null} */}
      <div
        className="header-chip parent-width row position-absolute"
        style={{
          zIndex: 1,
          bottom: 0,
          borderRadius: 0,
          left: 0,
          height: 5,
          backgroundColor: card.accentColor || card.titleColor || `#181818`,
        }}
      ></div>
    </div>
  );

  return gridCards ? (
    <div
      id="pricing-tabs-wrapper"
      className={
        `display-flex parent-width align-items-stretch hidden-scroll-wrapper mt-xxl mobile-p-0 gridCardPhone pricingTabDiv ` +
        (className || ``)
      }
      style={{
        overflow: `auto hidden`,
        justifyContent: isCenterBadge ? `center` : ``,
        ...(style || {}),
        columnGap: "140px",
      }}
    >
      {gridCards?.map(renderCard)}
    </div>
  ) : null;
}
