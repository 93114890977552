import React, { useEffect, useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import {
  DKButton,
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKListPicker2,
  DKLabel,
  showLoader,
  removeLoader,
  DKIcon,
  DKIcons,
  showAlert,
} from "deskera-ui-library";
import { Provider, useSelector } from "react-redux";
import Utility from "../../utility/Utility";
import IAM from "../../services/iam";

import Popup from "./Popup";
import { store } from "../../redux/store";
import Tenant from "../../services/tenant";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import RouteManager from "../../managers/RouteManager";
function AddNewTenant(props) {
  const { t, i18n } = useTranslation();
  const tenantEditData = props?.tenantEdit;
  const [tenantName, setTenantName] = useState(tenantEditData?.name || "");
  const [triggerValidation, setTriggerValidation] = useState(false);
  const onSave = () => {
    if (Utility.isEmpty(tenantName)) {
      setTriggerValidation(true);
      return;
    } else {
      callAddApi();
    }
  };

  const callAddApi = () => {
    showLoader(t("ADDING_ORGANIZATION"));
    Tenant.getOrgWithoutSetup({
      tenantName,
      isBookkeeper: false,
      refreshToken: "refreshToken",
    }).then((res) => {
      IAM.switchTenant({ tenantId: res.tenantId }).then((res) => {
        removeLoader();
        RouteManager.navigateToHome();
        window.location.reload();
      });
    });
  };

  const onClose = () => {
    if (props?.id) {
      const popup = document.getElementById(props.id);
      if (!Utility.isEmpty(popup)) {
        ReactDOM.unmountComponentAtNode(popup);
        popup?.remove();
      }
    }
    props?.onClose?.();
  };
  return (
    <div className="column parent-width">
      <div className="row parent-width">
        <div className="row">
          <DKLabel
            text={`${
              !Utility.isEmpty(tenantEditData)
                ? t("COMPLETE_ORG_SETUP")
                : t("NEW_ORGANIZATION")
            }`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row justify-content-end">
          {Utility.isEmpty(props.id) && (
            <DKButton
              title={t("CANCEL")}
              className="bg-gray1 border-m fw-m"
              onClick={onClose}
            />
          )}
          <DKButton
            title={tenantEditData ? t("SAVE") : t("ADD")}
            className="bg-button ml-r text-white fw-m"
            onClick={() => onSave(tenantEditData ? true : false)}
          />
        </div>
      </div>
      <div className="row mt-l">
        <DKInput
          autoFocus={Utility.isEmpty(tenantEditData)}
          type={INPUT_TYPE.TEXT}
          className="parent-width"
          title={t("COMPANY_NAME")}
          readOnly={!Utility.isEmpty(tenantEditData)}
          required
          value={tenantName}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(text) => {
            setTriggerValidation(!!text);
            setTenantName(text);
          }}
          canValidate={triggerValidation && Utility.isEmpty(tenantName)}
        />
      </div>
    </div>
  );
}
export const showAddNewTenantPopup = (
  config = {
    tenantEdit: null,
  },
  onSave,
  onClose
) => {
  const id = `add-new-tenant-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <Provider store={store}>
      <Popup popupWindowStyles={{ overflow: "visible" }}>
        <AddNewTenant id={id} {...config} onSave={onSave} onClose={onClose} />
      </Popup>
    </Provider>,
    document.body.appendChild(div)
  );
};
export default AddNewTenant;
