import React, { Component } from "react";

import { DKLabel, DKIcon, DKButton, DKInput } from "deskera-ui-library";

import ic_lock from "../../assets/icons/ic_lock.png";
import Utility from "../../utility/Utility";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
class DisableTFA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmTapped: false,
    };
  }
  render() {
    const { t } = this.props;
    return (
      <div className=" transparent-background">
        <div className="popup-window">
          {this.getHeader()}
          {this.getBody()}
        </div>
      </div>
    );
  }
  onConfirm() {
    this.setState({ confirmTapped: true });
    if (!Utility.isEmpty(this.state.password.trim())) {
      this.props.onConfirm(this.state.password);
    }
  }
  onCancel() {
    this.props.onCancel();
  }
  getHeader() {
    return (
      <>
        <div className="row justify-content-between">
          <div className="row">
            <DKIcon src={ic_lock} className=" ic-s-2 mr-s" />
            <DKLabel text={t(`DISABLE_2FA`)} className="fw-m fs-l" />
          </div>
          <div>
            <div className="row">
              <DKButton
                title={t("CANCEL")}
                className="bg-gray1 border-m fw-m"
                onClick={() => this.onCancel()}
              />
              <DKButton
                title={t("CONFIRM")}
                className="bg-blue ml-r text-white fw-m"
                onClick={() => this.onConfirm()}
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-between mt-m ml-m">
          <div className="row">
            <DKLabel text={t(`ARE_YOU_SURE_DISABLE`)} className="fw-s fs-m" />
          </div>
        </div>
      </>
    );
  }
  getBody() {
    let { password } = this.state;
    return (
      <div className="text-align-left parent-width">
        <div className="mt-xl parent-width">
          <DKLabel text={t("ENTER_PASSWORD")} className="fw-m" />
          <div className="row parent-width">
            <DKInput
              name="Password"
              type="password"
              className="mt-s bg-white"
              value={this.state.password}
              onChange={(value) => this.setState({ password: value })}
              canValidate={this.state.confirmTapped}
              invalid={this.state.confirmTapped && password.trim() === ""}
              required={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DisableTFA);
