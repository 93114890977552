import React, { Component } from "react";
import SubscriptionManager from "../../managers/SubscriptionManager";

import { DKLabel, DKIcon, DKButton, DKSpinner } from "deskera-ui-library";

import ic_document from "../../assets/icons/ic_document.png";
import Subscription from "../../services/subscription";
import { CURRENCYLIST, INVOICE_STATUS } from "../../constants/Enum";
import InvoiceHistoryPopup from "./InvoiceHistoryPopup";
import DataParser from "../../Helper/DataParser";
import Utility, { toCurrencyFormat } from "../../utility/Utility";
import BillingManager from "../../managers/BillingManager";
import { isMobileAppWebView } from "../../utility/ViewportSizeUtils";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
class BillingHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      apiResponseReceived: false,
      showInvoiceHistoryPopup: false,
      invoiceData: null,
    };
  }
  componentDidMount() {
    this.getBillingHistoryData();
  }

  componentWillReceiveProps(nextProps) {
    this.getBillingHistoryData();
  }

  getBillingHistoryData() {
    Subscription.getPaidInvoice().then(
      (data) => {
        this.setState({ apiResponseReceived: true });
        if (data) {
          this.updateInvoiceCount();
        }
      },
      (err) => {
        this.setState({ apiResponseReceived: true });
      }
    );
    Subscription.getFailedInvoice().then(
      (data) => {
        if (data) {
          this.updateInvoiceCount();
        }
      },
      (err) => {}
    );
    Subscription.getInvoice().then(
      (data) => {
        if (data.length !== 0) {
          const shouldCalculateSum = data.every(
            (obj) =>
              obj.Currency.toUpperCase() === data[0].Currency.toUpperCase() &&
              DataParser.getDateFormatted(new Date(obj.ChargeDate)) ===
                DataParser.getDateFormatted(new Date(data[0].ChargeDate))
          );

          let sumTotal = 0.0,
            dateObj;
          if (shouldCalculateSum) {
            data.map((obj) => (sumTotal += obj.TotalAmount));

            dateObj = {
              total: sumTotal,
              currency: data[0].Currency,
              chargeDate: DataParser.getDateFormatted(
                new Date(data[0].ChargeDate)
              ),
            };
          } else {
            const dateArr = DataParser.getLatestUpcomingDate(data);
            dateObj = {
              total: dateArr[1].TotalAmount,
              currency: dateArr[1].Currency,
              chargeDate: dateArr[0],
            };
          }

          this.setState({
            invoiceData: dateObj,
          });
        }
      },
      (err) => {}
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className=" column parent-width bg-white pt-r pb-l pl-r pr-r border-radius-s border-m ">
        <div className="row">
          <DKIcon src={ic_document} className="mr-s ic-s-2" />
          <DKLabel text={t("BILLING_HISTORY")} className=" fs-m fw-m" />
        </div>
        <div className="row mb-l">
          <DKLabel
            text={t("CHECK_ALL_INVOICES")}
            className="text-gray mt-xs ml-xl"
          />
        </div>
        {this.state.apiResponseReceived &&
          this.state.count > 0 &&
          this.getAllBillingCount()}
        {!this.state.apiResponseReceived && this.getLoader()}
        {this.state.apiResponseReceived &&
          this.state.count === 0 &&
          this.getEmptyState()}

        {BillingManager.isCardDetailsAdded() && this.getInvoiceDateSection()}

        {this.state.showInvoiceHistoryPopup && (
          <InvoiceHistoryPopup
            onClose={() => {
              this.setState({ showInvoiceHistoryPopup: false });
            }}
          />
        )}
      </div>
    );
  }
  getInvoiceDateSection() {
    return (
      <div>
        {!Utility.isEmpty(this.state.invoiceData) &&
          this.state.invoiceData.total !== 0 && (
            <DKLabel
              text={`Invoice of ${toCurrencyFormat(
                this.state.invoiceData.total,
                this.state.invoiceData.currency.toUpperCase()
              )} is to be processed on ${DataParser.getDateFormatted(
                new Date(this.state.invoiceData.chargeDate)
              )}`}
              className="mt-l"
            />
          )}
      </div>
    );
  }
  getLoader() {
    return (
      <div className="row justify-content-center">
        <DKSpinner />
      </div>
    );
  }
  getEmptyState() {
    return (
      <DKLabel
        text={t("NO_INVOICES")}
        className="row justify-content-center fs-r text-gray"
      />
    );
  }
  getAllBillingCount() {
    return (
      <>
        {this.state.count > 0 && (
          <div className="row ">
            <DKLabel text={t("TOTAL_INVOICE")} className=" fs-m fw-m ml-xl" />
            <DKLabel
              text={
                this.state.count < 10
                  ? "0" + this.state.count
                  : this.state.count
              }
              className=" fs-xxxl fw-m ml-r"
            />
            <DKButton
              title={t("VIEW_DETAILS")}
              className="bg-gray1 border-m  fw-m ml-l"
              onClick={() => {
                this.setState({ showInvoiceHistoryPopup: true });
              }}
            />
          </div>
        )}
      </>
    );
  }
  updateInvoiceCount() {
    this.setState({
      count:
        SubscriptionManager.getInvoices()[INVOICE_STATUS.PAID].length +
        SubscriptionManager.getInvoices()[INVOICE_STATUS.FAILED].length,
    });
  }
}

export default withTranslation()(BillingHistory);
