import React, { Component } from "react";
import {
  DKButton,
  DKLabel,
  DKInput,
  DKListPicker2,
  DKIcon,
  DKIcons,
  INPUT_VIEW_DIRECTION,
  showLoader,
} from "deskera-ui-library";
import ic_check from "../../assets/icons/ic_check_mark.png";
import ic_arrow_right from "../../assets/icons/ic_arrow_right.png";
import ic_deskera_logo from "../../assets/icons/ic_deskera-logo-dark@2x.png";
import ic_feather_check from "../../assets/icons/ic_feather-check.png";
import ic_feather_arrow_right from "../../assets/icons/ic_feather-arrow-right@2x.png";
import ic_feather_arrow_left from "../../assets/icons/ic_feather-arrow-left@2x.png";
import ic_close_white from "../../assets/icons/ic_close_white.png";
import {
  CONTACT_US,
  CURRENT_ENV,
  CURRENT_ENV_FOR_CONTACT_SALES,
  ONBOARD_DETAILS,
} from "../../constants/Constant";
import Utility, { getCapitalized } from "../../utility/Utility";
import RouteManager from "../../managers/RouteManager";
import http from "../../http";
import ApiConstants from "../../constants/ApiConstants";
import UserManager from "../../managers/UserManager";
import { INPUT_TYPE } from "../../constants/Enum";
import "./onboard.css";
import User from "../../services/user";
import { Cookies } from "../../utility/Utility";
import { Analytics } from "../../utility/GTMEvents";
import CRMFormAPIs from "../../services/CRMFormsAPIs";
import CRMPlusForms from "../../managers/CRMPlusForms";
import UTMManager from "../../managers/UTMManager";
import Subscription from "../../services/subscription";
import { isValidPhoneNumber } from "libphonenumber-js";
import CompanyDetailManager from "../../managers/CompanyDetailManager";
export class AddOnboardPopup extends React.Component {
  constructor(props) {
    super(props);
    if (Boolean(Cookies.get("showContactUsPopup")) === undefined) {
      Cookies.set("showContactUsPopup", false, 1);
    }
    this.state = {
      isProfileCompleted: false,
      profileCompleted: [],
      profileSteps: 0,
      formData: {
        firstName: "",
        lastName: "",
        countryCode: "",
        phone: "",
        name: "",
        industry: "",
        noOfEmployees: "",
        title: "",
        currency: "",
        password: "",
        emailId: "",
      },
      confirmPassword: "",
      countryName: "",
      selectedCounrtryObject: "",
      showDropdown: false,
      onClickNext: false,
      showPopup: Boolean(Cookies.get("showContactUsPopup"))
        ? Boolean(Cookies.get("showContactUsPopup"))
        : false,
      userId: "",
      hasError1: false,
      showTemp: false,
      hasError2: false,
      hasError3: false,
      hasError4: false,
      hasError5: false,
      hasError6: false,
      hasError7: false,
      hasError8: false,
      hasError9: false,
      hasError10: false,
      finalDialogueClose: false,
      isCountryDisable:false
    };
  }
  componentDidMount() {
    const name = UserManager?.getUserDetails()?.name || "";

    this.setState({
      userId: UserManager?.getUserDetails()?.imID,
      
      formData: {
        ...this.state.formData,
        firstName: name?.split(' ')?.slice(0, 1)?.join('') || "",
        lastName:  name?.split(' ')?.slice(1)?.join('') || "",
        emailId: UserManager?.getUserDetails()?.email,
      },
    });
    Subscription.getCountryFromRequest().then((res) => {
      let currency = "";
      let countryDisable = this.state.isCountryDisable;
      currency = Utility.getCountryData().find(
        (country) => country.name === res?.country?.names?.en
      );
      if((!currency || !res?.country?.iso_code) && CompanyDetailManager.getCountryCode()) {
        let countryFromTenantServiceMap = Utility.getCountryData().find(
          (country) => country.code === CompanyDetailManager.getCountryCode()
        );
        currency = countryFromTenantServiceMap;
        if(countryFromTenantServiceMap) {
          countryDisable = true;
        }
      }
      this.setState({
        selectedCounrtryObject: res?.country?.names?.en || currency?.name,
        formData: {
          ...this.state.formData,
          countryCode: res?.country?.iso_code || currency?.code,
          currency: currency?.currencyCode,
        },
        isCountryDisable: countryDisable
      });
    });
    // Utility.setPersistentValue(true,"showContactUsPopup")
  }
  createRequestJSON = (isPaymentPage) => {
    let full_name = "";

    if (
      this.state.formData.firstName === undefined &&
      this.state.formData.lastName === undefined
    ) {
      full_name = null;
    } else {
      full_name =
        this.state.formData.firstName + " " + this.state.formData.lastName;
      //full name for splitting it into fist name and last name with middle name include in last name
    }
    let cells = {
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_first_name]:
        full_name === null ? null : full_name.split(" ").slice(0, 1).join(" "),
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_last_name]:
        full_name === null ? null : full_name.split(" ").slice(1).join(" "),
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_name]:
        full_name === null ? null : full_name,
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_email]:
        this.state.formData.emailId === undefined
          ? undefined
          : this.state.formData.emailId,
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_phone]:
        this.state.formData.phone === undefined
          ? undefined
          : this.state.formData.phone,
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_utm_campaign]:
        UTMManager.utm_campaign === undefined
          ? undefined
          : UTMManager.utm_campaign,
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_organization]:
        this.state.formData.name === undefined
          ? undefined
          : this.state.formData.name,
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_no_of_employees]:
        this.state.formData.noOfEmployees === undefined
          ? undefined
          : this.state.formData.noOfEmployees,
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_industry_type]:
        this.state.formData.industry === undefined
          ? undefined
          : this.state.formData.industry,
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_job_title]:
        this.state.formData.title === undefined
          ? undefined
          : this.state.formData.title,
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_utm_medium]:
        UTMManager.utm_medium === undefined ? undefined : UTMManager.utm_medium,
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_utm_source]:
        UTMManager.utm_source === undefined ? undefined : UTMManager.utm_source,
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_source_url]:
        window.location.toString(),
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_country]:
        this.state.formData.countryCode === undefined
          ? undefined
          : this.state.formData.countryCode,
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_default_key_a]: [
        1,
      ],
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_default_key_b]: [
        1,
      ],
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_field_default_key_c]: [
        1,
      ],
    };
    let result = {
      cells: cells,
    };
    return result;
  };
  createRequestJSONNew = (isPaymentPage) => {
    let full_name = "";

    if (
      this.state.formData.firstName === undefined &&
      this.state.formData.lastName === undefined
    ) {
      full_name = null;
    } else {
      full_name =
        this.state.formData.firstName + " " + this.state.formData.lastName;
      //full name for splitting it into fist name and last name with middle name include in last name
    }
    let cells = {
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_first_name]:
        full_name === null ? null : full_name.split(" ").slice(0, 1).join(" "),
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_last_name]:
        full_name === null ? null : full_name.split(" ").slice(1).join(" "),
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_name]:
        full_name === null ? null : full_name,
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_email]:
        this.state.formData.emailId === undefined
          ? undefined
          : this.state.formData.emailId,
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_phone]:
        this.state.formData.phone === undefined
          ? undefined
          : this.state.formData.phone,
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_utm_campaign]:
        UTMManager.utm_campaign === undefined
          ? undefined
          : UTMManager.utm_campaign,
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_organization]:
        this.state.formData.name === undefined
          ? undefined
          : this.state.formData.name,
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV]
        .form_field_no_of_employees]:
        this.state.formData.noOfEmployees === undefined
          ? undefined
          : this.state.formData.noOfEmployees,
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_industry_type]:
        this.state.formData.industry === undefined
          ? undefined
          : this.state.formData.industry,
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_job_title]:
        this.state.formData.title === undefined
          ? undefined
          : this.state.formData.title,
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_utm_medium]:
        UTMManager.utm_medium === undefined ? undefined : UTMManager.utm_medium,
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_utm_source]:
        UTMManager.utm_source === undefined ? undefined : UTMManager.utm_source,
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_source_url]:
        window.location.toString(),
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_country]:
        this.state.formData.countryCode === undefined
          ? undefined
          : this.state.formData.countryCode,
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_default_key_a]:
        [1],
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_default_key_b]:
        [1],
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_field_default_key_c]:
        [1],
    };
    let result = {
      cells: cells,
    };
    return result;
  };
  createRequestJSONIND = (isPaymentPage) => {
    let full_name = "";

    if (
      this.state.formData.firstName === undefined &&
      this.state.formData.lastName === undefined
    ) {
      full_name = null;
    } else {
      full_name =
        this.state.formData.firstName + " " + this.state.formData.lastName;
      //full name for splitting it into fist name and last name with middle name include in last name
    }
    let cells = {
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_first_name]:
        full_name === null ? null : full_name.split(" ").slice(0, 1).join(" "),
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_last_name]:
        full_name === null ? null : full_name.split(" ").slice(1).join(" "),
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_name]:
        full_name === null ? null : full_name,
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_email]:
        this.state.formData.emailId === undefined
          ? undefined
          : this.state.formData.emailId,
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_phone]:
        this.state.formData.phone === undefined
          ? undefined
          : this.state.formData.phone,
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_utm_campaign]:
        UTMManager.utm_campaign === undefined
          ? undefined
          : UTMManager.utm_campaign,
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_organization]:
        this.state.formData.name === undefined
          ? undefined
          : this.state.formData.name,
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV]
        .form_field_no_of_employees]:
        this.state.formData.noOfEmployees === undefined
          ? undefined
          : this.state.formData.noOfEmployees,
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_industry_type]:
        this.state.formData.industry === undefined
          ? undefined
          : this.state.formData.industry,
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_job_title]:
        this.state.formData.title === undefined
          ? undefined
          : this.state.formData.title,
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_utm_medium]:
        UTMManager.utm_medium === undefined ? undefined : UTMManager.utm_medium,
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_utm_source]:
        UTMManager.utm_source === undefined ? undefined : UTMManager.utm_source,
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_source_url]:
        window.location.toString(),
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_country]:
        this.state.formData.countryCode === undefined
          ? undefined
          : this.state.formData.countryCode,
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_default_key_a]:
        [1],
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_default_key_b]:
        [1],
      [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_field_default_key_c]:
        [1],
    };
    let result = {
      cells: cells,
    };
    return result;
  };
  signup_form_data_new(isPaymentPage) {
    let dataTobeSentNew = this.createRequestJSONNew(isPaymentPage);

    let apiManager = new CRMFormAPIs();
    apiManager.submitCRMForm(
      [CRMPlusForms.signup_form_data_new[CURRENT_ENV].form_id],
      dataTobeSentNew,
      (response) => {
        this.signup_form_data_ind(isPaymentPage);
      },
      (error) => {
        this.signup_form_data_ind(isPaymentPage);
        this.setState({
          errorMsg: "Something went wrong, please try again later.",
          submitButtonSet: true,
        });
      }
    );
  }
  signup_form_data_ind(isPaymentPage) {
    let dataTobeSentIND = this.createRequestJSONIND(isPaymentPage);
    let apiManager = new CRMFormAPIs();
    apiManager
      .submitCRMForm(
        [CRMPlusForms.signup_form_data_ind[CURRENT_ENV].form_id],
        dataTobeSentIND,
        (response) => {},
        (error) => {
          this.setState({
            errorMsg: "Something went wrong, please try again later.",
            submitButtonSet: true,
          });
        }
      )
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        window.location.reload();
        console.log("err", err);
      });
  }
  submitCRMForm(isPaymentPage = false) {
    let dataTobeSent = this.createRequestJSON(isPaymentPage);
    let apiManager = new CRMFormAPIs();
    apiManager.submitCRMForm(
      [CRMPlusForms.signup_form_data[CURRENT_ENV].form_id],
      dataTobeSent,
      (response) => {
        this.signup_form_data_new(isPaymentPage);
      },
      (error) => {
        this.signup_form_data_new(isPaymentPage);
        this.setState({
          errorMsg: "Something went wrong, please try again later.",
          submitButtonSet: true,
        });
      }
    );
  }
  onSaveFormDetails() {
    const data = this.state.formData;
    http
      .put(`${ApiConstants.URL.IAM.UPDATE_PROFILE(this.state.userId)}`, data)
      .then((res) => {
        Cookies.set("showContactUsPopup", true, 1);
        Cookies.set("showBannerSetup", true, 1);
        this.submitCRMForm(true);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  checkErrorOnPage1() {
    let hasError = false;
    if (this.state.formData.firstName.trim() === "") {
      this.setState({
        hasError1: true,
      });
      hasError = true;
    }
    if (this.state.formData.lastName.trim() === "") {
      this.setState({
        hasError2: true,
      });
      hasError = true;
    }
    if (this.state.formData.countryCode.trim() === "") {
      this.setState({
        hasError3: true,
      });
      hasError = true;
    }
    return hasError;
  }
  checkErrorOnPage2() {
    let hasError = false;
    if (this.state.formData.name.trim() === "") {
      this.setState({
        hasError4: true,
      });
      hasError = true;
    }
    if (this.state.formData.industry.trim() === "") {
      this.setState({
        hasError5: true,
      });
      hasError = true;
    }
    if (this.state.formData.noOfEmployees.trim() === "") {
      this.setState({
        hasError6: true,
      });
      hasError = true;
    }
    if (this.state.formData.title.trim() === "") {
      this.setState({
        hasError7: true,
      });
      hasError = true;
    }
    return hasError;
  }
  checkErrorOnPage3() {
    let hasError = false;
    if (
      this.state.formData.password.trim() === "" ||
      this.state.formData.password.length < 8
    ) {
      this.setState({
        hasError8: true,
      });
      hasError = true;
    }
    if (
      this.state.confirmPassword.trim() === "" ||
      this.state.confirmPassword !== this.state.formData.password
    ) {
      this.setState({
        hasError9: true,
      });
      hasError = true;
    }
    return hasError;
  }
  isPhoneInvalid(phoneNumber, countryCode) {
    let hasError = false;
    if (this.state.formData.phone.trim() == "") {
      this.setState({
        hasError10: true,
      });
      hasError = true;
    }
    return hasError;
  }

  render() {
    const output = document.getElementById("cookies");
    return (
      <>
        {Boolean(Cookies.get("showContactUsPopup")) === false &&
          !this.state.finalDialogueClose && (
            <div
              className="transparent-background "
              style={{
                background: "#1818184a",
                backdropFilter: "blur(3px)",
              }}
            >
              <div
                className="popup-window p-0 position-relative bg-dark-gray new-onboarding-flow-wrapper"
                style={{
                  width: 752,
                  height: 475,
                  maxWidth: "90vw",
                  overflowY: "hidden",
                }}
              >
                <div className="row parent-width parent-height bg-dark-gray">
                  {/* <div className="row parent-width "> */}
                  <div
                    className="column parent-height"
                    style={{ minWidth: "35%" }}
                  >
                    <div
                      style={{
                        padding: 32,
                        width: "inherit",
                        justifyContent: "left",
                        display: "flex",
                        flexDirection: " column",
                      }}
                    >
                      <img
                        className="deskera-logo "
                        src={ic_deskera_logo}
                      ></img>
                      <DKLabel
                        text={"Welcome!"}
                        className="welcome fw-b text-white font-neue-haas-grotesk "
                        style={{ marginTop: 45 }}
                      />
                      <DKLabel
                        text={"A few things first"}
                        className="welcome-sub-text fw-b text-white font-inter "
                        style={{ marginTop: 13 }}
                      />

                      <div className="" style={{ marginTop: 45 }}>
                        <div className="row">
                          <div
                            className={`bg-${
                              this.state.profileSteps === 0
                                ? `deskera`
                                : `black`
                            } row border-m p-m border-white text-black fw-b fs-l justify-content-center align-items-center mr-m`}
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: "100%",
                            }}
                          >
                            {this.state.profileSteps !== 0 ? (
                              <img src={ic_feather_check} />
                            ) : (
                              <div style={{ fontSize: 16 }}>1</div>
                            )}
                          </div>
                          <div
                            className="row text-white font-inter"
                            style={{ fontSize: 13, fontWeight: 400 }}
                          >
                            {" "}
                            About you
                          </div>
                        </div>
                        <div
                          className="bg-white m-v-xs"
                          style={{
                            height: 45,
                            width: 1,
                            left: 50,
                            marginLeft: 15,
                          }}
                        ></div>
                        <div className="row">
                          <div
                            className={`bg-${
                              this.state.profileSteps === 0
                                ? `black`
                                : this.state.profileSteps === 2
                                ? `black`
                                : `deskera`
                            } row border-m p-m border-white text-${
                              this.state.profileSteps === 0 ? `white` : `black`
                            } fw-b fs-l justify-content-center align-items-center mr-m`}
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: "100%",
                            }}
                          >
                            {this.state.profileSteps === 2 ? (
                              <img src={ic_feather_check} />
                            ) : (
                              <div style={{ fontSize: 16 }}>2</div>
                            )}
                          </div>
                          <div
                            className="row text-white font-inter"
                            style={{ fontSize: 13, fontWeight: 400 }}
                          >
                            {" "}
                            About your business
                          </div>
                        </div>
                        <div
                          className="bg-white m-v-xs"
                          style={{
                            height: 45,
                            width: 1,
                            left: 50,
                            marginLeft: 15,
                          }}
                        ></div>
                        <div className="row">
                          <div
                            className={`bg-${
                              this.state.profileSteps === 2
                                ? `deskera`
                                : `black`
                            } row border-m border-white text-${
                              this.state.profileSteps === 2 ? `black` : `white`
                            } p-m fw-b fs-m justify-content-center align-items-center mr-m`}
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: "100%",
                            }}
                          >
                            <div style={{ fontSize: 16 }}>3</div>
                          </div>
                          <div
                            className="row text-white font-inter"
                            style={{ fontSize: 13, fontWeight: 400 }}
                          >
                            {" "}
                            Set Password
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.profileSteps === 0 && (
                    <div
                      className="column parent-height"
                      style={{
                        minWidth: "65%",
                        borderLeft: "2px solid #474747",
                      }}
                    >
                      <div
                        className="column bg-dark-gray parent-width parent-height "
                        style={{ padding: 32 }}
                      >
                        <DKLabel
                          text={
                            "Please enter a few more details to get started."
                          }
                          className="fs-l fw-b text-white "
                          // style={{ top: 32, left: 302 }}
                        />
                        <DKLabel
                          text={"Define the form fields we need here."}
                          className="fs-m text-white "
                          style={{ marginTop: 13 }}
                        />

                        <div
                          className="form parent-width position-relative "
                          style={{ marginTop: 16, minHeight: 306 }}
                        >
                          <div
                            className="input-fields row parent-width "
                            style={{ alignItems: "baseline" }}
                          >
                            <div
                              className={`input-field pr-s ${
                                this.state.hasError1 ? "has-Error" : ""
                              } `}
                              style={{
                                width: "50%",
                              }}
                            >
                              <DKInput
                                name="First name"
                                placeholder="First name"
                                value={this.state.formData.firstName}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onChange={(value) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      firstName: getCapitalized(value),
                                    },
                                    hasError1: false,
                                  });
                                }}
                                errorMessage={"*Define the field"}
                                canValidate={this.state.hasError1}
                                valueStyle={{
                                  backgroundColor: "#3C3C3C",
                                  border: "1px solid #A59E8E",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                titleStyle={{
                                  color: "#D8D8D8",
                                }}
                              />
                            </div>
                            <div
                              className={`input-field pl-s ${
                                this.state.hasError2 ? "has-Error" : ""
                              } `}
                              style={{
                                width: "50%",
                              }}
                              // style={{ left: 520, top: 102 }}/
                            >
                              <DKInput
                                name="Last name"
                                placeholder="Last name"
                                value={this.state.formData.lastName}
                                className=""
                                onChange={(value) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      lastName: getCapitalized(value),
                                    },
                                    hasError2: false,
                                  });
                                }}
                                errorMessage={"*Define the field"}
                                canValidate={this.state.hasError2}
                                valueStyle={{
                                  backgroundColor: "#3C3C3C",
                                  border: "1px solid #A59E8E",
                                }}
                                titleStyle={{
                                  color: "#FFFFFF",
                                }}
                              />
                            </div>
                          </div>
                          <div className="input-fields column parent-width">
                            <div
                              className={`input-field  ${
                                this.state.hasError10 ? "has-Error" : ""
                              }`}
                              style={{ width: "100%", marginTop: 26 }}
                            >
                              <DKInput
                                name="Phone number "
                                placeholder="Phone number"
                                required={true}
                                value={this.state.formData.phone}
                                type={INPUT_TYPE.NUMBER}
                                className=""
                                validator={(value) => {
                                  if (
                                    isValidPhoneNumber(
                                      this.state.formData.phone,
                                      this.state.formData.countryCode
                                    )
                                  )
                                    return true;
                                  else return false;
                                }}
                                errorMessage={"*Invalid Phone Number"}
                                canValidate={this.state.hasError10}
                                onChange={(value) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      phone: getCapitalized(value),
                                    },
                                    hasError10: false,
                                  });
                                }}
                                valueStyle={{
                                  backgroundColor: "#3C3C3C",
                                  border: "1px solid #A59E8E",
                                }}
                                titleStyle={{
                                  color: "#d51010",
                                }}
                              />
                            </div>
                            <div
                              className={`input-field ${
                                this.state.hasError3 ? "has-Error" : ""
                              } `}
                              style={{ width: "100%", marginTop: 26 }}
                            >
                              <div className="onboarding-dropdown ">
                                <DKInput
                                  name="Country"
                                  placeholder="Country"
                                  required={true}
                                  type={INPUT_TYPE.DROPDOWN}
                                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                                  value={this.state.selectedCounrtryObject}
                                  className=""
                                  onChange={() => {}}
                                  formatter={(obj) => {
                                    return obj.name;
                                  }}
                                  errorMessage={"*Define the field"}
                                  canValidate={this.state.hasError3}
                                  displayKey="name"
                                  renderer={(index, obj) => {
                                    return (
                                      <DKLabel
                                        text={`${this.state.selectedCounrtryObject}`}
                                        className="text-white"
                                      />
                                    );
                                  }}
                                  dropdownConfig={{
                                    title: "Select Country",
                                    allowSearch: true,
                                    searchableKey: "name",
                                    style: { minWidth: 230, top: 0 },
                                    className: "shadow-m",
                                    data: Utility.getCountryData(),
                                    renderer: (index, obj) => {
                                      return <DKLabel text={`${obj.name}`} />;
                                    },
                                    onSelect: (index, value, rowIndex) => {
                                      this.setState(
                                        {
                                          formData: {
                                            ...this.state.formData,
                                            countryCode: value?.code,
                                            currency: value?.currencyCode,
                                          },
                                          selectedCounrtryObject: value.name,
                                          // countryName: value?.name
                                        },
                                        () => {
                                          this.isPhoneInvalid(
                                            this.state.formData.phone,
                                            this.state.formData.countryCode
                                          );
                                        }
                                      );
                                    },
                                  }}
                                  valueStyle={{
                                    backgroundColor: "#3C3C3C",
                                    border: "1px solid #A59E8E",
                                    color: "#D8D8D8",
                                    height: "48px",
                                    // width: 418,
                                  }}
                                  titleStyle={{
                                    color: "#FFFFFF",
                                  }}
                                  readOnly={this.state.isCountryDisable}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-end">
                          <DKButton
                            title="Next &nbsp;"
                            icon={ic_feather_arrow_right}
                            isReverse={true}
                            onClick={() => {
                              if (
                                !(
                                  this.checkErrorOnPage1() ||
                                  this.isPhoneInvalid(
                                    this.state.formData.phone,
                                    this.state.formData.countryCode
                                  )
                                )
                              ) {
                                this.setState({
                                  onClickNext: true,
                                  profileSteps: this.state.profileSteps + 1,
                                  isProfileCompleted: true,
                                });
                              }
                            }}
                            className="row fw-b fs-l text-white "
                            style={{
                              marginBottom: 32,
                              width: "115px",
                              height: "40px",
                              padding: "10px 22px 10px 18px",
                              border: "1px solid #A0A0A0",
                              backgroundColor: "#181818",
                              borderRadius: 4,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.profileSteps === 1 && (
                    <div
                      className="column parent-height"
                      style={{
                        minWidth: "65%",
                        borderLeft: "2px solid #474747",
                      }}
                    >
                      <div
                        className="column bg-dark-gray parent-width parent-height "
                        style={{ padding: 32 }}
                      >
                        <DKLabel
                          text={"Tell us more about your business"}
                          className="fs-l fw-b text-white "
                        />
                        <DKLabel
                          text={"Define the form fields"}
                          className="fs-m text-white "
                          style={{ marginTop: 13 }}
                        />
                        <div
                          className="form parent-width position-relative "
                          style={{ marginTop: 16, minHeight: 278 }}
                        >
                          <div
                            className={`input-field  ${
                              this.state.hasError4 ? "has-Error" : ""
                            } `}
                          >
                            <DKInput
                              name="Company name"
                              placeholder="What is your company's name?"
                              required
                              value={this.state.formData.name}
                              className=""
                              onChange={(value) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    name: getCapitalized(value),
                                  },
                                  hasError4: false,
                                });
                              }}
                              canValidate={this.state.hasError4}
                              errorMessage={"*Define the field"}
                              valueStyle={{
                                backgroundColor: "#3C3C3C",
                                border: "1px solid #A59E8E",

                                alignItems: "center",
                              }}
                              titleStyle={{
                                color: "#FFFFFF",
                              }}
                            />
                          </div>
                          <div
                            className={`input-field ${
                              this.state.hasError5 ? "has-Error" : ""
                            } `}
                            style={{ width: "100%", marginTop: 26 }}
                          >
                            <DKInput
                              name="Industry"
                              placeholder="Which industry does your company belong to?"
                              required
                              value={this.state.formData.industry}
                              className=""
                              errorMessage={"*Define the field"}
                              onChange={(value) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    industry: getCapitalized(value),
                                  },
                                  hasError5: false,
                                });
                              }}
                              canValidate={this.state.hasError5}
                              valueStyle={{
                                backgroundColor: "#3C3C3C",
                                border: "1px solid #A59E8E",
                              }}
                              titleStyle={{
                                color: "#FFFFFF",
                              }}
                            />
                          </div>
                          <div
                            className={`input-field ${
                              this.state.hasError6 ? "has-Error" : ""
                            } `}
                            style={{ marginTop: 26 }}
                          >
                            <DKInput
                              name="Employees"
                              placeholder="How many employees do you have?"
                              required
                              value={this.state.formData.noOfEmployees}
                              type={INPUT_TYPE.NUMBER}
                              className=""
                              onChange={(value) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    noOfEmployees: getCapitalized(value),
                                  },
                                  hasError6: false,
                                });
                              }}
                              canValidate={this.state.hasError6}
                              errorMessage={"*Define the field"}
                              valueStyle={{
                                backgroundColor: "#3C3C3C",
                                border: "1px solid #A59E8E",
                              }}
                              titleStyle={{
                                color: "#FFFFFF",
                              }}
                            />
                          </div>
                          <div
                            className={`input-field ${
                              this.state.hasError7 ? "has-Error" : ""
                            } `}
                            style={{ marginTop: 26 }}
                          >
                            <DKInput
                              name="Job Title"
                              placeholder="What is your job title?"
                              required
                              value={this.state.formData.title}
                              className=""
                              onChange={(value) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    title: getCapitalized(value),
                                  },
                                  hasError7: false,
                                });
                              }}
                              canValidate={this.state.hasError7}
                              valueStyle={{
                                backgroundColor: "#3C3C3C",
                                border: "1px solid #A59E8E",
                              }}
                              errorMessage={"*Define the field"}
                              titleStyle={{
                                color: "#FFFFFF",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="row justify-content-between"
                          style={{ bottom: 0, width: "100%", marginTop: 28 }}
                        >
                          <div
                            className="row justify-content-between"
                            style={{}}
                          >
                            <DKButton
                              title="&nbsp;&nbsp;Back"
                              icon={ic_feather_arrow_left}
                              style={{
                                width: 115.84,
                                height: 40,
                                fontSize: 16,
                                border: "1px solid #A0A0A0",
                                padding: "10px 16.72px 10px 20px",
                                backgroundColor: "#181818",
                                borderRadius: 4,
                              }}
                              isReverse={false}
                              onClick={() =>
                                this.setState({
                                  onClickNext: false,
                                  profileSteps: this.state.profileSteps - 1,
                                  isProfileCompleted: false,
                                })
                              }
                              className="row fw-b fs-l text-white bg-dark-gray border-radius-s border-m border-gray1"
                            />
                            <DKButton
                              title="Next &nbsp;"
                              icon={ic_feather_arrow_right}
                              isReverse={true}
                              onClick={() => {
                                if (!this.checkErrorOnPage2()) {
                                  this.setState({
                                    onClickNext: true,
                                    profileSteps: this.state.profileSteps + 1,
                                    isProfileCompleted: true,
                                  });
                                }
                              }}
                              className="row fw-b fs-l text-white "
                              style={{
                                width: "115px",
                                height: "40px",
                                padding: "10px 22px 10px 18px",
                                border: "1px solid #A0A0A0",
                                backgroundColor: "#181818",
                                borderRadius: 4,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.profileSteps === 2 && (
                    <div
                      className="column parent-height"
                      style={{
                        minWidth: "65%",
                        borderLeft: "2px solid #474747",
                      }}
                    >
                      <div
                        className="column bg-dark-gray parent-width parent-height "
                        style={{ padding: 32 }}
                      >
                        <DKLabel
                          text={"Please set a password to get started."}
                          className="fs-l fw-b text-white "
                          // style={{ top: 32, left: 302 }}
                        />
                        <DKLabel
                          text={"Define the form fields we need here."}
                          className="fs-m text-white "
                          style={{ marginTop: 13 }}
                        />

                        <div
                          className="form parent-width position-relative "
                          style={{ marginTop: 16 }}
                        >
                          <div
                            className="input-fields column parent-width "
                            style={{ alignItems: "baseline" }}
                          >
                            <div
                              className={`input-field pr-s ${
                                this.state.hasError8 ? "has-Error" : ""
                              } `}
                              style={{
                                width: "100%",
                              }}
                            >
                              <DKInput
                                name="Set Password"
                                placeholder="Set Password"
                                type={"password"}
                                value={this.state.formData.password}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onChange={(value) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      password: value,
                                    },
                                    hasError8: false,
                                  });
                                }}
                                // invalid={true}
                                validator={(value) => {
                                  if (value.length >= 8) return true;
                                  else return false;
                                }}
                                errorMessage={
                                  "Password length should be minimum 8 characters"
                                }
                                canValidate={this.state.hasError8}
                                valueStyle={{
                                  backgroundColor: "#3C3C3C",
                                  border: "1px solid #A59E8E",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                titleStyle={{
                                  color: "#D8D8D8",
                                }}
                              />
                            </div>
                            <div
                              className={`input-field ${
                                this.state.hasError9 ? "has-Error" : ""
                              } `}
                              style={{
                                width: "100%",
                                marginTop: 26,
                              }}
                            >
                              <DKInput
                                name="Confirm Password"
                                placeholder="Confirm Password"
                                value={this.state.confirmPassword}
                                className=""
                                onChange={(value) => {
                                  this.setState({
                                    confirmPassword: value,
                                    hasError9: false,
                                  });
                                }}
                                validator={(value) => {
                                  if (value === this.state.formData?.password) {
                                    return true;
                                  } else return false;
                                }}
                                errorMessage={"Password does not match"}
                                canValidate={this.state.hasError9}
                                valueStyle={{
                                  backgroundColor: "#3C3C3C",
                                  border: "1px solid #A59E8E",
                                }}
                                titleStyle={{
                                  color: "#FFFFFF",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="position-absolute"
                          style={{
                            bottom: 0,
                            right: 0,
                            marginBottom: 32,
                            marginRight: 32,
                          }}
                        >
                          <DKButton
                            title="I'm ready"
                            isReverse={true}
                            style={{
                              width: 174,
                              height: 40,
                              fontSize: 18,
                              border: "1px #00000000",
                              padding: "10px 46.84px 9px 47px",
                              backgroundColor: "#3856D5",
                              borderRadius: 4,
                            }}
                            className="row fw-b text-white"
                            onClick={() => {
                              if (!this.checkErrorOnPage3()) {
                                showLoader("Please Wait...");
                                this.onSaveFormDetails();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        <div>
          {Boolean(Cookies.get("showContactUsPopup")) === true &&
            !this.state.finalDialogueClose && (
              <div
                className="transparent-background "
                style={{
                  background: "#1818184a",
                  backdropFilter: "blur(3px)",
                }}
              >
                <div
                  className="popup-window p-0 bg-dark-gray"
                  style={{
                    width: 560,
                    height: "fit-content",
                    maxWidth: "60vw",
                  }}
                >
                  <div
                    className="bg-dark-gray column parent-width parent-height align-items-center position-relative justify-content-center"
                    style={{ padding: 26 }}
                  >
                    <img
                      src={ic_close_white}
                      onClick={() => {
                        Cookies.deleteCookie("showContactUsPopup");
                        Cookies.deleteCookie("showBannerSetup");
                        this.setState({
                          showPopup: false,
                          finalDialogueClose: true,
                        });
                      }}
                      className="position-absolute cursor-hand"
                      style={{ right: 13, top: 13, width: 12, height: 12 }}
                    />
                    <DKLabel
                      text={`Scale Your Business\n           with Deskera`}
                      className="scale font-neue-haas-grotesk"
                      style={{
                        fontWeight: 700,
                        fontSize: 36,
                        color: "#FACA60",
                      }}
                    />
                    <DKLabel
                      text={`You are currently on Deskera Free,\nwhich has a limited set of features.`}
                      className="scale-sub-text"
                      style={{ marginTop: 13, fontSize: 16, color: "#FFFFFF" }}
                    />
                    <DKLabel
                      text={`Get advanced features and unlock Deskera’s \n          superpowers by upgrading now`}
                      className="scale-sub-text"
                      style={{ marginTop: 13, fontSize: 16, color: "#FFFFFF" }}
                    />
                    <div
                      className="row justify-content-center"
                      style={{
                        marginTop: 28,
                      }}
                    >
                      <DKButton
                        title={"Contact Sales"}
                        style={{
                          backgroundColor: "#FACA60",
                          marginRight: 23,
                          width: 168,
                          height: 44,
                          padding: "12px 30px 12px 30px",
                          borderRadius: 4,
                        }}
                        className="row fw-b fs-l text-black"
                        onClick={() =>
                          window.open(
                            `${CURRENT_ENV_FOR_CONTACT_SALES}` + `${CONTACT_US}`
                          )
                        }
                      />
                      {this.state.isDemoRequested ? (
                        <DKButton
                          title={"Request Demo"}
                          className="row fw-b fs-l border-white text-white"
                          style={{
                            backgroundColor: "#181818",
                            width: 168,
                            height: 44,
                            color: "#A0A0A0",
                            padding: "12px 36px 12px 36px",
                            borderRadius: 4,
                            border: "0.8px solid #A0A0A0",
                          }}
                          onClick={() =>
                            window.open(`https://www.deskera.xyz/book-a-demo`)
                          }
                        />
                      ) : (
                        <DKButton
                          title={"Maybe Later"}
                          className="row fw-b fs-l border-white border-radius-s"
                          style={{
                            backgroundColor: "#181818",
                            width: 168,
                            height: 44,
                            color: "#A0A0A0",
                            padding: "12px 36px 12px 36px",
                            borderRadius: 4,
                            border: "0.8px solid #A0A0A0",
                          }}
                          onClick={() => {
                            Cookies.deleteCookie("showContactUsPopup");
                            Cookies.deleteCookie("showBannerSetup");
                            this.setState({
                              showPopup: false,
                              finalDialogueClose: true,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </>
    );
  }
}

export default AddOnboardPopup;
