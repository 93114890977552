import React, { Component } from "react";

import {
    DKLabel,
    DKButton,
    DKInput,
    INPUT_VIEW_DIRECTION,
    INPUT_TYPE,
} from "deskera-ui-library";

import ApiConstants from "../../constants/ApiConstants";
import IAM from "../../services/iam";
import Utility from "../../utility/Utility";

import Timer from "./Timer";

class ChangeEmailPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            displayVerifyCodeContent: false,
            otp: "",
            verifiedSuccessful: false,
            errorMsg: "",
            sessionID: "",
            checkTapped: false,
            isEmailValid: true,
            minutes: 0,
            seconds: 0,
            disabledResendCode: true,
            timeLeft: true,
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleBackSpace);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleClickOutside);
    }

    handleBackSpace(event) {
        if (event.key === "Backspace" || event.key === "Delete") {
            let inputName= event.target.id;
            let digit = inputName[3];
            let newIndex = (parseInt(digit)-1).toString()
            const backInput = document.getElementById("otp"+newIndex);
            if(event.target.value.trim().length === 0 && backInput !== null) {
                backInput.focus()
            }
        }
    }

    render() {
        return (
            <div className=" transparent-background">
                <div className="popup-window">
                    <div className="row justify-content-between">
                        <DKLabel text={this.state.verifiedSuccessful ? "Email change successful" : "Change Email Address"} className="fw-m fs-l" />
                    </div>
                    <div className="parent-width">
                        {!this.state.displayVerifyCodeContent && this.getNewEmailContent()}
                        {this.state.displayVerifyCodeContent && !this.state.verifiedSuccessful && this.getCodeContent()}
                        {this.state.verifiedSuccessful && this.getVerifiedSuccessfulContent()}
                    </div>
                    
                </div>
            </div>
        )
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    getNewEmailContent() {
        return (
            <div className="column mt-m mb-s">
                <div className="row mt-m" style={{ alignItems: "flex-start" }}>
                    <DKInput
                        name={"New email"}
                        placeholder={"Enter new email"}
                        required={true}
                        value={this.state.email}
                        onChange={(value) => {
                            this.setState({
                                email: value
                            });
                        }}
                        type={INPUT_TYPE.EMAIL}
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                        canValidate={this.state.checkTapped}
                        errorMessage={this.checkEmail()}
                        tooltip={{
                            content:
                              "Please use an email address with no account attached",
                            className: 'bg-chip-blue dk-tooltip-width'
                        }}
                        title="Enter new email address"
                    />
                </div>
                <div className="row mt-l" style={{ alignItems: "flex-start" }}>
                    <DKInput
                        title="Type password to confirm"
                        name="Password"
                        required={true}
                        value={this.state.password}
                        onChange={(value) => {
                            this.setState({
                                password: value
                            });
                        }}
                        type={INPUT_TYPE.PASSWORD}
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                        canValidate={this.state.checkTapped}
                        errorMessage={this.checkPassword()}
                        validator={(text) => {
                            return text.length !== 0 && text.length > 8 && text.length < 72
                        }}
                    />
                </div>
                <div className=" mt-l" style={{width:"100%", display:"flex", justifyContent:"end"}}>
                    <DKButton
                        title="Cancel"
                        className="bg-gray1 border-m fw-m"
                        onClick={this.cancelTapped}
                    />
                    <DKButton
                        title="Save"
                        className="bg-blue ml-r text-white fw-m"
                        onClick={this.saveTapped}
                    />
                </div>
            </div>
        )
    }

    checkEmail() {
        if(this.state.email.length === 0){
            return "Email is required"
        } else {
            if(!Utility.isValidEmail(this.state.email)) {
                return "Please enter a valid email";
            }
        }
    }

    checkPassword() {
        if (this.state.password.length === 0){
            return "Password is required"
        } else {
            return "Password length cannot be less than 8 character or more than 72 characters"
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    getCodeContent() {
        return (
            <div className="column mt-xl mb-s">
                <DKLabel 
                    text={`We want to make sure it’s really you. In order to further verify your identity, enter the verification code that was sent to ` + this.state.email} 
                    className="fs-m mb-s"
                />
                <div style={{display: "flex"}} className="mb-s">
                    <div style={{width:"200px"}}>
                        <DKInput
                            needHeader={false}
                            name={"otp"}
                            required={false}
                            value={this.state.otp}
                            onChange={(value) => {
                                this.setState({otp: value})
                            }}
                            tabIndex="1" 
                            className="mr-m"
                            type={INPUT_TYPE.TEXT}
                        />
                    </div>
                </div>
                {this.state.errorMsg.length>0 &&
                    <DKLabel
                        text={this.state.errorMsg}
                        className="text-red mt-r mb-m"
                    />
                }
                <Timer
                    time={this.state.minutes}
                    timeLeft = {(val) => {
                        if(val.minutes === 0 && val.seconds ==0){
                            this.setState({
                                timeLeft:false
                            })
                        }else {
                            this.setState({
                                timeLeft:true
                            })
                        }
                    }}
                    isAnytimeLeft={this.state.timeLeft}
                    onTimerEnds={this.showResendButton}
                />
                <div className="row" style={{display:"flex", justifyContent: "space-between"}}>
                    <DKButton
                        title="Back"
                        className="bg-gray1 border-m fw-m"
                        onClick={this.backTapped}
                    />
                    <div style={{display:"flex"}}>
                        <DKButton
                            title="Resend Code"
                            className={`bg-white border-m ${this.state.disabledResendCode ? 'border-grey text-grey' : 'border-blue text-blue'} fw-m `}
                            onClick={!this.state.disabledResendCode ? this.saveTapped : this.disabledBtn}
                            style={{cursor: this.state.disabledResendCode ? "default" : "pointer"}}
                        />
                        <DKButton
                            title="Confirm"
                            className="bg-blue ml-r text-white fw-m"
                            onClick={this.confirmTapped}
                        />
                    </div>                        
                </div>
            </div>
        )
    }

    getIndex(key){
        let digit = key[3];
        return (parseInt(digit)+1).toString()
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    getVerifiedSuccessfulContent() {
        return (
            <div className="column mt-xl mb-s">
                <DKLabel text={`Your email has been changed. We have sent you an email to verify your new email address.`} className="fs-m"/>
                <div className=" mt-m" style={{width:"100%", display:"flex", justifyContent:"end"}}>
                    <DKButton
                        title="Log in"
                        className="bg-blue ml-r text-white fw-m"
                        onClick={this.loginTapped}
                    />
                </div>
            </div>
        )
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    cancelTapped = () => {
        this.props.onCancel();
    };

    saveTapped = () => {
        this.setState({
            checkTapped: true,
            disabledResendCode: true
        })

        const checkAllInputIsValid = this.checkInput();

        let data = {
            newEmail: this.state.email,
            password: this.state.password
        }

        if(checkAllInputIsValid){
            IAM.sendChangeEmailOTP(data).then(
                (res) => {
                    let min = Math.floor(res.otpExpiryDurationSeconds/60)
                    this.setState({
                        displayVerifyCodeContent: true,
                        sessionID: res.sessionID,
                        minutes: min,
                    })
                },
                (err) => {
                    this.setState({
                        errorMsg: err
                    })
                }
            )
        }
    }

    checkInput() {
        let msg = ""
        if(
            this.state.password.length === 0 ||
            this.state.email.length === 0
        ) {
            msg = "Please enter values"
        }

        if(
            this.state.password.length !== 0 &&
            (this.state.password.length < 8 ||
            this.state.password.length > 72) 
        ) {
            msg = "Please check password"
        }

        this.setState({
            errorMsg: msg
        })

        return msg.length > 0 ? false :true
    }

    showResendButton = () => {
        this.setState({
            disabledResendCode: false,
            minutes: 0,
        })
    }

    backTapped = () => {
        this.setState({
            displayVerifyCodeContent: false
        })
    }

    disabledBtn = () => {
        console.log("disable resend button")
    }

    confirmTapped = () => {
        let data = { 
            newEmail: this.state.email,
            sessionID: this.state.sessionID,
            verificationCode: this.state.otp
        }
        if (this.state.otp.length === 6){
            IAM.confirmChangeEmailOTP(data).then(
                (res) => {
                    this.setState({
                        verifiedSuccessful: true,
                    })
                },
                (err) => {
                    this.setState({
                        errorMsg: err
                    })
                }
            )
        } else {
            this.setState({
                errorMsg: "Please enter valid OTP"
            })
        }
    }

    loginTapped = () => {
        IAM.logOut().then((res) => {
            window.open(
              ApiConstants.URL.IAM.REDIRECT + window.location.href,
              "_self"
            );
        });
    }
}

export default ChangeEmailPopup;