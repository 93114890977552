import React, { Fragment, useState } from "react";
import PricingHeroTabs from "./PricingHeroTabs";
import PricingPlanPreview from "./PricingPlanPreview";
import Utility from "../../utility/Utility";
import { PLANS_NAME_V2, PLANS_V2 } from "../../constants/Enum";
import PlanSpecification from "./PlanSpecification";
import SVGIcon from "../../constants/SVGIcon";
export default function PricingTabs(props) {
  const [showPlanSpecification, setShowPlanSpecification] = useState(false);
  const [activeTab, setActiveTab] = useState(props?.activeTab ?? 0);
  const getPlanValue = (planname) => {
    let planValueName = PLANS_V2.ERP;
    switch (planname) {
      case PLANS_NAME_V2.ERP:
        planValueName = PLANS_V2.ERP;
        break;
      case PLANS_NAME_V2.ERP_PLUS:
        planValueName = PLANS_V2.ERP_PLUS;
        break;
      case PLANS_NAME_V2.MRP_PLUS:
        planValueName = PLANS_V2.MRP_PLUS;
        break;
      default:
        planValueName = PLANS_V2.ERP;
        break;
    }
    return Utility.getPriceValue(
      planValueName,
      this.state.countryCode ? this.state.countryCode : `us`
    );
  };
  const getPricingData = (priceData) => {
    const pricingData = priceData;
    pricingData.plans = priceData?.plans?.map((plan) => {
      return {
        ...plan,
        currency: Utility.getCurrencySymbol(
          props.currencyCode ? props.currencyCode : `$`
        ),
        amount: this.getPlanValue(plan.name),
      };
    });

    return pricingData;
  };
  const getBusinessFunctionData = () => {
    const functions = JSON.parse(
      JSON.stringify(props.pricingDataFunctions(activeTab))
    );
    functions.gridCards = functions.gridCards.map((card, index) => {
      if (index !== activeTab) {
        card.className += ` inactive`;
      }
      if (!card.styles) {
        card.styles = { minWidth: 180 };
      }
      return card;
    });
    return functions;
  };
  return (
    <Fragment>
      <PricingHeroTabs
        {...getBusinessFunctionData()}
        onClick={(index) => setActiveTab(index)}
        style={{ padding: `0px 30px 0px`, maxWidth: `1000px`, width: `100vw` }}
        isCenterBadge={true}
      />
      <div
        className="column screen-width"
        style={{
          borderBottom: `2px solid #D6D3D1`,
          borderBottomColor:
            getBusinessFunctionData().gridCards[activeTab].titleColor ||
            getBusinessFunctionData().gridCards[activeTab].accentColor ||
            `#181818`,
        }}
      ></div>
      <div
        className="column align-items-center justify-content-center"
        id="pricing-table-tab-content-wrapper"
        style={{
          background: activeTab === 1 ? `#FFFAF7` : `#F9FCFF`,
          width: `100vw`,
          paddingBottom: `60px`,
        }}
      >
        {/* <img
                    alt=""
                    loading="lazy"
                    src={getBusinessFunctionData().gridCards[activeTab].image}
                    className=" mobile-height-auto mobile-max-width-90"
                    style={{
                        width: `140%`,
                        maxWidth: 1440,
                        height: `auto`
                    }}
                /> */}
        <div
          id="pricing-table-card-wrapper"
          className="row parent-width"
          style={{ maxWidth: "1000px" }}
        >
          <PricingPlanPreview
            PricingPreviewData={
              getBusinessFunctionData().gridCards[activeTab].data
            }
            currencyCode={props.currencyCode}
            countryCode={props.countryCode}
            isLoadingLocation={props.isLoadingLocation}
            activeTab={activeTab}
            tenantId={props?.tenantId}
            userId={props?.userId}
          />
        </div>
        <div
          className="row justify-content-start fw-s"
          style={{
            maxWidth:
              props.countryCode === "IN" && activeTab === 1
                ? "720px"
                : "1000px",
            fontSize: "10px",
            marginTop: "10px",
          }}
        >
          *Cost shown does not include the required, one-time implementation and
          setup fees
        </div>
        <div
          onClick={() => setShowPlanSpecification(!showPlanSpecification)}
          className="row justify-content-center cursor-hand fw-b link-text "
          style={{
            color: activeTab === 1 ? `#B64F0B` : "#2E4BC6",
            lineHeight: "19.2px",
            width: "auto",
            height: "19px",
            fontSize: "16px",
            fontWeight: "400px",
            marginTop: "60px",
          }}
        >
          {showPlanSpecification
            ? `Hide All Plan Features`
            : `Show All Plan Features`}{" "}
          &nbsp;
          {/* <img src={activeTab === 1 ? Vector_8_mrp : Vector_8_erp} width={25} height={15} style={{ marginTop: 5 }} /> */}
          <SVGIcon
            name={
              activeTab === 1 ? `ic-pricing-mrp-arrow` : `ic-pricing-erp-arrow`
            }
          />
        </div>

        {showPlanSpecification && (
          <div className="row parent-width" style={{ maxWidth: "1000px" }}>
            {props.DataProvider.pricingSpecificationData &&
              props.DataProvider.pricingSpecificationData[activeTab].map(
                (specificationData, index) => {
                  return (
                    <PlanSpecification
                      pricingSpecificationData={specificationData}
                      activeTab={activeTab}
                      key={index}
                    />
                  );
                }
              )}
          </div>
        )}
      </div>
    </Fragment>
  );
}
