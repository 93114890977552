import ApiConstants from "../constants/ApiConstants";
import axios from "axios";

import { showAlert, showToast, TOAST_TYPE } from "deskera-ui-library";

import AppManager from "../managers/AppManager";
import Utility from "../utility/Utility";
import ApiManager from "../managers/ApiManager";
import IAM from "../services/iam";
const ERRORS_TO_IGNORE = ["Billing Info not added yet", "Trial not allowed"];
const ERROR_CODE_TO_IGNORE = [4014];
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: ApiConstants.URL.BASE,
});

const FAILED_REQUEST_MAX_RETRY_COUNT = 2;
const FAILED_REQUEST_RETRY_DELAY = 2000;

const requestInterceptSuccess = (config) => {
  return config;
};
const requestInterceptError = (error) => {
  return Promise.reject(error);
};
const responseInterceptSuccess = (response) => {
  AppManager.didSessionExpired = false;
  if (response.data && response.data.code && response.data.errorMessage) {
    showAlert("Error occurred!", response.data.errorMessage);
    return Promise.reject(response.data);
  }
  return response.data;
};

const retryApiCall = async (error) => {
  const errorConfig = { ...error.config };

  errorConfig._retryCount = errorConfig?._retryCount || 0;

  if (
    errorConfig?._retryCount >= FAILED_REQUEST_MAX_RETRY_COUNT ||
    (errorConfig.method === "post" && !errorConfig.params?._allowRetry)
  ) {
    return Promise.reject(error);
  }

  errorConfig._retryCount += 1;
  errorConfig._isRetryRequest = true;
  const delayRetry = new Promise((resolve) =>
    setTimeout(() => resolve(), FAILED_REQUEST_RETRY_DELAY)
  );
  return delayRetry.then(() => axiosInstance.request(errorConfig));
};

const onEmptyResponse = async (error) => {
  if (!AppManager.didSessionExpired && !error?.config?._isRetryRequest) {
    const authData = await IAM.checkIfUserLoggedIn();
    if (authData && authData?.accessToken) {
      // ACTIVE SESSION // DO NOTHING
    } else {
      AppManager.gotoLoginPage();
      return Promise.reject(error);
    }
  }

  return retryApiCall(error);
};

const responseInterceptError = async (error) => {
  if (Utility.isEmpty(error.response)) {
    return onEmptyResponse(error);
  } else {
    if (error.response.data) {
      if (
        (error.response.data.errorCode || error.response.data.code) &&
        error.response.data.errorMessage
      )
        if (
          !ERRORS_TO_IGNORE.includes(error.response.data.errorMessage) &&
          !ERROR_CODE_TO_IGNORE.includes(error.response.data.errorCode)
        ) {
          if (
            error.response.data.errorMessage.toLowerCase() ===
              "user not authorized" ||
            error.response.data.errorMessage.toLowerCase() ===
              "user not authorised"
          ) {
            showToast(error.response.data.errorMessage, TOAST_TYPE.FAILURE);
          } else {
            if (
              error.config &&
              error.config.url &&
              error.config.url === ApiConstants.URL.CRM.GUIDE &&
              error.response.data.errorMessage === "record not found"
            ) {
              // no alert required if helpcenter get
            } else if (
              error.response.data.errorMessage.includes(
                "Unable to create app user in tenant :"
              )
            ) {
              showAlert(
                "Error occurred!",
                "Error adding the user due to user limit"
              );
            } else if (
              error.response.data.errorMessage.includes(
                "Stripe error in processing"
              )
            ) {
              showAlert(
                "Error occurred!",
                "Please try again or add a new card."
              );
            } else if (
              error.response.data.errorMessage.includes(
                "Failed to update owner permission"
              )
            ) {
              showAlert("Action Prevented", "You cannot perform this action");
            } else if (
              error.response.data.errorMessage.includes(
                "BK and Client not associated"
              ) ||
              error.response.data.errorMessage.includes(
                "Not allowed for bookkeeper paid subscription"
              ) ||
              error.response.data.errorMessage.includes(
                "Could not validate the user"
              )
            ) {
              console.log(error.response.data.errorMessage);
            } else {
              showAlert("Error occurred!", error.response.data.errorMessage);
            }
          }
        }
      return Promise.reject(error.response.data);
    } else {
      showAlert(
        "Error occurred!",
        "There was some problem with server. Please try again later."
      );
      return Promise.reject(error.response);
    }
  }
};

axiosInstance.interceptors.request.use(
  (response) => requestInterceptSuccess(response),
  (error) => requestInterceptError(error)
);

axiosInstance.interceptors.response.use(
  (response) => responseInterceptSuccess(response),
  (error) => responseInterceptError(error)
);

// Enable below interceptor for testing with local subengine APIs.
// For subengine APIs to access locally replace baseURL and add x-access-token in headers
// axiosInstance.interceptors.request.use((req) => {
//   if (req.url.includes("subengine")) {
//     var updatedUrl = req.baseURL.replace("https://api-dev.deskera.xyz","http://localhost:8080")

//     // let Hdrs = req.headers;
//     // todo why doesn't this work ?
//     // console.log("Hdrs are ", Hdrs)
//     let updatedHeaders = {...req["headers"],
//         "x-access-token": ApiConstants.ACCESS_TOKEN,
//     }
//     req.baseURL = updatedUrl;
//     req['headers'] = updatedHeaders;
//   }
// 	// Important: request interceptors **must** return the request.
// 	return req;
// });

export default axiosInstance;
