import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import TimeoutManager from "../../managers/TimeoutManager";
import ApiConstants from "../../constants/ApiConstants";
import { deepClone } from "../common/CommonUtils";
import { t } from "i18next";
import {
    DKLabel,
    DKButton,
    DKInput,
    showToast,
    TOAST_TYPE,
    Toggle,
    INPUT_TYPE,
    INPUT_VIEW_DIRECTION
} from "deskera-ui-library";

class TimeoutSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialTimeoutData: {
                sessionTimeoutEnabled: false,
                sessionTimeout: 540,
                idleTimeoutEnabled: false,
                idleTimeout: 60,
            },
            timeoutData: {
                sessionTimeoutEnabled: false,
                sessionTimeout: 540,
                idleTimeoutEnabled: false,
                idleTimeout: 60,
            },
            idleTimeoutOptions: TimeoutManager.convertToTimeoutOptions(ApiConstants.IDLE_TIMEOUT_OPTIONS),
            sessionTimeoutOptions: TimeoutManager.convertToTimeoutOptions(ApiConstants.SESSION_TIMEOUT_OPTIONS),
            isInvalidIdleTimeout: false,
            isDataChange: false
        }
    }

    componentDidMount() {
        this.setTimeoutData();
    }

    async setTimeoutData() {
        if (ApiConstants.TIMEOUT_ENABLED) {
            let timeoutData = TimeoutManager.getTimeoutSettingsData();
            if (!timeoutData) {
                await TimeoutManager.getIdleTimeoutTokenExpiry().then(
                    (response) => {
                        if (response.id) {
                            TimeoutManager.setTimeoutSettingsData(response);
                            timeoutData = response;
                        }
                    }, (error) => {
                        console.log(error);
                    },
                );
            }

            if (timeoutData?.id) {
                const data = {
                    sessionTimeoutEnabled: timeoutData.tokenExpiryEnabled,
                    sessionTimeout: timeoutData.tokenExpiryValue,
                    idleTimeoutEnabled: timeoutData.idleTimeoutEnabled,
                    idleTimeout: timeoutData.idleTimeoutValue,
                };

                this.setState({
                    initialTimeoutData: deepClone(data),
                    timeoutData: deepClone(data)
                });
            }
        }
    }

    setIdleTimeoutTokenExpiry() {
        if (this.state.isInvalidIdleTimeout) {
            return;
        }

        const payload = {
            enableTokenExpiry: this.state.timeoutData.sessionTimeoutEnabled,
            tokenExpiryValue: this.state.timeoutData.sessionTimeout,
            enableIdleTimeout: this.state.timeoutData.idleTimeoutEnabled,
            idleTimeoutValue: this.state.timeoutData.idleTimeout
        }

        TimeoutManager.setIdleTimeoutTokenExpiry(payload).then(
            (response) => {
                if (response.id) {
                    const data = {
                        sessionTimeoutEnabled: response.tokenExpiryEnabled,
                        sessionTimeout: response.tokenExpiryValue,
                        idleTimeoutEnabled: response.idleTimeoutEnabled,
                        idleTimeout: response.idleTimeoutValue,
                    };

                    this.setState({
                        initialTimeoutData: deepClone(data),
                        timeoutData: deepClone(data)
                    });

                    showToast(t("TIMEOUT_SAVE_SUCCESS"), TOAST_TYPE.SUCCESS);
                }
                this.setState({
                    isDataChange: false
                });
            }, () => {
                showToast(t("TIMEOUT_SAVE_FAILURE"), TOAST_TYPE.ERROR);
            }
        );
    }

    onDataChange() {
        const isInvalidIdleTimeout = this.state.timeoutData.sessionTimeoutEnabled && this.state.timeoutData.idleTimeoutEnabled &&
            (this.state.timeoutData.idleTimeout > this.state.timeoutData.sessionTimeout);

        let isDataChange = false;
        if (this.state.initialTimeoutData.sessionTimeoutEnabled !== this.state.timeoutData.sessionTimeoutEnabled ||
            this.state.initialTimeoutData.idleTimeoutEnabled !== this.state.timeoutData.idleTimeoutEnabled ||
            this.state.initialTimeoutData.sessionTimeout !== this.state.timeoutData.sessionTimeout ||
            this.state.initialTimeoutData.idleTimeout !== this.state.timeoutData.idleTimeout) {
            isDataChange = true;
        }

        if (!this.state.initialTimeoutData.sessionTimeoutEnabled &&
            !this.state.initialTimeoutData.idleTimeoutEnabled &&
            this.state.initialTimeoutData.sessionTimeoutEnabled === this.state.timeoutData.sessionTimeoutEnabled &&
            this.state.initialTimeoutData.idleTimeoutEnabled === this.state.timeoutData.idleTimeoutEnabled) {
            isDataChange = false;
        }

        this.setState({
            isDataChange: !isInvalidIdleTimeout && isDataChange,
            isInvalidIdleTimeout: isInvalidIdleTimeout
        });
    }

    getSessionTimeoutView() {
        return (
            <div className="parent-width">
                <div className="row justify-content-between">
                    <DKLabel text={t("SESSION_TIMEOUT")} className="fw-m fs-m" />
                    <Toggle
                        isOn={this.state.timeoutData.sessionTimeoutEnabled}
                        onChange={() => {
                            this.setState({
                                timeoutData: {
                                    ...this.state.timeoutData,
                                    sessionTimeoutEnabled: !this.state.timeoutData.sessionTimeoutEnabled
                                }
                            }, this.onDataChange);
                        }}
                        color="bg-blue"
                    />
                </div>
                <DKLabel text={t("SET_SESSION_TIMEOUT_HERE")} className="text-gray" />
                <div className="mt-l mb-s">
                    <div style={{ maxWidth: "250px" }}>
                        <DKInput
                            title={t("SESSION_TIMEOUT")}
                            type={INPUT_TYPE.DROPDOWN}
                            direction={INPUT_VIEW_DIRECTION.VERTICAL}
                            formatter={(item) => {
                                return item.name;
                            }}
                            renderer={(item) => {
                                if (typeof item !== "object") {
                                    const newItem = this.state.sessionTimeoutOptions.find(t => t.id === item)
                                    return newItem ? newItem.name : item;
                                }
                                return item;
                            }}
                            dropdownConfig={{
                                data: this.state.sessionTimeoutOptions,
                                renderer: (i, item) => {
                                    return item.name;
                                },
                                onSelect: (i, item) => {
                                    this.setState({
                                        timeoutData: {
                                            ...this.state.timeoutData,
                                            sessionTimeout: item.id
                                        }
                                    }, this.onDataChange);
                                },
                            }}
                            value={this.state.timeoutData.sessionTimeout}
                            valueStyle={{
                                backgroundColor: "white"
                            }}
                            required={this.state.timeoutData.sessionTimeoutEnabled}
                            readOnly={!this.state.timeoutData.sessionTimeoutEnabled}
                            onChange={() => { }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    getIdleTimeoutView() {
        return (
            <div className="parent-width">
                <div className="row justify-content-between">
                    <DKLabel text={t("INACTIVE_TIMEOUT")} className="fw-m fs-m" />
                    <Toggle
                        isOn={this.state.timeoutData.idleTimeoutEnabled}
                        onChange={() => {
                            this.setState({
                                timeoutData: {
                                    ...this.state.timeoutData,
                                    idleTimeoutEnabled: !this.state.timeoutData.idleTimeoutEnabled
                                }
                            }, this.onDataChange);
                        }}
                        color="bg-blue"
                    />
                </div>
                <div>
                    <DKLabel text={t("SET_INACTIVE_TIMEOUT_HERE")} className="text-gray" />
                    <div className="mt-l mb-s">
                        <div style={{ maxWidth: "250px" }}>
                            <DKInput
                                title={t("INACTIVE_TIMEOUT")}
                                type={INPUT_TYPE.DROPDOWN}
                                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                                formatter={(item) => {
                                    return item.name;
                                }}
                                renderer={(item) => {
                                    if (typeof item !== "object") {
                                        const newItem = this.state.idleTimeoutOptions.find(t => t.id === item)
                                        return newItem ? newItem.name : item;
                                    }
                                    return item;
                                }}
                                dropdownConfig={{
                                    data: this.state.idleTimeoutOptions,
                                    renderer: (i, item) => {
                                        return item.name;
                                    },
                                    onSelect: (i, item) => {
                                        this.setState({
                                            timeoutData: {
                                                ...this.state.timeoutData,
                                                idleTimeout: item.id
                                            }
                                        }, this.onDataChange);
                                    },
                                }}
                                value={this.state.timeoutData.idleTimeout}
                                titleStyle={{
                                    color: this.state.isInvalidIdleTimeout && "rgb(203, 26, 38)"
                                }}
                                valueStyle={{
                                    backgroundColor: "white",
                                    border: this.state.isInvalidIdleTimeout && "1px solid rgb(203, 26, 38)"
                                }}
                                required={this.state.timeoutData.idleTimeoutEnabled}
                                readOnly={!this.state.timeoutData.idleTimeoutEnabled}
                                onChange={() => { }}
                            />
                        </div>
                        <div>
                            {this.state.isInvalidIdleTimeout &&
                                <DKLabel
                                    className="fs-s text-red"
                                    style={{
                                        marginLeft: "2px",
                                        marginTop: "5px",
                                        marginBottom: "-16px"
                                    }}
                                    text={t("INACTIVE_TIMEOUT_ERROR_MSG")}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="p-l border-m border-radius-s shadow-s bg-white parent-width column mb-xl">
                <div className="parent-width">
                    <div className="row justify-content-between">
                        <div>
                            <DKLabel text={t("TIMEOUT_SETTINGS")} className="fw-m fs-m" />
                            <DKLabel text={t("TIMEOUT_SETTINGS_TEXT")} className="text-gray mt-xs" />
                        </div>
                        {this.state.isDataChange &&
                            <DKButton
                                title={t("Save")}
                                className="bg-blue text-white fw-m"
                                onClick={() => {
                                    this.setIdleTimeoutTokenExpiry();
                                }}
                            />
                        }
                    </div>
                    <div className="row align-items-stretch mt-l">
                        <div className="row border-box bg-gray1 border-radius-s border-m p-l mr-r">
                            {this.getSessionTimeoutView()}
                        </div>
                        <div className="row border-box bg-gray1 border-radius-s border-m p-l ml-r">
                            {this.getIdleTimeoutView()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TimeoutSettings);
