import React, { Component } from "react";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";

import { DKLabel, DKButton, DKIcon } from "deskera-ui-library";

import {
  CURRENCYLIST,
  INTERVALS,
  PRODUCTS,
  BOOKKEEPER_FLOW,
  PLANS,
} from "../../constants/Enum";
import BillingProductCard from "../common/BillingProductCard";
import ic_coupon from "../../assets/icons/white/ic_coupon.png";
import Payload from "../../constants/Payload";
import SubscriptionManager from "../../managers/SubscriptionManager";
import CreditCardView from "../common/CreditCardView";
import PrepaidCodePopup from "../common/PrepaidCodePopup";
import RedeemPrepaidCoupons from "../common/RedeemPrepaidCoupons";
import BillingHistory from "../common/BillingHistory";
import CouponsManager from "../../managers/CouponsManager";
import PermissionManager from "../../managers/PermissionManager";
import BillingManager from "../../managers/BillingManager";
import Utility from "../../utility/Utility";
import PlanManager from "../../managers/PlanManager";
import ProductIcon from "../common/ProductIcon";
import ic_info from "../../assets/icons/ic_info.png";
import AdyenCardView from "../common/AddAdyenCardUI/AdyenCardView";
import CashfreeView from "../common/CashfreeUI/CashfreeView";
import Subscription from "../../services/subscription";
import DataParser from "../../Helper/DataParser";
import { isMobileAppWebView } from "../../utility/ViewportSizeUtils";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import BillingProductCardForERP from "../common/BillingProductCardForERP";
import BillingProductCardForMRP from "../common/BillingProductCardForMRP";
import CreditCodePopup from "../../components/plan-details/CreditCodePopup";
class BillingDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkoutTapped: false,
      needPrepaidPopup: false,
      needCreditPopup: false,
      columnPosition: window.innerWidth < 1450 ? "wrap" : "nowrap",
    };
  }
  componentDidMount() {
    RouteManager.setPresenter(this);

    localStorage.setItem(BOOKKEEPER_FLOW.TENANT_ID, "");
    localStorage.setItem(BOOKKEEPER_FLOW.USER_ID, "");

    if (!PermissionManager.isSubscriptionAccessible()) {
      RouteManager.navigateToHome();
      return;
    }

    Subscription.getDetails()
      .then((res) => {
        this.refreshScreen();
      })
      .catch((err) => {
        this.refreshScreen();
      });

    window.addEventListener("resize", this.setPosition);
  }

  setPosition = () => {
    if (window.innerWidth < 1450) {
      this.setState({
        columnPosition: "wrap",
      });
    } else {
      this.setState({
        columnPosition: "nowrap",
      });
    }
  };

  refreshScreen = () => {
    this.setState({});
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    const { t } = this.props;
    return (
      <div className="parent-width">
        <div className="row">
          {/* <div className="fw-m fs-l">My Subscriptions</div> */}
          {PlanManager.isFreemium && (
            <DKLabel
              text={"( You are on a freemium plan, choose a plan to upgrade )"}
              className="ml-s fw-b"
            />
          )}
        </div>
        <div
          className="row row-responsive mt-l align-items-start flex"
          style={{ flexWrap: this.state.columnPosition }}
        >
          <div
            className="column pr-r-only-web"
            style={{ flex: 3, width: "100%" }}
          >
            <div className="column parent-width">
              {!Utility.isEmpty(SubscriptionManager.subscriptionDetails) &&
                this.getAllProductsView()}
              {!isMobileAppWebView() &&
                !Utility.isEmpty(
                  SubscriptionManager.get() &&
                    SubscriptionManager.get().ProductLimits
                ) &&
                this.accountInfoView()}
            </div>
          </div>
          <div className="column right-section-2" style={{ flex: 1 }}>
            {/* stripe */}
            {!CouponsManager.isVendorPrepaidCouponApplied() &&
              (BillingManager.showStripePayment() ||
                !BillingManager.isDefaultPaymentGateway()) &&
              this.getCreditCardDetailsView()}
            {/* adyen */}
            {!CouponsManager.isVendorPrepaidCouponApplied() &&
              BillingManager.showAdyenPayment() &&
              this.getAdyenCardView()}
            {/* cashfree */}
            {!CouponsManager.isVendorPrepaidCouponApplied() &&
              BillingManager.showCashfreePayment() &&
              this.getCashfreeView()}

            {!isMobileAppWebView() && <BillingHistory />}
            {!isMobileAppWebView() && <RedeemPrepaidCoupons />}
            <DKButton
              title={
                isMobileAppWebView() ? t("REDEEM_COUPON") : t("PREPAID_CODE_?")
              }
              className={`mt-l text-white fw-m ${
                isMobileAppWebView()
                  ? " p-v-r bg-purple align-self-center justify-content-center mobile-width-85 "
                  : " bg-blue"
              }`}
              style={{ width: "100%" }}
              icon={ic_coupon}
              onClick={() => {
                this.setState({
                  needPrepaidPopup: true,
                });
              }}
            />
            <DKButton
              title={
                isMobileAppWebView() ? t("REDEEM_CREDIT") : t("CREDIT_CODE_?")
              }
              className={`mt-l text-white fw-m ${
                isMobileAppWebView()
                  ? " p-v-r bg-purple align-self-center justify-content-center mobile-width-85 "
                  : " bg-blue"
              }`}
              style={{ width: "100%" }}
              icon={ic_coupon}
              onClick={() => {
                this.setState({
                  needCreditPopup: true,
                });
              }}
            />
          </div>
        </div>
        {this.state.needPrepaidPopup && (
          <PrepaidCodePopup
            onClose={this.closePrepaidPopup}
            onCouponApply={this.prepaidCouponApplied}
          />
        )}
        {this.state.needCreditPopup && (
          <CreditCodePopup
            onClose={() => {
              this.setState({ needCreditPopup: false });
            }}
            onCodeApplied={(response) => {
              this.setState({ needCreditPopup: false });
              window.location.reload();
            }}
          />
        )}
      </div>
    );
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  prepaidCouponApplied = () => {
    this.setState({ needPrepaidPopup: false });
    this.refreshScreen();
  };

  closePrepaidPopup = () => {
    this.setState({ needPrepaidPopup: false });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getCreditCardDetailsView() {
    return (
      <CreditCardView
        onCardAdded={() => {
          BillingManager.setCardAsAdded(true);
          this.setState({});
        }}
      />
    );
  }

  getAdyenCardView = () => {
    return (
      <AdyenCardView
        onCardAdded={() => {
          BillingManager.setCardAsAdded(true);
          this.setState({});
        }}
      />
    );
  };

  getCashfreeView = () => {
    return <CashfreeView />;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getPlanDate = (nextChargeDate, plan) => {
    let prepaidList = CouponsManager.getPrepaidCoupons();

    if (prepaidList.length > 0) {
      return CouponsManager.getCouponListForEachPlan(
        nextChargeDate,
        prepaidList,
        plan
      );
    } else {
      return DataParser.getBillDateFormat(new Date(nextChargeDate));
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getAllProductsView() {
    let newERPPlan = SubscriptionManager.getPlanForNewERPCard();
    let mrpPlan = Payload.PLAN.ERP;
    if (
      SubscriptionManager.isSubscriptionEnabledForNewERPPlan() &&
      !SubscriptionManager.isMRPEnabled()
    ) {
      mrpPlan = Payload.PLAN.AIO;
    }
    let newERPProduct = SubscriptionManager.getProductForNewERPCard();

    const hideNewCards =
      SubscriptionManager.anyOneOldPlanIsActive() &&
      SubscriptionManager.getCurrencySameAsActivePlanCurrency() !==
        CURRENCYLIST.INR &&
      SubscriptionManager.getCurrencySameAsActivePlanCurrency() !==
        CURRENCYLIST.USD;
    return (
      <div
        className=" main-holder-p-horizontal main-holder-p-vertical pt-l pb-l border-m border-radius-s shadow-s  parent-width column mb-xl bg-white"
        // style={{ backgroundColor: "rgb(245, 245, 245)" }}
      >
        <DKLabel text={"My Subscriptions"} className="fw-m fs-m" />
        <DKLabel
          text={t("MANAGE_YOUR_SUBSCRIPTION")}
          className="text-gray mt-xs mb-s"
        />
        {!(
          CouponsManager.isPrepaidCouponApplied() &&
          SubscriptionManager.areAllProductsActive()
        ) &&
          SubscriptionManager.showOldCardIfSubscriptionFromOldPlan(
            Payload.PLAN.AIO
          ) && (
            <BillingProductCard
              product={PRODUCTS.AIO}
              plan={
                SubscriptionManager.subscriptionDetails &&
                SubscriptionManager.subscriptionDetails[Payload.PLAN.AIO][
                  Payload.PLAN_DETAILS.NAME
                ]
              }
              status={SubscriptionManager.getSubscriptionStatus(
                SubscriptionManager.subscriptionDetails &&
                  SubscriptionManager.subscriptionDetails[Payload.PLAN.AIO]
              )}
              planInterval={
                SubscriptionManager.subscriptionDetails &&
                SubscriptionManager.subscriptionDetails[Payload.PLAN.AIO][
                  Payload.PLAN_DETAILS.INTERVAL
                ]
              }
              currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
              remainingTrialDays={
                SubscriptionManager.subscriptionDetails &&
                SubscriptionManager.subscriptionDetails[Payload.PLAN.AIO][
                  Payload.PLAN_DETAILS.TRIAL_DAYS
                ]
              }
              nextChargedDate={
                SubscriptionManager.subscriptionDetails &&
                SubscriptionManager.subscriptionDetails[Payload.PLAN.AIO][
                  Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
                ]
              }
              activeTill={
                SubscriptionManager.subscriptionDetails &&
                this.getPlanDate(
                  SubscriptionManager.subscriptionDetails[Payload.PLAN.AIO][
                    Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
                  ],
                  "aio"
                )
              }
              onDataChange={this.refreshScreen}
            />
          )}
        {SubscriptionManager.showOldCardIfSubscriptionFromOldPlan(
          Payload.PLAN.ERP
        ) && (
          <BillingProductCard
            product={PRODUCTS.ERP}
            plan={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.ERP][
                Payload.PLAN_DETAILS.NAME
              ]
            }
            status={SubscriptionManager.getSubscriptionStatus(
              SubscriptionManager.subscriptionDetails &&
                SubscriptionManager.subscriptionDetails[Payload.PLAN.ERP]
            )}
            planInterval={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.ERP][
                Payload.PLAN_DETAILS.INTERVAL
              ]
            }
            currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
            remainingTrialDays={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.ERP][
                Payload.PLAN_DETAILS.TRIAL_DAYS
              ]
            }
            nextChargedDate={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.ERP][
                Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
              ]
            }
            activeTill={
              SubscriptionManager.subscriptionDetails &&
              this.getPlanDate(
                SubscriptionManager.subscriptionDetails[Payload.PLAN.ERP][
                  Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
                ],
                "erp"
              )
            }
            onDataChange={this.refreshScreen}
          />
        )}
        {SubscriptionManager.showOldCardIfSubscriptionFromOldPlan(
          Payload.PLAN.CRM
        ) && (
          <BillingProductCard
            product={PRODUCTS.CRM}
            plan={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.CRM][
                Payload.PLAN_DETAILS.NAME
              ]
            }
            status={SubscriptionManager.getSubscriptionStatus(
              SubscriptionManager.subscriptionDetails &&
                SubscriptionManager.subscriptionDetails[Payload.PLAN.CRM]
            )}
            planInterval={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.CRM][
                Payload.PLAN_DETAILS.INTERVAL
              ]
            }
            currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
            remainingTrialDays={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.CRM][
                Payload.PLAN_DETAILS.TRIAL_DAYS
              ]
            }
            nextChargedDate={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.CRM][
                Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
              ]
            }
            activeTill={
              SubscriptionManager.subscriptionDetails &&
              this.getPlanDate(
                SubscriptionManager.subscriptionDetails[Payload.PLAN.CRM][
                  Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
                ],
                "crm"
              )
            }
            onDataChange={this.refreshScreen}
          />
        )}
        {SubscriptionManager.showOldCardIfSubscriptionFromOldPlan(
          Payload.PLAN.PEOPLE
        ) && (
          <BillingProductCard
            product={PRODUCTS.PEOPLE}
            plan={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.PEOPLE][
                Payload.PLAN_DETAILS.NAME
              ]
            }
            status={SubscriptionManager.getSubscriptionStatus(
              SubscriptionManager.subscriptionDetails &&
                SubscriptionManager.subscriptionDetails[Payload.PLAN.PEOPLE]
            )}
            planInterval={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.PEOPLE][
                Payload.PLAN_DETAILS.INTERVAL
              ]
            }
            currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
            remainingTrialDays={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.PEOPLE][
                Payload.PLAN_DETAILS.TRIAL_DAYS
              ]
            }
            nextChargedDate={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[Payload.PLAN.PEOPLE][
                Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
              ]
            }
            activeTill={
              SubscriptionManager.subscriptionDetails &&
              this.getPlanDate(
                SubscriptionManager.subscriptionDetails[Payload.PLAN.PEOPLE][
                  Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
                ],
                "people"
              )
            }
            onDataChange={this.refreshScreen}
          />
        )}

        {/* ERP Card */}
        {!hideNewCards && (
          <BillingProductCardForERP
            product={PRODUCTS.ERP}
            plan={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[newERPPlan][
                Payload.PLAN_DETAILS.NAME
              ]
            }
            status={SubscriptionManager.getSubscriptionStatusForNewERP(
              SubscriptionManager.subscriptionDetails &&
                SubscriptionManager.subscriptionDetails[newERPPlan]
            )}
            planInterval={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[newERPPlan][
                Payload.PLAN_DETAILS.INTERVAL
              ]
            }
            currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
            remainingTrialDays={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[newERPPlan][
                Payload.PLAN_DETAILS.TRIAL_DAYS
              ]
            }
            nextChargedDate={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[newERPPlan][
                Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
              ]
            }
            activeTill={
              SubscriptionManager.subscriptionDetails &&
              this.getPlanDate(
                SubscriptionManager.subscriptionDetails[newERPPlan][
                  Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
                ],
                newERPProduct?.toLowerCase()
              )
            }
            onDataChange={this.refreshScreen}
          />
        )}

        {/* MRP Card */}
        {!hideNewCards && (
          <BillingProductCardForMRP
            product={PRODUCTS.ERP}
            plan={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[mrpPlan][
                Payload.PLAN_DETAILS.NAME
              ]
            }
            status={SubscriptionManager.getSubscriptionStatusForNewMRP(
              SubscriptionManager.subscriptionDetails &&
                SubscriptionManager.subscriptionDetails[mrpPlan]
            )}
            planInterval={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[mrpPlan][
                Payload.PLAN_DETAILS.INTERVAL
              ]
            }
            currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
            remainingTrialDays={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[mrpPlan][
                Payload.PLAN_DETAILS.TRIAL_DAYS
              ]
            }
            nextChargedDate={
              SubscriptionManager.subscriptionDetails &&
              SubscriptionManager.subscriptionDetails[mrpPlan][
                Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
              ]
            }
            activeTill={
              SubscriptionManager.subscriptionDetails &&
              this.getPlanDate(
                SubscriptionManager.subscriptionDetails[mrpPlan][
                  Payload.PLAN_DETAILS.NEXT_CHARGE_DATE
                ],
                mrpPlan === Payload.PLAN.ERP ? "erp" : "aio"
              )
            }
            onDataChange={this.refreshScreen}
          />
        )}

        {SubscriptionManager.getCurrencySameAsActivePlanCurrency() ===
          CURRENCYLIST.INR && (
          <div className="row justify-content-end mt-m">
            {t("PRICE_EXCLUDES_GST")}
          </div>
        )}
      </div>
    );
  }
  ////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////
  accountInfoView = () => {
    const isMRPEnabled = SubscriptionManager.isMRPEnabled();
    return (
      <div
        className={` main-holder-p-horizontal main-holder-p-vertical pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width column mb-xl ${
          isMobileAppWebView() ? " border-radius-r " : ""
        }`}
      >
        <DKLabel text={t("ACCOUNT_INFORMATION")} className="fw-m fs-m" />
        <div className={" pl-s pr-s pt-xs pb-xs "}>
          {SubscriptionManager.get() &&
            SubscriptionManager.get().ProductLimits.map((data) => {
              return (
                <div className="row mt-l">
                  <ProductIcon
                    product={data.Code.toLowerCase()}
                    className="ic-r mr-m"
                  />
                  <DKLabel
                    text={
                      "Deskera " +
                      PlanManager.getProductDisplayName(
                        data.Code.toLowerCase()
                      ) +
                      (isMRPEnabled && data.Code.toLowerCase() === PRODUCTS.ERP
                        ? ", MRP"
                        : "")
                    }
                    className="fw-m fs-l mr-l"
                    style={{ width: "180px" }}
                  />
                  <div
                    className="bg-gray2 border-m border-radius-m pt-s pb-s pl-s pr-s"
                    style={{ display: "flex" }}
                  >
                    <DKLabel
                      text={`${data.FreeUserCount} ${t(
                        "POWER_USER(S)_ALLOWED"
                      )}`}
                      className="fw-m fs-r "
                    />
                    <DKIcon
                      src={ic_info}
                      className="ic-s ml-r cursor-hand"
                      onClick={() => {
                        RouteManager.navigateToPage(
                          PAGE_ROUTES.PLAN_SELECTION,
                          "product=" +
                            data.Code.toLowerCase() +
                            "&interval=" +
                            INTERVALS.MONTHLY
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };
}
export default withTranslation()(BillingDashboard);
