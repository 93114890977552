import React, { Component } from "react";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";

import { DKButton, DKLabel, showLoader, removeLoader } from "deskera-ui-library";

import UserDetails from "../common/UserDetails";
import ProductList from "../common/ProductList";
import PlanDetails from "../common/PlanDetails";
import Updates from "../common/Updates";
import { SUBSCRIPTION_STATUS, PRODUCTS, PLANS } from "../../constants/Enum";
// import { MAX_SECTION_WIDTH } from "../../constants/Constant";
import SubscriptionManager from "../../managers/SubscriptionManager";
import PlanManager from "../../managers/PlanManager";
import ApiConstants from "../../constants/ApiConstants";

import ic_btn_ios from "../../assets/mobile/ic_app_store.svg";
import ic_btn_android from "../../assets/mobile/ic_google_play.svg";

import ic_combined from "../../assets/awards/ic_combined.png";
import UserManager from "../../managers/UserManager";
import PermissionManager from "../../managers/PermissionManager";
import CouponsManager from "../../managers/CouponsManager";
import DataParser from "../../Helper/DataParser";
import Subscription from "../../services/subscription";
import AddOnboardPopup from "../common/AddOnboardPopup";
import http from "../../http";
import Utility, { Cookies } from "../../utility/Utility";
import { Analytics } from "../../utility/GTMEvents";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import PlanDetailsCard from "../common/PlanDetailsCard";
import Payload from "../../constants/Payload";
import { getBillingDetails } from "../../services/credit";
import StatusBadge from "../common/StatusBadge";
import { COLORS } from "../../constants/Colors";
import ProductIcon from "../common/ProductIcon";
import { TALK_TO_SALES_URL_MRP } from "../../constants/Constant";
class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planDetailsHeight: window.innerHeight - 110,
      profileNotSet: "",
      creditBasedBillingDetails: null,
    };
  }
  componentDidMount() {
    RouteManager.setPresenter(this);
    if (UserManager.isUserAdmin() || UserManager.isUserOwner()) {
      this.getUserDetails(UserManager?.getUserDetails()?.imID);
    }
    Subscription.getDetails()
      .then((res) => {
        if (res?.AIOPlan?.Name === PLANS.FREEMIUM) {
          PlanManager.isFreemium = true;
        }
      })
      .catch((err) => {});
    if (!Utility.isEmpty(SubscriptionManager.subscriptionDetails)) {
      this.fetchCreditBasedBillingDetails();
    }
    window.addEventListener("resize", this.setPlanDetailsHeight);
  }

  fetchCreditBasedBillingDetails = async () => {
    showLoader("Fetching billing details...");
    try {
      const response = await getBillingDetails();
      this.setState({ creditBasedBillingDetails: response });
    } catch (err) {
      console.error("Error loading credit based billing details: ", err);
    } finally {
      removeLoader();
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  getUserDetails(userId) {
    http.get(ApiConstants.URL.IAM.GET_PROFILE(userId)).then((res) => {
      if (
        res?.userNotUpdated === false &&
        typeof window !== undefined &&
        Boolean(Cookies.get("free_trial_sso")) === true
      ) {
        Analytics.OnSocialSignup(res);
        Cookies.deleteCookieOfCustumDomain(
          "free_trial_sso",
          process.env.REACT_APP_GTM_COOKIE_URL
        );
      }

      this.setState({
        profileNotSet: res?.userNotUpdated,
      });
    });
  }

  setPlanDetailsHeight = () => {
    this.setState({
      planDetailsHeight: window.innerHeight - 110,
    });
  };

  refreshScreen = () => {
    this.setState({});
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="row fw-m fs-l">
          {(UserManager.isUserAdmin() || UserManager.isUserOwner()
            ? t("ADMIN")
            : t("USER")) + t("CENTER")}
          {PlanManager.isFreemium && (
            <DKLabel
              text={"( You are on a freemium plan, choose a plan to upgrade )"}
              className="ml-s"
            />
          )}
        </div>
        <div className="row row-responsive mt-m align-items-start">
          <div className="column pr-r-only-web" style={{ flex: 1 }}>
            <div className="column parent-width border-box">
              {!PlanManager.isFreemium &&
                (this.state.profileNotSet ||
                  Boolean(Cookies.get("showBannerSetup")) === true) &&
                this.showOnBoardFlow()}
              {this.getUserDetailsView()}
              {this.getAllProductsView()}
              {this.getFooter()}
            </div>
          </div>
          <div
            className="column right-section-1 hide-scroll-bar scroll-y-only-web"
            style={{
              maxHeight: this.state.planDetailsHeight,
              marginTop: 0,
            }}
          >
            {!PermissionManager.showPlanDetailsSection() && (
              <React.Fragment>
                <DKLabel text={"Plan Details"} className="mb-m fw-m fs-m" />
                {SubscriptionManager.showNewCreditBasedFlow() ? this.getCreditPlanDetailsView() : this.getPlanDetailsView()}
              </React.Fragment>
            )}
            {/* {this.getUpdatesView()} */}
          </div>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  showOnBoardFlow() {
    return <AddOnboardPopup />;
  }

  getUserDetailsView() {
    return <UserDetails />;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getFooter() {
    return (
      <div
        className="mt-l mb-xl pl-l pr-l pt-l pb-r border-m border-radius-s shadow-s bg-white row justify-content-between align-items-start row-responsive"
        style={{ display: "flex", flexWrap: "wrap", gap: "2%" }}
      >
        {this.getMobileView()}

        {this.getAwardsView()}
      </div>
    );
  }

  getMobileView() {
    return (
      <div className="column width-m-100" style={{ width: "49%" }}>
        <DKLabel text={t("DESKERA_MOBILE_APP")} className="fw-m fs-m" />
        <DKLabel
          text={t("DESKERA_MOBILE_APP_TEXT")}
          className="text-gray mt-xs"
        />
        <div className="row " style={{ display: "flex", flexWrap: "wrap" }}>
          <img
            src={ic_btn_ios}
            alt=""
            className=" mr-m mt-l cursor-hand"
            onClick={() => {
              window.open(ApiConstants.URL_MOBILE_IOS, "_blank");
            }}
            style={{ opacity: 0.9, height: 37 }}
          />
          <img
            src={ic_btn_android}
            alt=""
            className=" mr-m mt-l cursor-hand"
            onClick={() => {
              window.open(ApiConstants.URL_MOBILE_ANDROID, "_blank");
            }}
            style={{ opacity: 0.9, height: 37 }}
          />
        </div>
        <DKLabel text={t("DOWNLOAD_NOW")} className="text-gray mt-m" />
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getAwardsView() {
    return (
      <div className="column width-m-100" style={{ width: "49%" }}>
        <DKLabel text={t("AWARDS")} className="fw-m fs-m" />
        <DKLabel text={t("AWARDS_TEXT")} className="text-gray mt-xs" />
        <div className="parent-width">
          <img src={ic_combined} alt="" className="mt-s dashboard-award" />
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getAllProductsView() {
    return <ProductList />;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getCreditPlanDetailsView() {
    if (Utility.isEmpty(SubscriptionManager.subscriptionDetails)) {
      return null;
    }

    const availableCredits = this.state?.creditBasedBillingDetails?.TotalAvailableCredits;
    return (
      <div
        className={" mb-l pl-r pr-r pt-r border-m border-radius-s parent-width column shadow-ms bg-deskera-secondary"}
        style={{ paddingBottom: 26, width: 350 }}
      >
        <div className="row mt-r justify-content-between">
          <div className="row">
            <div>
              <div
                className="row border-radius-s fs-r fw-m cursor-hand text-wrap-none"
                style={{
                  padding: 3,
                  paddingRight: 8,
                  backgroundColor: COLORS.erp,
                  color: COLORS.white,
                }}
                onClick={() => {
                  RouteManager.navigateToPage(PAGE_ROUTES.BILLING);
                }}
              >
                <ProductIcon product={PRODUCTS.ERP_V2} />
                <DKLabel text="Deskera ERP" />
              </div>
            </div>
          </div>
          <StatusBadge status={SUBSCRIPTION_STATUS.ACTIVE} />
        </div>
        <DKLabel className="mt-r" style={{ minHeight: 28 }} text="Usage based plan" />
        <div className="mt-r">
          <DKLabel className={"fw-b fs-xl-2"} text={`${availableCredits || 0} credits`} />
          <DKLabel className="mt-xs" text="Billed monthly" />
        </div>
        {PermissionManager.isSubscriptionAccessible() ? (
          <div className="row mt-l">
            <DKButton
              title="Know More"
              className="bg-white border-m shadow-s fw-m mr-s"
              onClick={() => {
                RouteManager.navigateToPage(PAGE_ROUTES.BILLING);
              }}
            />
            <DKButton
              title="Talk to Sales"
              className="bg-blue text-white border-m fw-m"
              onClick={() => {
                window?.open(TALK_TO_SALES_URL_MRP, "_blank");
              }}
            />
          </div>
        ) : (
          <DKLabel className="text-red mt-s mb-s" text="No permissions provided, please contact owner/admin" />
        )}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getPlanDetailsView() {
    if (Utility.isEmpty(SubscriptionManager.subscriptionDetails)) {
      return null;
    }
    if (!SubscriptionManager.anyOneOldPlanIsActive()) {
      let newERPPlan = SubscriptionManager.getPlanForNewERPCard();

      let mrpPlan = Payload.PLAN.ERP;
      if (
        SubscriptionManager.isSubscriptionEnabledForNewERPPlan() &&
        !SubscriptionManager.isMRPEnabled()
      ) {
        mrpPlan = Payload.PLAN.AIO;
      }

      let arr = [];

      if (SubscriptionManager.isMRPEnabled()) {
        arr.push(
          <PlanDetailsCard
            product={PRODUCTS.MRP}
            plan={SubscriptionManager.erpPlan(mrpPlan)}
            planInterval={SubscriptionManager.erpPlanInterval(mrpPlan)}
            status={SubscriptionManager.mrpPlanStatus(mrpPlan)}
            currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
            remainingTrialDays={0}
            onTrialActivate={this.refreshScreen}
            nextChargeDate={"-"}
          />
        );
      } else {
        if (SubscriptionManager.isNewERPPlan()) {
          arr.push(
            <PlanDetailsCard
              product={PRODUCTS.ERP_V2}
              plan={SubscriptionManager.erpPlan(newERPPlan)}
              planInterval={SubscriptionManager.erpPlanInterval(newERPPlan)}
              status={SubscriptionManager.erpPlanStatus(newERPPlan)}
              remainingTrialDays={SubscriptionManager.erpPlanDaysRemaining(
                newERPPlan
              )}
              currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
              onTrialActivate={this.refreshScreen}
              nextChargeDate={"-"}
            />
          );
        } else {
          arr.push(
            <PlanDetailsCard
              product={PRODUCTS.ERP_V2}
              plan={SubscriptionManager.erpPlan(newERPPlan)}
              planInterval={SubscriptionManager.erpPlanInterval(newERPPlan)}
              status={SubscriptionManager.erpPlanStatus(newERPPlan)}
              remainingTrialDays={SubscriptionManager.erpPlanDaysRemaining(
                newERPPlan
              )}
              currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
              onTrialActivate={this.refreshScreen}
              nextChargeDate={"-"}
            />
          );

          arr.push(
            <PlanDetailsCard
              product={PRODUCTS.MRP}
              plan={SubscriptionManager.erpPlan(mrpPlan)}
              planInterval={SubscriptionManager.erpPlanInterval(mrpPlan)}
              status={SubscriptionManager.mrpPlanStatus(mrpPlan)}
              currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
              remainingTrialDays={0}
              onTrialActivate={this.refreshScreen}
              nextChargeDate={"-"}
            />
          );
        }
      }
      return arr;
    } else {
      let currentPlans = SubscriptionManager.getParsedCurrentSubscription();
      let arr = [];
      currentPlans.forEach((plan) => {
        if (
          plan.status !== SUBSCRIPTION_STATUS.TRIAL_PENDING &&
          plan.status !== SUBSCRIPTION_STATUS.TRIAL_ENDED &&
          PermissionManager.showProducts(plan.product)
        ) {
          let date;
          if (plan.nextChargedDate !== "Lifetime") {
            let prepaidList = CouponsManager.getPrepaidCoupons();
            if (prepaidList.length > 0) {
              date = CouponsManager.getCouponListForEachPlan(
                plan.nextChargedDate,
                prepaidList,
                plan.product
              );
            } else {
              date = DataParser.getBillDateFormat(
                new Date(plan.nextChargedDate)
              );
            }
          } else {
            date = plan.nextChargedDate;
          }
          arr.push(
            <PlanDetails
              product={plan.product}
              plan={plan.plan}
              planInterval={plan.interval}
              currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
              status={plan.status}
              nextChargeDate={date}
              remainingTrialDays={plan.trialDays}
              onTrialActivate={this.refreshScreen}
            />
          );
        }
      });
      if (arr.length === 0) {
        let filter_aio = [];
        if (currentPlans.length > 0) {
          filter_aio = currentPlans.filter((obj) => {
            return obj.product === PRODUCTS.AIO;
          });
        }

        arr.push(
          <PlanDetails
            product={PlanManager.getDefaultProduct()}
            plan={PlanManager.getDefaultPlan()}
            planInterval={PlanManager.getDefaultInterval()}
            status={
              filter_aio.length > 0
                ? filter_aio[0].status
                : SUBSCRIPTION_STATUS.TRIAL_PENDING
            }
            remainingTrialDays={0}
            onTrialActivate={this.refreshScreen}
            nextChargeDate={"-"}
          />
        );
      }
      return arr;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getUpdatesView() {
    return <Updates />;
  }
}
export default withTranslation()(AdminDashboard);
