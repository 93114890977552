import React, { Component } from "react";

import {
  DKLabel,
  DKButton,
  showAlert,
  DKIcon,
  DKInput
} from "deskera-ui-library";

import Subscription from "../../services/subscription";
import ProductIcon from "./ProductIcon";
import PlanManager from "../../managers/PlanManager";
import PlanIcon from "./PlanIcon";
import { getCapitalized } from "../../utility/Utility";

import ic_coupons from "../../assets/icons/ic_cards.png";
import { trackEventAC } from "../../managers/ActiveCampaignWrapper";
import UserManager from "../../managers/UserManager";
import { OLD_PLAN_TO_NEW_PLAN, TRACK_EVENT } from "../../constants/Enum";
import CurrencyManager from "../../managers/CurrencyManager";
import { TALK_TO_SALES_URL_MRP } from "../../constants/Constant";

/*
- onSave
- onClose
*/

class PrepaidCodePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      checkTapped: false,
      prepaidCodeDataReceived: false,
      couponDetails: null,
      canApplyCoupon: null,
      canOverrideCoupon: null,
      reason: null,
      tenantID: props?.tenantID ? props?.tenantID : null,
      userID: props?.userID ? props?.userID : null,
    };
  }
  render() {
    return (
      <div className=" transparent-background">
        <div className="popup-window" style={{ maxWidth: 500 }}>
          {this.getHeader()}
          {this.getCodeForm()}
          {this.state.couponDetails && this.getCouponDetails()}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getHeader() {
    return (
      <div className="row justify-content-between">
        <div>
          <div className="row">
            <DKIcon src={ic_coupons} className="mr-s ic-s-2" />
            <DKLabel text={`Redeem Prepaid code`} className="fw-m fs-l" />
          </div>
        </div>
        <div>
          <div className="row">
            <DKButton
              title="Close"
              className="bg-gray1 border-m fw-m"
              onClick={this.cancelTapped}
            />
          </div>
        </div>
      </div>
    );
  }

  cancelTapped = () => {
    this.props.onClose();
  };
  saveTapped = () => {};

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getCodeForm() {
    return (
      <div className="column mt-xl mb-l parent-width">
        <DKLabel text={`Enter prepaid code below and validate`} />
        <div className="parent-width row mt-m" style={{ alignItems: "flex-start" }}>
          <DKInput
            needHeader={false}
            name={"Prepaid code"}
            placeholder={"prepaid code"}
            required={true}
            value={this.state.code}
            onChange={(value) => {
              this.setState({
                code: value.toUpperCase(),
                couponDetails: null,
                canApplyCoupon: null,
                canOverrideCoupon: null,
              });
            }}
            canValidate={this.state.checkTapped}
            invalid={this.state.checkTapped && this.state.code.trim() === ""}
          />
          <DKButton
            className="text-wrap-none text-white bg-green fw-m ml-m mt-xs"
            title="Validate"
            onClick={this.getPrepaidCodeDetails}
          />
        </div>
        <div className='row mt-xl'>
					<DKLabel text="Don't have a code?" />
					<DKButton
						title="Talk to Sales"
						className="bg-white text-blue fw-m p-0 text-underline"
						style={{paddingLeft: 4}}
						onClick={() => {
							window?.open(TALK_TO_SALES_URL_MRP, "_blank");
						}}
					/>
				</div>
      </div>
    );
  }

  getPrepaidCodeDetails = () => {
    this.setState({
      checkTapped: true,
      couponDetails: null,
      canApplyCoupon: null,
      canOverrideCoupon: null,
    });
    if (this.state.code.trim() !== "") {
      Subscription.getPrepaidCodeDetails(
        {
          PrepaidCode: this.state.code.trim(),
        },
        this.state.tenantID,
        this.state.userID
        )
        .then((res) => {
          if (res.CanApply === false && res.CanOverride === false) {
            showAlert("Error occured!", res.Reason);
          } else {
            this.setState({
              couponDetails: res.CodeDetails,
              canApplyCoupon: res.CanApply,
              canOverrideCoupon: res.CanOverride,
              reason: res.Reason,
            });
          }
        })
        .catch(() => {});
    }
  };

  getCouponDetails() {
    let data = this.state.couponDetails;
    return (
      <div className="column parent-width mt-xxl mb-l">
        <DKLabel text="Coupon details" className="fw-m fs-m text-gray mb-s" />
        {data.Products.map((obj) => {
          return this.getPlanDetailsRow(obj);
        })}
        <div className="row mt-xxl">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m fw-m mr-r"
            onClick={this.cancelTapped}
          />
          <DKButton
            title="Apply this coupon"
            className="bg-blue  fw-m text-white"
            onClick={this.showApplyCouponWarning}
          />
        </div>
      </div>
    );
  }

  showApplyCouponWarning = () => {
    showAlert(
      "Important Warning!",
      "Once you apply this coupon, all your existing subscriptions will be expired and new subscriptions will be activated.",
      [
        {
          title: "Cancel",
          className: "bg-gray1 border-m fw-m",
          onClick: () => {},
        },
        {
          title: "Proceed",
          className: "bg-blue text-white fw-m ml-r",
          onClick: () => {
            if(this.state.tenantID !== null && this.state.userID !== null){
              this.bookkeeperSub();
            } else {
              this.applyPrepaidCode();
            }
          },
        },
      ]
    );
  };

  bookkeeperSub = () => {
    this.applyPrepaidCode();
  }

  applyPrepaidCode = () => {
    const code_ac = this.state.code.trim();
    Subscription.applyPrepaidCode(
      this.state.code.trim(),
      this.state.canOverrideCoupon ? this.state.canOverrideCoupon : false,
      CurrencyManager.getCurrentCurrency().toLowerCase(),
      this.state.tenantID,
      this.state.userID
    ).then((res) => {
      trackEventAC(
        {
          event: TRACK_EVENT.CODE_APPLIED,
          eventData: `${code_ac} code applied successfully`,
          email: UserManager.getUserEmail(),
        },
        () => {}
      );

      Subscription.getDetails()
        .then((res) => {
          this.props.onCouponApply();
        })
        .catch((err) => {});

      showAlert(
        "Coupon applied!",
        "Prepaid code has been applied successfully. Now you can access the plan features.",
        [
          {
            title: "Ok",
            className: "bg-blue text-white fw-m",
            onClick: () => {
              if(this.state.tenantID !== null && this.state.userID !== null) {
                window.close();
              } else {
                window.location.reload();
              }
            },
          },
        ]
      );
      this.cancelTapped();
    })
    .catch((err) => {console.log("err", err)});

  };

  getPlanDetailsRow(data) {
    const planName = OLD_PLAN_TO_NEW_PLAN[data?.PlanName?.toLowerCase()] || '';
    return (
      <div className="column parent-width pt-s pb-s mt-r">
        <div
          className="row justify-content-between"
          style={{ alignItems: "flex-start" }}
        >
          <div>
            <div className="row" style={{ alignItems: "flex-start" }}>
              <ProductIcon product={data.Name} className="mr-r" />
              <div className="column parent-width">
                <DKLabel
                  className="fw-m fs-m"
                  text={
                    "Deskera " +
                    PlanManager.getProductDisplayName(data.Name.toLowerCase())
                  }
                />
                <div className="row mt-s">
                  <PlanIcon
                    plan={this.state.couponDetails.Plan.toLowerCase()}
                    className="mr-s"
                  />
                  <DKLabel
                    className="text-gray"
                    text={
                      getCapitalized(planName) +
                      " Plan for " +
                      (this.state.couponDetails.PrepaidDuration === 0 &&
                      this.state.couponDetails.PrepaidDurationDays
                        ? this.state.couponDetails.PrepaidDurationDays + " days"
                        : this.state.couponDetails.PrepaidDuration > 1000
                        ? "lifetime"
                        : this.state.couponDetails.PrepaidDuration + " months")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="column align-items-end">
            <DKLabel text="FREE" className="fw-m mt-m" />
            <DKLabel
              text={"for " + data.UserCount + " user"}
              className=" text-gray"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PrepaidCodePopup;
