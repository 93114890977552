import { PAGE_ROUTES } from "../managers/RouteManager"
export const walkthrough = [
    {
        title: "Start Tour",
        msg: "Let's start the walkthrough",
        tooltipPosition: {
            top: "45%",
            left: "45%"
        },
        arrowPosition:"",
        navigateToPage: PAGE_ROUTES.HOME
    },
    {
        title: "",
        msg: "This section display the details of the current organisation",
        tooltipPosition: {
            top: "0",
            left: "20%"
        },
        arrowPosition: "bottom",
        navigateToPage: ""
    },
    {
        title: "",
        msg: "This section displays the subscription you have bought",
        tooltipPosition: {
            top: "0",
            left: "75%"
        },
        arrowPosition: "bottom",
        navigateToPage: PAGE_ROUTES.REPORTS
    },
    {
        title: "",
        msg: "Dashboard page will displayed the overall reports in the respective products",
        tooltipPosition: {
            top: "45%",
            left: "45%"
        },
        arrowPosition:"",
        navigateToPage: PAGE_ROUTES.USERS
    },
    {
        title: "",
        msg: "Create new user with this button and assign relevant role and permission to the new user",
        tooltipPosition: {
            top: "20%",
            left: "90%"
        },
        arrowPosition:"top",
        navigateToPage: ""
    },
    {
        title: "",
        msg: "Under actions, you will be able to do the following : " +
        " \n - Edit User" +
        " \n - Delete User ",
        tooltipPosition: {
            top: "15%",
            left: "80%"
        },
        arrowPosition:"bottom",
        navigateToPage: PAGE_ROUTES.BILLING
    },
    {
        title: "",
        msg: "This sections allows you to: " +
        " \n -Start Trial (when applicable)" +
        " \n -Buy subscription plan for respective product" +
        " \n -Edit subscription plan for respective product",
        tooltipPosition: {
            top: "0%",
            left: "30%"
        },
        arrowPosition:"bottom",
        navigateToPage: ""
    },
    {
        title: "",
        msg: "Add or edit card in this section",
        tooltipPosition: {
            top: "0%",
            left: "80%"
        },
        arrowPosition:"bottom",
        navigateToPage: ""
    },
    {
        title: "",
        msg: "View billing history",
        tooltipPosition: {
            top: "23%",
            left: "80%"
        },
        arrowPosition:"bottom",
        navigateToPage: ""
    },
    {
        title: "",
        msg: "View prepaid code history",
        tooltipPosition: {
            top: "36%",
            left: "80%"
        },
        arrowPosition:"bottom",
        navigateToPage: ""
    },
    {
        title: "",
        msg: "Apply prepaid code",
        tooltipPosition: {
            top: "70%",
            left: "80%"
        },
        arrowPosition:"top",
        navigateToPage: PAGE_ROUTES.SECURITY
    },
    {
        title: "",
        msg: "Enable 2FA in this section",
        tooltipPosition: {
            top: "10%",
            left: "12%"
        },
        arrowPosition:"bottom",
        navigateToPage: ""
    },
    {
        title: "",
        msg: "Change password in this section",
        tooltipPosition: {
            top: "35%",
            left: "17%"
        },
        arrowPosition:"bottom",
        navigateToPage: PAGE_ROUTES.SETTINGS
    },
    {
        title: "",
        msg: "Edit company name in this section",
        tooltipPosition: {
            top: "2%",
            left: "12%"
        },
        arrowPosition:"bottom",
        navigateToPage: ""
    },
    {
        title: "",
        msg: "You are able to delete current company with this button",
        tooltipPosition: {
            top: "30%",
            left: "60%"
        },
        arrowPosition:"bottom",
        navigateToPage: ""
    },
    {
        title: "",
        msg: "You are able to delete account with this button",
        tooltipPosition: {
            top: "42%",
            left: "62%"
        },
        arrowPosition:"bottom",
        navigateToPage: ""
    },
    {
        title: "",
        msg: "You are able to reset your current company with this button.\n However, such action is irreversible.",
        tooltipPosition: {
            top: "52%",
            left: "62%"
        },
        arrowPosition:"bottom",
        navigateToPage: ""
    },
    {
        title: "Finished Tour",
        msg: "You have finished the tour. You will now be redirected to 'My Subscriptions' page to start your trial.",
        tooltipPosition: {
            top: "40%",
            left: "38%"
        },
        arrowPosition:"",
        navigateToPage: PAGE_ROUTES.BILLING
    }
]