import React, { Component } from "react";

import {
  DKLabel,
  DKButton
} from "deskera-ui-library";

import STARTUP1 from "../../assets/pricingSection/startup.svg";
import STARTUP2 from "../../assets/pricingSection/essential.svg";
import STARTUP3 from "../../assets/pricingSection/professional.svg";
import PlanManager from "../../managers/PlanManager";
import { planDetails } from "../../constants/Plans";

import { BOOKKEEPER_FLOW, PLANS, PRODUCTS } from "../../constants/Enum";
import RouteManager from "../../managers/RouteManager";
import SubscriptionManager from "../../managers/SubscriptionManager";

import ic_check from "../../assets/icons/white/ic_check_mark.png";
import Subscription from "../../services/subscription";
import { COLORS } from "../../constants/Colors";
import AppManager from "../../managers/AppManager";
import PriceLabel from "./PriceLabel";
import CouponsManager from "../../managers/CouponsManager";
import CurrencyManager from "../../managers/CurrencyManager";
import { isMobileAppWebView } from "../../utility/ViewportSizeUtils";

/*
- onDataChange()
*/

export default class PriceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: this.props.plan,
      product: this.props.product,
      planInterval: this.props.planInterval,
      planData: null,
      currency: this.props.currency,
      tenantID: this.props.tenantID,
      userID: this.props.userID
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProp) {
    this.setState({
      product: nextProp.product,
      plan: nextProp.plan,
      planInterval: nextProp.planInterval,
      currency: nextProp.currency,
      tenantID: nextProp.tenantID,
      userID: nextProp.userID
    });
  }

  ////////////////////////////////////
  // UI
  ////////////////////////////////////

  render() {
    let isPlanActive = SubscriptionManager.isPlanActive(
      this.state.product,
      this.state.plan,
      this.state.planInterval
    );

    let isPlanTrialPending = SubscriptionManager.isPlanTrialPending(
      this.state.product
    );

    let planPrice = PlanManager.getPlanPriceByCurrency(
      this.state.product,
      this.state.plan,
      this.state.planInterval,
      this.state.currency
    );

    let inactivePlan = localStorage.getItem(BOOKKEEPER_FLOW.TENANT_ID) !== "" ? "Buy Now" : "Buy plan";

    return (
      <div
        className={`column align-items-center pl-l pr-l pb-l pt-l bg-white  border-m position-relative shadow-s
        ${isMobileAppWebView() ? ' border-radius-r ' : 'border-radius-s'}`}
        style={{ minWidth: 300, maxWidth: 350 }}
      >
        {this.state.plan === PLANS.ESSENTIAL && (
          <div
            className=" position-absolute border-radius-r pl-s pr-s"
            style={{
              backgroundColor: PlanManager.getProductColor(this.state.product),
              top: -10,
              paddingTop: 2,
              paddingBottom: 3,
            }}
          >
            <DKLabel className=" text-white fw-m" text="Most Popular" />
          </div>
        )}
        <img
          className="ic-xl mb-s"
          src={
            this.state.plan === PLANS.STARTUP
              ? STARTUP1
              : this.state.plan === PLANS.ESSENTIAL
              ? STARTUP2
              : STARTUP3
          }
          alt=""
        />
        <DKLabel
          className="mb-s mt-s fw-b fs-xl text-align-center"
          text={PlanManager.getPlanDisplayName(this.state.plan)}
        />
        <DKLabel
          className=" text-align-center text-gray "
          text={planDetails[this.state.product][this.state.plan].description}
          style={{ height: 50 }}
        />
        <div className="mt-xl bg-gray2 pt-s pl-r pb-s pr-r border-radius-xxl">
          <PriceLabel
            price={planPrice}
            currency={this.state.currency}
            discountedPrice={CouponsManager.getDiscountedPrice(
              planPrice,
              this.state.product,
              this.state.planInterval
            )}
          />
        </div>
        <DKLabel
          className="mt-s text-align-center text-gray "
          text="per user, per month"
        />

        {!isPlanTrialPending && (
          <DKButton
            title={isPlanActive ? "Current plan" : inactivePlan}
            icon={isPlanActive ? ic_check : null}
            className="fw-m text-white mt-xxl justify-content-center"
            style={{
              width: "80%",
              backgroundColor: isPlanActive
                ? COLORS.dark_gray
                : PlanManager.getProductColor(this.state.product),
            }}
            onClick={
              !isPlanActive
                ? () => {
                    let addons = null;
                    let isAddOnRequired = false;
                    if (
                      isAddOnRequired &&
                      (this.state.product === PRODUCTS.AIO ||
                        this.state.product === PRODUCTS.CRM)
                    ) {
                      addons = [
                        {
                          Code: "CONTACT_EMAIL_COUNT",
                          Description: "Contact & email size",
                          Amount: 100,
                          Items: [
                            { Code: "CONTACT_COUNT", Value: 5000 },
                            { Code: "EMAIL_SEND_COUNT", Value: 50000 },
                          ],
                        },
                      ];
                    }

                    RouteManager.gotoCheckout(
                      this.state.product,
                      this.state.plan,
                      this.state.planInterval,
                      addons,
                      this.state.tenantID,
                      this.state.userID
                    );
                  }
                : () => {}
            }
          />
        )}

        {isPlanTrialPending && (
          <DKButton
            title={isPlanTrialPending ? "Start 15 days trial" : "Trial over"}
            className={
              "fw-m  mt-xxl justify-content-center text-white" //+ (isPlanTrialPending ? "text-gray" : "text-orange")
            }
            style={{
              // textDecoration: isPlanTrialPending ? "underline" : "none",
              backgroundColor: PlanManager.getProductColor(this.state.product),
              width: "80%",
            }}
            onClick={
              isPlanTrialPending
                ? () => {
                    AppManager.showTrialActivationWarning(this.startTrial);
                  }
                : () => {}
            }
          />
        )}

        <DKLabel className="mt-xxl mb-r fw-b row " text="Plan includes" />
        {this.getFeatures(
          planDetails[this.state.product][this.state.plan].features
        )}
      </div>
    );
  }

  startTrial = () => {
    let planID = PlanManager.getPlanID(
      this.state.product,
      this.state.plan,
      this.state.planInterval
    );
    Subscription.activatePlan(
      {
        PlanID: planID,
        Currency: SubscriptionManager.getCurrencySameAsActivePlanCurrency().toLowerCase(),
      },
      true
    ).then((res) => {
      this.props.onDataChange();
    });
  };

  getFeatures(features) {
    let result = features.map((item) => {
      return (
        <div className={"row mt-s "}>
          <DKLabel className="" text={"✓ " + item} />
        </div>
      );
    });
    return result;
  }
  ////////////////////////////////////
  // private
  ////////////////////////////////////

  setPlanDetails() {
    let planDetailsFromAPI = PlanManager.getPlanDetailsFromAPI();
    if (planDetailsFromAPI === null) {
      return;
    }
    let planData = PlanManager.getPlanIDPriceCurrency(
      planDetailsFromAPI,
      this.state.plan,
      this.state.planInterval
    );
    this.setState({
      planData: planData,
    });
  }
}
