import { FC, useEffect, useState } from 'react';
import { DKSegmentControl, showLoader, removeLoader } from 'deskera-ui-library';
import RouteManager from '../../managers/RouteManager';
import Billing from './Billing';
import { PLAN_DETAILS_TABS } from '../../constants/Enum';
import PlanSection from './PlanSection';
import { getBillingDetails } from '../../services/credit';
import SubscriptionManager from '../../managers/SubscriptionManager';
import CouponsManager from '../../managers/CouponsManager';

interface IPlanDetailsProps {}

const SEGMENTS = [
	PLAN_DETAILS_TABS.PLAN_DETAILS,
	PLAN_DETAILS_TABS.BILLING,
	// PLAN_DETAILS_TABS.CURRENT_USAGE,
	// PLAN_DETAILS_TABS.PAYMENT_METHODS,
];
const initialBillingDetails = {
	CreditsApplied: [],
	NextChargeDate: new Date(),
	TotalAvailableCredits: 0,
	PricingInfo: {
		pricing: [],
	},
};
const NewPlanDetails: FC<IPlanDetailsProps> = (props) => {
	const [activeTab, setActiveTab] = useState(PLAN_DETAILS_TABS.PLAN_DETAILS);
	const [data, setData] = useState({ ...initialBillingDetails });
	const selectedIndex = SEGMENTS.indexOf(activeTab);

	useEffect(() => {
		RouteManager.setPresenter({ props });
	}, []);

	useEffect(() => {
		fetchBillingDetails();
	}, []);
	const fetchBillingDetails = async () => {
		showLoader('Fetching billing details...');
		try {
			const response: any = await getBillingDetails();
			setData(response);
		} catch (error) {
		} finally {
			removeLoader();
		}
	};

	return (
		<div className="column parent-width screen-height">
			<div className="parent-width row fw-m fs-l">
				<DKSegmentControl
					width={300}
					segments={SEGMENTS}
					backgroundColor="bg-gray2"
					selectedColor="text-black"
					barColor="bg-white"
					selectedIndex={selectedIndex === -1 ? 0 : selectedIndex}
					onSelect={(index: number) => setActiveTab(SEGMENTS[index])}
				/>
			</div>
			<div className="column parent-width flex-1 mt-xl">
				{activeTab === PLAN_DETAILS_TABS.PLAN_DETAILS && (
					<PlanSection
						routeInfo={(props as any)?.match}
						creditData={data?.CreditsApplied ?? []}
						nextChargeDate={data?.NextChargeDate}
						totalAvailableCredits={data?.TotalAvailableCredits}
						onCodeApplied={(response: any) => {
							fetchBillingDetails();
							if (response) {
								SubscriptionManager.set(response);
								SubscriptionManager.setCurrentSubscription(
									response
								);
								CouponsManager.setDiscountCoupons(
									response.DiscountCoupons
								);
								CouponsManager.setPrepaidCoupons(
									response.PrepaidCoupons
								);
							}
						}}
					/>
				)}
				{activeTab === PLAN_DETAILS_TABS.BILLING && <Billing billingData={data} />}
				{activeTab === PLAN_DETAILS_TABS.CURRENT_USAGE && <Billing billingData={data} />}
				{activeTab === PLAN_DETAILS_TABS.PAYMENT_METHODS && <Billing billingData={data} />}
			</div>
		</div>
	);
};

export default NewPlanDetails;
