import React, { Component } from "react";
import RouteManager from "../../managers/RouteManager";
import PriceSection from "../common/PriceSection";
import PlanManager from "../../managers/PlanManager";
import Utility, { Cookies } from "../../utility/Utility";
import UserManager from "../../managers/UserManager";
import { isMobileAppWebView } from "../../utility/ViewportSizeUtils";

import { DKLabel } from "deskera-ui-library";
import PricingHeroTabs from "../common/PricingHeroTabs";
import PricingTabs from "../common/PricingTabs";
import PriceHeroTabsManager, {
  pricingDataFunctions,
  pricingDataFunctionsUS,
} from "../../managers/PriceHeroTabsManager";
import {
  BOOKKEEPER_FLOW,
  PLANS_NAME_V2,
  PLANS_V2,
  URL_PARAMS,
} from "../../constants/Enum";
import Subscription from "../../services/subscription";
import PriceHeroTabsManagerUS from "../../managers/PriceHeroTabsManagerUS";

export default class PlanSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: PlanManager.getDefaultProduct(),
      planInterval: PlanManager.getDefaultInterval(),
      userId: "",
      tenantId: "",
      isEdit: false,
      activeTab: 0,
      countryName: null,
      currencyCode: null,
      countryCode: null,
    };
  }
  componentDidMount() {
    RouteManager.setPresenter(this);
    if (!UserManager.isUserOwner() && !UserManager.isUserAdmin()) {
      RouteManager.navigateToHome();
      return;
    }
    Subscription.getCountryFromRequest().then((data) => {
      this.setState({ countryCode: data?.country?.iso_code });
    });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let para_product = !Utility.isEmpty(urlParams.get("product"))
      ? urlParams.get("product")
      : PlanManager.getDefaultProduct();

    let para_interval = !Utility.isEmpty(urlParams.get("interval"))
      ? urlParams.get("interval")
      : PlanManager.getDefaultInterval();

    let urlTabIndex = !Utility.isEmpty(urlParams.get("tab"))
      ? urlParams.get("tab")
      : 0;

    let userID = !Utility.isEmpty(urlParams.get(URL_PARAMS.USER_ID))
      ? urlParams.get(URL_PARAMS.USER_ID)
      : "";

    let tenantID = !Utility.isEmpty(urlParams.get(URL_PARAMS.TENANT_ID))
      ? urlParams.get(URL_PARAMS.TENANT_ID)
      : "";

    let isEdit = !Utility.isEmpty(urlParams.get(URL_PARAMS.IS_EDIT))
      ? urlParams.get(URL_PARAMS.IS_EDIT)
      : "";

    localStorage.setItem(BOOKKEEPER_FLOW.TENANT_ID, tenantID);
    localStorage.setItem(BOOKKEEPER_FLOW.USER_ID, userID);
    localStorage.setItem(BOOKKEEPER_FLOW.IS_EDIT, isEdit);

    this.setState({
      product: para_product,
      planInterval: para_interval,
      tenantId: tenantID,
      userId: userID,
      isEdit: isEdit,
      activeTab: urlTabIndex,
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div>
        <div className="row fw-m fs-l">Select plan</div>
        <DKLabel
          text="Select plan below as per your business requirements"
          className="text-gray mt-xs"
        />
        <div
          className="row row-responsive align-items-start"
          style={{ overflow: "hidden" }}
        >
          <div
            className={`column align-items-center ${
              isMobileAppWebView() ? " parent-width " : " pr-r"
            }`}
            style={{ flex: 1 }}
          >
            <div
              className={`column ${
                isMobileAppWebView() ? " parent-width " : ""
              }`}
              // style={{ maxWidth: MAX_SECTION_WIDTH }}
            >
              {this.getAllPlanDetails()}
            </div>
          </div>
        </div>
      </div>
    );
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setActiveTab = (index) => {
    this.setState({
      activeTab: index,
    });
  };
  getBusinessFunctionData = () => {
    // const functions = JSON.parse(
    //   JSON.stringify(this.props?.pricingDataFunctions(this.state.activeTab))
    // );
    const functions = "";
    functions.gridCards = functions.gridCards.map((card, index) => {
      if (index !== this.state.activeTab) {
        card.className += ` inactive`;
      }
      if (!card.styles) {
        card.styles = { minWidth: 180 };
      }
      return card;
    });
    return functions;
  };
  getPlanValue(planname) {
    let planValueName = PLANS_V2.ERP;
    switch (planname) {
      case PLANS_NAME_V2.ERP:
        planValueName = PLANS_V2.ERP;
        break;
      case PLANS_NAME_V2.ERP_PLUS:
        planValueName = PLANS_V2.ERP_PLUS;
        break;
      case PLANS_NAME_V2.MRP_PLUS:
        planValueName = PLANS_V2.MRP_PLUS;
        break;
      default:
        planValueName = PLANS_V2.ERP;
        break;
    }
    return Utility.getPriceValue(
      planValueName,
      this.state.countryCode ? this.state.countryCode : `us`
    );
  }
  getPricingData() {
    const pricingData = PriceHeroTabsManager.PricingPreviewData;
    pricingData.plans = PriceHeroTabsManager.PricingPreviewData?.plans?.map(
      (plan) => {
        return {
          ...plan,
          currency: Utility.getCurrencySymbol(
            this.state.currencyCode ? this.state.currencyCode : `$`
          ),
          amount: this.getPlanValue(plan.name),
        };
      }
    );

    return pricingData;
  }
  getAllPlanDetails() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let urlTabIndex = !Utility.isEmpty(urlParams.get("tab"))
      ? Number(urlParams.get("tab"))
      : 0;
    return (
      <div
        className="page-holder column align-items-center"
        style={{ maxWidth: 1000 }}
      >
        {
          <PricingTabs
            pricingDataFunctions={
              this.state.countryCode === "IN"
                ? pricingDataFunctions
                : pricingDataFunctionsUS
            }
            DataProvider={
              this.state.countryCode === "IN"
                ? PriceHeroTabsManager
                : PriceHeroTabsManagerUS
            }
            currencyCode={this.state.currencyCode}
            countryCode={this.state.countryCode}
            isLoadingLocation={this.state.isLoadingLocation}
            activeTab={urlTabIndex}
            tenantId={this.state?.tenantId}
            userId={this.state?.userId}
          />
        }
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}
