import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Table from '../../services/tables';
export interface TableSliceInterface  {
    data:any
}
const initialState : TableSliceInterface = {
    data:{}
}
export const fetchTables = createAsyncThunk('table/getTables',
    async(data,thunkAPI)=>{
        const response = await Table.getAllViews();
        return response;
    }
)
const tableSlice = createSlice({
    name: "table",
    initialState,
    reducers:{
        getTables:(state) =>{
            return state.data
        },
        setTables: (state,action: PayloadAction<any>)=>{
            state.data = action;
        }
    },
    extraReducers:(builder)=>{
        builder.addCase(fetchTables.fulfilled,(state,action: PayloadAction<any>)=>{
            state.data = action;
        })
    }
})
export default tableSlice.reducer;