import React from "react";
import vector_tick_outline from "../../assets/website/icons/ic-check-circle-outline.svg";
import ic_cheveron_single_right_circle from "../../assets/website/icons/ic_cheveron_single_right_circle.svg";
import "../../css/pageStyles.css";
import "../../css/pagesCommon.css";
import { DKLabel } from "deskera-ui-library";
import SVGIcon from "../../constants/SVGIcon";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import SubscriptionManager from "../../managers/SubscriptionManager";
import { PLAN_IDS } from "../../constants/Constant";
import Payload from "../../constants/Payload";
import {
  CTA_TITLES,
  INTERVALS,
  NEW_PLAN_TO_OLD_PLAN,
  PRODUCTS,
  SUBSCRIPTION_STATUS,
  TRACK_EVENT,
} from "../../constants/Enum";
import UserManager from "../../managers/UserManager";
import { trackEventAC } from "../../managers/ActiveCampaignWrapper";
import Subscription from "../../services/subscription";
import PlanManager from "../../managers/PlanManager";
import AppManager from "../../managers/AppManager";

const PricingPlanPreview = (props) => {
  const goToPricingPage = () => {
    if (props.countryCode === "in") {
      window.open("/in" + "https://www.deskera.com/" + "#talk-to-sales");
    } else if (props.countryCode === "my") {
      window.open("/my" + "https://www.deskera.com/" + "#talk-to-sales");
    } else {
      window.open("https://www.deskera.com/" + "#talk-to-sales");
    }
  };
  const getPricingCard = (card) => {
    return (
      card && (
        <div
          className={`pricing-preview-card mobile-width-90 column justify-content-between ${card.className}`}
          style={{
            backgroundImage: `url(${card.backGround})`,
            backgroundSize: "cover",
            width: card.width,
            paddingBottom: props.activeTab === 1 ? `26px` : ``,
          }}
        >
          <div className="row mb-m " style={{ paddingTop: 10 }}>
            <SVGIcon
              name={card.icon}
              className=" ic-l"
              style={{
                height: 60,
                marginBottom: 18,
              }}
            />
          </div>
          <div className="row mb-s pricing-preview-plan-title header-neue">
            {card.name}
          </div>
          <DKLabel
            className="mb-s font-inter"
            style={{
              fontWeight: 400,
              fontSize: 12,
              lineHeight: "16px",
              letterSpacing: "-0.8%",
              marginBottom: card.name === `Enterprise` ? 0 : 12,
            }}
            text={card.description}
          />
          {card.name === `Enterprise` && (
            <div className="row row-responsive  align-items-stretch justify-content-center enterprice-talk-to-sales">
              {renderCtaButton(card.cta, card.onClick, card)}
            </div>
          )}
          {card.amount && card.amount !== 0 && card.name !== `Enterprise` && (
            <>
              <DKLabel
                className="font-inter mb-s"
                style={{
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: "16px",
                  letterSpacing: "-0.8%",
                }}
                text={"starts at"}
              />
              <div className="row mb-r font-inter">
                {!props.isLoadingLocation ? (
                  <>
                    <sub
                      className="pricing-preview-price font-inter"
                      style={{
                        marginTop: -6,
                        fontSize: 12,
                      }}
                    >
                      {card.currency}&nbsp;
                    </sub>
                    <div className="pricing-preview-price font-inter fw-b">
                      {card.amount}
                    </div>
                  </>
                ) : (
                  <>
                    <sub
                      className="fs-l fw-m"
                      style={{
                        marginTop: -6,
                        fontSize: 12,
                      }}
                    ></sub>
                    <div className="pricing-preview-price font-inter fw-b">
                      --
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          {card.amountAlt && card.name !== `Enterprise` && (
            <div className="max-width-90 row mb-s">
              <span
                className="pricing-preview-plan-name font-inter sub-heading-font"
                style={{ fontWeight: "normal" }}
              >
                {card.amountAlt}
              </span>
            </div>
          )}
          {card.billingInfo && card.name !== `Enterprise` && (
            <div
              className="row font-inter"
              style={{
                fontWeight: 400,
                fontSize: 12,
                lineHeight: "130%",
                letterSpacing: "-0.8%",
              }}
            >
              {card.billingInfo}
            </div>
          )}
          {card.trialInfo && card.name !== `Enterprise` && (
            <div
              className="row mb-m mt-xs"
              style={{
                fontSize: 12,
                lineHeight: "8px",
                letterSpacing: "-0.8%",
              }}
            >
              {card.trialInfo}
            </div>
          )}

          <div
            className={`column mb-m mt-xs pricingCardContent ${
              props.activeTab === 1
                ? `pricingCardContentMRP ${
                    card.currency === "₹" && `pricingCardMRPIndia`
                  }`
                : ``
            }`}
            style={{
              letterSpacing: "-0.8%",
              borderWidth: card.featureBorderColor.borderWidth,
              borderStyle: card.featureBorderColor.borderStyle,
              borderColor: card.featureBorderColor.borderColor,
            }}
          >
            {card.contentHeader && (
              <div
                className="row mt-s mb-s"
                style={{
                  lineHeight: "normal",
                  alignItems: "flex-start",
                }}
              >
                <img
                  src={ic_cheveron_single_right_circle}
                  style={{ width: 16 }}
                />
                &nbsp;
                <span
                  className="font-inter"
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    textAlign: "-webkit-left",
                  }}
                >
                  {card.contentHeader}
                </span>
              </div>
            )}

            <div style={{ whiteSpace: "nowrap" }}>
              {renderContent(card.content)}
            </div>
          </div>
          <div className="row row-responsive  align-items-stretch justify-content-center">
            {renderCtaButton(card.cta, card.onClick, card)}
          </div>

          {/* </div> */}
        </div>
      )
    );
  };
  function renderContent(content) {
    return content.map((data) => {
      return (
        <div className="column">
          <div
            className="row mt-s mb-s"
            style={{
              lineHeight: "normal",
              alignItems: "flex-start",
            }}
          >
            <img src={vector_tick_outline} style={{ width: 16 }} />
            &nbsp;
            <span
              className="font-inter"
              style={{ fontSize: 12, fontWeight: 600 }}
            >
              {data}
            </span>
          </div>
        </div>
      );
    });
  }
  const onCTAClicked = (card, isTrialAvailable = false) => {
    if (isTrialAvailable) {
      startTrial(NEW_PLAN_TO_OLD_PLAN[card.id]);
    } else {
      RouteManager.gotoCheckout(
        PRODUCTS.ERP_V2,
        NEW_PLAN_TO_OLD_PLAN[card.id],
        INTERVALS.YEARLY,
        null,
        props?.tenantId,
        props?.userId
      );
    }
  };
  const startTrial = (plan) => {
    let planID = PlanManager.getPlanID(PRODUCTS.ERP_V2, plan, INTERVALS.YEARLY);
    Subscription.activatePlan({
      PlanID: planID,
      Currency:
        SubscriptionManager.getCurrencySameAsActivePlanCurrency().toLowerCase(),
    }).then((res) => {
      RouteManager.navigateToPage(PAGE_ROUTES.BILLING);
      trackEventAC(
        {
          event: TRACK_EVENT.START_TRIAL,
          eventData: `Trial started successfully for product ERP and plan ${plan.toLowerCase()}`,
          email: UserManager.getUserEmail(),
        },
        () => {}
      );
    });
  };
  const isCurrentPlanCard = (card) => {
    return getCurrentPlanId() === card.name;
  };
  const getCurrentPlanId = () => {
    const subscriptionKey =
      SubscriptionManager.isMRPEnabled() ||
      SubscriptionManager.isSubscriptionEnabledForNewERPPlan()
        ? Payload.PLAN.ERP
        : Payload.PLAN.AIO;
    const planDisplayName =
      SubscriptionManager.subscriptionDetails?.[subscriptionKey]?.DisplayName;
    return planDisplayName;
  };
  function renderCtaButton(cta, onClick, card) {
    const isCurrentPlan = isCurrentPlanCard(card);
    let showCTAArrow = true;
    let redirectToCheckout = false;
    const isMRPSubscribed = SubscriptionManager.isMRPEnabled();
    let ctaTitle = CTA_TITLES.TALK_TO_SALE;
    const subsStatus = SubscriptionManager.getSubscriptionStatusForNewERP(
      SubscriptionManager.subscriptionDetails?.[Payload.PLAN.ERP]
    );

    if (props.activeTab === 0) {
      if (!isMRPSubscribed) {
        switch (subsStatus) {
          case SUBSCRIPTION_STATUS.TRIAL_PENDING:
            if (card.id === PLAN_IDS.SMALL_BUSINESS) {
              redirectToCheckout = true;
              ctaTitle = CTA_TITLES.START_TRIAL;
            }
            break;
          case SUBSCRIPTION_STATUS.IN_TRIAL:
          case SUBSCRIPTION_STATUS.TRIAL_ENDED:
          case SUBSCRIPTION_STATUS.EXPIRED:
          case SUBSCRIPTION_STATUS.CANCELLED:
          case SUBSCRIPTION_STATUS.ACTIVE:
            if (isCurrentPlan) {
              redirectToCheckout = false;
              showCTAArrow = false;
              ctaTitle = CTA_TITLES.CURRENT_PLAN;
            } else {
              if (card.id === PLAN_IDS.SMALL_BUSINESS) {
                redirectToCheckout = true;
                ctaTitle = CTA_TITLES.BUY_NOW;
              }
            }
            break;
          default:
            break;
        }
      } else {
        if (card.id === PLAN_IDS.SMALL_BUSINESS) {
          redirectToCheckout = true;
          ctaTitle = CTA_TITLES.BUY_NOW;
        }
      }
    } else if (isMRPSubscribed && isCurrentPlan) {
      showCTAArrow = false;
      ctaTitle = CTA_TITLES.CURRENT_PLAN;
    }

    return (
      <div
        className={`max-width-90 row mt-auto width-auto cursor-hand align-items-center justify-content-center border-radius-m ${
          cta.className || ""
        }`}
        style={{
          minWidth: cta.btnSize || 166.76,
          height: 40,
          backgroundColor: cta.btnColor,
          border: cta.borderColor ? `1px solid ${cta.borderColor}` : "none",
          zIndex: 9,
          padding: "10px 16px",
        }}
        onClick={() => {
          if (redirectToCheckout) {
            if (subsStatus === SUBSCRIPTION_STATUS.TRIAL_PENDING) {
              AppManager.showTrialActivationWarning(() =>
                onCTAClicked(card, subsStatus)
              );
            } else {
              onCTAClicked(card);
            }
          } else {
            if (!isCurrentPlan) {
              window.open(cta?.url, "_blank");
            }
          }
        }}
      >
        <DKLabel
          text={ctaTitle}
          className={`fs-r mr-r fw-m cursor-hand`}
          style={{
            color: cta.accentColor || "#1664d7",
            whiteSpace: "nowrap",
          }}
        />
        {showCTAArrow && renderCtaArrow(cta.accentColor, 16)}
      </div>
    );
  }
  function renderCtaArrow(fillColor = "#1664d7", size = 24) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox={`0 0 24 24`}
        height={size}
        width={size}
      >
        <path
          stroke={fillColor || "#000000"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m19 12-6-6m6 6-6 6m6-6H5"
        />
      </svg>
    );
  }
  return (
    <div className="column parent-width position-relative pricing-preview justify-content-center align-items-center">
      <div
        className="row row-responsive  align-items-stretch justify-content-center"
        style={{ gap: 15, marginTop: 50, maxWidth: 1000 }}
      >
        {props?.PricingPreviewData &&
          props?.PricingPreviewData?.plans?.map(getPricingCard)}
      </div>
      <div className="row row-responsive  align-items-stretch justify-content-center">
        {!props?.PricingPreviewData &&
          renderCtaButton(
            {
              title: "Checkout Detailed Pricing Plans and FAQs",
              btnColor: "transparent",
              borderColor: "transparent",
              accentColor: "#000",
              className: "align-self-center mt-xxl",
              onClick: () => goToPricingPage(),
            },
            null,
            null
          )}
      </div>
    </div>
  );
};

export default PricingPlanPreview;
