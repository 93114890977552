import React, { Component } from "react";
import PropTypes from "prop-types";

import { PRODUCTS } from "../../constants/Enum";

import { DKIcon, DKLabel, DKTooltipWrapper } from "deskera-ui-library";

import PlanManager from "../../managers/PlanManager";

import ic_aio from "../../assets/productIcons/ic_aio.svg";
import ic_books from "../../assets/productIcons/ic_books.svg";
import ic_books_plus from "../../assets/productIcons/books-plus-icon.svg";
import ic_crm from "../../assets/productIcons/ic_crm.svg";
import ic_crm_plus from "../../assets/productIcons/bold-crm-plus.svg";
import ic_people from "../../assets/productIcons/ic_people.svg";
import ic_people_plus from "../../assets/productIcons/people-plus-icon.svg";
import ic_website_builder from "../../assets/productIcons/ic_website_builder.svg";
import ic_shop from "../../assets/productIcons/ic_website_builder.svg";
import ic_report_builder from "../../assets/productIcons/ic_report_builder.svg";
import ic_document_builder from "../../assets/productIcons/ic_document_builder.svg";
import ic_document_scanner from "../../assets/productIcons/ic_document_scanner.svg";
import ic_mrp_fill from "../../assets/productIcons/ic-MRP-fill-bg.svg";

import ic_mrp_fill_bg  from "../../assets/productIcons/new/ic-mrp-fill-bg.png";
import ic_erp_fill_bg  from "../../assets/productIcons/new/ic-erp-fill-bg.png";
import ic_hrms_fill_bg  from "../../assets/productIcons/new/ic-hrms-fill-bg.png";
import ic_crm_fill_bg  from "../../assets/productIcons/new/ic-crm-fill-bg.png";
import ic_report_builder_fill_bg  from "../../assets/productIcons/new/ic-report-builder-fill-bg.png";

import ic_expense from "../../assets/productIcons/ic_expense.svg";
import ic_attendance from "../../assets/productIcons/ic_attendance.svg";
import ic_chat from "../../assets/productIcons/ic_chat.svg";
import ic_console from "../../assets/productIcons/ic_console.svg";
// import ic_organisation from "../../assets/productIcons/ic_organisation.svg";
import ic_workflow from "../../assets/productIcons/ic_workflow.svg";
import ic_ai from "../../assets/productIcons/ic_ai.png";
import { getCapitalized } from "../../utility/Utility";

/*
PROPS:
- className
- product
- userRole
- showNameOnMouseOver
*/
export default class ProductIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: this.props.product
        ? this.props.product
        : PlanManager.getDefaultProduct(),
      showProductName: false,
    };
  }
  componentWillReceiveProps(nextProp) {
    this.setState({
      product: nextProp.product
        ? nextProp.product
        : PlanManager.getDefaultProduct(),
    });
  }
  render() {
    return (
      <DKIcon
        src={this.getIconSrc(this.state.product)}
        className={this.props.className}
      />
    );
  }

  showName = () => {
    if (this.props.showNameOnMouseOver) {
      this.setState({ showProductName: true });
    }
  };
  hideName = () => {
    this.setState({ showProductName: false });
  };

  getIconSrc(product) {
    switch (product.toLowerCase()) {
      case PRODUCTS.AIO:
        return ic_aio;
      case PRODUCTS.ERP:
        return ic_erp_fill_bg;
      case PRODUCTS.BOOKS_PLUS:
        return ic_erp_fill_bg;
      case PRODUCTS.CRM:
        return ic_crm_fill_bg;
      case PRODUCTS.CRM_PLUS:
        return ic_crm_fill_bg;
      case PRODUCTS.PEOPLE:
        return ic_hrms_fill_bg;
      case PRODUCTS.PEOPLE_PLUS:
        return ic_hrms_fill_bg;
      case PRODUCTS.WEBSITE_BUILDER:
        return ic_website_builder;
      case PRODUCTS.SHOP:
        return ic_shop;
      case PRODUCTS.REPORT_BUILDER:
        return ic_report_builder_fill_bg;
      case PRODUCTS.DOCUMENT_BUILDER:
        return ic_document_builder;
      case PRODUCTS.DOCUMENT_SCANNER:
        return ic_document_scanner;
      case PRODUCTS.ORGANISATION:
        return ic_aio;
      case PRODUCTS.CONSOLE:
        return ic_console;
      case PRODUCTS.WORKFLOW_ENGINE:
        return ic_workflow;
      case PRODUCTS.ATTENDANCE:
        return ic_attendance;
      case PRODUCTS.EXPENSE:
        return ic_expense;
      case PRODUCTS.CHAT:
        return ic_chat;
      case PRODUCTS.EM: //Expense
        return ic_expense;
      case PRODUCTS.DS: //Shop
        return ic_shop;
      case PRODUCTS.EL: //Leave Management
        return ic_attendance;
      case PRODUCTS.ERP_V2:
        return ic_erp_fill_bg;
      case PRODUCTS.MRP:
        return ic_mrp_fill_bg;
      case PRODUCTS.AI:
        return ic_ai;
      default:
        return ic_aio;
    }
  }
}

ProductIcon.propTypes = {
  className: PropTypes.string,
  product: PropTypes.string,
  userRole: PropTypes.string,
  showNameOnMouseOver: PropTypes.bool,
};
