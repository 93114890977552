import React, { Component } from "react";

import { DKLabel, DKButton, DKSpinner } from "deskera-ui-library";

import { toCurrencyFormat } from "../../utility/Utility";
import PlanManager from "../../managers/PlanManager";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import { INTERVALS, PLAN_UPDATE_TYPE, PRODUCTS } from "../../constants/Enum";
import PlanIcon from "./PlanIcon";
import Subscription from "../../services/subscription";
import AddOnManager from "../../managers/AddOnManager";
import ProductIcon from "./ProductIcon";
import { COLORS } from "../../constants/Colors";
import UserManager from "../../managers/UserManager";
import SubscriptionManager from "../../managers/SubscriptionManager";
import CurrencyManager from "../../managers/CurrencyManager";
import { isMobileAppWebView } from "../../utility/ViewportSizeUtils";
import { planDetails } from "../../constants/Plans";

/*
- product
- plan
- planInterval
- status
- discountCodeApplied
*/

class CheckoutPlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: this.props.product
        ? this.props.product
        : PlanManager.getDefaultProduct(),
      plan: this.props.plan ? this.props.plan : PlanManager.getDefaultPlan(),
      planInterval: this.props.planInterval
        ? this.props.planInterval
        : PlanManager.getDefaultInterval(),
      status: this.props.status ? this.props.status : null,
      updatedAmountData: null,
      callingAPI: false,
      currentCurrency: this.props.currentCurrency,
      isERPOrMRPFlow:
        this.props.product === PRODUCTS.ERP_V2 ||
        this.props.product === PRODUCTS.MRP,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getPlanID();
    }, 2000);
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      product: nextProp.product
        ? nextProp.product
        : PlanManager.getDefaultProduct(),
      plan: nextProp.plan ? nextProp.plan : PlanManager.getDefaultPlan(),
      planInterval: nextProp.planInterval
        ? nextProp.planInterval
        : PlanManager.getDefaultInterval(),
      status: nextProp.status ? nextProp.status : null,
      currentCurrency: nextProp.currentCurrency,
      isERPOrMRPFlow:
        nextProp.product === PRODUCTS.ERP_V2 ||
        nextProp.product === PRODUCTS.MRP,
    });

    if (this.props.discountCodeApplied) {
      this.getPlanID();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentCurrency !== prevProps.currentCurrency) {
      this.setState({
        currentCurrency: prevProps.currentCurrency,
      });
    }
  }

  getPlanID() {
    let planID = PlanManager.getPlanID(
      this.state.product,
      this.state.plan,
      this.state.planInterval
    );
    this.setState({
      callingAPI: true,
    });

    let request = {
      PlanID: planID,
      Addons: AddOnManager.getAddOns(),
      Currency:
        this.state.currentCurrency && this.state.currentCurrency.toLowerCase(),
    };

    Subscription.getPlanUpdatedAmount(request)
      .then((res) => {
        this.setState({
          updatedAmountData: res,
          callingAPI: false,
          currentCurrency: res.Currency,
        });
      })
      .catch((err) => {
        this.setState({
          callingAPI: false,
        });
      });
  }

  render() {
    let updatedAmountData = this.state.updatedAmountData
      ? this.state.updatedAmountData
      : null;

    return (
      <div
        className={` mb- pl-r pr-r pt-r  border-m border-radius-s parent-width column shadow-ms pb-r bg-white ${
          isMobileAppWebView() ? " border-radius-r " : ""
        }`}
      >
        <div className="row justify-content-between ">
          <DKLabel text="Subscription details" className="fw-m" />
        </div>

        {this.getProductPlanDetails()}
        {this.getPlanDesscription()}
        {this.getPriceSection()}

        {this.state.callingAPI && (
          <div className="row justify-content-center mt-xxl mb-l  ">
            <DKSpinner title="Updating total amount..." />
          </div>
        )}
        {updatedAmountData && this.getTotalSection()}

        {(UserManager.isUserOwner() || UserManager.isUserAdmin()) &&
          this.getActionButtons()}
        {SubscriptionManager.isAnyProductInTrial() &&
          this.getSubscriptionNotes()}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getProductPlanDetails() {
    return (
      <div className="row mt-l">
        <div>
          <div
            className="row border-radius-s fs-m fw-m cursor-hand"
            style={{
              padding: 4,
              paddingRight: 8,
              // paddingLeft: 8,
              backgroundColor:
                this.state.product === PRODUCTS.AIO
                  ? COLORS.deskera
                  : PlanManager.getProductColor(this.state.product),
              color:
                this.state.product === PRODUCTS.AIO
                  ? COLORS.black
                  : COLORS.white,
            }}
            onClick={this.gotoChangePlanScreen}
          >
            <ProductIcon product={this.state.product} className="mr-" />
            {this.state.product
              ? PlanManager.getProductDisplayName(this.state.product)
              : "-"}
          </div>
        </div>
        <div
          className="fs-l ml-r fw-m cursor-hand"
          onClick={this.gotoChangePlanScreen}
        >
          {this.state.plan
            ? PlanManager.getPlanDisplayName(
                this.state.plan,
                this.state.isERPOrMRPFlow
              )
            : "-"}{" "}
          Plan
        </div>
        <PlanIcon
          className="ml-s"
          plan={this.state.plan}
          onClick={this.gotoChangePlanScreen}
        />
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getPlanDesscription() {
    return (
      <DKLabel
        className="mt-r"
        text={
          this.state.product && this.state.plan
            ? PlanManager.getPlanDescription(
                this.state.product,
                this.state.plan
              )
            : "-"
        }
      />
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getPriceSection() {
    let updatedAmountData = this.state.updatedAmountData
      ? this.state.updatedAmountData
      : null;
    let chargeableAmount = updatedAmountData
      ? updatedAmountData.ChargeableAmount
      : null;

    let paidUserCount = updatedAmountData ? updatedAmountData.PaidUserCount : 1;

    let finalAmount = this.getFinalAmount();

    //////////////////////////////////////////////////////////////////////////////////////////

    const product =
      this.state.product === PRODUCTS.ERP_V2 ? PRODUCTS.ERP_V2 : PRODUCTS.MRP;
    const planDetailed =
      planDetails[product][PlanManager.getParsedPlan(this.state.plan)];
    const country = SubscriptionManager.getIndiaOrGlobalLocation();
    return (
      <div className="row mt-m">
        <div className="bg-deskera border-radius-s pt-s pb-s pl-s pr-s">
          <div className="row">
            {/* ///////////////////// */}

            <DKLabel
              className={
                "fw-b  mr-r " +
                (updatedAmountData && finalAmount !== chargeableAmount
                  ? " fs-l text-cut"
                  : " fs-xl")
              }
              style={{
                opacity:
                  updatedAmountData && finalAmount !== chargeableAmount
                    ? 0.6
                    : 1,
              }}
              text={toCurrencyFormat(finalAmount, this.state.currentCurrency)}
            />

            {/* ///////////////////// */}

            {updatedAmountData && finalAmount !== chargeableAmount && (
              <DKLabel
                className="fw-b fs-xl mr-r"
                text={toCurrencyFormat(
                  this.getPlanChargeableAmount(),
                  this.state.currentCurrency
                )}
              />
            )}

            {/* ///////////////////// */}

            {updatedAmountData && paidUserCount > 1 && (
              <DKLabel text={` for ${paidUserCount} users`} />
            )}
          </div>

          {/* ///////////////////// */}

          <div className="row">
            {/* {this.state.planInterval === INTERVALS.YEARLY && ( */}
            <DKLabel
              className="fw-m mr-s"
              text={toCurrencyFormat(
                updatedAmountData
                  ? this.getPlanChargeableMonthlyAmount()
                  : this.getPlanAmount(),
                this.state.currentCurrency
              )}
            />
            {/* )} */}
            {!this.state.isERPOrMRPFlow && (
              <span>
                <DKLabel className="" text="per user / month" />
                <DKLabel
                  className="fw-m ml-s"
                  text={
                    this.state.planInterval === INTERVALS.YEARLY
                      ? "(billed annually)"
                      : "(billed monthly)"
                  }
                />
              </span>
            )}

            {this.state.isERPOrMRPFlow && (
              <DKLabel
                className="fs-s"
                text={planDetailed?.billingInfo ?? ""}
              />
            )}
            {this.state.isERPOrMRPFlow && (
              <DKLabel
                className="fs-s fw-m ml-s"
                text={`${planDetailed?.users?.[country] ?? 0} user/s`}
              />
            )}
          </div>
          {updatedAmountData && updatedAmountData.TaxInfo.length > 0 && (
            <DKLabel className="" text="(GST to be charged additionally)" />
          )}
          {/* ///////////////////// */}
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getTotalSection() {
    let data = this.state.updatedAmountData;

    return (
      <div className="column parent-width mt-l">
        {this.getTotalRow(
          "Total",
          toCurrencyFormat(this.getTotalAmount(), this.state.currentCurrency),
          "fs-xl fw-b"
        )}

        {this.getTotalAmount() !== this.getNewPlanAmount() &&
          this.getTotalRow(
            "Plan Amount",
            data
              ? toCurrencyFormat(
                  this.getNewPlanAmount(),
                  this.state.currentCurrency
                )
              : "-",
            "text-gray mt-s"
          )}
        {data &&
          data.TaxInfo.length > 0 &&
          this.getGSTRow(
            data.TaxInfo,
            data
              ? toCurrencyFormat(data.TaxAmount, this.state.currentCurrency)
              : "-"
          )}

        {data &&
          data.AddonAmount > 0 &&
          this.getTotalRow(
            "Add On Amount",
            data ? this.getAddOnAmount() : "-",
            "text-gray mt-s"
          )}
        {data.UpdateType === PLAN_UPDATE_TYPE.UPGRADE &&
          this.getTotalRow(
            "Current Plan Amount Paid",
            data ? this.getCurrentPlanAmountPaid() : "-",
            "text-grays mt-s text-red",
            true
          )}
        {data.DiscountAmount > 0 &&
          this.getTotalRow(
            "Discount applied",
            "- " +
              toCurrencyFormat(data.DiscountAmount, this.state.currentCurrency),
            "text-green mt-s"
          )}
        {data.UpdateType === PLAN_UPDATE_TYPE.UPGRADE &&
          this.getTotalRow(
            "(Prorated for " + (data ? this.getProRatedDays() : "-") + " days)",
            "",
            "text-gray mt-s"
          )}
      </div>
    );
  }

  getTotalRow(leftText, rightText, className, showMinusSign = false) {
    return (
      <div className="row justify-content-between">
        <DKLabel text={leftText} className={className} />
        <DKLabel
          text={showMinusSign ? "- " + rightText : rightText}
          className={className}
        />
      </div>
    );
  }

  getGSTRow(taxDetail, amount) {
    return (
      <div style={{ width: "inherit" }}>
        {taxDetail.map((item) => (
          <div className="row justify-content-between">
            <DKLabel text={item.TaxLabel} className="text-gray mt-s" />
            <DKLabel text={amount} className="text-gray mt-s" />
          </div>
        ))}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getPlanAmount() {
    return PlanManager.getPlanPriceByCurrency(
      this.state.product,
      this.state.plan,
      this.state.planInterval,
      this.state.currentCurrency
    );
  }

  getPaidUserCount() {
    let updatedAmountData = this.state.updatedAmountData
      ? this.state.updatedAmountData
      : null;
    let paidUserCount = updatedAmountData ? updatedAmountData.PaidUserCount : 1;
    return paidUserCount;
  }

  getFinalAmount() {
    let planAmount = this.getPlanAmount();

    let finalAmount =
      planAmount *
      (this.state.planInterval === INTERVALS.YEARLY ? 12 : 1) *
      this.getPaidUserCount();

    return finalAmount;
  }

  getTotalAmount() {
    let amount = this.getPlanChargeableAmount();
    return amount;
  }
  getPlanChargeableAmount() {
    if (this.state.updatedAmountData) {
      let data = this.state.updatedAmountData;
      return data.ChargeableAmount;
    }
    return 0;
  }
  getPlanChargeableMonthlyAmount() {
    return (
      this.getPlanChargeableAmount() /
      this.getPaidUserCount() /
      (this.state.planInterval === INTERVALS.YEARLY ? 12 : 1)
    );
  }
  getNewPlanAmount() {
    let data = this.state.updatedAmountData;
    return data.NewPlanAmountToPay;
  }
  getNewAddOnAmount() {
    let data = this.state.updatedAmountData;
    return toCurrencyFormat(
      data.NewAddonAmountToPay,
      this.state.currentCurrency
    );
  }
  getAddOnAmount() {
    let data = this.state.updatedAmountData;
    return toCurrencyFormat(data.AddonAmount, this.state.currentCurrency);
  }

  getCurrentPlanAmountPaid() {
    let data = this.state.updatedAmountData;
    return toCurrencyFormat(
      data.CurrentPlanAmountPaid,
      this.state.currentCurrency
    );
  }
  getProRatedDays() {
    let data = this.state.updatedAmountData;
    return data.DaysProRated;
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getActionButtons() {
    return (
      <div
        className={`row mt-xl mb-m ${
          isMobileAppWebView() ? " justify-content-center " : ""
        }`}
      >
        <DKButton
          title="Change plan"
          className="bg-gray1 border-m shadow-m fw-m mr-m"
          onClick={this.gotoChangePlanScreen}
        />
        <DKButton
          title="Checkout"
          className={` text-white shadow-m fw-m ${
            isMobileAppWebView() ? " bg-purple " : "bg-blue"
          }`}
          onClick={
            this.props.onCheckout
              ? () => {
                  this.props.onCheckout();
                }
              : null
          }
        />
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  gotoChangePlanScreen = () => {
    RouteManager.navigateToPage(
      PAGE_ROUTES.PLAN_SELECTION,
      "product=" + this.state.product + "&interval=" + this.state.planInterval
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getSubscriptionNotes() {
    return (
      <div className="pt-r pb-r pl-r pr-r border-m border-radius-s  mt-m bg-gray1">
        <DKLabel
          text="Will my credit card be charged right now?"
          className="fw-m"
        />
        <DKLabel
          text={`No. You won't be charged until after your free trial ends. After your free trial is over, you will be charged ${
            this.state.updatedAmountData
              ? toCurrencyFormat(
                  this.getTotalAmount() / this.getPaidUserCount(),
                  this.state.currentCurrency
                )
              : "$ -"
          } per user until you decide to cancel.`}
          className="text-gray mt-s"
        />
      </div>
    );
  }
}

export default CheckoutPlanDetails;
