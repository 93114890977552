import React from "react";

import {
  DKButton,
  DKIcon,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  removeLoader,
  showAlert,
  showLoader,
  Toggle,
  DKCheckMark,
} from "deskera-ui-library";

import ic_settings from "../../assets/icons/ic_settings.png";
import PasswordPolicyService from "../../services/password_policy";
import Utility from "../../utility/Utility";

export const PASSWORD_POLICY_FIELD = {
  ENABLED: "enabled",
  MUST_INCLUDE_SPECIAL_CHARS: "must_include_special_chars",
  MUST_INCLUDE_UPPER_CASE_LETTERS: "must_include_upper_case_letters",
  MUST_INCLUDE_NUMBERS: "must_include_numbers",
  MAX_LENGTH: "max_length",
  MIN_LENGTH: "min_length",
  EXPIRES_IN_DAYS: "expires_in_days",
  RESTRICT_LAST_PASSWORDS_COUNT: "restrict_last_passwords_count",
  EXPIRE_EXISTING_USERS_PASSWORD: "expire_existing_users_password",
};

class PasswordPolicyPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customError: [],
      didSaveTapped: false,
      enabled: false,
      expireExistingUsersPassword: false,
      maxLength: 15,
      minLength: 6,
      mustIncludeSpecialChars: false,
      mustIncludeUpperCaseLetters: false,
      mustIncludeNumbers: false,
      expirePasswordAfterDays: 90,
      restrictLastPasswordsCount: 3,
    };
  }

  componentDidMount = () => {
    this.getInitialState();
  };

  getInitialState = () => {
    showLoader();
    PasswordPolicyService.getPasswordPolicy()
      .then(
        (res) => {
          this.setState({ ...res });
        },
        (err) => {}
      )
      .finally(() => removeLoader());
  };

  updatePasswordPolicy = () => {
    showLoader();

    const payload = {
      enabled: this.state.enabled === true,
      mustIncludeNumbers: this.state.mustIncludeNumbers === true,
      mustIncludeSpecialChars: this.state.mustIncludeSpecialChars === true,
      mustIncludeUpperCaseLetters:
        this.state.mustIncludeUpperCaseLetters === true,
      maxLength: this.state.maxLength,
      minLength: this.state.minLength,
      expirePasswordAfterDays: this.state.expirePasswordAfterDays,
      restrictLastPasswordsCount: this.state.restrictLastPasswordsCount,
      expireExistingUsersPassword: this.state.expireExistingUsersPassword,
    };

    PasswordPolicyService.savePasswordPolicy(payload).then(
      (res) => {
        removeLoader();
        if (this.state.enabled) {
          showAlert(
            "Password policy updated!",
            "Your password policy has been updated successfully.<br/><br/>Would you like to initiate a forced password reset for all users in your organization? An email will be sent to each user with instructions to reset their password.",
            [
              {
                title: "Close",
                className: "bg-gray1 fw-m",
                onClick: this.cancelTapped,
              },
              {
                title: "Proceed",
                className: "bg-blue text-white fw-m ml-r",
                onClick: this.expireExistingUsersPassword,
              },
            ]
          );
        } else {
          showAlert(
            "Password policy updated!",
            "Your password policy has been updated successfully.",
            [
              {
                title: "Ok",
                className: "bg-blue text-white fw-m",
                onClick: this.cancelTapped,
              },
            ]
          );
        }
      },
      (err) => {
        removeLoader();
        showAlert(
          "Error!",
          "Failed to setup password policy. Please try again after some time.",
          [
            {
              title: "Ok",
              className: "bg-blue text-white fw-m",
              onClick: () => this.cancelTapped(),
            },
          ]
        );
      }
    );
  };

  expireExistingUsersPassword = () => {
    showLoader();
    PasswordPolicyService.expireExistingUsersPassword().then(
      (res) => {
        removeLoader();
        showAlert(
          "Success!",
          "An email was successfully sent to all users with instructions to reset their passwords!",
          [
            {
              title: "Ok",
              className: "bg-blue text-white fw-m",
              onClick: () => this.cancelTapped(),
            },
          ]
        );
      },
      (err) => {
        removeLoader();
        showAlert("Error!", "Failed to expire password for existing users.", [
          {
            title: "Ok",
            className: "bg-blue text-white fw-m",
            onClick: () => this.cancelTapped(),
          },
        ]);
      }
    );
  };

  handleInputChange = (type, value = null) => {
    if (type === PASSWORD_POLICY_FIELD.ENABLED) {
      this.setState({ enabled: !this.state.enabled });
    } else if (this.state.enabled === true) {
      switch (type) {
        case PASSWORD_POLICY_FIELD.MUST_INCLUDE_SPECIAL_CHARS:
          this.setState({
            mustIncludeSpecialChars: !this.state.mustIncludeSpecialChars,
          });
          break;
        case PASSWORD_POLICY_FIELD.MUST_INCLUDE_UPPER_CASE_LETTERS:
          this.setState({
            mustIncludeUpperCaseLetters:
              !this.state.mustIncludeUpperCaseLetters,
          });
          break;
        case PASSWORD_POLICY_FIELD.MUST_INCLUDE_NUMBERS:
          this.setState({ mustIncludeNumbers: !this.state.mustIncludeNumbers });
          break;
        case PASSWORD_POLICY_FIELD.MAX_LENGTH:
          this.setState({ maxLength: parseInt(value) });
          break;
        case PASSWORD_POLICY_FIELD.MIN_LENGTH:
          this.setState({ minLength: parseInt(value) });
          break;
        case PASSWORD_POLICY_FIELD.EXPIRES_IN_DAYS:
          this.setState({ expirePasswordAfterDays: parseInt(value) });
          break;
        case PASSWORD_POLICY_FIELD.RESTRICT_LAST_PASSWORDS_COUNT:
          this.setState({ restrictLastPasswordsCount: parseInt(value) });
          break;
      }
    }
  };

  cancelTapped = () => {
    this.props.onCancel();
  };

  saveTapped = () => {
    this.setState({
      didSaveTapped: true,
    });
    const isValid = this.validateInputs();

    if (isValid) {
      this.updatePasswordPolicy();
    }
  };

  validateInputs = () => {
    let errors = [];

    if (
      !!this.state.minLength &&
      (isNaN(this.state.minLength) ||
        this.state.minLength < 1 ||
        this.state.minLength > 127)
    ) {
      errors.push("Please enter minimum password length between 1 to 127");
    }

    if (
      !!this.state.maxLength &&
      (isNaN(this.state.maxLength) ||
        this.state.maxLength < 1 ||
        this.state.maxLength > 128)
    ) {
      errors.push("Please enter maximum password length between 1 to 128");
    }

    if (
      !(
        !this.state.minLength ||
        isNaN(this.state.minLength) ||
        !this.state.maxLength ||
        isNaN(this.state.maxLength)
      ) &&
      this.state.maxLength < this.state.minLength
    ) {
      errors.push("Password minimum length should be less than maximum length");
    }

    if (
      !!this.state.expirePasswordAfterDays &&
      (isNaN(this.state.expirePasswordAfterDays) ||
        this.state.expirePasswordAfterDays < 1 ||
        this.state.expirePasswordAfterDays > 365)
    ) {
      errors.push("Please enter password expires after days between 1 to 365");
    }

    if (
      !!this.state.restrictLastPasswordsCount &&
      (isNaN(this.state.restrictLastPasswordsCount) ||
        this.state.restrictLastPasswordsCount < 1 ||
        this.state.restrictLastPasswordsCount > 5)
    ) {
      errors.push("Please enter restrict last passwords count between 1 to 5");
    }

    if (errors.length !== 0) {
      this.setState({
        customError: errors,
      });
    }

    return errors.length === 0;
  };

  render() {
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ maxWidth: 500 }}>
          {this.getHeader()}
          {this.getInputView()}
        </div>
      </div>
    );
  }

  getHeader() {
    return (
      <div className="row justify-content-between">
        <div>
          <div className="row">
            <DKIcon src={ic_settings} className=" ic-s-2 mr-s" />
            <DKLabel text="Setup Password Policy" className="fw-m fs-l" />
          </div>
        </div>
        <div>
          <div className="row">
            <DKButton
              title="Cancel"
              className="bg-gray1 border-m fw-m"
              onClick={this.cancelTapped}
            />
            <DKButton
              title="Save"
              className="bg-blue ml-r text-white fw-m"
              onClick={this.saveTapped}
            />
          </div>
        </div>
      </div>
    );
  }

  getInputView = () => {
    const inputStyle = this.state.enabled ? {} : { opacity: 0.5 };
    const { customError } = this.state;

    return (
      <div className="parent-width text-align-left">
        <div className="row justify-content-between mt-xl">
          <DKLabel text="Enable password policy" className="fw-m" />
          <Toggle
            isOn={this.state.enabled}
            onChange={() =>
              this.handleInputChange(PASSWORD_POLICY_FIELD.ENABLED)
            }
            color="bg-blue"
          />
        </div>

        <div className="row justify-content-between mt-xl">
          <DKCheckMark
            title="Must include special characters"
            isSelected={this.state.mustIncludeSpecialChars}
            onClick={() =>
              this.handleInputChange(
                PASSWORD_POLICY_FIELD.MUST_INCLUDE_SPECIAL_CHARS
              )
            }
            color="bg-blue"
            disabled={!this.state.enabled}
          />
        </div>

        <div className="row justify-content-between mt-xl">
          <DKCheckMark
            title="Must include upper case letters"
            isSelected={this.state.mustIncludeUpperCaseLetters}
            onClick={() =>
              this.handleInputChange(
                PASSWORD_POLICY_FIELD.MUST_INCLUDE_UPPER_CASE_LETTERS
              )
            }
            color="bg-blue"
            disabled={!this.state.enabled}
          />
        </div>

        <div className="row justify-content-between mt-xl">
          <DKCheckMark
            title="Must include numbers"
            isSelected={this.state.mustIncludeNumbers}
            onClick={() =>
              this.handleInputChange(PASSWORD_POLICY_FIELD.MUST_INCLUDE_NUMBERS)
            }
            color="bg-blue"
            disabled={!this.state.enabled}
          />
        </div>

        <div className="row justify-content-between mt-l">
          <DKInput
            title="Minimum password length"
            needHeader={false}
            required={false}
            placeholder="Minimum password length"
            value={this.state.minLength || ""}
            valueStyle={inputStyle}
            type={INPUT_TYPE.NUMBER}
            onChange={(value) =>
              this.handleInputChange(PASSWORD_POLICY_FIELD.MIN_LENGTH, value)
            }
            canValidate={this.state.didSaveTapped}
            invalid={
              this.state.didSaveTapped &&
              (isNaN(this.state.minLength) || this.state.minLength < 0)
            }
            className="column mr-m"
            readOnly={!this.state.enabled}
          />
          <DKInput
            title="Maximum password length"
            needHeader={false}
            required={false}
            placeholder="Maximum password length"
            value={this.state.maxLength || ""}
            valueStyle={inputStyle}
            type={INPUT_TYPE.NUMBER}
            onChange={(value) =>
              this.handleInputChange(PASSWORD_POLICY_FIELD.MAX_LENGTH, value)
            }
            canValidate={this.state.didSaveTapped}
            invalid={
              this.state.didSaveTapped &&
              (isNaN(this.state.maxLength) || this.state.maxLength < 0)
            }
            className="column"
            readOnly={!this.state.enabled}
          />
        </div>

        <div className="row justify-content-between mt-xl">
          <DKInput
            title="Password expires after days"
            needHeader={false}
            required={false}
            placeholder="Password expires after days"
            value={this.state.expirePasswordAfterDays || ""}
            valueStyle={inputStyle}
            type={INPUT_TYPE.NUMBER}
            onChange={(value) =>
              this.handleInputChange(
                PASSWORD_POLICY_FIELD.EXPIRES_IN_DAYS,
                value
              )
            }
            canValidate={this.state.didSaveTapped}
            invalid={
              this.state.didSaveTapped &&
              (isNaN(this.state.expirePasswordAfterDays) ||
                this.state.expirePasswordAfterDays < 0)
            }
            className="column mr-m"
            readOnly={!this.state.enabled}
          />
          <DKInput
            title="Restrict last passwords count"
            needHeader={false}
            required={false}
            placeholder="Restrict last passwords count"
            value={this.state.restrictLastPasswordsCount || ""}
            valueStyle={inputStyle}
            type={INPUT_TYPE.NUMBER}
            onChange={(value) =>
              this.handleInputChange(
                PASSWORD_POLICY_FIELD.RESTRICT_LAST_PASSWORDS_COUNT,
                value
              )
            }
            canValidate={this.state.didSaveTapped}
            invalid={
              this.state.didSaveTapped &&
              (isNaN(this.state.restrictLastPasswordsCount) ||
                this.state.restrictLastPasswordsCount < 0)
            }
            className="column"
            readOnly={!this.state.enabled}
          />
        </div>

        {customError.map((error, index) => (
          <DKLabel key={index} text={error} className="text-red mt-r mb-m" />
        ))}
      </div>
    );
  };
}

export default PasswordPolicyPopup;
