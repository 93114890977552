import { FC, useState } from 'react';
import {
	DKLabel,
	DKButton,
	showAlert,
	DKIcon,
	DKInput,
} from 'deskera-ui-library';

import ic_coupons from '../../assets/icons/ic_cards.png';
import { applyCode, checkCode } from '../../services/credit';
import { TALK_TO_SALES_URL_MRP } from '../../constants/Constant';

interface ICreditCodePopupProps {
	onClose: () => void;
	onCodeApplied: (response: any) => void;
}

const CreditCodePopup: FC<ICreditCodePopupProps> = ({
	onClose,
	onCodeApplied,
}) => {
	const [code, setCode] = useState<string>();
	const [validateTapped, setValidateTapped] = useState<boolean>();
	const [validatingCode, setValidatingCode] = useState<boolean>(false);
	const [applyingCode, setApplyingCode] = useState<boolean>(false);
	const [codeDetails, setCodeDetails] = useState<any>();

	const codeExist = !!codeDetails?.Active;

	const getHeader = () => {
		return (
			<div className="row justify-content-between">
				<div>
					<div className="row">
						<DKIcon src={ic_coupons} className="mr-s ic-s-2" />
						<DKLabel
							text={`Redeem Credit code`}
							className="fw-m fs-l"
						/>
					</div>
				</div>
				<div>
					<div className="row">
						<DKButton
							title="Close"
							className="bg-gray1 border-m fw-m"
							onClick={onClose}
						/>
					</div>
				</div>
			</div>
		);
	};

	const getCodeForm = () => {
		return (
			<div className="column mt-xl mb-r parent-width">
				<DKLabel text={`Enter credit code below and validate`} />
				<div
					className="parent-width row mt-m"
					style={{ alignItems: 'flex-start' }}
				>
					<div className="column" style={{ width: '90%' }}>
						<DKInput
							needHeader={false}
							name={'Credit code'}
							placeholder={'credit code'}
							required={true}
							value={code}
							onChange={(code: string) => {
								setCodeDetails(null);
								setValidateTapped(false);
								setCode(code);
							}}
							canValidate={validateTapped}
							invalid={validateTapped && code?.trim() === ''}
						/>
						{codeDetails && (
							<DKLabel
								text={
									codeExist
										? `Credit code is valid, click to apply`
										: 'Credit code does not exist!'
								}
								className={`fs-s mt-s ${
									codeExist ? 'text-green' : 'text-red'
								}`}
							/>
						)}
					</div>
					{!codeExist && (
						<DKButton
							className={`text-wrap-none fw-m ml-m mt-xs ${
								validatingCode
									? 'bg-gray5 text-white'
									: 'text-white bg-green'
							}`}
							title={
								validatingCode ? 'Validating...' : 'Validate'
							}
							disabled={validatingCode}
							onClick={() => {
								if (!validatingCode) {
									setCodeDetails(null);
									setValidateTapped(true);
									validateCode();
								}
							}}
						/>
					)}
					{codeExist && (
						<DKButton
							className={`text-wrap-none fw-m ml-m mt-xs ${
								applyingCode
									? 'bg-gray5 text-white'
									: 'text-white bg-blue'
							}`}
							title={applyingCode ? 'Applying...' : 'Apply'}
							disabled={applyingCode}
							onClick={() => {
								if (!applyingCode) {
									onCodeApply();
								}
							}}
						/>
					)}
				</div>
				<div className='row mt-xl'>
					<DKLabel text="Don't have a code?" />
					<DKButton
						title="Talk to Sales"
						className="bg-white text-blue fw-m p-0 text-underline"
						style={{paddingLeft: 4}}
						onClick={() => {
							window?.open(TALK_TO_SALES_URL_MRP, "_blank");
						}}
					/>
				</div>
			</div>
		);
	};

	const validateCode = async () => {
		if (!code || code?.trim()?.length === 0) {
			return;
		}
		try {
			setValidatingCode(true);
			const codeData: any = await checkCode({ Code: code });
			setCodeDetails(codeData);
		} catch (error) {
		} finally {
			setValidatingCode(false);
		}
	};

	const onCodeApply = async () => {
		if (!code || code?.trim()?.length === 0) {
			return;
		}
		try {
			setApplyingCode(true);
			const response: any = await applyCode({ Code: code });
			if (response) {
				onCodeApplied(response);
			}
		} catch (error) {
			showAlert('Error!', 'Error applying code, please try again!');
		} finally {
			setApplyingCode(false);
		}
	};

	return (
		<div className=" transparent-background">
			<div className="popup-window" style={{ maxWidth: 500 }}>
				{getHeader()}
				{getCodeForm()}
			</div>
		</div>
	);
};

export default CreditCodePopup;
