import {
  COMPLIANCE_COUNTRIES,
  PERMISSIONS,
  PRODUCTS,
  USER_ROLE,
  USER_STATUS,
  USER_TYPE,
} from "../constants/Enum";
import Utility from "../utility/Utility";
import PlanManager from "./PlanManager";
import SubscriptionManager from "./SubscriptionManager";
class UserManager {
  static userDetails = null;
  static usersList = null;
  static userRoleGroup = null;
  static metaData = null;
  static loggedInUserPermissions = null;
  static guestUserPermissions = null;
  static peopleUserDetails = null;
  static isUserDataAvailable() {
    return this.userDetails !== null && this.userDetails !== undefined;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getUserDetails = () => {
    return this.userDetails;
  };

  static setUserDetails = (data) => {
    this.userDetails = data;
  };

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getUserName = () => {
    return this.userDetails ? this.userDetails.name : null;
  };
  static getUserEmail = () => {
    return this.userDetails ? this.userDetails.email : null;
  };
  static getUserPhoneNumber = () => {
    return this.userDetails ? this.userDetails.phone : null;
  };
  static getUserCountry = () => {
    return this.userDetails ? this.userDetails.country : null;
  };
  static setUserCountry = (country) => {
    this.userDetails.country = country;
  };
  static getUserCurrency = () => {
    return this.userDetails ? this.userDetails.currency : null;
  };
  static getUserID = () => {
    return this.userDetails ? this.userDetails.id : null;
  };
  static getUserTenantID = () => {
    return this.userDetails ? this.userDetails.tenantID : null;
  };
  static getUserTenantName = () => {
    // use this from CompanyDetailManager
    return this.userDetails ? this.userDetails.tenantName : "Company Name";
  };

  static setUUID = (uuid) => {
    this.userDetails.uuid = uuid;
  }

  static getUUID = () => {
    return this.userDetails ? this.userDetails.uuid : "";
  }

  static updateTenantName = (name) => {
    this.userDetails = { ...this.userDetails, tenantName: name };
  };

  static updateTenantCreatedDate = (date) => {
    this.userDetails = {...this.userDetails, tenantCreatedDate: date}
  };

  static getTenantCreatedDate = () => {
    return this.userDetails.tenantCreatedDate;
  }

  static isOrgSet = () => {
    if (this.userDetails) {
      if (this.userDetails.isOrgSet === null) {
        return false;
      }
      return true;
    }
    return false;
  };

  static isUserAdmin() {
    if (this.userDetails && this.userDetails.roleShortInfo) {
      return this.userDetails.roleShortInfo.some(
        (role) =>
          role.appName === PRODUCTS.CONSOLE.toUpperCase() &&
          role.shortCode === USER_ROLE.ADMIN.toLowerCase()
      );
    }
    return false;
  }
  static isUserOwner() {
    if (this.userDetails && this.userDetails.roleShortInfo) {
      return this.userDetails.roleShortInfo.some(
        (role) =>
          role.appName === PRODUCTS.ORGANISATION.toUpperCase() &&
          role.shortCode === USER_ROLE.OWNER.toLowerCase()
      );
    }
    return false;
  }

  static isUserManager() {
    if (this.userDetails && this.userDetails.roleShortInfo) {
      return this.userDetails.roleShortInfo.some(
        (role) =>
          role.appName === PRODUCTS.CONSOLE.toUpperCase() &&
          role.shortCode === USER_ROLE.MANAGER.toLowerCase()
      );
    }
    return false;
  }

  static isUserUser() {
    if (this.userDetails && this.userDetails.roleShortInfo) {
      return this.userDetails.roleShortInfo.some(
        (role) =>
          role.appName === PRODUCTS.CONSOLE.toUpperCase() &&
          role.shortCode === USER_ROLE.MEMBER.toLowerCase()
      );
    }
    return false;
  }

  static setUsersPermission(data) {
    if (!Utility.isEmpty(data)) {
      UserManager.guestUserPermissions = data;
    }
  }

  static getUsersPermission() {
    return UserManager.guestUserPermissions;
  }

  static getUserOrgRole() {
    if (this.isUserOwner()) {
      return USER_ROLE.OWNER;
    }
    if (this.isUserAdmin()) {
      return USER_ROLE.ADMIN;
    }
    return USER_ROLE.MEMBER;
  }
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getUserLists = () => {
    return this.usersList;
  };

  static setUsersList = (list) => {
    this.usersList = list
  };

  static getAllOrgUsers = (user) => {
    if (!Utility.isEmpty(this.usersList)) {
      let appsArray = [],
        rolesArray = [],
        productAccessArray = [],
        productRoleMapping = [];
      const products = this.usersList.find((obj, key) => obj.id === user.id);

      if (products.roleShortInfo && products.roleShortInfo.length > 0) {
        products.roleShortInfo.forEach((el) => {
          if (el.appName) {
            appsArray.push(el.appName);
          }
          if (el.shortCode) {
            let role = this.getAllRolesByProduct(el.appName).find(
              (role) => role.shortCode === el.shortCode
            );

            const roleName = !Utility.isEmpty(role) ? role.name : USER_ROLE.MEMBER;
            rolesArray.push(roleName);
            
            if (el.appName.toLowerCase() === PRODUCTS[el.appName]) {
              productRoleMapping.push({
                appName: PRODUCTS[el.appName],
                roleName: roleName
              });
            }
          }
        });
      }

      if (appsArray.length !== 0) {
        appsArray.forEach((el) => {
          if (el.toLowerCase() === PRODUCTS[el]) {
            productAccessArray.push(PRODUCTS[el]);
          }
        });
      }

      if (productAccessArray.length > 0) {
        productAccessArray = productAccessArray.sort();
      }

      let role = [];
      const userProducts = this.getUserProducts(productAccessArray);
      if (userProducts.length > 0) {
        userProducts.forEach((p) => {
          const mapping = productRoleMapping.find((prm) => {
            switch(p) {
              case PRODUCTS.PEOPLE_PLUS:
                if (prm.appName === PRODUCTS.PEOPLE || prm.appName === p) {
                  prm.appName = PRODUCTS.PEOPLE_PLUS;
                  return true;
                } else {
                  return false
                }
              case PRODUCTS.BOOKS_PLUS:
                if (prm.appName === PRODUCTS.ERP || prm.appName === p) {
                  prm.appName = PRODUCTS.BOOKS_PLUS;
                  return true;
                } else {
                  return false;
                }
              default:
                return prm.appName === p;
            }
          });
          if (mapping) {
            role.push(`${PlanManager.getProductDisplayName(mapping.appName, true)} - ${mapping.roleName}`);
          }
        })
      }

      // const role = this.setAllRoles(rolesArray);
      return {
        productAccessArray: productAccessArray,
        allAppsRole: rolesArray,
        role: role,
      };
    }
  };

  static parseRoleTitle(title) {
    if (title.toLowerCase() === "org_user" || title.toLowerCase() === "user") {
      return USER_ROLE.MEMBER.toLowerCase();
    }
    return title;
  }

  static setAllRoles = (rolesArray) => {
    if (rolesArray.includes(USER_ROLE.OWNER)) {
      return USER_ROLE.OWNER;
    } else {
      if (rolesArray.includes(USER_ROLE.ADMIN)) {
        return USER_ROLE.ADMIN;
      } else {
        if (rolesArray.includes(USER_ROLE.MANAGER)) {
          return USER_ROLE.MANAGER;
        } else {
          return USER_ROLE.MEMBER;
        }
      }
    }
  };

  static getUserRole(user) {
    return USER_ROLE.MEMBER;
  }
  static getUserStatus(status) {
    return USER_STATUS[status.toUpperCase()];
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static addUser = (user) => {
    this.usersList.push(user);
  };
  static getUserRoleGroup() {
    return this.userRoleGroup;
  }
  static setUserRoleGroup(userRoleGroup) {
    this.userRoleGroup = userRoleGroup;
  }
  static getParseUserRoleGroup() {
    let parseRoleGroup = [];
    if (!Utility.isEmpty(this.userRoleGroup)) {
      Object.keys(this.userRoleGroup).forEach((key) => {
        if (!Utility.isEmpty(this.userRoleGroup[key])) {
          parseRoleGroup = [...parseRoleGroup, ...this.userRoleGroup[key]];
        }
      });
    }
    return parseRoleGroup;
  }
  static getRolesByProduct(product, guest) {
    return this.getParseUserRoleGroup().filter(
      (data) => data.appName === product && data.guest === guest
    );
  }
  static getAllRolesByProduct(product) {
    return this.getParseUserRoleGroup().filter(
      (data) => data.appName === product
    );
  }
  static setMetaData(metaData) {
    this.metaData = metaData;
  }
  static getMetadata() {
    return this.metaData;
  }
  static setCurrentUserPermission(loggedInUserPermissions) {
    this.loggedInUserPermissions = loggedInUserPermissions;
  }
  static getCurrentUserPermission() {
    return this.loggedInUserPermissions;
  }
  static getParsedMetaData() {
    if (Utility.isEmpty(this.metaData)) {
      return [];
    } else {
      const clonedMetaData = JSON.parse(JSON.stringify(this.metaData));
      return clonedMetaData.map((data) => {
        return {
          appName: data.appName,
          roles: data.roleGroupDto.map((role) => {
            return {
              id: role.id,
              shortCode: role.shortCode,
              name: role.name,
              guest: role.guest,
              modules: role.modules.map((module) => {
                return {
                  [module.modules[0].name]: module.modules,
                };
              }),
            };
          }),
        };
      });
    }
  }
  static getRolesBasedOnProducts(product) {
    if(product === PRODUCTS.BOOKS_PLUS.toUpperCase()){
      product = PRODUCTS.ERP.toUpperCase();
    }
    
    if(product === PRODUCTS.PEOPLE_PLUS.toUpperCase()){
      product = PRODUCTS.PEOPLE.toUpperCase();
    }
    let roles = UserManager.getParsedMetaData().find(
      (role) => role.appName === product
    );
    return Utility.isEmpty(roles) ? [] : roles.roles;
  }
  static getRolesBasedOnUserType(product, isGuest = false) {
    return this.getRolesBasedOnProducts(product).filter(
      (role) => role.guest === isGuest
    );
  }

  static showProduct(plan) {
    if (!Utility.isEmpty(UserManager?.getUserDetails())) {
      const details = !UserManager.isUserOwner() && UserManager?.getUserDetails().roleShortInfo.find(
        (role) => role.appName.toLowerCase() === plan.toLowerCase()
      );
      if (details) {
        return true;
      } else {
        return false;
      }
    }
  }

  static getUserTypeArr(product) {
    let userTypeArr = [];
    if (!Utility.isEmpty(UserManager.getRolesBasedOnUserType(product, true))) {
      userTypeArr.push(USER_TYPE.GUEST);
    }
    if (!Utility.isEmpty(UserManager.getRolesBasedOnUserType(product, false))) {
      userTypeArr.push(USER_TYPE.PAID);
    }
    return userTypeArr;
  }

  static checkIfTenantIsUSOrIN() {
    return (UserManager.getUserCountry() === "US" || UserManager.getUserCountry() === "IN") ? true :false;
  }
  static getPeopleUser() {
    return this.peopleUserDetails
  }
  static setPeopleUser(data) {
    this.peopleUserDetails = data;
  }

  static getUserProducts(apps) {
    let userProducts = [];
    let index;
    let newArray = [...apps];
    let activeProduct = SubscriptionManager.getProductsToShow();

    if (SubscriptionManager.displayPlusProduct()) {
      if (UserManager.checkIfTenantIsUSOrIN()) {
        if (newArray.find((item) => [PRODUCTS.ERP, PRODUCTS.MRP].includes(item)) !== undefined) {
          index = newArray.findIndex((item) => [PRODUCTS.ERP, PRODUCTS.MRP].includes(item));
          newArray[index] = PRODUCTS.BOOKS_PLUS;
        }
      }
      if (newArray.find((item) => item === PRODUCTS.PEOPLE) !== undefined) {
        index = newArray.findIndex((item) => item === PRODUCTS.PEOPLE);
        newArray[index] = PRODUCTS.PEOPLE_PLUS;
      }
    }

    let activeProductFilter = activeProduct.filter((value) => value !== "crm");
    newArray.forEach((app) => {
      if (activeProductFilter.includes(app)) {
        userProducts.push(app);
      }
    });

    return userProducts;
  }
}

export default UserManager;
