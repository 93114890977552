import React, { Component } from "react";
import { PLANS, INTERVALS, PRODUCTS } from "../../constants/Enum";
import PriceCard from "./PriceCard";
import PlanManager from "../../managers/PlanManager";
import CurrencyPicker from "./CurrencyPicker";

import ic_aio from "../../assets/productIcons/ic_aio.svg";
import ic_books from "../../assets/productIcons/ic_books.svg";
import ic_crm from "../../assets/productIcons/ic_crm.svg";
import ic_people from "../../assets/productIcons/ic_people.svg";
import ic_company from "../../assets/icons/ic_company.png";
import ic_arrow_down from "../../assets/icons/ic_arrow_down2.png";
import ic_white_arrow_down from "../../assets/icons/white/ic_arrow_down.png";

import {
  DKButton,
  DKIcon,
  DKLabel
} from "deskera-ui-library";

import CurrencyManager from "../../managers/CurrencyManager";
import SubscriptionManager from "../../managers/SubscriptionManager";

import { CURRENCYLIST } from "../../constants/Enum";
import { isMobileAppWebView } from "../../utility/ViewportSizeUtils";
import BottomSheetPopup from "./BottomSheetPopup";

/*
- product
- className
- style
- header
- text
- themeColor
- themeColor_2
*/

export default class PriceSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: this.props.product,
      planInterval: this.props.planInterval
        ? this.props.planInterval
        : PlanManager.getDefaultInterval(),
      needCurrencyListPicker: false,
      displayGST: this.displayGSTInfo(),
      needProductListPicker: false,
      tenantID: this.props.tenantID,
      userID: this.props.userID,
      isBookkeeper: this.props.tenantID.length > 0 && this.props.userID.length > 0 ? true : false
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      product: nextProp.product,
      planInterval: nextProp.planInterval
        ? nextProp.planInterval
        : PlanManager.getDefaultInterval(),
      needCurrencyListPicker: nextProp.needCurrencyListPicker,
      displayGST: this.displayGSTInfo(),
      needProductListPicker: nextProp.needProductListPicker,
      tenantID: nextProp.tenantID,
      userID: nextProp.userID,
      isBookkeeper: this.props.tenantID.length > 0 && this.props.userID.length > 0 ? true : false
    });
  }

  refreshScreen = () => {
    this.setState({});
  };

  displayGSTInfo() {
    let currency = SubscriptionManager.getCurrencySameAsActivePlanCurrency();

    if (currency === CURRENCYLIST.INR) {
      return true
    } else {
      return false
    }
  }

  ////////////////////////////////////
  // UI
  ////////////////////////////////////

  render() {
    return (
      <div
        className={
          "column display-box parent-width align-self-center align-items-center justify-content-center position-relative mt-xl " +
          this.props.className
        }
        style={{ marginBottom: 70 }}
      >
        {this.getHeader()}
        <div
          className={`price-section parent-width ${isMobileAppWebView() ? ' overflow-x-auto mobile-pt-s' : ''} `}
          style={{
            ...{},
            ...this.props.style,
          }}
        >
          <PriceCard
            themeColor={this.props.themeColor}
            product={this.state.product}
            plan={PLANS.STARTUP}
            currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
            planInterval={this.state.planInterval}
            onBuyClick={(product, plan) => this.props.onBuyClick(product, plan)}
            onTryClick={(product, plan) => this.props.onTryClick(product, plan)}
            onDataChange={this.refreshScreen}
            userID={this.state.userID}
            tenantID={this.state.tenantID}
          />
          <PriceCard
            themeColor={this.props.themeColor}
            product={this.state.product}
            plan={PLANS.ESSENTIAL}
            currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
            planInterval={this.state.planInterval}
            onBuyClick={(product, plan) => this.props.onBuyClick(product, plan)}
            onTryClick={(product, plan) => this.props.onTryClick(product, plan)}
            onDataChange={this.refreshScreen}
            userID={this.state.userID}
            tenantID={this.state.tenantID}
          />
          <PriceCard
            themeColor={this.props.themeColor}
            product={this.state.product}
            plan={PLANS.PROFESSIONAL}
            currency={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
            planInterval={this.state.planInterval}
            onBuyClick={(product, plan) => this.props.onBuyClick(product, plan)}
            onTryClick={(product, plan) => this.props.onTryClick(product, plan)}
            onDataChange={this.refreshScreen}
            userID={this.state.userID}
            tenantID={this.state.tenantID}
          />
        </div>
        {this.state.displayGST &&
          <div className="row justify-content-end mt-m">
            * Price excludes GST
          </div>
        }
      </div>
    );
  }

  getCurrencyListHeader() {
    return (
      <div>
        <div
          className="row cursor-hand"
          onClick={this.toggleCurrencyListPicker}
        >
          <DKIcon src={ic_company} className="ic-s mr-s" />
          <DKLabel
            text={SubscriptionManager.getCurrencySameAsActivePlanCurrency()}
            className="fs-l"
          />
          <DKIcon src={ic_arrow_down} className="ic-s ml-s" />
        </div>
      </div>
    );
  }

  getHeader() {
    return (
      <div className=" z-index-2 row justify-content-between mt-m">
        {!isMobileAppWebView() && <div className="row">
          {!this.state.isBookkeeper && this.getHeaderButton(PRODUCTS.AIO, ic_aio)}
          {this.getHeaderButton(PRODUCTS.ERP, ic_books)}
          {!this.state.isBookkeeper && this.getHeaderButton(PRODUCTS.CRM, ic_crm)}
          {!this.state.isBookkeeper && this.getHeaderButton(PRODUCTS.PEOPLE, ic_people)}
        </div>}
        {isMobileAppWebView() && this.getProductListHeader()}
        {this.getIntervalSelection()}
        {/* {this.getCurrencyListHeader()}
				{this.state.needCurrencyListPicker  && this.renderCurrencyPicker()} */}
        {this.state.needProductListPicker && this.renderProductListPicker()}
      </div>
    );
  }

  getHeaderButton(product, icon) {
    return (
      <DKButton
        title={PlanManager.getProductDisplayName(product)}
        icon={icon}
        className={
          "fw-m  mr-m   " +
          (this.state.product === product ? "shadow-m border-m bg-white" : "")
        }
        onClick={() => {
          this.setState({ product: product }, () => {
            this.changeUrl();
          });
        }}
      />
    );
  }

  changeUrl() {
    return;
    // let url = `plans?product=${this.state.product}&interval=${this.state.planInterval}`;
    // if (typeof window.history.pushState != "undefined") {
    //   window.history.pushState(null, null, url);
    // } else {
    //   window.location.href = "/";
    //   // alert("Browser does not support HTML5.");
    // }
  }

  onSwitchCurrency(value) {
    CurrencyManager.setCurrentCurrency(value);
    this.setState({
      needCurrencyListPicker: !this.state.needCurrencyListPicker,
    });
  }

  renderCurrencyPicker() {
    return (
      <div
        ref={this.setWrapperRef}
        className="position-absolute mt-s"
        style={{ top: 46, right: 0 }}
      >
        <CurrencyPicker
          CurrencySelected={(e) => {
            this.onSwitchCurrency(e);
          }}
          onClose={this.hideCurrencyPicker}
        />
      </div>
    );
  }

  toggleCurrencyListPicker = () => {
    this.setState({
      needCurrencyListPicker: !this.state.needCurrencyListPicker,
    });
  };
  showCurrencyPicker = () => {
    this.setState({
      needCurrencyListPicker: true,
    });
  };
  hideCurrencyPicker = () => {
    this.setState({
      needCurrencyListPicker: false,
    });
  };

  getIntervalSelection() {
    return (
        <div
          className="row width-auto border-radius-m bg-white border-m shadow-m"
        >
          <div
            className={
              " cursor-hand pt-s pb-s pl-r pr-r " +
              (this.state.planInterval === INTERVALS.MONTHLY
                ? `border-radius-left fw-b  text-white ${isMobileAppWebView() ? ' bg-purple' : ' bg-dark-gray'}`
                : "")
            }
            onClick={() => {
              this.planIntervalChanged(INTERVALS.MONTHLY);
            }}
          >
            Monthly
          </div>
          <div
            className={
              " cursor-hand pt-s pb-s pl-r pr-r " +
              (this.state.planInterval === INTERVALS.YEARLY
                ? `border-radius-right fw-b text-white ${isMobileAppWebView() ? ' bg-purple' : ' bg-dark-gray'}`
                : "")
            }
            onClick={() => {
              this.planIntervalChanged(INTERVALS.YEARLY);
            }}
          >
            Yearly
          </div>
        </div>
    );
  }

  planIntervalChanged = (interval) => {
    this.setState(
      {
        planInterval: interval,
      },
      () => {
        this.changeUrl();
      }
    );
    // PlanManager.setDefaultPlanInterval(interval);
  };

  getProductListHeader() {
    return (

          <div
              className="row cursor-hand border-purple shadow-m justify-content-center border-radius-m p-xs bg-purple"
              style={{width: 120}}
              onClick={this.showProductListPicker}
          >
            <DKLabel
                text={PlanManager.getProductDisplayName(this.state.product)}
                className="fs-l fw-r text-white text-align-center"
                style={{width: 100}}
            />
            <DKIcon src={ic_white_arrow_down} className="ic-xs ml-s pr-xs" />
          </div>
    );
  }

  showProductListPicker = () => {
    this.setState({
      needProductListPicker: true,
    });
  };
  hideProductListPicker = () => {
    this.setState({
      needProductListPicker: false,
    });
  };

  renderProductListPicker() {
    return (<BottomSheetPopup
        data={PlanManager.getProductListNames()}
        onSelect={(data, index) => {
          this.hideProductListPicker()
          if(data && data.key) {
            this.setState({product: data.key, needProductListPicker: false,}, () => {
              this.changeUrl();
            });
          }
        }}
        onClose={this.hideProductListPicker}/>
      )
    }
}
