import { COLORS } from "../constants/Colors";
import { CURRENCYLIST, INTERVALS, PLANS, PRODUCTS } from "../constants/Enum";
import { planDetails } from "../constants/Plans";
import {
  getCapitalized,
  getCapitalizedForMultipleWords,
} from "../utility/Utility";
import PriceHeroTabsManager from "./PriceHeroTabsManager";
import SubscriptionManager from "./SubscriptionManager";

export default class PlanManager {
  static planDetailsFromAPI = null;
  static defaultPlanInterval = INTERVALS.YEARLY;
  static setPlanDetailsFromAPI(data) {
    PlanManager.planDetailsFromAPI = data;
  }
  static getPlanDetailsFromAPI(data) {
    return PlanManager.planDetailsFromAPI;
  }

  static getProductDisplayName(product, isFullCharacters) {
    switch (product.toLowerCase()) {
      case PRODUCTS.AIO:
        return "All-in-One";
      case PRODUCTS.ERP:
        return "ERP";
      case PRODUCTS.BOOKS_PLUS:
        return "ERP";
      case PRODUCTS.CRM:
        return "CRM";
      case PRODUCTS.CRM_PLUS:
        return isFullCharacters ? "CRM Plus" : "CRM +";
      case PRODUCTS.PEOPLE:
        return "People";
      case PRODUCTS.PEOPLE_PLUS:
        return isFullCharacters ? "People Plus" : "People +";
      case PRODUCTS.WEBSITE_BUILDER:
        return "Website Builder";
      case PRODUCTS.SHOP:
        return "Shop";
      case PRODUCTS.REPORT_BUILDER:
        return "Report Builder";
      case PRODUCTS.DOCUMENT_BUILDER:
        return "Doc Builder";
      case PRODUCTS.DOCUMENT_SCANNER:
        return "Doc Scanner";
      case PRODUCTS.ORGANISATION:
        return "Organisation";
      case PRODUCTS.CONSOLE:
        return "Console";
      case PRODUCTS.WORKFLOW_ENGINE:
        return "Workflow Engine";
      case PRODUCTS.ATTENDANCE:
        return "Attendance";
      case PRODUCTS.EXPENSE:
        return "Expense";
      case PRODUCTS.CHAT:
        return "Chat";
      case PRODUCTS.EM: //Expense
        return "Expense";
      case PRODUCTS.DS: //Shop
        return "Shop";
      case PRODUCTS.EL: //Leave Management
        return "Leave Management";
      case PRODUCTS.ERP_V2:
        return "ERP";
      case PRODUCTS.MRP:
        return "MRP";
      case PRODUCTS.AI:
        return "AI";
      default:
        return "Product";
    }
  }

  static getPlanDisplayName(plan, isERPOrMRPFlow = false) {
    if (plan === PLANS.FREEMIUM) PlanManager.isFreemium = true;
    switch (this.getParsedPlan(plan)) {
      case PLANS.STARTUP:
        if (isERPOrMRPFlow) {
          return getCapitalizedForMultipleWords(PLANS.SMALL_BUSINESS);
        } else {
          return getCapitalized(PLANS.STARTUP);
        }
      case PLANS.ESSENTIAL:
        if (isERPOrMRPFlow) {
          return getCapitalized(PLANS.GROWTH);
        } else {
          return getCapitalized(PLANS.ESSENTIAL);
        }
      case PLANS.PROFESSIONAL:
        if (isERPOrMRPFlow) {
          return getCapitalizedForMultipleWords(PLANS.MID_MARKET);
        } else {
          return getCapitalized(PLANS.PROFESSIONAL);
        }
      case PLANS.FREEMIUM:
        return getCapitalized(PLANS.FREEMIUM);
      case PLANS.SMALL_BUSINESS:
        return getCapitalized(PLANS.SMALL_BUSINESS);
      case PLANS.GROWTH:
        return getCapitalized(PLANS.GROWTH);
      case PLANS.MID_MARKET:
        return getCapitalized(PLANS.MID_MARKET);
      default:
        return getCapitalized(PLANS.PROFESSIONAL);
    }
  }

  static getProductColor(product) {
    switch (product.toLowerCase()) {
      case PRODUCTS.ERP:
      case PRODUCTS.ERP_V2:
        return COLORS.erp;
      case PRODUCTS.CRM:
        return COLORS.crm;
      case PRODUCTS.PEOPLE:
        return COLORS.people;
      case PRODUCTS.MRP:
        return COLORS.mrp;
      default:
        return COLORS.aio;
    }
  }

  static getProductSecondaryColor(product) {
    switch (product.toLowerCase()) {
      case PRODUCTS.ERP:
        return COLORS.erp_secondary;
      case PRODUCTS.CRM:
        return COLORS.crm_secondary;
      case PRODUCTS.PEOPLE:
        return COLORS.people_secondary;
      default:
        return COLORS.aio_secondary;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  static isValidProduct(product) {
    let list_product = PlanManager.getProductList();
    let filter_arr = list_product.filter(
      (obj) => obj === product.toLowerCase()
    );
    return filter_arr.length > 0;
  }

  static isValidPlan(plan) {
    let list_plan = PlanManager.getPlanList();
    let filter_arr = list_plan.filter((obj) => obj === plan.toLowerCase());
    return filter_arr.length > 0;
  }

  static isValidInterval(interval) {
    let list_plan = PlanManager.getIntervalList();
    let filter_arr = list_plan.filter((obj) => obj === interval.toLowerCase());
    return filter_arr.length > 0;
  }

  ////////////////////////////////////////////////////////////////////////////////////////

  static getProductList() {
    return Object.keys(PRODUCTS).map((obj) => PRODUCTS[obj]);
  }

  static getPlanList() {
    return Object.keys(PLANS).map((obj) => PLANS[obj]);
  }

  static getIntervalList() {
    return Object.keys(INTERVALS).map((obj) => INTERVALS[obj]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  static getDefaultProduct() {
    return PRODUCTS.AIO;
  }
  static getDefaultPlan() {
    return PLANS.PROFESSIONAL;
  }

  static getDefaultPlanForERP() {
    return PLANS.ESSENTIAL;
  }

  static setDefaultPlanInterval(interval) {
    // called from Price section
    PlanManager.defaultPlanInterval = interval;
  }
  static getDefaultInterval() {
    return PlanManager.defaultPlanInterval;
  }
  static getDefaultCurrencyCode() {
    return "usd";
  }

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////

  static getParsedPlan(plan) {
    if (plan === null || plan === undefined) {
      return plan;
    }
    if (
      plan.toLowerCase().includes(PLANS.INDIVIDUAL) ||
      plan.toLowerCase().includes(PLANS.STARTUP)
    ) {
      return PLANS.STARTUP;
    } else if (plan.toLowerCase().includes(PLANS.PROFESSIONAL)) {
      return PLANS.PROFESSIONAL;
    } else if (plan === PLANS.FREEMIUM) {
      return PLANS.FREEMIUM;
    }
    return plan.toLowerCase();
  }

  static getPlanDescription(product, plan) {
    return planDetails[product.toLowerCase()] &&
      planDetails[product.toLowerCase()][PlanManager.getParsedPlan(plan)]
      ? planDetails[product.toLowerCase()][PlanManager.getParsedPlan(plan)][
          "description"
        ]
      : "";
  }

  static getPlanDescriptionForERPAndMRP(product, plan, location) {
    return planDetails[product.toLowerCase()] &&
      planDetails[product.toLowerCase()][PlanManager.getParsedPlan(plan)]
      ? planDetails[product.toLowerCase()][PlanManager.getParsedPlan(plan)][
          "descriptionObj"
        ][location]
      : "";
  }

  static getPlanPrice(product, plan, interval) {
    if (
      planDetails[product.toLowerCase()] &&
      planDetails[product.toLowerCase()][PlanManager.getParsedPlan(plan)]
    ) {
      return planDetails[product.toLowerCase()][
        PlanManager.getParsedPlan(plan)
      ]["pricing"][SubscriptionManager.getCurrencySameAsActivePlanCurrency()][
        interval.toLowerCase()
      ].toFixed(2);
    } else {
      return "";
    }
  }

  static getPlanPriceByCurrency(product, plan, interval, currency) {
    if (currency === undefined || currency === "") {
      currency = "USD";
    }

    if (product === PRODUCTS.ERP_V2 || product === PRODUCTS.MRP) {
      let currencyList = [CURRENCYLIST.INR, CURRENCYLIST.USD];
      if (!currencyList.includes(currency)) {
        currency = "USD";
      }
    }

    if (
      planDetails[product.toLowerCase()] &&
      planDetails[product.toLowerCase()][PlanManager.getParsedPlan(plan)]
    ) {
      return planDetails[product.toLowerCase()][
        PlanManager.getParsedPlan(plan)
      ]["pricing"][currency][interval.toLowerCase()].toFixed(2);
    } else {
      return "";
    }
  }

  static getPlanID(product, plan, interval) {
    const country = SubscriptionManager.getIndiaOrGlobalLocation();

    const isERPOrMRPFlow =
      product === PRODUCTS.ERP_V2 || product === PRODUCTS.MRP;

    if (
      planDetails[product.toLowerCase()] &&
      planDetails[product.toLowerCase()][PlanManager.getParsedPlan(plan)]
    ) {
      if (!isERPOrMRPFlow) {
        return planDetails[product.toLowerCase()][
          PlanManager.getParsedPlan(plan)
        ]["planID"][interval.toLowerCase()];
      } else {
        return planDetails[product.toLowerCase()][
          PlanManager.getParsedPlan(plan)
        ]["planID"][country][interval.toLowerCase()];
      }
    } else {
      return "";
    }
  }

  static getProductListNames() {
    return [
      { key: PRODUCTS.AIO, value: this.getProductDisplayName(PRODUCTS.AIO) },
      { key: PRODUCTS.ERP, value: this.getProductDisplayName(PRODUCTS.ERP) },
      { key: PRODUCTS.CRM, value: this.getProductDisplayName(PRODUCTS.CRM) },
      {
        key: PRODUCTS.PEOPLE,
        value: this.getProductDisplayName(PRODUCTS.PEOPLE),
      },
    ];
  }
}
