import React, { useEffect, useState } from "react";
import {
    DKButton,
    DKLabel,
    DKInput,
    INPUT_TYPE,
    INPUT_VIEW_DIRECTION,
    DKIcon,
    DKIcons,
    showLoader,
    removeLoader,
    showToast,
    TOAST_TYPE
} from "deskera-ui-library";
import User from "../../services/user";
import ic_invisibility from "../../assets/icons/ic_invisibility.svg"
import ic_visibility from "../../assets/icons/ic_visibility.png"
import PasswordValidation from "./PasswordValidation";

function SetPasswordPopup(props) {
    const [password, setPassword] = useState({
        type: INPUT_TYPE.PASSWORD,
        title: "Enter new password",
        value: ""
    });
    const [confirmPassword, setConfirmPassword] = useState({
        type: INPUT_TYPE.PASSWORD,
        title: "Confirm new password",
        value: ""
    });
    const [saveTapped, setSaveTapped] = useState(false);
    const [isPasswordFocus, setIsPasswordFocus] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPasswordPolicyEnabled, setIsPasswordPolicyEnabled] = useState(false);

    useEffect(() => {
        if (saveTapped) {
            onSave();
        }
    }, [saveTapped]);

    const isValidPassword = () => {
        if(isPasswordPolicyEnabled) {
            return isPasswordValid && confirmPassword.value === password.value;
        }
        return password.value.length >= 8 && password.value.length <= 72 && confirmPassword.value === password.value;
    }

    const onSave = () => {
        if (!isValidPassword()) {
            return;
        }

        props.onClose();
        showLoader("Updating user password...");
        const payload = {
            userName: props.userData.email,
            password: confirmPassword.value,
            confirmationCode: -1
        };

        User.setPassword(payload).then(
            () => {
                removeLoader();
                showToast("Password updated successfully!", TOAST_TYPE.SUCCESS);
            }, () => {
                removeLoader();
            }
        );
    }

    const updateFieldState = (state, setState, field, value) => {
        if (field === "value") {
            value = String(value).replace(/\s/g, "")
        }

        setState({
            ...state,
            [field]: value
        });
    }

    const validator = (password) => {
        if(isPasswordPolicyEnabled) {
            return isPasswordValid;
        }
        return password.length >= 8 && password.length <= 72;
    }

    const getNewPassErrMessage = (password) => {
        if (password.length === 0) {
            return "Please enter a valid password";
        } else {
            if(isPasswordPolicyEnabled) {
                if(!isPasswordValid) {
                    return "Invalid password";
                }
            } else if (password.length <= 8) {
                return "Password cannot be less than 8 characters";
            } else if (password.length > 72) {
                return "Password cannot be more than 72 characters";
            }
        }
    }

    const getConfirmPassErrMessage = (password, confirmPassword) => {
        if (confirmPassword.length === 0) {
            return "Please enter a valid password";
        } else {
            if (password !== confirmPassword) {
                return "Confirm password should match new password";
            }
        }
    }

    const getHideShowPasswordIcon = (state, setState) => {
        return (
            <div className="z-index-1" style={{ marginLeft: "-25px", marginTop: "24px", opacity: 0.75 }}>
                <DKIcon
                    src={state.type === INPUT_TYPE.PASSWORD ? ic_invisibility : ic_visibility}
                    className="ic-s z-index-1 cursor-hand p-xs"
                    onClick={() => {
                        const type = state.type === INPUT_TYPE.PASSWORD ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD;
                        updateFieldState(state, setState, "type", type);
                    }}
                />
            </div>
        );
    }

    const getPasswordField = () => {
      return (
        <PasswordValidation
          username={props.userData.email}
          password={password.value}
          isPasswordFocus={isPasswordFocus}
          setIsPasswordValid={setIsPasswordValid}
          setIsPasswordPolicyEnabled={setIsPasswordPolicyEnabled}
        >
          <div className="row mt-xl">
            {password.type === INPUT_TYPE.PASSWORD && (
              <DKInput
                title={password.title}
                type={INPUT_TYPE.PASSWORD}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={true}
                value={password.value}
                onChange={(value) => {
                  setSaveTapped(false);
                  updateFieldState(password, setPassword, "value", value);
                }}
                canValidate={saveTapped}
                validator={validator}
                errorMessage={getNewPassErrMessage(password.value)}
                onFocus={() => setIsPasswordFocus(true)}
                onBlur={() => setIsPasswordFocus(false)}
              />
            )}
            {password.type === INPUT_TYPE.TEXT && (
              <DKInput
                title={password.title}
                type={INPUT_TYPE.TEXT}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                required={true}
                value={password.value}
                onChange={(value) => {
                  setSaveTapped(false);
                  updateFieldState(password, setPassword, "value", value);
                }}
                canValidate={saveTapped}
                validator={validator}
                errorMessage={getNewPassErrMessage(password.value)}
                onFocus={() => setIsPasswordFocus(true)}
                onBlur={() => setIsPasswordFocus(false)}
              />
            )}
            {getHideShowPasswordIcon(password, setPassword)}
          </div>
        </PasswordValidation>
      );
    };

    const getConfirmPasswordField = () => {
        return (
            <div className="row mt-xl">
                {confirmPassword.type === INPUT_TYPE.PASSWORD &&
                    <DKInput
                        title={confirmPassword.title}
                        type={INPUT_TYPE.PASSWORD}
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                        required={true}
                        value={confirmPassword.value}
                        onChange={(value) => {
                            setSaveTapped(false);
                            updateFieldState(confirmPassword, setConfirmPassword, "value", value);
                        }}
                        canValidate={saveTapped}
                        validator={() => saveTapped && password.value === confirmPassword.value}
                        errorMessage={getConfirmPassErrMessage(password.value, confirmPassword.value)}
                    />
                }
                {confirmPassword.type === INPUT_TYPE.TEXT &&
                    <DKInput
                        title={confirmPassword.title}
                        type={INPUT_TYPE.TEXT}
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                        required={true}
                        value={confirmPassword.value}
                        onChange={(value) => {
                            setSaveTapped(false);
                            updateFieldState(confirmPassword, setConfirmPassword, "value", value);
                        }}
                        canValidate={saveTapped}
                        validator={() => saveTapped && password.value === confirmPassword.value}
                        errorMessage={getConfirmPassErrMessage(password.value, confirmPassword.value)}
                    />
                }
                {getHideShowPasswordIcon(confirmPassword, setConfirmPassword)}
            </div>
        );
    }

    return (
        <div className=" transparent-background">
            <div className="popup-window" style={{ maxWidth: "325px", minWidth: "325px", overflowY: 'visible' }}>
                <div className="parent-width mb-l">
                    <div className="row justify-content-between">
                        <div className="row">
                            <DKIcon src={DKIcons.ic_key} className="ic-s-2 mr-s" />
                            <DKLabel text="Set Password" className="fw-m fs-l" />
                        </div>
                        <div>
                            <div className="row">
                                <DKButton
                                    title="Cancel"
                                    className="bg-gray1 border-m fw-m"
                                    onClick={props.onClose}
                                />
                                <DKButton
                                    title="Save"
                                    className="bg-blue ml-r text-white fw-m"
                                    onClick={() => {
                                        setSaveTapped(true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="parent-width">
                        {getPasswordField()}
                        {getConfirmPasswordField()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SetPasswordPopup;
