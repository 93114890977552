import { PLAN_IDS, pageUrls } from "../constants/Constant";

import bgPricingGreen from "../assets/bg-gradients/pricing-green.webp";
import bgPricingYellow from "../assets/bg-gradients/pricing-yellow.webp";
import bgPricingBlue from "../assets/bg-gradients/pricing-blue.svg";
import bgSmallBusiness from "../assets/bg-gradients/pricing-page/ig-gradient-plan-pack-small-business.webp";
import bgMidMarket from "../assets/bg-gradients/pricing-page/ig-gradient-plan-pack-mid-market.webp";
import bgGrowth from "../assets/bg-gradients/pricing-page/ig-gradient-plan-pack-growth.webp";
import bgEnterprise from "../assets/bg-gradients/pricing-page/ig-gradient-plan-pack-enterprise.webp";

import pricing_all_in_one_yellow_icon from "../assets/website/icons/pricing-all-in-one-yellow-icon.webp";
import pricing_enterprise_gray_icon from "../assets/website/icons/pricing-enterprise-gray-icon.webp";
import pricing_mrp_gray_icon from "../assets/website/icons/pricing-mrp-gray-icon.webp";
import { planDetailsV2 } from "../constants/Plans";
import { PLANS_NAME_V2 } from "../constants/Enum";
import { COLORS } from "../constants/Colors";
import RouteManager from "./RouteManager";
import Utility from "../utility/Utility";
export default class PriceHeroTabsManagerUS {
  /// /////////////////////////////////////////////////////////////////////////////////
  /// ////////////////////////////////////////////////////////////////////////////////

  static PricingPreviewData = {
    header: `Right sized options <br> <span style="color:rgba(195, 95, 200, 1);""> For your Business </span>`,
    subHeader: `Industry leading features at wallet friendly prices. Implement<br>financial controls, reduce inventory costs and optimize<br> manufacturing and warehouse operations with the <span style="color:rgba(195, 95, 200, 1);">#1 Cloud<br>Software</span>☝ trusted by 300,000+ users.`,
    plans: [
      {
        icon: pricing_all_in_one_yellow_icon,
        // className: `gradient-border gradient-border-blue`,
        backGround: bgPricingBlue,
        name: PLANS_NAME_V2.ERP,
        amount: planDetailsV2.erp.pricing[`us`],
        currency: `$`,
        description: `<span style="font-weight:bold;">Deskera ERP offers businesses a starter suite for Accounting, Financial Management, CRM, and HR Management</span><br><br>Try it free for 14 days. No credit card required`,
        trialInfo: `plans start with free trial for 14 days`,
        billingInfo: `per user per month (billed annually)`,
        cta: {
          title: `Talk to ERP Specialist`,
          accentColor: `#000000`,
          btnColor: `#F6CF6A`,
          borderColor: `#D2B058`,
          onClick: () => this.goToPricingPage(),
        },
        content: [
          `Accounting & Inventory`,
          `CRM & Email Campaigns`,
          `Payroll & HRMS`,
          `Integrated, all in one platform`,
        ],
        featureBorderColor: {
          borderWidth: `1px 0px`,
          borderStyle: `solid`,
          borderColor: `rgba(61, 156, 64, 0.53)`,
        },
      },
      {
        icon: pricing_enterprise_gray_icon,
        // className: `gradient-border gradient-border-green`,
        backGround: bgPricingGreen,
        name: PLANS_NAME_V2.ERP_PLUS,
        amount: planDetailsV2.erp_plus.pricing[`us`],
        currency: `$`,
        description: `Deskera ERP+ offers growing businesses Inventory and Warehouse Management, Workflows and Automations, Project Accounting, and everything under ERP`,
        trialInfo: `plan starts with minimum 10 users`,
        billingInfo: `per user per month (billed annually)`,
        cta: {
          title: `Talk to ERP Specialist`,
          accentColor: `#000000`,
          btnColor: `#F6CF6A`,
          borderColor: `#D2B058`,
          onClick: () => this.goToPricingPage(),
        },
        content: [
          `All Deskera ERP Features`,
          `Inventory & Warehouse Management`,
          `Workflows & Automations`,
          `Project Accounting`,
        ],
        featureBorderColor: {
          borderWidth: `1px 0px`,
          borderStyle: `solid`,
          borderColor: `rgba(170, 142, 45, 0.5)`,
        },
      },
      {
        icon: pricing_mrp_gray_icon,
        // className: `gradient-border gradient-border-yellow`,
        backGround: bgPricingYellow,
        name: PLANS_NAME_V2.MRP_PLUS,
        amount: planDetailsV2.mrp_plus.pricing[`us`],
        currency: `$`,
        description: `Deskera MRP+ is designed for manufacturing businesses to manage materials, resources, and production processes efficiently`,
        trialInfo: `plan starts with minimum 5 users`,
        billingInfo: `per user per month (billed annually)`,
        cta: {
          title: `Talk to MRP Specialist`,
          accentColor: `#000000`,
          btnColor: `#F6CF6A`,
          borderColor: `#D2B058`,
          btnSize: 210,
          onClick: () => this.goToPricingPage(),
        },
        content: [
          `All Deskera ERP Features`,
          `All Deskera MRP Features`,
          `Custom Integrations`,
          `Custom Dashboards`,
          `Custom Reports`,
        ],
        featureBorderColor: {
          borderWidth: `1px 0px`,
          borderStyle: `solid`,
          borderColor: `rgba(170, 142, 45, 0.5)`,
        },
      },
    ],
  };
  static goToPricingPage = () => {
    RouteManager.navigateToPage(pageUrls.PRICING + `#talk-to-sales`);
  };
  static PricingPreviewERPData = {
    header: `Best in class Cloud <span class="text-green">ERP</span>. <div>Best in class <span class="text-green">pricing</span>.</div>`,
    subHeader: `Deskera comes in three flavors`,
    plans: [
      // {
      //   id: PLAN_IDS.SMALL_BUSINESS,
      //   icon: `ic-small-business`,
      //   className: `gradient-border mix-blend-border`,
      //   backGround: bgSmallBusiness,
      //   name: "Small Business",
      //   amount: "250",
      //   width: 238,
      //   currency: "$",
      //   description: `Ideal for businesses with 0-5 employees`,
      //   trialInfo: `includes 2 users`,
      //   billingInfo: `per month billed annually`,
      //   cta: {
      //     title: `Start Free Trial`,
      //     accentColor: "#181818",
      //     btnColor: "#F0BAB9",
      //     borderColor: "#E06E6C",
      //     url: process.env.REACT_APP_BASE_URL_WEBSITE + pageUrls.SIGN_UP,
      //   },
      //   content: [
      //     "Accounting",
      //     "Financial Reports",
      //     "Bank Connect",
      //     "Integrated CRM",
      //     "Expense and Leave Tracking",
      //   ],
      //   featureBorderColor: {
      //     borderWidth: "1px 0px",
      //     borderStyle: "solid",
      //     borderColor: "#E06E6C",
      //   },
      //   planID: process.env.REACT_APP_ERP_SMALL_BUSINESS,
      // },
      {
        id: PLAN_IDS.GROWTH,
        icon: `ic-growth`,
        className: `gradient-border mix-blend-border`,
        backGround: bgGrowth,
        name: "Growth",
        amount: "1,000",
        width: 322,
        currency: "$",
        description: `Ideal for businesses with 5-20 employees`,
        trialInfo: `includes 5 users`,
        billingInfo: `per month billed annually`,
        cta: {
          title: `Talk to Sales `,
          accentColor: "#181818",
          btnColor: "#C5E3E8",
          borderColor: "#83C3CD",
          btnSize: 145,
          url:
            process.env.REACT_APP_BASE_URL_WEBSITE +
            "/contact-us?prefilltext= I want to know more about ERP - Growth plan",
        },
        // contentHeader: "Everything in Small Business, plus :",
        content: [
          "Acccounting",
          "Financial Reports",
          "Bank Connect",
          "Integrated CRM",
          "Expense and Leave Tracking",
          "Class, Locations, Departments",
          "Warehouse/Lot/Serial Tracking",
          "Automations",
        ],
        featureBorderColor: {
          borderWidth: "1px 0px",
          borderStyle: "solid",
          borderColor: "#83C3CD",
        },
        planID: process.env.REACT_APP_ERP_GROWTH,
      },
      {
        id: PLAN_IDS.MID_MARKET,
        icon: `ic-mid-market`,
        className: `gradient-border mix-blend-border`,
        backGround: bgMidMarket,
        name: "Mid Market",
        amount: "2,000",
        width: 322,
        currency: "$",
        description: `Ideal for businesses with 20-50 employees`,
        trialInfo: `includes 10 users`,
        billingInfo: `per month billed annually`,
        cta: {
          title: `Talk to Sales`,
          accentColor: "#181818",
          btnColor: "#E9C7D4",
          borderColor: "#CF86A2",
          btnSize: 145,
          url:
            process.env.REACT_APP_BASE_URL_WEBSITE +
            "/contact-us?prefilltext= I want to know more about ERP - Mid Market plan",
        },
        contentHeader: "Everything in Growth, plus :",
        content: [
          "Financial Controls",
          "Warehouse Roles",
          "Time Tracking",
          "Landed Costs",
          "Report Builder",
        ],
        featureBorderColor: {
          borderWidth: "1px 0px",
          borderStyle: "solid",
          borderColor: "#CF86A2",
        },
        planID: process.env.REACT_APP_ERP_MID_MARKET,
      },
      {
        id: PLAN_IDS.ENTERPRISE,
        icon: `ic-enterprise`,
        className: `gradient-border mix-blend-border`,
        backGround: bgEnterprise,
        name: "Enterprise",
        amount: "",
        width: 322,
        currency: "$",

        description: `Ideal for businesses with more than 50 employees`,
        trialInfo: `includes 15 users`,
        billingInfo: `per month billed annually`,
        cta: {
          title: `Talk to Sales`,
          accentColor: "#181818",
          btnColor: "#D9DCF1",
          borderColor: "#A5ACDE",
          btnSize: 145,
          url:
            process.env.REACT_APP_BASE_URL_WEBSITE +
            "/contact-us?prefilltext= I want to know more about ERP - Enterprise plan",
        },
        contentHeader: "All inclusive with advanced features",
        content: [
          "Revenue Recognition",
          "Multi-Book Accounting",
          "Custom Reports & Dashboards",
          "Custom Automations",
          "Custom Integrations",
        ],
        featureBorderColor: {
          borderWidth: "1px 0px",
          borderStyle: "solid",
          borderColor: "#A5ACDE",
        },
        planID: "",
      },
    ],
  };
  static PricingPreviewMRPData = {
    header: `Best in class Cloud <span class="text-green">ERP</span>. <div>Best in class <span class="text-green">pricing</span>.</div>`,
    subHeader: `Deskera comes in three flavors`,
    plans: [
      {
        icon: `ic-growth`,
        className: `gradient-border mix-blend-border`,
        backGround: bgGrowth,
        name: "Growth",
        amount: "1,800",
        width: 322,
        currency: "$",
        description: `Ideal for businesses with 5-20 employees`,
        trialInfo: `includes 5 users`,
        billingInfo: `per month billed annually`,
        cta: {
          title: `Talk to Sales`,
          accentColor: "#181818",
          btnColor: "#C5E3E8",
          borderColor: "#83C3CD",
          btnSize: 145,
          url:
            process.env.REACT_APP_BASE_URL_WEBSITE +
            "/contact-us?prefilltext= I want to know more about MRP - Growth plan",
        },
        content: [
          "Job Management",
          "Multi Level BOM",
          "Work Orders",
          "Yield Calculations",
          "Manufacturing Reports",
        ],
        featureBorderColor: {
          borderWidth: "1px 0px",
          borderStyle: "solid",
          borderColor: "#83C3CD",
        },
      },
      {
        icon: `ic-mid-market`,
        className: `gradient-border mix-blend-border`,
        backGround: bgMidMarket,
        name: "Mid Market",
        amount: "2,900",
        width: 322,
        currency: "$",
        description: `Ideal for businesses with 20-50 employees `,
        trialInfo: `includes 10 users`,
        billingInfo: `per month billed annually`,
        cta: {
          title: `Talk to Sales`,
          accentColor: "#181818",
          btnColor: "#E9C7D4",
          borderColor: "#CF86A2",
          btnSize: 145,
          url:
            process.env.REACT_APP_BASE_URL_WEBSITE +
            "/contact-us?prefilltext= I want to know more about MRP - Mid Market plan",
        },
        contentHeader: "Everything in Growth, plus :",
        content: [
          "Demand Forecasting",
          "Super BOM",
          "Production Scheduling",
          "Quality Checks",
          "Contract Manufacturing",
        ],
        featureBorderColor: {
          borderWidth: "1px 0px",
          borderStyle: "solid",
          borderColor: "#CF86A2",
        },
      },
      {
        icon: `ic-enterprise`,
        className: `gradient-border mix-blend-border`,
        backGround: bgEnterprise,
        name: "Enterprise",
        amount: "",
        width: 322,
        currency: "$",

        description: `Ideal for businesses with more than 50 employees`,
        trialInfo: `includes 15 users`,
        billingInfo: `per month billed annually`,
        cta: {
          title: `Talk to Sales`,
          accentColor: "#000000",
          btnColor: "#D9DCF1",
          borderColor: "#A5ACDE",
          btnSize: 145,
          url:
            process.env.REACT_APP_BASE_URL_WEBSITE +
            "/contact-us?prefilltext= I want to know more about MRP - Enterprise plan",
        },
        contentHeader: "All inclusive with advanced features",
        content: [
          "Custom Workflows",
          "Custom Routing",
          "Custom Automations",
          "Custom Reports & Dashboards",
        ],
        featureBorderColor: {
          borderWidth: "1px 0px",
          borderStyle: "solid",
          borderColor: "#A5ACDE",
        },
      },
    ],
  };
  static pricingSpecificationData = [
    [
      {
        id: `#ERP`,
        title: `ERP`,
        icon: "ic-pricing-erp-tab",
        color: `#2E4BC6`,
        // subtitle: `Organize your business books online to for seamless the fiscal efficiency in your organization. `,
        // plan_values: {
        //     us: [44, 55, 66]
        // },
        plan_available: {
          small_business: true,
          growth: true,
          mid_market: true,
          enterprise: true,
        },
        features: [
          {
            features_name: `Estimates`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Sales Order`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Invoices`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Stripe Integration`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Paypal Integration`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Square Integration`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Shopify Integration`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Woo Commerce Integration`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Amazon Integration`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Purchase Requisitions`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Purchase Orders`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Bills & Expenses`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Document Template Designer`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Payment Milestones`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Customer & Vendors`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Products & Services`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Price Lists`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Inventory Tracking`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Order Fulfillment`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Goods Receipt`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Inventory Reorder`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Product Variants`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Inventory Barcoding`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `UOM Schemas`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Multi-Warehouse`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Row Rack Bin`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Warehouse Roles`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Stock Adjustment`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Stock Transfers`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Lot/Batch/Serial Tracking`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Bill of Materials`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Pick-Pack-Ship`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Landed Cost`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Shipstation Integration`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Bank Feeds (US only)`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Import Bank Transactions`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Bank Rules`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Chart of Accounts`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Journal Entries`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Deposits`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Expenses`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Credit Notes`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Debit Notes`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Budgeting`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Financial Year Closing`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Class, Department, Location`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Custom Fields`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Multi Book Accounting`,
            enterprise: true,
          },
          {
            features_name: `Financial Reports`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Sales Reports`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Purchase Reports`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Inventory Reports`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Warehouse Reports`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Multi Entity Consolidation`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Approval Flows`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Workflow Automation`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Revenue Recognition`,
            enterprise: true,
          },
          {
            features_name: `Expense Amortization`,
            enterprise: true,
          },
          {
            features_name: `Reclassification`,
            enterprise: true,
          },
          {
            features_name: `Multi Currency Accounting`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Revaluations`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Inter Company Eliminations`,
            enterprise: true,
          },
          {
            features_name: `Role Based Access`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Audit Log`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Account Management`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Contact Database`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Deals & Opportunities`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Sales Pipelines`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Tasks & Activities`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Forms`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Landing Pages`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Email Broadcasts`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Drip Email Sequence`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Sales Automation`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Marketing Automation`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `CRM Reports`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Recruitment Management`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Employee Database`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: Utility.isLocationIndia()
              ? `Payroll`
              : `Payroll Integration`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Time Offs`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Employee Expenses`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Timesheet Tracking`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Shift Management`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Attendance Tracking`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Geo Fencing`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Performance Appraisals`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Mobile App`,
            small_business: true,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Report Builder`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `APIs`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Custom Integrations`,
            enterprise: true,
            isOptional: true,
          },
          {
            features_name: `Custom Reports`,
            mid_market: true,
            enterprise: true,
            isOptional: true,
          },
          {
            features_name: `Custom Dashboards`,
            mid_market: true,
            enterprise: true,
            isOptional: true,
          },
        ],
        // talk_to_expert_button: {
        //     name: `Talk to Accounting Expert  `,
        //     link: pageUrls.PRICING + `#talk-to-sales`
        // }
      },
    ],
    [
      {
        id: `#MRP`,
        title: `MRP`,
        icon: "ic-pricing-mrp-tab",
        color: `#B64F0B`,
        // subtitle: `Organize your business books online to for seamless the fiscal efficiency in your organization. `,
        // plan_values: {
        //     us: [44, 55, 66]
        // },
        plan_available: {
          growth: true,
          mid_market: true,
          enterprise: true,
        },
        features: [
          {
            features_name: `Material Master`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Multi Level BOM`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Finished Good Costing`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Track Operations`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Track Workstations/Machines`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Track Operators/Manpower`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Work Order Management`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Job Management`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Explode BOM`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Yield Calculation`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `WIP Manufacturing`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Manufacturing Reports`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Super BOM`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Demand Forecasting`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Production Scheduling`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Quality Checks`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Contract Manufacturing`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Estimates`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Sales Order`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Invoices`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Stripe Integration`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Paypal Integration`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Square Integration`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Shopify Integration`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Woo Commerce Integration`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Purchase Requisitions`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Purchase Orders`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Bills & Expenses`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Document Template Designer`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Payment Milestones`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Customer & Vendors`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Products & Services`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Price Lists`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Inventory Tracking`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Order Fulfillment`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Goods Receipt`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Inventory Reorder`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Product Variants`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Inventory Barcoding`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `UOM Schemas`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Multi-Warehouse`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Warehouse Roles`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Row Rack Bin`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Stock Adjustment`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Stock Transfers`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Lot/Batch/Serial Tracking`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Bill of Materials`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Pick-Pack-Ship`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Landed Cost`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Shipstation Integration`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Bank Feeds (US only)`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Import Bank Transactions`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Bank Rules`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Chart of Accounts`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Journal Entries`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Deposits`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Expenses`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Credit Notes`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Debit Notes`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Budgeting`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Financial Year Closing`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Class, Department, Location`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Custom Fields`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Multi Book Accounting`,
            enterprise: true,
          },
          {
            features_name: `Financial Reports`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Sales Reports`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Purchase Reports`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Inventory Reports`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Warehouse Reports`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Multi Entity Consolidation`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Approval Flows`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Workflow Automation`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Revenue Recognition`,
            enterprise: true,
          },
          {
            features_name: `Expense Amortization`,
            enterprise: true,
          },
          {
            features_name: `Reclassification`,
            enterprise: true,
          },
          {
            features_name: `Multi Currency Accounting`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Revaluations`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Inter Company Eliminations`,
            enterprise: true,
          },
          {
            features_name: `Role Based Access`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Audit Log`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Account Management`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Contact Database`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Deals & Opportunities`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Sales Pipelines`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Tasks & Activities`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Forms`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Landing Pages`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Email Broadcasts`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Drip Email Sequence`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Sales Automation`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Marketing Automation`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `CRM Reports`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Recruitment Management`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Employee Database`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: Utility.isLocationIndia()
              ? `Payroll`
              : `Payroll Integration`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Time Offs`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Employee Expenses`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Timesheet Tracking`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Shift Management`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Attendance Tracking`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Geo Fencing`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Performance Appraisals`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Mobile App`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Report Builder`,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `APIs`,
            growth: true,
            mid_market: true,
            enterprise: true,
          },
          {
            features_name: `Custom Integrations`,
            enterprise: true,
            isOptional: true,
          },
          {
            features_name: `Custom Reports`,
            mid_market: true,
            enterprise: true,
            isOptional: true,
          },
          {
            features_name: `Custom Dashboards`,
            mid_market: true,
            enterprise: true,
            isOptional: true,
          },
        ],
        // talk_to_expert_button: {
        //     name: `Talk to Accounting Expert  `,
        //     link: pageUrls.PRICING + `#talk-to-sales`
        // }
      },
    ],
  ];
}
export const pricingDataFunctions = () => {
  return {
    className: `mt-xxxl align-self-center`,
    style: {
      maxWidth: 1400,
      padding: 30,
      paddingBottom: 0,
      width: `100vw`,
    },
    numColumns: 7,
    minCardWidth: `180px`,
    gridCards: [
      {
        className: `pricing-tabs-icon`,
        accentColor: `#2E4BC6`,
        icon: {
          name: `ic-pricing-erp-tab`,
          color: `#A3BFF7`,
        },
        header: `ERP`,

        data: PriceHeroTabsManagerUS.PricingPreviewERPData,
      },
      {
        className: `pricing-tabs-icon`,
        accentColor: `#B64F0B`,
        icon: {
          name: `ic-pricing-mrp-tab`,
          color: COLORS.erp,
        },
        header: `MRP`,
        data: PriceHeroTabsManagerUS.PricingPreviewMRPData,
      },
    ],
  };
};
