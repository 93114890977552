import ApiConstants from "../constants/ApiConstants";
import http from "../http";
import MixPanelManager from "../managers/common/MIxpanelManger";
import { trackEventAC } from "../managers/ActiveCampaignWrapper";
import { TRACK_EVENT } from "../constants/Enum";
import IAMManager from "../managers/IAMManager";
import UserManager from "../managers/UserManager";

import {
  removeLoader,
  showLoader,
  showToast, 
  TOAST_TYPE
} from "deskera-ui-library";

export default class IAM {
  static _authInstance = null;
  static statusCallPromise = null;

  static clearStoredStatusCall() {
    IAM.statusCallPromise = null;
  }

  static getLoginStatus(params) {
    return http.get(ApiConstants.URL.IAM, { params: params }).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static async checkIfUserLoggedIn() {
    /* For avoiding parallel status calls */
    if (IAM.statusCallPromise) {
      return IAM.statusCallPromise;
    }

    IAM.statusCallPromise = fetch(
      ApiConstants.URL.BASE + ApiConstants.URL.IAM.STATUS,
      {
        method: "GET",
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        // IAM._authInstance.clearStoredStatusCall();
        return response.json();
      })
      .catch((error) => {
        return error;
      });

    return IAM.statusCallPromise;
  }

  static logOut() {
    showLoader("Logging out...");
    return http.get(ApiConstants.URL.IAM.LOG_OUT).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
  static switchTenant(params) {
    showLoader("Switching tenant...");
    return http.post(ApiConstants.URL.IAM.SWITCH_TENANT, params).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
  static getTwoFactorAuthStatus(params) {
    showLoader("Getting status...");
    return http.get(ApiConstants.URL.IAM.TFA_STATUS, { params: params }).then(
      (res) => {
        removeLoader();
        IAMManager.setTFAStatus(res);
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
  static enableTwoFactorAuthStatus(params) {
    showLoader("Generating QR code...");
    return http.post(ApiConstants.URL.IAM.CODE_TFA, params).then(
      (res) => {
        removeLoader();
        IAMManager.setTwoFactorAuthCode(res);
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
  static registerTwoFactorAuthStatusApp(params) {
    showLoader("Submitting your response...");
    return http.post(ApiConstants.URL.IAM.ENABLE_TFA, params).then(
      (res) => {
        removeLoader();
        IAMManager.setRegisterTwoFactorAuthStatusApp(res);
        MixPanelManager.mfaEnabled();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
  static disableTwoFactorAuthStatusApp(params) {
    showLoader("Disabling Two Factor Authentication");
    return http.delete(ApiConstants.URL.IAM.DELETE_TFA, { data: params }).then(
      (res) => {
        removeLoader();
        MixPanelManager.mfaDisabled();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }

  static changePassword(params) {
    showLoader("Updating password...");
    return http
      .post(ApiConstants.URL.IAM.CHANGE_PASSWORD, {
        newPassword: params.newPassword,
        oldPassword: params.oldPassword,
      })
      .then(
        (res) => {
          removeLoader();
          MixPanelManager.passwordChange();
          return Promise.resolve(res);
        },
        (err) => {
          removeLoader();
          return Promise.reject(err);
        }
      );
  }
  static resendVerificationEmail() {
    const encodedMailAddress = encodeURIComponent(UserManager.getUserEmail());
    return http
      .get(
        `${ApiConstants.URL.IAM.RESEND_VERIFICATION_MAIL}?email=${encodedMailAddress}`
      )
      .then(
        (data) => {
          showToast(
            `Verification e-mail sent successfully`,
            TOAST_TYPE.SUCCESS
          );
          Promise.resolve(data);
        },
        (err) => {
          showToast(
            `Unable to send verification e-mail. Please try Again`,
            TOAST_TYPE.FAILURE
          );
          Promise.reject(err);
        }
      );
  }

  static sendChangeEmailOTP(data) {
    showLoader("Send Email...");
    return http.post(ApiConstants.URL.IAM.CHANGE_EMAIL_OTP, data).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }

  static confirmChangeEmailOTP(data) {
    showLoader("Verifying...");
    return http.post(ApiConstants.URL.IAM.CONFIRM_EMAIL_OTP, data).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }

  static resetCompany(params) {
    showLoader("Resetting company...");
    return http.delete(ApiConstants.URL.IAM.RESET_COMPANY + '?password=' + btoa(params.password)).then(
      (res) => {
        removeLoader();
        MixPanelManager.resetCompany();
        Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }

  static deleteCompany(params, callback) {
    showLoader("Deleting company...");
    return http.delete(ApiConstants.URL.IAM.DELETE_COMPANY + params.id + '?password=' + btoa(params.password)).then(
      (res) => {
        removeLoader();
        MixPanelManager.deleteCompany();
        trackEventAC(
          {
            event: TRACK_EVENT.DELETE_COMPANY,
            eventData: "Company deleted successfully",
            email: UserManager.getUserEmail(),
          },
          (clbk) => {
            if (clbk) {
              callback(true);
            }
          }
        );
      },
      (err) => {
        removeLoader();
        callback(false);
      }
    );
  }
}
