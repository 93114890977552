import React, { Component } from "react";

import Intercom from "react-intercom";
import UserManager from "../../managers/UserManager";
import IntercomManager from "../../managers/IntercomManager";

class IntercomDiv extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    IntercomManager.trackEvent("User Login", {
      url: "GO",
    });
  }

  render() {
    let user = {
      // app_id: process.env.REACT_APP_INTERCOM_ID,
      // email: UserManager.getUserEmail(),
      // name: UserManager.getUserName(),
      // company: {
      //   company_id: UserManager.getUserTenantID(),
      //   name: UserManager.getUserTenantName(),
      // },
    };

    return <Intercom appID={process.env.REACT_APP_INTERCOM_ID} {...user} />;
  }
}

export default IntercomDiv;
