import React from "react";

import { DKButton, DKIcon, DKLabel, DKLine } from "deskera-ui-library";

import ic_document from "../../assets/icons/ic_document.png";
import Subscription from "../../services/subscription";
import SubscriptionManager from "../../managers/SubscriptionManager";
import { toCurrencyFormat, getCapitalized } from "../../utility/Utility";
import { INVOICE_STATUS } from "../../constants/Enum";
import InvoiceDetailPopup from "./InvoiceDetailPopup";
import MixPanelManager from "../../managers/common/MIxpanelManger";

class InvoiceHistoryPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceList: SubscriptionManager.getInvoices(),
      selectedTab: 0,
      showInvoiceDetailPopup: false,
      invoiceDetailsData: null,
      selectedSegment: 0,
    };
  }

  componentDidMount() {
    MixPanelManager.invoiceView({ doc_type: "INVOICE_LIST_VIEW" });
  }

  render() {
    return (
      <div>
        <div className=" transparent-background">
          <div className="popup-window" style={{ maxWidth: 500, height: 500 }}>
            {this.getHeader()}
            {this.state.invoiceList && this.getHeaderSegment()}
            <div className="parent-width column p-h-l parent-width pt-m">
              {this.state.selectedSegment === 0 && this.getPaidInvoices()}
              {this.state.selectedSegment === 1 && this.getFailedInvoices()}
            </div>
          </div>
        </div>
        {this.state.showInvoiceDetailPopup && (
          <InvoiceDetailPopup
            invoiceDetailsData={this.state.invoiceDetailsData}
            onClose={this.closeInvoiceDetailsPopup}
          />
        )}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getOptionButton = (
    index,
    title,
    selected = index === this.state.selectedSegment
  ) => {
    return (
      <div
        className=" cursor-hand "
        onClick={() => {
          this.setState({ selectedSegment: index });
        }}
      >
        <DKLabel
          text={title}
          className={"mt-m" + (selected ? " fw-b text-app-color " : "")}
        />
        {selected && (
          <div className="parent-width bg-app mt-r" style={{ height: 2.5 }} />
        )}
      </div>
    );
  };

  getHeaderSegment = () => {
    return (
      <div className="parent-width">
        <DKLine />
        <div
          className="row bg-gray1 border-radius-s p-h-r align-items-start"
          style={{ gap: 30, backgroundColor: "rgb(250, 250, 250)" }}
        >
          {this.getOptionButton(0, getCapitalized(INVOICE_STATUS.PAID))}
          {this.getOptionButton(1, getCapitalized(INVOICE_STATUS.FAILED))}
        </div>
        <DKLine />
      </div>
    );
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getPaidInvoices() {
    const { invoiceList } = this.state;
    return (
      <div className="parent-width">
        {invoiceList && invoiceList.paid.length !== 0
          ? invoiceList.paid.map((obj, index) => {
              return this.getInvoiceRow(obj, index);
            })
          : this.getEmptyState("No paid invoices")}
      </div>
    );
  }

  getFailedInvoices() {
    const { invoiceList } = this.state;
    return (
      <div className="parent-width">
        {invoiceList && invoiceList.failed.length !== 0
          ? invoiceList.failed.map((obj, index) => {
              return this.getInvoiceRow(obj, index);
            })
          : this.getEmptyState("No failed invoices")}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getHeader() {
    return (
      <div className="row justify-content-between mb-s">
        <div>
          <div className="row">
            <DKIcon src={ic_document} className="mr-s ic-s-2" />
            <DKLabel text="Billing history (Invoices)" className="fw-m fs-l" />
          </div>
        </div>
        <div>
          <div className="row">
            <DKButton
              title="Close"
              className="bg-gray1 border-m fw-m"
              onClick={this.cancelTapped}
            />
          </div>
        </div>
      </div>
    );
  }

  cancelTapped = () => {
    this.props.onClose();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getEmptyState(title) {
    return (
      <div
        className="column parent-width align-items-center "
        style={{ marginTop: "30%" }}
      >
        <DKLabel text={title} className="text-gray" />
      </div>
    );
  }

  getInvoiceRow(obj, index) {
    return (
      <div
        className="column parent-width cursor-hand"
        onClick={() => this.setInvoiceDetailsPopup(obj)}
      >
        <div
          key={index}
          className="row justify-content-between border-box pl-r pr-r "
        >
          <div style={{ flex: "0", paddingRight: "15px" }}>{index + 1}.</div>
          <div style={{ flex: "2" }}>
            <DKLabel text={"# " + obj.InvoiceID} className="fw-b" />
            {obj.ChargeDate && (
              <DKLabel className="text-gray mt-xs" text={obj.ChargeDate} />
            )}
          </div>
          <DKLabel
            className="fs-l fw-m"
            text={toCurrencyFormat(obj.TotalAmount, obj.Currency.toUpperCase())}
          />
        </div>
        <DKLine style={{ marginTop: 12, marginBottom: 12 }} />
      </div>
    );
  }

  onTabClick = (index) => {
    this.setState(
      {
        selectedTab: index,
      },
      () => {
        if (index === 0) {
          Subscription.getPaidInvoice(null, index === 0 ? true : false).then(
            (res) => {
              this.setState({
                invoiceList: SubscriptionManager.getInvoices(),
              });
            },
            (err) => {}
          );
        } else if (index === 1) {
          Subscription.getFailedInvoice().then(
            (data) => {
              this.setState({
                invoiceList: SubscriptionManager.getInvoices(),
              });
            },
            (err) => {}
          );
        }
      }
    );
  };

  setInvoiceDetailsPopup = (data) => {
    this.setState({
      showInvoiceDetailPopup: true,
      invoiceDetailsData: data,
    });
  };

  closeInvoiceDetailsPopup = () => {
    this.setState({
      showInvoiceDetailPopup: false,
    });
  };
}

export default InvoiceHistoryPopup;
