import React, { Component } from "react";
import { CURRENCYLIST } from "../../constants/Enum";

import { DKLabel } from "deskera-ui-library";

import { toCurrencyFormat } from "../../utility/Utility";

/*
PROPS:
- price
- discountedPrice
*/

class PriceLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: this.props.price,
      discountedPrice: this.props.discountedPrice,
      currency: this.props.currency,
      displayGST: this.displayGST(this.props.currency),
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      price: nextProps.price,
      discountedPrice: nextProps.discountedPrice,
      currency: nextProps.currency,
      displayGST: this.displayGST(nextProps.currency),
    });
  }

  displayGST(currency) {
    if (currency === CURRENCYLIST.INR) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    let isDiscountAvailable =
      this.state.discountedPrice &&
      this.state.discountedPrice < this.state.price;

    let isDisplayGST = this.state.displayGST;

    return (
      <div className="parent-width row">
        <DKLabel
          className={
            "fw-b mr-r " +
            (isDiscountAvailable ? "fs-m text-cut text-gray " : "fs-xl-2")
          }
          text={toCurrencyFormat(this.state.price, this.state.currency)}
        />
        {isDiscountAvailable && (
          <DKLabel
            className="fw-b fs-xl-2 mr-r"
            text={toCurrencyFormat(
              this.state.discountedPrice,
              this.state.currency
            )}
          />
        )}
        {isDisplayGST && (
          <div className="pb-s" style={{ marginLeft: "-5px" }}>
            *
          </div>
        )}
      </div>
    );
  }
}

export default PriceLabel;
