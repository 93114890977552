import { DKLabel, removeLoader, showLoader } from "deskera-ui-library";
import { useEffect, useState } from "react";
import { PASSWORD_VALIDATION_REGEX } from "../../constants/Constant";
import PasswordPolicyService from "../../services/password_policy";
import Utility from "../../utility/Utility";

function ValidationMessage({ data }) {
  const fontColorClass = data.isValid ? "text-green" : "text-red";
  return data.canValidate ? (
    <div className="d-flex align-items-baseline mb-s">
      <DKLabel
        className={"mr-s " + fontColorClass}
        text={data.isValid ? "&#x2714;" : "&#x2716;"}
      />
      <DKLabel className={fontColorClass} text={data.message} />
    </div>
  ) : (
    <></>
  );
}

const defaultState = {
  minLength: {
    canValidate: false,
    isValid: false,
    message: "Password must be at least {minLength} characters long.",
  },
  maxLength: {
    canValidate: false,
    isValid: false,
    message: "Password cannot be longer than {maxLength} characters.",
  },
  minMaxLength: {
    canValidate: false,
    isValid: false,
    message:
      "Password must be between {minLength} and {maxLength} characters long.",
  },
  containUpperCaseLetter: {
    canValidate: false,
    isValid: false,
    message: "Password must contain at least one uppercase letter.",
  },
  containSpecialCharacter: {
    canValidate: false,
    isValid: false,
    message: "Password must contain at least one special character.",
  },
  containNumber: {
    canValidate: false,
    isValid: false,
    message: "Password must contain at least one number.",
  },
  preventSequence: {
    canValidate: true,
    isValid: false,
    message:
      "Password cannot contain more than 3 consecutive/repeated characters. For e.g. 0000, 1234, abcd, $$$$, etc.",
  },
  validCharacters: {
    canValidate: true,
    isValid: false,
    message:
      "Password must only contain letters, numbers, and allowed special characters: ~, !, @, #, $, %, ^, &, *, (, ), -, _, =, +, etc.",
  },
};

const validationsSeq = [
  "minLength",
  "maxLength",
  "minMaxLength",
  "containUpperCaseLetter",
  "containSpecialCharacter",
  "containNumber",
  "preventSequence",
  "validCharacters",
];

function PasswordValidation({
  username,
  password,
  isPasswordFocus,
  setIsPasswordValid,
  setIsPasswordPolicyEnabled,
  children,
}) {
  const [passwordValidation, setPasswordValidation] = useState({
    ...defaultState,
  });
  const [passwordPolicy, setPasswordPolicy] = useState({
    enabled: false,
  });

  useEffect(() => {
    fetchPasswordPolicy();
  }, []);

  useEffect(() => {
    if (passwordPolicy.enabled) {
      validatePasswordPolicy();
    }
  }, [password, passwordPolicy]);

  useEffect(() => {
    if (passwordPolicy.enabled) {
      setIsPasswordValid(validatePassword());
    }
  }, [passwordValidation, passwordPolicy]);

  const fetchPasswordPolicy = () => {
    if(!username?.length) {
      return;
    }
    showLoader();
    PasswordPolicyService.getPasswordPolicyByUsername("desk." + username)
      .then(
        (res) => {
          if (!Utility.isEmpty(res)) {
            setPasswordPolicy({ ...res });
            updateValidation(res);
            setIsPasswordPolicyEnabled(!!res.enabled);
          }
        },
        (err) => {}
      )
      .finally(() => removeLoader());
  };

  const updateValidation = (passwordPolicy) => {
    const updatedState = { ...passwordValidation };

    if (passwordPolicy.minLength > 0 && passwordPolicy.maxLength > 0) {
      updatedState.minMaxLength.canValidate = true;
      updatedState.minMaxLength.message =
        updatedState.minMaxLength.message.replace(
          "{minLength}",
          passwordPolicy.minLength
        );
      updatedState.minMaxLength.message =
        updatedState.minMaxLength.message.replace(
          "{maxLength}",
          passwordPolicy.maxLength
        );
    } else if (passwordPolicy.minLength > 0) {
      updatedState.minLength.canValidate = true;
      updatedState.minLength.message = updatedState.minLength.message.replace(
        "{minLength}",
        passwordPolicy.minLength
      );
    } else if (passwordPolicy.maxLength > 0) {
      updatedState.maxLength.canValidate = true;
      updatedState.maxLength.message = updatedState.maxLength.message.replace(
        "{maxLength}",
        passwordPolicy.maxLength
      );
    }

    updatedState.containUpperCaseLetter.canValidate =
      passwordPolicy.mustIncludeUpperCaseLetters === true;
    updatedState.containSpecialCharacter.canValidate =
      passwordPolicy.mustIncludeSpecialChars === true;
    updatedState.containNumber.canValidate =
      passwordPolicy.mustIncludeNumbers === true;

    setPasswordValidation({ ...updatedState });
  };

  const validatePasswordPolicy = () => {
    const updatedState = { ...passwordValidation };

    if (!password?.length) {
      updatedState.minLength.isValid = false;
      updatedState.maxLength.isValid = false;
      updatedState.minMaxLength.isValid = false;
      updatedState.containUpperCaseLetter.isValid = false;
      updatedState.containSpecialCharacter.isValid = false;
      updatedState.containNumber.isValid = false;
      updatedState.preventSequence.isValid = false;
      updatedState.validCharacters.isValid = false;
    } else {
      if (updatedState.minLength.canValidate) {
        updatedState.minLength.isValid =
          password.length >= passwordPolicy.minLength;
      }
      if (updatedState.maxLength.canValidate) {
        updatedState.maxLength.isValid =
          password.length <= passwordPolicy.maxLength;
      }
      if (updatedState.minMaxLength.canValidate) {
        updatedState.minMaxLength.isValid =
          password.length >= passwordPolicy.minLength &&
          password.length <= passwordPolicy.maxLength;
      }
      if (updatedState.containUpperCaseLetter.canValidate) {
        updatedState.containUpperCaseLetter.isValid =
          PASSWORD_VALIDATION_REGEX.CONTAINS_UPPER_CASE.test(password);
      }
      if (updatedState.containSpecialCharacter.canValidate) {
        updatedState.containSpecialCharacter.isValid =
          PASSWORD_VALIDATION_REGEX.CONTAINS_SPECIAL_CHAR.test(password);
      }
      if (updatedState.containNumber.canValidate) {
        updatedState.containNumber.isValid =
          PASSWORD_VALIDATION_REGEX.CONTAINS_NUMBER.test(password);
      }
      if (updatedState.preventSequence.canValidate) {
        updatedState.preventSequence.isValid = !(
          PASSWORD_VALIDATION_REGEX.CONTAINS_REPETATION.test(password) ||
          isIncrementingSequence()
        );
      }
      if (updatedState.validCharacters.canValidate) {
        updatedState.validCharacters.isValid =
          PASSWORD_VALIDATION_REGEX.VALID_CHAR.test(password);
      }
    }

    setPasswordValidation({ ...updatedState });
  };

  const isIncrementingSequence = () => {
    // Check if the string contains only letters or numbers and has more than 3 characters
    if (/[a-zA-Z]+/.test(password) && password.length > 3) {
      // Check for incrementing sequence for letters
      let count = 1;
      for (let i = 1; i < password.length; i++) {
        if (password.charCodeAt(i) === password.charCodeAt(i - 1) + 1) {
          count++;
        } else {
          count = 1;
        }
        if (count > 3) {
          return true;
        }
      }
    } else if (/\d+/.test(password) && password.length > 3) {
      // Check for incrementing sequence for numbers
      let count = 1;
      for (let i = 1; i < password.length; i++) {
        if (parseInt(password[i]) === parseInt(password[i - 1]) + 1) {
          count++;
        } else {
          count = 1;
        }
        if (count > 3) {
          return true;
        }
      }
    }
    return false;
  };

  const validatePassword = () => {
    if (
      passwordValidation.minLength.canValidate &&
      !passwordValidation.minLength.isValid
    ) {
      return false;
    } else if (
      passwordValidation.maxLength.canValidate &&
      !passwordValidation.maxLength.isValid
    ) {
      return false;
    } else if (
      passwordValidation.minMaxLength.canValidate &&
      !passwordValidation.minMaxLength.isValid
    ) {
      return false;
    } else if (
      passwordValidation.containUpperCaseLetter.canValidate &&
      !passwordValidation.containUpperCaseLetter.isValid
    ) {
      return false;
    } else if (
      passwordValidation.containSpecialCharacter.canValidate &&
      !passwordValidation.containSpecialCharacter.isValid
    ) {
      return false;
    } else if (
      passwordValidation.containNumber.canValidate &&
      !passwordValidation.containNumber.isValid
    ) {
      return false;
    } else if (
      passwordValidation.preventSequence.canValidate &&
      !passwordValidation.preventSequence.isValid
    ) {
      return false;
    } else if (
      passwordValidation.validCharacters.canValidate &&
      !passwordValidation.validCharacters.isValid
    ) {
      return false;
    }

    return true;
  };

  return (
    <div className="position-relative">
      {children}
      {passwordPolicy?.enabled && isPasswordFocus && !validatePassword() && (
        <div className="parent-width bg-white position-absolute z-index-2 border-radius-s border-s">
          <div className="p-s">
            {validationsSeq.map((validationKey) => (
              <ValidationMessage
                key={validationKey}
                data={passwordValidation[validationKey]}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PasswordValidation;
