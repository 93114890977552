import React from "react";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";

class AdyenCardFormView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCardAdded: props.isCardAdded,
    };
  }

  componentDidMount() {
    this.initAdyenCheckout();
  }

  render() {
    return (
      <div
        ref={(ref) => {
          this.ref = ref;
        }}
        className="parent-width"
      />
    );
  }

  initAdyenCheckout() {
    const configuration = {
      paymentMethodsResponse: {
        paymentMethods: [
          {
            name: "Credit Card",
            type: "scheme",
          },
        ],
      },
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      locale: "en-US",
      translations: {
        "en-US": {
          "creditCard.numberField.placeholder": "Card number",
          "creditCard.expiryDateField.placeholder": "MM/YY",
          "creditCard.cvcField.placeholder.3digits": "XXX",
          payButton: "Save card",
        },
      },
      environment: "test",
      paymentMethodsConfiguration: {
        card: {
          enableStoreDetails: false,
          hideCVC: false,
          onChange: (state, dropin) => {
            this.props.onChange(state);
          },
          onSubmit: (state, dropin) => {},
        },
      },
    };
    const checkout = new AdyenCheckout(configuration);
    const dropin = checkout
      .create("dropin", {
        showPayButton: false,
        setStatusAutomatically: false,
      })
      .mount(this.ref);
  }
}

export default AdyenCardFormView;
