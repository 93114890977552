import React, { Component } from "react";

import {
  DKLabel,
  DKIcon
} from "deskera-ui-library";

import ic_warning_red from "../../assets/icons/ic_warning_red.png";

/*
- remainingDays
*/

class PlanExpiryBadge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remainingTrialDays: this.props.remainingTrialDays,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      remainingTrialDays: nextProps.remainingTrialDays,
    });
  }

  render() {
    return (
      <div className="mt-l   pl-s pr-s pt-s pb-s  border-radius-s border-red parent-width column bg-chip-orange">
        <div className="row">
          <DKIcon src={ic_warning_red} className="ic-s mr-r" />
          <DKLabel
            className="fw-m text-red"
            text={`${this.state.remainingTrialDays} - days free trial remaining`}
          />
        </div>
        {/* <div className="row">
          <DKIcon
            src={ic_warning_red}
            className="ic-s-2 mr-r"
            style={{ opacity: 0 }}
          />
          <DKLabel
            className=" text-red mt-s"
            text="(Your FREE trial ends on 21 Apr 2021)"
          />
        </div> */}
      </div>
    );
  }
}

export default PlanExpiryBadge;
