import React, { Component } from "react";

import {
  DKLabel,
  DKButton,
  DKCheckMark,
  DKInput
} from "deskera-ui-library";

import { DELETE_OPTIONS } from "../../constants/Enum";
class DeleteAccountPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: this.props.deleteAccState.otherDeleteReason,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      inputValue: nextProps.deleteAccState.otherDeleteReason,
    });
  }

  onInputChange = (value) => {
    this.setState(
      {
        inputValue: value,
      },
      () => {
        this.props.onInputValueChange(value);
      }
    );
  };

  render() {
    return (
      <div className=" transparent-background">
        <div className="popup-window">
          {this.getHeader()}
          <DKLabel
            text="What is the biggest reason for your wish to delete account?"
            className="mt-xl fw-m fs-m"
          />
          <div className="column parent-width mt-l">
            {DELETE_OPTIONS.map((obj, key) => {
              return (
                <DKCheckMark
                  title={obj.label}
                  key={key}
                  className="mb-m"
                  isSelected={
                    obj.label === this.props.deleteAccState.selectedDeleteReason
                  }
                  onClick={(e) => this.props.onSelect(e.target.id)}
                />
              );
            })}

            {this.props.deleteAccState.selectedDeleteReason ===
            DELETE_OPTIONS[4].label ? (
              <DKInput
                name="Reason"
                placeholder="Reason"
                value={this.state.inputValue}
                onChange={this.onInputChange}
                required
              />
            ) : null}

            {this.props.deleteAccState.showError ? (
              <DKLabel
                text="Please give an valid input"
                className="textField-invalid-text mt-s mb-l"
              />
            ) : null}

            <DKLabel text="Note:" className="fw-b mt-m" />
            <DKLabel
              text="This will delete your Account and associated companies with users. 
                You would be logged out of the system and your data would not be recoverable."
              className="fs-r mt-xs mb-m text-gray"
            />
          </div>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getHeader() {
    return (
      <div className="row justify-content-between">
        <DKLabel text="Sad to see you go" className="fw-m fs-l" />
        <div>
          <div className="row">
            <DKButton
              title="Cancel"
              className="bg-gray1 border-m fw-m"
              onClick={this.cancelTapped}
            />
            <DKButton
              title="Continue"
              className="bg-blue ml-r text-white fw-m"
              onClick={this.continueTapped}
            />
          </div>
        </div>
      </div>
    );
  }

  cancelTapped = () => {
    this.props.onCancel();
  };
  continueTapped = () => {
    this.props.continueTapped();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
}

export default DeleteAccountPopup;
