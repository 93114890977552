export default class UTMManager {
    static utm_campaign = null;
    static utm_source = null;
    static utm_medium = null;

    static getURLParameters(mp) {
        setTimeout(() => {
            if (typeof window !== 'undefined') {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                let params = {};
                let first_params = {};
                if (urlParams.get('utm_campaign')) {
                    UTMManager.utm_campaign = urlParams.get('utm_campaign');
                }
                if (urlParams.get('utm_source')) {
                    UTMManager.utm_source = urlParams.get('utm_source');
                }
                if (urlParams.get('utm_medium')) {
                    UTMManager.utm_medium = urlParams.get('utm_medium');
                }
                UTMManager.utm_campaign = UTMManager.utm_campaign === null ? urlParams.get('utm_campaign') : UTMManager.utm_campaign;
                UTMManager.utm_source = UTMManager.utm_source === null ? urlParams.get('utm_source') : UTMManager.utm_source;
                UTMManager.utm_medium = UTMManager.utm_medium === null ? urlParams.get('utm_medium') : UTMManager.utm_medium;

                params['utm_campaign [last touch]'] = UTMManager.utm_campaign;
                params['utm_source [last touch]'] = UTMManager.utm_source;
                params['utm_medium [last touch]'] = UTMManager.utm_medium;

                first_params['utm_campaign [first touch]'] = UTMManager.utm_campaign;
                first_params['utm_source [first touch]'] = UTMManager.utm_source;
                first_params['utm_medium [first touch]'] = UTMManager.utm_medium;
                if (mp) {
                    mp.people.set(params);
                    mp.people.set_once(first_params);
                    mp.register(params);
                }
            }
        }, 100);
    }

    static getParameterValueFor(param) {
        if (typeof window !== 'undefined') {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            return urlParams.get(param) ? urlParams.get(param) : '';
        }
    }
}
