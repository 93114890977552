import ChartParser from "../Helper/ChartParser";
import Utility from "../utility/Utility";

class ReportManager {
  static invoiceData = [];
  static ageReceivable = [];
  static bankAccountDetails = [];
  static pipelineData = [];
  static contactData = [];
  static dealsData = [];
  static expenseClaimed = [];
  static payRoll = [];
  static leavesPerMonth = [];
  static pipelineData = [];
  static pipelineFilterValue = [];
  static bankNames = [];
  static employeePaystub = [];
  static expenseClaimAmount = {
    maxClaimAmount:0,
    totalClaimAmount:0
  }
  
  static setInvoicesData = (data) => {
    if (!Utility.isEmpty(data)) {
      this.invoiceData = ChartParser.invoiceReportParser(data);
    }
  };

  static getInvoiceData = () => {
    return this.invoiceData;
  };

  static setAgeReceivable = (data) => {
    if (!Utility.isEmpty(data)) {
      this.ageReceivable = ChartParser.ageReceivableParser(data);
    }
  };

  static getAgeReceivable = () => {
    return this.ageReceivable;
  };

  static setBankAccountDetails = (data) => {
    if (!Utility.isEmpty(data)) {
      this.bankAccountDetails = ChartParser.bankAccountReport(data);
    }
  };

  static getBankAccountDetails = () => {
    return this.bankAccountDetails;
  };

  static setExpenseClaimed = (data,params) => {
    if (!Utility.isEmpty(data)) {
      this.expenseClaimed = ChartParser.expensesClaimed(data.data,params);
      this.expenseClaimAmount = {
        maxClaimAmount: data.maxClaimAmount,
        totalClaimAmount: data.totalClaimAmount
      }
    }
  };

  static getExpenseClaimed = () => {
    return this.expenseClaimed;
  };
  static getExpenseClaimAmount = () => {
    return this.expenseClaimAmount
  }

  static setPayRoll = (data) => {
    if (!Utility.isEmpty(data)) {
      this.payRoll = ChartParser.payrollParser(data);
    }
  };

  static getPayRoll = () => {
    return this.payRoll;
  };

  static setEmloyeePaystub = (data) => {
    if (!Utility.isEmpty(data)) {
      this.employeePaystub = ChartParser.employeePaystub(data);
    }
  };

  static getEmloyeePaystub = () => {
    return this.employeePaystub;
  };

  static setExpenseBreakDown = (data) => {
    if (!Utility.isEmpty(data)) {
      this.expenseBreakDown = ChartParser.expenseBreakDown(data);
    }
  }

  static getExpenseBreakDown = () => {
    return this.expenseBreakDown;
  };

  static setLeavesPerMonth = (data) => {
    if (!Utility.isEmpty(data)) {
      this.leavesPerMonth = ChartParser.leavePerMonthParser(data);
    }
  };

  static getLeavesPerMonth = () => {
    return this.leavesPerMonth;
  };

  static setPipelineViewData = (data) => {
    let pipelines = ChartParser.stagesByPipeline(data);
    this.pipelineData = pipelines;
    if (Utility.isEmpty(this.pipelineFilterValue)) this.setPipelineFilterValue(pipelines);
  }
  static getPipelineViewData = () => {
    return this.pipelineData;
  }
  static setContactData = (data) => {
    this.contactData = ChartParser.contactReports(data);
  }
  static getContactData = () => {
    return this.contactData;
  }
  static setDealsData = (data,period) => {
    this.dealsData = ChartParser.wonAndLossDealData(data,period);
  }
  static getDealsData = () => {
    return this.dealsData;
  };
  static setPipelineFilterValue(data) {
    this.pipelineFilterValue = data;
  }
  static getPipelineFilterData() {
    return this.pipelineFilterValue;
  }

  static setBankNames = (data) => {
    if (!Utility.isEmpty(data)) {
      this.bankNames = data.content.map((obj) => ({
        name: obj.name,
        value: obj.code
      }));
    }
  }

  static getBankNames = () => {
    return this.bankNames;
  }

  static setAllLeavesPerMonth = (data) => {
    if (!Utility.isEmpty(data)) {
      this.leavesPerMonth = ChartParser.allLeavePerMonthParser(data);
    }
  };

}

export default ReportManager;
