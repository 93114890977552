import React, { Component } from "react";

import { DKLabel, DKIcon, DKLine } from "deskera-ui-library";

import ic_coupon from "../../assets/icons/ic_coupon.png";
import CouponsManager from "../../managers/CouponsManager";
import DataParser from "../../Helper/DataParser";
import { PLANS } from "../../constants/Enum";
import { isMobileAppWebView } from "../../utility/ViewportSizeUtils";
import { t } from "i18next";

class RedeemPrepaidCoupons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    return (
      <div className=" column parent-width mt-l bg-white pt-r pb-l pl-r pr-l border-radius-s border-m ">
        <div className="row ">
          <DKIcon src={ic_coupon} className="mr-s ic-s-2" />
          <DKLabel text={"Active Prepaid Codes"} className=" fs-m fw-m" />
        </div>
        <DKLabel
          text={t("SEE_ALL_YOUR_REDEEMED")}
          className=" text-gray mt-xs mb-xl ml-xl"
        />
        {this.getAllCoupons()}
      </div>
    );
  }
  getAllCoupons() {
    let reedomCouponDetails = CouponsManager.getPrepaidCoupons();
    let arr = [];

    reedomCouponDetails.forEach((couponDetails, index) => {
      let formatDate = DataParser.getNextChargedDate(
        new Date(couponDetails.RedeemedDate),
        couponDetails.PrepaidDuration
      );
      let appSumoCode =
        couponDetails.PlanName === PLANS.PROFESSIONAL_I ||
        couponDetails.PlanName === PLANS.PROFESSIONAL_A ||
        couponDetails.PrepaidDuration >= 1200
          ? true
          : false;

      arr.push(
        <div className=" border-box pl-xl parent-width pr-xl">
          <DKLabel text={"Code: " + couponDetails.Code} className="fw-m" />
          <DKLabel
            text={couponDetails.Description}
            className="text-gray mt-xs "
          />
          <DKLabel
            text={
              appSumoCode === true
                ? t("ACTIVE_FOR_LIFETIME")
                : t("ACTIVE_UNTIL: ") + formatDate
            }
            className="text-gray mt-xs "
          />
          {index < reedomCouponDetails.length - 1 && (
            <DKLine className="mt-m mb-m parent-wdith" />
          )}
        </div>
      );
    });

    if (arr.length === 0) {
      arr.push(
        <DKLabel
          text={t("NO_CODE_REDEEMED_YET")}
          className="text-gray align-self-center mt-s mb-m"
        />
      );
    }
    return arr;
  }
}

export default RedeemPrepaidCoupons;
