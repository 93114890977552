import React from "react";

import {
  DKIcon,
  DKLabel,
  DKButton,
  removeLoader,
  showAlert,
  showToast,
  TOAST_TYPE,
  DKSpinner
} from "deskera-ui-library";

import ic_cards from "../../../assets/icons/ic_cards.png";
import UserManager from "../../../managers/UserManager";
import Subscription from "../../../services/subscription";
import BillingManager from "../../../managers/BillingManager";
import Utility from "../../../utility/Utility";
import ic_edit from "../../../assets/icons/ic_edit.png";
import { trackEventAC } from "../../../managers/ActiveCampaignWrapper";
import AdyenCardFormView from "./AdyenCardFormView";
import { TRACK_EVENT } from "../../../constants/Enum";
import { isMobileAppWebView } from "../../../utility/ViewportSizeUtils";

class AdyenCardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCardValid: false,
      cardDetails: BillingManager.getDefaultPaymentCard(),
      isCardAdded: false,
      didAddNewCardTapped: false,
      cardResponseReceived: false,
      paymentCardDetails: null,
    };
  }
  componentDidMount() {
    this.getCardDetails();
  }

  getCardDetails(isNewCardAdded = false) {
    if (
      isNewCardAdded ||
      Utility.isEmpty(BillingManager.getDefaultPaymentCard())
    ) {
      Subscription.getPaymentMethods()
        .then((res) => {
          this.setCardValues();
          if (isNewCardAdded) {
            this.setState({
              isCardAdded: true,
              didAddNewCardTapped: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ cardResponseReceived: true });
        });
    } else {
      this.setCardValues();
    }
  }

  setCardValues() {
    let cardInfo = BillingManager.getDefaultPaymentCard();
    this.setState({
      cardDetails: cardInfo,
      isCardAdded: cardInfo && cardInfo.Last4 ? true : false,
      cardResponseReceived: true,
    });
    if (cardInfo && cardInfo.Last4 && this.props.onCardAdded) {
      this.props.onCardAdded();
    }
  }

  render() {
    return (
      <div className={`pl-l pr-l pt-l pb-l mb-l border-radius-s shadow-s bg-white column parent-width ${isMobileAppWebView() ? ' border-radius-r ' : ''}`}>
        <div className="row">
          <DKIcon src={ic_cards} className="mr-s" />
          <DKLabel
            text={
              this.state.isCardAdded
                ? "Saved card details"
                : "Credit Card details / Save card"
            }
            className="fw-m fs-m"
          />
        </div>

        <DKLabel
          text={
            this.state.isCardAdded
              ? "Card will be charged on your subscription date"
              : "Add a Credit card to initiate the payment"
          }
          className="text-gray mt-xs ml-xxl"
        />

        {(!this.state.isCardAdded || this.state.didAddNewCardTapped) &&
          this.state.cardResponseReceived && (
            <AdyenCardFormView
              isCardAdded={this.state.isCardAdded}
              onChange={this.onInputChange}
            />
          )}

        {(!this.state.isCardAdded || this.state.didAddNewCardTapped) &&
          this.state.cardResponseReceived && (
            <div className="row ">
              <DKButton
                title="Save card"
                className={`${
                  this.state.isCardValid ? "bg-blue" : "bg-gray"
                } text-white fw-m text-wrap-none`}
                onClick={() => {
                  if (this.state.isCardValid) {
                    this.onDetailsSubmit();
                  }
                }}
              />

              {this.state.didAddNewCardTapped && (
                <DKButton
                  title="Cancel"
                  className="bg-gray1 border-m ml-m"
                  onClick={() => {
                    this.setState({
                      didAddNewCardTapped: false,
                    });
                  }}
                />
              )}

              {this.props.isPopup && (
                <DKButton
                  title="Cancel"
                  className="bg-gray1 border-m ml-m"
                  onClick={() => {
                    this.props.onCancel();
                  }}
                />
              )}
            </div>
          )}

        {this.state.isCardAdded && !this.state.didAddNewCardTapped && (
          <div className="column parent-width mt-l bg-gray1 border-m border-radius-s pt-r pb-r pl-r pr-r">
            <DKLabel
              text={
                (this.state.cardDetails && this.state.cardDetails.CardBrand
                  ? this.state.cardDetails.CardBrand.toUpperCase()
                  : "") +
                " ******* " +
                (this.state.cardDetails && this.state.cardDetails.Last4
                  ? this.state.cardDetails.Last4
                  : "")
              }
            />
          </div>
        )}

        {this.state.isCardAdded && !this.state.didAddNewCardTapped && (
          <DKButton
            title="Edit card"
            className="bg-gray1 border-m text-whites fw-m mt-l "
            icon={ic_edit}
            onClick={() => {
              this.setState({
                didAddNewCardTapped: true,
              });
            }}
          />
        )}
        {!this.state.cardResponseReceived && (
          <DKSpinner title="Fetching card details" className="mt-xl mb-l" />
        )}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onDetailsSubmit = () => {
    const dataObj = {
      type: this.state.paymentCardDetails.paymentMethod.type,
      holderName: this.state.paymentCardDetails.paymentMethod.holderName,
      encryptedCardNumber:
        this.state.paymentCardDetails.paymentMethod.encryptedCardNumber,
      encryptedExpiryMonth:
        this.state.paymentCardDetails.paymentMethod.encryptedExpiryMonth,
      encryptedExpiryYear:
        this.state.paymentCardDetails.paymentMethod.encryptedExpiryYear,
      encryptedSecurityCode:
        this.state.paymentCardDetails.paymentMethod.encryptedSecurityCode,
      brand: " ",
    };
    Subscription.sendPaymentDetails({
      PaymentDetails: dataObj,
    })
      .then(
        (response) => {
          removeLoader();
          if (response.result === "success") {
            showAlert(
              "Card added successfully!",
              "Your credit card has been added for subscription. Will be charged on your subscription date."
            );

            trackEventAC(
              {
                event: TRACK_EVENT.ADD_CARD,
                eventData: "Card added successfully",
                email: UserManager.getUserEmail(),
              },
              () => {}
            );

            this.getCardDetails(true);
          }
        },
        (error) => {
          removeLoader();
          showToast(error.errorMessage, TOAST_TYPE.FAILURE);
        }
      )
      .catch((err) => {
        removeLoader();
        showToast(err.errorMessage, TOAST_TYPE.FAILURE);
      });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onInputChange = (input) => {
    if (input.isValid) {
      this.setState({
        isCardValid: true,
        paymentCardDetails: input.data,
      });
    } else {
      this.setState({
        isCardValid: false,
        paymentCardDetails: input.data,
      });
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}

export default AdyenCardView;
