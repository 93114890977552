import React, { Component } from "react";

import {
  DKLabel,
  DKButton
} from "deskera-ui-library";

import ic_award from "../../assets/mobile/ic_award.png";
import ic_btn_ios from "../../assets/mobile/ic_app_store.svg";
import ic_btn_android from "../../assets/mobile/ic_google_play.svg";
import ic_close from "../../assets/icons/ic_close.png";
import ApiConstants from "../../constants/ApiConstants";

/*
- onClose
*/

class MobileAppPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className=" transparent-background">
        <div
          className="popup-window justify-content-center align-items-center position-relative"
          style={{ maxWidth: 450 }}
        >
          <DKButton
            icon={ic_close}
            onClick={() => {
              this.props.onClose();
            }}
            className="position-absolute"
            style={{ top: 5, right: 3 }}
          />
          <DKLabel
            text="Deskera has the best mobile app. Period."
            className="text-align-center fw-b fs-xxl mt-m"
          />
          <DKLabel
            text="Deskera Mobile is an always-on mobile application designed for the needs of today’s workforce."
            className="text-align-center text-gray mt-s"
          />
          <DKLabel
            text="This amazing feature is only available on our award winning mobile app."
            className="text-align-center mt-l fw-m"
          />
          <img
            src={ic_award}
            alt=""
            className=" mt-xxl"
            style={{ width: "50%" }}
          />
          <div className="row justify-content-center mt-xxl mb-l">
            <img
              src={ic_btn_ios}
              alt=""
              className="ml-m mr-m cursor-hand"
              onClick={() => {
                window.open(ApiConstants.URL_MOBILE_IOS, "_blank");
              }}
            />
            <img
              src={ic_btn_android}
              alt=""
              className="ml-m mr-m cursor-hand"
              onClick={() => {
                window.open(ApiConstants.URL_MOBILE_ANDROID, "_blank");
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MobileAppPopup;
