import { COMPLIANCE_COUNTRIES, COMPLIANCE_COUNTRIES_FOR_OLD_BOOKS, COMPLIANCE_COUNTRIES_FOR_PEOPLE_PLUS, PRODUCTS } from "../../constants/Enum";
import UserManager from "../../managers/UserManager";
import Utility from "../../utility/Utility";
export function isComplianceCountry(product = '') {
  if(Utility.isEmpty(UserManager.getUserCountry())) {
    return true
  } else {
    if (product === PRODUCTS.ERP || product === PRODUCTS.ERP_V2 || product === PRODUCTS.BOOKS_PLUS) {
      return !(COMPLIANCE_COUNTRIES_FOR_OLD_BOOKS.includes(UserManager.getUserCountry()))
    } else if (product === PRODUCTS.PEOPLE_PLUS) {
      return COMPLIANCE_COUNTRIES_FOR_PEOPLE_PLUS.includes(UserManager.getUserCountry())
    }
    return COMPLIANCE_COUNTRIES.includes(UserManager.getUserCountry())
  }
}
export function numberFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}