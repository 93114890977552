class ReleaseNoteManager {
  static newUpdates = {
    date: "Mon, 21 Jun 2021",
    items: [
      {
        id: "1",
        title:
          "Users can create UOM schema and tag multiple UOM at product level",
        url: "https://www.deskera.com/care/how-to-configure-unit-of-measurement-in-deskera-books/",
      },
      {
        id: "2",
        title: "Health check changes for website builder",
        url: "https://www.deskera.com/care/how-to-design-my-website-using-deskera-website-builder/",
      },
      {
        id: "3",
        title:
          "Contact Module - default sorting in contact list should be on the created at field in DESC order",
        url: "https://www.deskera.com/care/create-contact-deskera-sales/",
      },
    ],
  };

  static getUpdated = () => {
    return this.newUpdates;
  };
}

export default ReleaseNoteManager;
