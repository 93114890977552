import React, { Component } from "react";

import {
  DKIcon,
  DKLabel,
  Toggle,
  DKListPicker,
  DKCheckMark,
  showAlert,
} from "deskera-ui-library";

import Utility, { getCapitalized } from "../../utility/Utility";

import ic_arrow_down from "../../assets/icons/ic_arrow_down.png";
import ProductIcon from "./ProductIcon";
import PlanManager from "../../managers/PlanManager";
import { PRODUCTS, USER_TYPE } from "../../constants/Enum";
import { USER_ROLE } from "../../constants/Enum";
import UserManager from "../../managers/UserManager";
export default class UserProductRoleCard extends Component {
  /**
   *
   * @props
   * product
   * RoleArray
   * isProductAssigned
   * UserType
   * className
   */

  constructor(props) {
    super(props);
    this.state = {
      showRoles: false,
      showUserType: false,
      currentRole: this.props.role ? this.props.role : "Select a role",
      isProductAssigned: this.props.isProductAssigned,
      roleArray: this.props.RoleArray,
      permissionsArray: this.props.permissions,
      manageAllCheck: false,
      readAllCheck: false,
      totalLengthOfManagePerm: this.props.totalLengthOfManagePerm,
      totalLenghtOfReadPerm: this.props.totalLengthofReadPerm,
    };
  }

  componentDidMount() {
    this.checkIfAllPermIsSelected();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        isProductAssigned: nextProps.isProductAssigned,
        roleArray: nextProps.RoleArray,
        permissionsArray: nextProps.permissions,
        totalLengthOfManagePerm: nextProps.totalLengthOfManagePerm,
        totalLenghtOfReadPerm: nextProps.totalLengthofReadPerm,
      },
      () => this.checkIfAllPermIsSelected()
    );
  }

  checkIfAllPermIsSelected() {
    let findManageAllChecked = 0;
    let findReadAllChecked = 0;
    this.state.permissionsArray.map((item) => {
      Object.keys(item).forEach((key) => {
        item[key].forEach((permission) => {
          if (
            permission.accessLevel === "rwd" ||
            permission.accessLevel === "rw"
          ) {
            if (permission.isChecked) {
              findManageAllChecked += 1;
            } else {
              if (findManageAllChecked != 0) {
                findManageAllChecked -= 1;
              }
            }
          }
          if (permission.accessLevel === "r") {
            if (permission.isChecked) {
              findReadAllChecked += 1;
            } else {
              if (findReadAllChecked != 0) {
                findReadAllChecked -= 1;
              }
            }
          }
        });
      });

      if (this.state.totalLengthOfManagePerm === findManageAllChecked) {
        this.setState({
          manageAllCheck: true,
        });
      } else {
        this.setState({
          manageAllCheck: false,
        });
      }

      if (this.state.totalLenghtOfReadPerm === findReadAllChecked) {
        this.setState({
          readAllCheck: true,
        });
      } else {
        this.setState({
          readAllCheck: false,
        });
      }
    });
  }

  render() {
    return (
      <>
        <div
          className={`column mb-xl parent-width ${
            this.props.className ? this.props.className : ""
          }`}
        >
          <div className={`row justify-content-between`}>
            <div>
              <div className="row">
                <ProductIcon product={this.props.product.toLowerCase()} />
                <DKLabel
                  text={
                    "Deskera " +
                    PlanManager.getProductDisplayName(
                      this.props.product.toLowerCase()
                    )
                  }
                  className={`fw-m ml-r`}
                />
              </div>
            </div>
            <div>
              <div className="row-reverse ">
                <Toggle
                  isOn={this.state.isProductAssigned}
                  onChange={this.productToggle}
                  color="bg-blue"
                />

                {this.state.isProductAssigned === true && (
                  <div className="pt-xs pb-xs pl-s pr-s border-m border-radius-m cursor-hand mr-m">
                    <div className="row">
                      <div
                        className="row "
                        onClick={() => {
                          this.onRoleClick();
                        }}
                      >
                        <DKLabel
                          text={getCapitalized(this.state.currentRole)}
                        />
                        <DKIcon
                          src={ic_arrow_down}
                          className=" ic-xs mt-xs ml-s"
                        />
                      </div>
                      {this.state.showRoles && this.renderRolesListPicker()}
                    </div>
                  </div>
                )}

                {this.state.isProductAssigned === true && (
                  <div className="pt-xs pb-xs pl-s pr-s border-m border-radius-m cursor-hand mr-m">
                    <div className="row">
                      <div
                        className="row "
                        onClick={() => {
                          this.userTypeClick();
                        }}
                      >
                        <DKLabel
                          text={getCapitalized(this.props.UserType) + " user"}
                        />
                        <DKIcon
                          src={ic_arrow_down}
                          className=" ic-xs mt-xs ml-s"
                        />
                      </div>
                      {this.state.showUserType === true &&
                        this.renderUserTypeListPicker()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            {this.renderPermissions()}
          </div>
        </div>
      </>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  onRoleClick = () => {
    const preventAction = this.shouldPreventAction();
    if (preventAction) {
      showAlert("Action prevented", "You cannot perform this action");
      return;
    }

    this.setState({
      showRoles: true,
      showUserType: false,
    });
  };

  userTypeClick = () => {
    const preventAction = this.shouldPreventAction();
    if (preventAction) {
      showAlert("Action prevented", "You cannot perform this action");
      return;
    }

    this.setState({ showUserType: true, showRoles: false });
  };

  renderUserTypeListPicker = () => {
    let userTypes = this.props.userTypesArray;
    return (
      <div className="position-absolute">
        <DKListPicker
          data={userTypes}
          onClose={() => this.setState({ showUserType: false })}
          onSelect={(index, value) => this.userTypeSelected(value)}
          style={{ top: -userTypes.length * 17, right: "-9px" }}
          className="shadow-m"
        />
      </div>
    );
  };
  renderRolesListPicker = () => {
    return (
      <div className="position-absolute">
        <DKListPicker
          data={this.state.roleArray
            .filter((item) => {
              return item.shortCode !== "paid_auditor";
            })
            .map((data) => data.name)}
          onClose={() => this.setState({ showRoles: false })}
          onSelect={(index, value) => {
            this.onRoleSelect(this.state.roleArray[index]);
          }}
          style={{ top: -this.state.roleArray.length * 17, right: "-6px" }}
          className="shadow-m"
        />
      </div>
    );
  };
  renderPermissions = () => {
    const isConsoleAdmin =
      this.state.currentRole.toLowerCase() === USER_ROLE.ADMIN.toLowerCase() &&
      this.props.product.toLowerCase() === PRODUCTS.CONSOLE.toLowerCase() &&
      !Utility.isEmpty(this.props.selectedUser) &&
      this.props.selectedUser.email === UserManager.getUserEmail() &&
      !UserManager.isUserOwner();

    const showPermissions =
      this.state.currentRole !== "Select a role" &&
      this.props.permissions.length !== 0 &&
      this.state.isProductAssigned;

    let selectedUserRoles = [];
    !Utility.isEmpty(this.props.selectedUser) &&
      this.props.selectedUser.roleShortInfo.map((item) => {
        selectedUserRoles.push(getCapitalized(item.shortCode));
      });
    const isSelectedUserOwnerAndBooksProduct =
      UserManager.setAllRoles(selectedUserRoles) === USER_ROLE.OWNER &&
      (this.props.product.toLowerCase() === PRODUCTS.ERP ||
        this.props.product.toLowerCase() === PRODUCTS.CONSOLE);

    if (
      this.props.product.toLowerCase() === PRODUCTS.ERP ||
      this.props.product.toLowerCase() === PRODUCTS.BOOKS_PLUS ||
      this.props.product.toLowerCase() === PRODUCTS.CRM_PLUS
    ) {
      return <div></div>;
    }

    return (
      <>
        <div className="column parent-width">
          {showPermissions &&
            !isConsoleAdmin &&
            !isSelectedUserOwnerAndBooksProduct && (
              <div className="row parent-width mt-l pl-r pb-r pt-r pr-r justify-content-between bg-gray1 border-m">
                <div className="column fw-b" style={{ width: "33.33%" }}>
                  Modules
                </div>
                <div className="column fw-b" style={{ width: "33.33%" }}>
                  <DKCheckMark
                    title={"Manage All"}
                    key={"Manage All"}
                    isSelected={this.state.manageAllCheck}
                    onClick={(e, data, selectAll) => {
                      this.setState({
                        manageAllCheck: !this.state.manageAllCheck,
                      });
                      this.props.onMasterCheck(
                        ["rwd", "rw"],
                        this.state.manageAllCheck
                      );
                    }}
                  />
                </div>
                <div className="column fw-b" style={{ width: "33.33%" }}>
                  <DKCheckMark
                    title={"Read All"}
                    key={"Read All"}
                    isSelected={this.state.readAllCheck}
                    onClick={(e, selectAll) => {
                      this.setState({
                        readAllCheck: !this.state.readAllCheck,
                      });
                      this.props.onMasterCheck(["r"], this.state.readAllCheck);
                    }}
                  />{" "}
                </div>
              </div>
            )}
          {showPermissions &&
            !isConsoleAdmin &&
            !isSelectedUserOwnerAndBooksProduct &&
            this.props.permissions.map((permission) => {
              return (
                <div className="row parent-width pl-r pb-r pt-r pr-r justify-content-between bg-white border-m">
                  {Object.keys(permission).map((key) => {
                    let managePer = permission[key].find(
                      (per) =>
                        per.accessLevel === "rwd" || per.accessLevel === "rw"
                    );
                    let readPer = permission[key].find(
                      (per) => per.accessLevel === "r"
                    );
                    return (
                      <>
                        <div className="column" style={{ width: "33.33%" }}>
                          {permission[key].length > 0 && <div>{key}</div>}
                        </div>
                        <div className="column" style={{ width: "33.33%" }}>
                          {!Utility.isEmpty(managePer) && (
                            <DKCheckMark
                              title={"Manage"}
                              key={key}
                              isSelected={managePer.isChecked}
                              onClick={() => this.onPermissionSelect(managePer)}
                            />
                          )}
                        </div>
                        <div className="column" style={{ width: "33.33%" }}>
                          {!Utility.isEmpty(readPer) && (
                            <DKCheckMark
                              title={"Read"}
                              key={key}
                              isSelected={readPer.isChecked}
                              onClick={() => this.onPermissionSelect(readPer)}
                            />
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </>
    );
  };

  isMasterSelected(level) {
    let isChildSelected = [],
      isSameLevel = [];
    this.props.permissions.forEach((permission) => {
      Object.keys(permission).forEach((key) => {
        permission[key].forEach((per) => {
          if (level.includes(per.accessLevel)) {
            isSameLevel.push(per.accessLevel);
          }

          if (level.includes(per.accessLevel) && per.isChecked) {
            isChildSelected.push(per.isChecked);
          }
        });
      });
    });
    return (
      isChildSelected.length === isSameLevel.length &&
      isChildSelected.length !== 0
    );
  }
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  userTypeSelected = (userType) => {
    this.setState({ showUserType: false, currentRole: "Select a role" });
    this.props.userToggle(userType);
  };
  productToggle = (toggle) => {
    const preventAction = this.shouldPreventAction();
    if (preventAction) {
      showAlert("Action prevented", "You cannot perform this action");
      return;
    }

    this.setState({ showRoles: false });
    this.props.productToggle(this.props.product, !this.state.isProductAssigned);
  };
  onRoleSelect(data) {
    this.setState({ showRoles: false, currentRole: data.name });
    this.props.onRoleSelect(data);
  }
  onPermissionSelect = (permission) => {
    this.props.onCheck(permission);
  };

  shouldPreventAction() {
    let isAdminLoggedIn = false;
    if (!UserManager.isUserOwner()) {
      isAdminLoggedIn = UserManager.isUserAdmin();
    }
    let selectedUserRoles = [];
    !Utility.isEmpty(this.props.selectedUser) &&
      this.props.selectedUser.roleShortInfo.map((item) => {
        selectedUserRoles.push(getCapitalized(item.shortCode));
      });
    const isSelectedUserOwnerAndBooksProduct =
      (UserManager.setAllRoles(selectedUserRoles) === USER_ROLE.OWNER ||
        (UserManager.setAllRoles(selectedUserRoles) === USER_ROLE.ADMIN &&
          isAdminLoggedIn)) &&
      (this.props.product.toLowerCase() === PRODUCTS.ERP ||
        this.props.product.toLowerCase() === PRODUCTS.BOOKS_PLUS ||
        this.props.product.toLowerCase() === PRODUCTS.CONSOLE);

    return isSelectedUserOwnerAndBooksProduct;
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
}
