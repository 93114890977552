import React, { Component } from "react";
import RouteManager from "../../managers/RouteManager";

import {
  DKLabel,
  DKButton,
  DKIcon,
  DKLine,
  DKDataGrid,
  INPUT_TYPE,
  DKIcons,
  showAlert,
  DKTooltipWrapper,
  showLoader,
  removeLoader,
  showToast,
  TOAST_TYPE
} from "deskera-ui-library";
import UserManager from "../../managers/UserManager";
import User from "../../services/user";
import AddUserPopup from "../common/AddUserPopup";
import Utility, { toCurrencyFormat } from "../../utility/Utility";
import Subscription from "../../services/subscription";
import PlanManager from "../../managers/PlanManager";
import ProductIcon from "../common/ProductIcon";
import PlanIcon from "../common/PlanIcon";
import { PRODUCTS, USER_ROLE } from "../../constants/Enum";
import MixPanelManager from "../../managers/common/MIxpanelManger";
import PermissionManager from "../../managers/PermissionManager";
import SubscriptionManager from "../../managers/SubscriptionManager";
import SideBarService from "../../services/sidebar";

import ic_no_data from "../../assets/icons/ic_no_data_3.png";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import debounce from "../../utility/Debounce";
import SetPasswordPopup from "../common/SetPasswordPopup";

class UserDashboard extends Component {
  COLUMNS = [
    {
      key: "name",
      name: "User name",
      type: INPUT_TYPE.TEXT,
      width: 200,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      renderer: (data) => {
        return (
          <DKTooltipWrapper
            content={data.value.length <= 35 ? "" : data?.value}
            tooltipClassName="bg-gray1 width-100"
          >
            <div
              className="pt-s"
              style={{
                display: data.value.length <= 35 ? "flex" : "",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 240,
              }}
            >
              {data.value}
            </div>
          </DKTooltipWrapper>
        );
      },
    },
    {
      key: "email",
      name: "Email",
      type: INPUT_TYPE.TEXT,
      width: 300,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      renderer: (data) => {
        return (
          <DKTooltipWrapper
            content={data.value.length <= 40 ? "" : data?.value}
            tooltipClassName="bg-gray1 width-100"
          >
            <div
              className="pt-s"
              style={{
                display: data.value.length <= 40 ? "flex" : "",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 290,
              }}
            >
              {data.value}
            </div>
          </DKTooltipWrapper>
        );
      },
    },
    {
      key: "products",
      name: "Products Access",
      type: INPUT_TYPE.TEXT,
      width: 250,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      allowColumnSort: false,
      renderer: (data) => {
        return (
          <div className="row" style={{ width: this.width_2 }}>
            {this.getAllAppsIcons(data.value, data.rowData)}
          </div>
        );
      },
    },
    {
      key: "role",
      name: "Role",
      type: INPUT_TYPE.TEXT,
      width: 250,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      allowColumnSort: false,
      renderer: (data) => {
        const ownerColor = "bg-chip-orange text-orange border-orange";
        const otherColor = "bg-white text-gray border-m";
        const isOwner =
          data?.rowData?.allAppsRole &&
          Array.isArray(data?.rowData?.allAppsRole)
            ? data.rowData.allAppsRole.includes(USER_ROLE.OWNER)
            : false;
        return (
          <>
            {Array.isArray(data.value) &&
              data.value.map((r) => {
                return (
                  <div
                    className={` ${isOwner ? ownerColor : otherColor} p-v-xs p-h-r border-radius-l fw-m text-wrap-none m-s`}
                  >
                    <div className="text-align-left">{r}</div>
                  </div>
                );
              })}
          </>
        );
      },
    },
    {
      key: "status",
      name: "MFA Status",
      type: INPUT_TYPE.TEXT,
      width: 120,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      allowColumnSort: false,
      renderer: (data) => {
        let color;

        if (data.value === "Enabled") {
          color = "bg-chip-green text-green border-green";
        } else {
          color = "bg-chip-red text-red border-red";
        }

        return (
          <div
            className={`${color} p-v-xs p-h-r border-radius-l fw-m text-wrap-none m-s`}
          >
            <DKLabel text={data.value} className="fw-m" />
          </div>
        );
      },
    },
    {
      id: "action",
      key: "action",
      name: "Actions",
      type: INPUT_TYPE.BUTTON,
      width: 90,
      classname: "justify-content-center",
      options: [],
    },
  ]
  constructor(props) {
    super(props);

    this.width_1 = 370;
    this.width_2 = 350;
    this.width_3 = 120;
    this.width_4 = 120;
    this.width_5 = 100;

    this.state = {
      list: UserManager.getUserLists(),
      needAddUserPopup: false,
      showAddUserAmountPopUp: false,
      addUserAmountData: null,
      page: 0,
      totalPage: 0,
      tableData: null,
      userRole: "",
      userToEdit: null,
      tableWidth: SideBarService.getContainerWidth() - 60,
      searchText: "",
      sortData: null,
      columnConfig: [],
      sort: "firstName",
      sortDir: "asc",
      showSetPasswordPopup: false,
      userToSetPassword: null
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.setSize);
    RouteManager.setPresenter(this);
    if (!PermissionManager.isUserManagementAccessible()) {
      RouteManager.navigateToHome();
      return;
    }

    setTimeout(() => {
      if (Utility.isEmpty(UserManager.getUserRoleGroup())) {
        showLoader("Updating user list...");
        User.getRoleGroups().then(() => {}).finally(() => {
          removeLoader();
          this.getAllUsers();
        });
      } else {
        this.getAllUsers();
      }

      this.getMetaData();
    }, 10);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setSize);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  setSize = () => {
    this.setState({
      tableWidth: SideBarService.getContainerWidth() - 60,
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getAllUsers(params = {}) {
    User.getUsersList(this.state.page.toString(), {
      ...params,
      sortDir: this.state.sortDir.toLowerCase(),
      sort: this.state.sort,
    }).then((res) => {
      this.setState({
        list: UserManager.getUserLists(),
        totalPage: res.totalPages,
      });
      this.getTableArray();
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getTableArray() {
    let dataTableArray = [];

    if (this.state.list.length > 0) {
      for (var i = 0; i < this.state.list.length; i++) {
        let userRowData = UserManager.getAllOrgUsers(this.state.list[i]);

        this.setState({ allAppsRole: userRowData.allAppsRole });

        let userApps = userRowData.productAccessArray;

        let items = {
          id: this.state.list[i].id,
          userName: this.state.list[i].userName,
          name: this.state.list[i].firstName + " " + this.state.list[i].lastName,
          firstName: this.state.list[i].firstName,
          lastName: this.state.list[i].lastName,
          products: userApps,
          role: userRowData.role,
          status: this.state.list[i].mfaenabled ? "Enabled" : "Disabled",
          email: this.state.list[i].email,
          roleShortInfo: this.state.list[i].roleShortInfo,
          allAppsRole: userRowData.allAppsRole,
        };
        dataTableArray.push(items);
      }
    }
    this.setState({ tableData: dataTableArray });
  }

  getAllAppsIcons(apps, userData) {
    let activeProduct = SubscriptionManager.getProductsToShow();
    let icons = [];
    let newArray = [...apps];
    let index;
    let displayPlusProduct = SubscriptionManager.displayPlusProduct();
    if (displayPlusProduct) {
      if (UserManager.checkIfTenantIsUSOrIN()) {
        if (newArray.find((item) => [PRODUCTS.ERP, PRODUCTS.MRP].includes(item)) !== undefined) {
          index = newArray.findIndex((item) => [PRODUCTS.ERP, PRODUCTS.MRP].includes(item));
          newArray[index] = PRODUCTS.BOOKS_PLUS;
        }
      }
      if (newArray.find((item) => item === PRODUCTS.PEOPLE) !== undefined) {
        index = newArray.findIndex((item) => item === PRODUCTS.PEOPLE);
        newArray[index] = PRODUCTS.PEOPLE_PLUS;
      }
    }
    let activeProductFilter = activeProduct.filter((value) => value !== "crm");
    newArray.forEach((app, index) => {
      if (activeProductFilter.includes(app))
        icons.push(this.getAppIcon(app, index, userData));
    });
    return icons;
  }

  getAppIcon(product, index, userData) {
    return (
      <div onClick={() => this.editUserTapped(userData)}>
        <ProductIcon
          product={product}
          userRole={userData.allAppsRole[index]}
          className="ic-r-2 mr-r cursor-hand"
          showNameOnMouseOver={true}
        />
      </div>
    );
  }

  deleteTapped = (user) => {
    if (
      PermissionManager.canPerformUserMgmtActions() ||
      (user.role === USER_ROLE.OWNER && !UserManager.isUserOwner())
    ) {
      showAlert(t("ACTION_PREVENTED"), t("YOU_CANNOT_PERFORM_THIS_ACTION"));
      return;
    }
    let buttons = [
      { title: t("CANCEL"), className: "bg-gray2", onClick: () => {} },
      {
        title: t("DELETE"),
        className: "bg-red text-white ml-r",
        onClick: () => {
          User.deleteUserFromList({
            userId: user.rowData.id,
          }).then(
            (res) => {
              this.updateUserList();
            },
            (err) => {}
          );
        },
      },
    ];
    showAlert(
      t("DELETE_USER"),
      t("DELETING_THIS_USER_WILL_DELETE_IT_PERMANENTELY"),
      buttons
    );
  };

  checkIfUserIsOwner = (user) => {
    if (
      PermissionManager.canPerformUserMgmtActions() ||
      (user.role === USER_ROLE.OWNER && !UserManager.isUserOwner())
    ) {
      showAlert(t("ACTION_PREVENTED"), t("YOU_CANNOT_PERFORM_THIS_ACTION"));
      return;
    }

    this.editUserTapped(user);
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  onListUpdate = () => {
    this.getTableArray();
  };

  updateUserList = (params = {}) => {
    User.getUsersList(this.state.page.toString(), {
      ...params,
      sortDir: this.state.sortDir.toLowerCase(),
      sort: this.state.sort,
    }).then((res) => {
      this.setState(
        {
          totalPage: res.totalPages,
          list: UserManager.getUserLists(),
        },
        () => this.onListUpdate()
      );
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    const { t } = this.props;
    return (
      <div className="parent-width">
        <div className="parent-width row fw-m fs-l">{t("USER_MANAGEMENT")}</div>
        <div className="parent-width row-responsive mt-l align-items-start">
          <div className="column" style={{ flex: 1 }}>
            <div
              className="column parent-width"
              // style={{ maxWidth: MAX_SECTION_WIDTH }}
            >
              {!PermissionManager.canPerformUserMgmtActions() && this.getAddUserView()}
              {this.getUserDetailsView()}
              {this.state.needAddUserPopup && this.getAddUserPopup()}
              {this.state.showAddUserAmountPopUp && this.getAddUserAmountPopUp()}
              {this.state.showSetPasswordPopup && this.getSetPasswordPopup()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getAddUserPopup() {
    return (
      <AddUserPopup
        onCancel={this.hideAddUserPopup}
        onSave={this.checkIfPaidUserAdded}
        user={this.state.userToEdit}
      />
    );
  }

  showAddUserPopup = () => {
    this.setState({ needAddUserPopup: true });
  };
  hideAddUserPopup = () => {
    this.setState({ needAddUserPopup: false, userToEdit: null });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  sortUserData = (data) => {
    if (data?.columnData?.key === "name" || data?.columnData?.key === "email") {
      this.setState(
        {
          sort:
            data?.columnData?.key === "name"
              ? "firstName"
              : data?.columnData?.key,
          sortDir: data?.order,
        },
        this.getAllUsers
      );
    }
  };
  getRowContextMenu = () => {
    let contextMenu = [
      {
        title: t("EDIT"),
        icon: DKIcons.ic_edit,
        className: " p-0",
        onClick: (data) => {
          let filterData = this.state.list.filter(
            (items) => items.id === data.rowData.id
          );
          this.checkIfUserIsOwner(filterData[0]);
        },
      },
      {
        title: t("SET_PASSWORD"),
        icon: DKIcons.ic_key,
        className: " p-0",
        onClick: (data) => {
          this.setState({
            showSetPasswordPopup: true,
            userToSetPassword: data.rowData
          });
        },
      },
      {
        title: t("RESET_PASSWORD"),
        icon: DKIcons.ic_key_2,
        className: " p-0",
        onClick: (data) => {
          this.resetPassword(data.rowData);
        },
      },
      {
        title: t("DELETE"),
        icon: DKIcons.ic_delete,
        className: " p-0",
        onClick: (data) => {
          this.deleteTapped(data);
        },
      },
    ];

    return contextMenu;
  };
  getNoDataView = () => {
    return (
      <div
        className="column align-self-center align-items-center "
        style={{ pointerEvents: "none" }}
      >
        <DKIcon src={ic_no_data} className="ic-l" style={{ opacity: 0.2 }} />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text="Once data is available, it will appear here"
          className="text-gray mt-s "
        />
      </div>
    );
  };
  getUserDetailsView() {
    return (
      <div className=" mb-xl mt-l pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width column align-items-start">
        <div>
          <DKLabel
            text={`All ${t("ORGANISATION")} users`}
            className="fw-m fs-m"
          />
          <DKLabel
            text={`List of all users added to your ${t("ORGANISATION")}`}
            className="text-gray mt-xs"
          />
        </div>

        <div className="parent-width column  mb-xl">
          {
            <DKDataGrid
              needTrailingColumn={true}
              allowBulkOperation={false}
              allowSearch
              onSearch={(data) => this.onSearch(data)}
              onSort={(data) => this.sortUserData(data)}
              allowColumnSort={true}
              allowColumnEdit={false}
              columns={this.COLUMNS}
              rows={
                Utility.isEmpty(this.state.tableData)
                  ? []
                  : this.state.tableData?.map((item) => {
                      return {
                        ...item,
                        rowContextMenu: this.getRowContextMenu(
                          item.selectedAccount
                        ),
                      };
                    })
              }
              currentPage={this.state.page + 1}
              totalPageCount={this.state.totalPage}
              onPrevPageTapped={() => {
                if (this.state.page !== 0) {
                  this.setState({ page: this.state.page - 1 }, () => {
                    this.getAllUsers();
                  });
                }
              }}
              onNextPageTapped={() => {
                if (this.state.page + 1 < this.state.totalPage) {
                  this.setState({ page: this.state.page + 1 }, () => {
                    this.getAllUsers();
                  });
                }
              }}
              width={this.state.tableWidth}
            />
          }
          {this.state.tableData &&
            this.state.tableData.length == 0 &&
            this.getNoDataView()}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getAddUserView() {
    return (
      <div className="pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width column">
        <div className="row justify-content-between">
          <div className="column">
            <DKLabel text="Add user" className="fw-m fs-m" />
            <DKLabel
              text={`You can add users to your ${t(
                "ORGANISATION"
              )} and assign them different products. All the users will be listed here. Click add user to get started`}
              className="text-gray mt-xs"
            />
          </div>
          <DKButton
            title={t("ADD_USER")}
            className="bg-blue text-white fw-m text-wrap-none ml-l mt-s"
            onClick={this.showAddUserPopup}
          />
        </div>
      </div>
    );
  }
  checkIfPaidUserAdded = (user) => {
    let roleObject;
    // here taking assigned role from the user payload
    if (user.updateUserInfo && user.updateUserInfo.id) {
      roleObject = user.updateUserInfo.user.appUserCreateRequestList.filter(
        (data) => Utility.isEmpty(data.isDelete)
      );
    } else {
      roleObject = user.appUserCreateRequestList;
    }
    const params = {};
    const allRoles = [...UserManager.getParseUserRoleGroup()];
    if (user.updateUserInfo) {
      //this is the edit case, need to check if user has assigned diff role than previously
      let diffRoles = roleObject.filter((role) => {
        const object = this.state.userToEdit.roleShortInfo.find(
          (info) =>
            info.appName === role.appName && info.shortCode === role.roleGroup
        );
        return Utility.isEmpty(object);
      });

      //now here we are taking diff of old roles and new roles and checking if there is paid role
      diffRoles.forEach((data) => {
        const selectedRole = allRoles.find(
          (role) =>
            role.appName === data.appName && role.shortCode === data.roleGroup
        );
        if (
          [PRODUCTS.ERP, PRODUCTS.CRM, PRODUCTS.PEOPLE].includes(
            data.appName.toLowerCase()
          ) &&
          !selectedRole.guest
        ) {
          const key = data.appName.toLowerCase() + "Count";
          params[key] = 1;
        }
      });
    } else {
      // here checking if assigned product is from ERP,CRM,PEOPLE for only these product we need to update the amount
      const appToCheck = roleObject.filter((role) =>
        [
          PRODUCTS.ERP,
          PRODUCTS.CRM,
          PRODUCTS.PEOPLE,
          PRODUCTS.CRM_PLUS,
        ].includes(role.appName.toLowerCase())
      );
      appToCheck.forEach((data) => {
        const selectedRole = allRoles.find(
          (role) =>
            role.appName === data.appName && role.shortCode === data.roleGroup
        );
        if (!selectedRole.guest) {
          const key = data.appName.toLowerCase() + "Count";
          params[key] = 1;
        }
      });
    }
    // if now paid role found then let add/update else call update user amount API
    if (Utility.isEmpty(params)) {
      this.callAddUser(user);
    } else {
      Subscription.getAddUserAmount(params).then(
        (res) => {
          this.setState({
            showAddUserAmountPopUp: true,
            addUserAmountData: { ...res, user },
          });
        },
        (err) => {}
      );
    }
  };

  callAddUser = (user) => {
    if (!this.state.userToEdit) {
      User.addUserToList(user).then(
        (res) => {
          if (this.state.addUserAmountData) {
            this.setState({ addUserAmountData: null });
          }
          this.hideAddUserPopup();
          this.updateUserList();
        },
        (err) => {}
      );
    } else {
      User.updateUser(user).then(
        (res) => {
          if (UserManager.getUserID() === user.updateUserInfo.id) {
            let userData = UserManager?.getUserDetails();
            userData.name = `${user.updateUserInfo.user.firstName} ${user.updateUserInfo.user.lastName}`;
            UserManager.setUserDetails(userData);
          }
          this.hideAddUserPopup();
          this.updateUserList();
        },
        (err) => {}
      );
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getAddUserAmountPopUp() {
    let { addUserAmountData } = this.state;

    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ maxWidth: 600 }}>
          {this.getPlanDetailsBody(addUserAmountData)}
          <div className="row mt-xxl">
            <DKButton
              title={t("CANCEL")}
              className="bg-gray1 border-m fw-m mr-r"
              onClick={() => this.closeAmountPopup()}
            />
            <DKButton
              title={t("CONTINUE")}
              className="bg-blue fw-m text-white"
              onClick={() => this.closeAmountPopup(true)}
            />
          </div>
        </div>
      </div>
    );
  }
  closeAmountPopup = (callToAddUser = false) => {
    this.setState({ showAddUserAmountPopUp: false });
    if (callToAddUser) this.callAddUser(this.state.addUserAmountData.user);
  };
  getPlanDetailsBody(data) {
    let totalAmount = 0;
    return (
      <div className="column parent-width">
        <DKLabel
          text={t("ESTIMATED_CHARGES")}
          className="fw-m fs-m text-gray mb-l"
        />
        <DKLine style={{ height: "5px" }} />

        {Object.keys(data).map((item) => {
          if (item !== "user") {
            totalAmount = totalAmount + data[item].Amount;
            const productType = data[item].ProductType;
            const needErpIconName = [PRODUCTS.AIO, PRODUCTS.ERP].includes(productType.toLowerCase());
            return (
              <div className="column parent-width pt-s pb-s mt-r mb-r">
                <div className="row justify-content-between align-items-center">
                  <div className="column">
                    <div className="row" style={{ alignItems: "flex-start" }}>
                      <ProductIcon
                        product={
                          needErpIconName ? PRODUCTS.ERP_V2 : productType
                        }
                        className="mr-r"
                      />
                      <div className="column parent-width">
                        <DKLabel
                          className="fw-m fs-m"
                          text={`Deskera ${PlanManager.getProductDisplayName(
                            needErpIconName
                              ? PRODUCTS.ERP_V2
                              : productType.toLowerCase()
                          )}`}
                        />
                        <div className="row mt-s">
                          <PlanIcon
                            plan={data[item].Plan.toLowerCase()}
                            className="mr-s"
                          />
                          <DKLabel
                            className="text-gray"
                            text={`${
                              data[item]?.PlanDisplayName ?? data[item]?.Plan
                            } Plan for ${
                              data[item].UsersCharged
                            } user up to ${new Date(
                              data[item].NextChargeDate
                            ).toDateString()}`}
                          />
                        </div>
                        <div className="row ml-xxl">
                          <DKLabel
                            className="text-gray"
                            text={`${data[item].Description}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="row justify-content-end">
                      <DKLabel
                        className="fs-xl"
                        text={toCurrencyFormat(
                          data[item].Amount.toFixed(2),
                          data[item].Currency.toUpperCase()
                        )}
                      />
                    </div>
                    {data[item].TaxInfo && data[item].TaxInfo.length > 0 && (
                      <DKLabel
                        className="text-gray fs-s"
                        text={`${
                          data[item].TaxInfo[0].TaxLabel
                        }: ${toCurrencyFormat(
                          data[item].TaxAmount.toFixed(2),
                          data[item].Currency.toUpperCase()
                        )} `}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          }
        })}

        <DKLine style={{ height: "5px" }} />
        {/* <div className="row mt-l justify-content-end">
          <DKLabel text={"Total"} className="fs-l fw-b mr-l" />
          <DKLabel
            text={`$ ${totalAmount.toFixed(2)}`}
            className="fs-xxl fw-b"
          />
        </div> */}
        <div className="row mt-l">
          <DKLabel className="text-gray" text={t("AMOUNT_CHARGED_TEXT")} />
        </div>
      </div>
    );
  }

  getPlanAmount(totalAmt, gstAmt, currency) {
    let planAmt = totalAmt - gstAmt;
    return toCurrencyFormat(planAmt.toFixed(2), currency);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  editUserTapped = (user) => {
    User.getUsersPermissions([user.id]).then(
      (res) => {
        user.permissions = res.length > 0 ? res[0].apps : user.roleShortInfo;
        this.setState({ userToEdit: user });
        this.showAddUserPopup();
      },
      (err) => {}
    );
  };

  getSetPasswordPopup = () => {
    return (
      <SetPasswordPopup
        userData={this.state.userToSetPassword}
        onClose={() => {
          this.setState({
            showSetPasswordPopup: false,
            userToSetPassword: null
          });
        }}
      />
    );
  }

  resetPassword = (userData) => {
    showLoader("Sending password reset link...");
    const params = {
      userName: userData.email
    };

    User.resetPassword(params).then(
      () => {
        removeLoader();
        showToast("Password reset link has been sent to the user's email address.", TOAST_TYPE.SUCCESS);
      }, () => {
        removeLoader();
      }
    );
  }

  pushUserChangeToMixPanel = (userRoles, user) => {
    let appAccessChanged = { products: [] };
    let appRoleChanged = { products: [] };
    userRoles.forEach((role) => {
      let isNewAppAccess = user.roleShortInfo.find(
        (item) => item.appName === role.appName
      );
      const roleInfo = UserManager.getAllRolesByProduct(role.appName).find(
        (item) => item.shortCode === role.roleGroup
      );
      if (Utility.isEmpty(isNewAppAccess)) {
        appAccessChanged.products.push({
          [role.appName]: {
            userId: user.id,
            assignedRole: role.roleGroup,
            isGuest: roleInfo ? roleInfo.guest : false,
          },
        });
      } else {
        if (isNewAppAccess.shortCode !== role.roleGroup) {
          appRoleChanged.products.push({
            [role.appName]: {
              userId: user.id,
              oldRole: isNewAppAccess.shortCode,
              newRole: role.roleGroup,
              isGuest: roleInfo ? roleInfo.guest : false,
            },
          });
        }
      }
    });
    if (appAccessChanged.products.length > 0) {
      MixPanelManager.userAppAccessChanged(appAccessChanged);
    }
    if (appRoleChanged.products.length > 0) {
      MixPanelManager.userRoleChange(appRoleChanged);
    }
  };
  getMetaData() {
    User.getMetadata().then((data) => {
      console.log("metadata", UserManager.getParsedMetaData());
    });
  }
  onSearch(searchText) {
    this.setState({ searchText }, this.callDebounce);
  }
  callDebounce = debounce(
    () => this.getAllUsers({ search: this.state?.searchText }),
    1000
  );
}
export default withTranslation()(UserDashboard);
