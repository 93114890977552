import React from "react";
import ic_cashfree_dark from "../../../assets/icons/ic_cashfree_dark.png";
import ic_deskera_logo from "../../../assets/icons/ic_deskera_logo.png";
import ic_close_ from "../../../assets/icons/ic_close_.png";
import Subscription from "../../../services/subscription";
import BillingManager from "../../../managers/BillingManager";
import Utility from "../../../utility/Utility";

import {
  DKButton,
  DKIcon,
  DKLabel,
  removeLoader,
  showLoader,
  showToast,
  TOAST_TYPE,
  DKInput,
} from "deskera-ui-library";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
class VerifyMobileOtp extends React.Component {
  MINUTES = 1;
  SECONDS = 0;

  constructor(props) {
    super(props);
    this.state = {
      mobileValue: "",
      showOtpField: false,
      otp: "",
      showResendBtn: false,
      showOtpTimer: true,
      minutes: this.MINUTES,
      seconds: this.SECONDS,
      isOtpSent: false,
      isMobileNumberAlreadyPresent: false,
    };
  }

  componentDidMount() {
    this.setState({
      isMobileNumberAlreadyPresent: !Utility.isEmpty(
        BillingManager.getPhoneNumber()
      ),
      showOtpField: !Utility.isEmpty(BillingManager.getPhoneNumber()),
      mobileValue: !Utility.isEmpty(BillingManager.getPhoneNumber())
        ? BillingManager.getPhoneNumber()
        : "",
    });
  }

  componentWillUnmount() {
    // if (!BillingManager.isMobileNumberVerified()) {
    //   BillingManager.setPhoneNumber("");
    // }
    this.setState({
      mobileValue: "",
      showOtpField: false,
      otp: "",
    });
    clearInterval(this.myInterval);
  }

  cancelTapped = () => {
    this.props.onClose();
  };

  updateTimer = () => {
    if (this.state.showOtpTimer) {
      this.myInterval = setInterval(() => {
        const { seconds, minutes } = this.state;

        if (this.state.minutes === 0 && this.state.seconds === 0) {
          this.setState({
            showResendBtn: true,
            showOtpTimer: false,
            minutes: this.MINUTES,
            seconds: this.SECONDS,
          });
        }

        if (seconds > 0) {
          this.setState(({ seconds }) => ({
            seconds: seconds - 1,
          }));
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(this.myInterval);
          } else {
            this.setState(({ minutes }) => ({
              minutes: minutes - 1,
              seconds: 59,
            }));
          }
        }
      }, 1000);
    }
  };

  addPhoneNumber = () => {
    this.setState({
      otp: "",
      showOtpField: this.state.isMobileNumberAlreadyPresent ? true : false,
    });
    if (
      this.state.mobileValue.trim() !== "" &&
      this.state.mobileValue.trim().length === 10
    ) {
      const userNumber = this.state.mobileValue.trim();
      showLoader(t("VERIFYING_DETAILS"));
      Subscription.addPhoneNumber(this.state.mobileValue.trim())
        .then(
          (res) => {
            removeLoader();
            showToast(
              t("OTP_HAS_BEEN_SENT_TO_YOUR_MOBILE_NUMBER"),
              TOAST_TYPE.SUCCESS
            );
            BillingManager.setPhoneNumber(userNumber);
            const seconds = Utility.getTimeInSeconds(
              res.OTPExpiryIntervalMinutes
            );
            this.setState({
              showOtpField: true,
              showResendBtn: true,
              showOtpTimer: true,
              isOtpSent: true,
              isMobileNumberAlreadyPresent: true,
              minutes: 0,
              seconds: seconds,
            });
            this.updateTimer();
          },
          (err) => {
            removeLoader();
          }
        )
        .catch((error) => {
          removeLoader();
          console.log("error", error);
        });
    }
  };

  sendOtpRequest = () => {
    if (this.state.otp.trim() !== "") {
      showLoader(t("SUBMITTING_OTP_DETAILS"));
      Subscription.getOtpForCashfree(this.state.otp.trim()).then(
        (resOtp) => {
          removeLoader();

          if (!resOtp.Valid) {
            showToast(
              `Your entered otp is incorrect${
                resOtp.TriesRemaining !== 0 ? ", please try again" : ""
              }. Remaining trials ${resOtp.TriesRemaining}.`,
              TOAST_TYPE.FAILURE
            );
          }

          if (!resOtp.Valid && resOtp.TriesRemaining === 0) {
            this.setState({
              minutes: 0,
              seconds: 0,
            });
          }

          if (resOtp.Valid) {
            this.props.onSave();
          }
        },
        (errOtp) => {
          removeLoader();
          showToast(
            t("AN_ERROR_OCCURED_WHILE_SUBMITTING_YOUR_OTP"),
            TOAST_TYPE.FAILURE
          );
        }
      );
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="transparent-background col">
        <div
          className="popup-window"
          style={{ padding: 20, minWidth: "415px" }}
        >
          {this.getBannerView()}
          {this.getFormView()}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////
  getBannerView = () => {
    return (
      <div className="row justify-content-between ">
        <DKLabel
          className=" fw-b fs-m"
          text={t("WE_NEED_TO_VERIFY_YOUR_PHONE_NUMBER")}
        />
        <DKIcon
          src={ic_close_}
          className=" ic-s cursor-hand "
          onClick={() => {
            this.props.onClose();
          }}
        />
      </div>
    );
  };

  getFormView = () => {
    const enableSaveMobileNumberBtn =
      this.state.mobileValue.trim() !== "" &&
      this.state.mobileValue.trim().length === 10;
    const enableSendOtpBtn =
      this.state.otp.trim() !== "" && this.state.otp.trim().length === 6;
    const showResendBtn = !this.state.showOtpTimer && this.state.showResendBtn;
    return (
      <div className="parent-width column pt-l ">
        {/* 1 */}
        <div className="row mb-l align-items-end">
          {/* 1-left */}
          <div className="row">
            <div className="column parent-width">
              <DKLabel
                text={t("YOU_WILL_RECEIVE_A_6_DIGIT_OTP_FOR_VERIFICATION.")}
                className="fs-m fw-4 mb-s text-gray"
              />
              <div className="row parent-width parent-height">
                <div className="row">
                  <div style={{ width: "60px" }}>
                    <DKInput
                      style={{
                        borderRight: 0,
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                        paddingRight: 0,
                      }}
                      needHeader={false}
                      name="Country code"
                      readOnly={true}
                      value="+ 91"
                    />
                  </div>
                  {!this.state.isMobileNumberAlreadyPresent && (
                    <DKInput
                      needHeader={false}
                      name={"Mobile Number"}
                      placeholder={"Enter mobile number"}
                      required={true}
                      value={this.state.mobileValue}
                      onChange={(value, evt) => {
                        this.setState({
                          mobileValue: value,
                        });
                      }}
                      invalid={false}
                      maxLength={"10"}
                      pattern="[0-9]*"
                      inputClassName={"focusElement"}
                      style={{
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                        borderLeft: 0,
                        paddingLeft: 0,
                      }}
                      disabled={false}
                    />
                  )}
                  {this.state.isMobileNumberAlreadyPresent && (
                    <DKInput
                      name="disabledBtn"
                      style={{
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                        borderLeft: 0,
                        paddingLeft: 0,
                      }}
                      needHeader={false}
                      value={[
                        BillingManager.getPhoneNumber().slice(0, 5),
                        " ",
                        BillingManager.getPhoneNumber().slice(
                          5,
                          BillingManager.getPhoneNumber().length
                        ),
                      ].join("")}
                      disabled
                    />
                  )}
                  {this.state.isMobileNumberAlreadyPresent && (
                    <DKButton
                      className={`text-wrap-none bg-gray1 fw-m ml-m border-m ${
                        this.state.seconds !== 0 ? "text-gray" : ""
                      }`}
                      title="Edit"
                      onClick={() => {
                        if (this.state.seconds !== 0) {
                          return;
                        }
                        clearInterval(this.myInterval);
                        this.setState({
                          isMobileNumberAlreadyPresent: false,
                          mobileValue: BillingManager.getPhoneNumber(),
                          otp: "",
                          showOtpField: false,
                          minutes: this.MINUTES,
                          seconds: this.SECONDS,
                        });
                      }}
                    />
                  )}
                </div>
                {!this.state.isMobileNumberAlreadyPresent && (
                  <DKButton
                    className={`text-wrap-none fw-m ml-m border-m parent-height ${
                      enableSaveMobileNumberBtn
                        ? "bg-blue text-white"
                        : "bg-gray1 text-gray"
                    }`}
                    style={{
                      cursor: enableSaveMobileNumberBtn ? "pointer" : "default",
                    }}
                    title="Continue"
                    onClick={() => {
                      if (enableSaveMobileNumberBtn) {
                        this.addPhoneNumber();
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* 2 */}
        {this.state.showOtpField && (
          <div
            className={`row mb-l ${
              !this.state.showOtpTimer ? "align-items-end" : ""
            } `}
          >
            {/* 2-left */}
            <div className="row">
              <div className="column parent-width">
                <DKLabel
                  text={"Enter your OTP here"}
                  className="fw-m fw-4 mb-s text-gray"
                />
                <div className="column parent-width">
                  <div className="row parent-width">
                    <DKInput
                      needHeader={false}
                      name={"OTP"}
                      className="button-justify"
                      placeholder={"Enter 6 pin number"}
                      required={true}
                      value={this.state.otp}
                      onChange={(value, evt) => {
                        this.setState({
                          otp: value,
                        });
                      }}
                      invalid={false}
                      maxLength={"6"}
                      pattern="[0-9]*"
                      style={{ border: 0 }}
                      showButton={showResendBtn}
                      onLinkBtnClick={() => {
                        if (this.state.showResendBtn) {
                          this.setState({
                            minutes: this.MINUTES,
                            seconds: this.SECONDS,
                          });
                          this.addPhoneNumber();
                        }
                      }}
                    />
                    <div>
                      {!this.state.isOtpSent && (
                        <DKButton
                          className={`text-wrap-none bg-blue text-white fw-m ml-m`}
                          style={{
                            cursor: "pointer",
                          }}
                          title="Send otp"
                          onClick={() => {
                            this.addPhoneNumber();
                          }}
                        />
                      )}

                      {this.state.isOtpSent && (
                        <DKButton
                          className={`text-wrap-none border-m fw-m ml-m ${
                            enableSendOtpBtn && !showResendBtn
                              ? "bg-blue text-white"
                              : "bg-gray1 text-gray"
                          }`}
                          style={{
                            cursor:
                              enableSendOtpBtn && !showResendBtn
                                ? "pointer"
                                : "default",
                          }}
                          title="Verify"
                          onClick={() => {
                            if (enableSendOtpBtn && !showResendBtn) {
                              this.sendOtpRequest();
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>

                  {this.state.showOtpTimer && (
                    <DKLabel
                      text={`${this.state.seconds} seconds remaining`}
                      className="fw-s text-gray"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  ////////////////////////////////////////////////////////////////////////////
}

export default withTranslation()(VerifyMobileOtp);
