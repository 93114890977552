import ApiConstants from "../constants/ApiConstants";
import http from "../http";
import Payload from "../constants/Payload";
import Utility from "../utility/Utility";
import SubscriptionManager from "../managers/SubscriptionManager";
import BillingManager from "../managers/BillingManager";
import PlanManager from "../managers/PlanManager";
import { INTERVALS, PLANS, PRODUCTS, BOOKKEEPER_FLOW } from "../constants/Enum";
import CouponsManager from "../managers/CouponsManager";
import IntercomManager from "../managers/IntercomManager";
import MixPanelManager from "../managers/common/MIxpanelManger";

import { showAlert, showLoader, removeLoader } from "deskera-ui-library";

const SUBSCRIPTION_API_VERSION = "v2";
const PHONE_NUMBER_PREFIX_VAL = "91";
export default class Subscription {
  static currentLocation = "US";

  static getDetails(params) {
    showLoader("Fetching subscription details...");
    if (Utility.isEmpty(params)) params = {};
    params[Payload.COMMON.VERSION] = SUBSCRIPTION_API_VERSION;

    if (
      localStorage.getItem(BOOKKEEPER_FLOW.TENANT_ID) !== "" &&
      localStorage.getItem(BOOKKEEPER_FLOW.USER_ID) !== ""
    ) {
      params["UserID"] = localStorage.getItem(BOOKKEEPER_FLOW.USER_ID);
      params["TenantID"] = localStorage.getItem(BOOKKEEPER_FLOW.TENANT_ID);
    }

    return http
      .get(ApiConstants.URL.SUBSCRIPTION.GET_DETAILS, {
        params: params,
      })
      .then(
        (res) => {
          removeLoader();
          SubscriptionManager.set(res);
          SubscriptionManager.setCurrentSubscription(res);
          CouponsManager.setDiscountCoupons(res.DiscountCoupons);
          CouponsManager.setPrepaidCoupons(res.PrepaidCoupons);
          return Promise.resolve(res);
        },
        (err) => {
          removeLoader();
          return Promise.reject(err);
        }
      );
  }

  static bookkeeperSubscription(tenantID = null, userID = null) {
    return http
      .post(
        ApiConstants.URL.SUBSCRIPTION.BOOKKEEPER_SUBSCRIPTION +
          "?UserID=" +
          userID +
          "&TenantID=" +
          tenantID
      )
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static activatePlan(params, isTrial = false, tenantID = null, userID = null) {
    showLoader(isTrial ? "Activating trial..." : "Activating subscription...");
    let url;
    if (tenantID !== null && userID !== null) {
      url =
        ApiConstants.URL.SUBSCRIPTION.START_TRIAL +
        "?UserID=" +
        userID +
        "&TenantID=" +
        tenantID;
    } else {
      url = ApiConstants.URL.SUBSCRIPTION.START_TRIAL;
    }

    return http.put(url, params).then(
      (res) => {
        removeLoader();
        if (tenantID !== null && userID !== null) {
          showAlert(
            "Congratulations!",
            isTrial
              ? "Your plan trial is activated successfully. This trial is 100% FREE. Continue using for 15 days."
              : "Your plan subscription is activated successfully. Continue using the app now.",
            [
              {
                title: "Ok",
                className: "bg-blue fw-m text-white",
                onClick: () => {
                  window.close();
                },
              },
            ]
          );
        } else {
          showAlert(
            "Congratulations!",
            isTrial
              ? "Your plan trial is activated successfully. This trial is 100% FREE. Continue using for 15 days."
              : "Your plan subscription is activated successfully. Continue using the app now."
          );
        }
        SubscriptionManager.set(res);
        SubscriptionManager.setCurrentSubscription(res);
        if (isTrial) {
          IntercomManager.trackEvent("page", {
            url: "/myaccount/billing/purchase-plan/trial-success",
          });
        } else {
          IntercomManager.trackEvent("page", {
            url: "/myaccount/billing/purchase-plan/plan-activate-success",
          });
        }
        if (tenantID === null && userID === null) {
          setTimeout(() => this.pushToMixPanel(params.PlanID, isTrial), 1000);
        }
        return Promise.resolve(res);
      },
      (err) => {
        setTimeout(
          () => this.pushToMixPanel(params.PlanID, isTrial, true),
          1000
        );
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
  static getPlanDetails(params) {
    if (Utility.isEmpty(params)) params = {};
    params[Payload.COMMON.VERSION] = SUBSCRIPTION_API_VERSION;
    return http
      .get(ApiConstants.URL.SUBSCRIPTION.PLAN_DETAILS, {
        params: params,
      })
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
  static getPaymentMethods(params) {
    if (Utility.isEmpty(params)) params = {};
    if (
      localStorage.getItem(BOOKKEEPER_FLOW.TENANT_ID) !== "" &&
      localStorage.getItem(BOOKKEEPER_FLOW.USER_ID) !== ""
    ) {
      params["UserID"] = localStorage.getItem(BOOKKEEPER_FLOW.USER_ID);
      params["TenantID"] = localStorage.getItem(BOOKKEEPER_FLOW.TENANT_ID);
    }

    return http
      .get(ApiConstants.URL.SUBSCRIPTION.GET_PAYMENT_METHOD, {
        params: params,
      })
      .then(
        (res) => {
          if (res.PaymentGateway.toLowerCase() === "cashfree") {
            const modifiedRes = this.restructureResponse(res);
            const phoneNum =
              res.Phone && res.Phone.length > 10
                ? res.Phone.substr(2)
                : res.Phone;
            BillingManager.setPhoneNumber(phoneNum);
            BillingManager.setIsPhoneNumberValidated(res.IsPhoneValidated);
            BillingManager.setPaymentMethods(modifiedRes);
            BillingManager.setPaymentMethodData(res);
          } else {
            const phoneNum =
              res.Phone && res.Phone.length > 10
                ? res.Phone.substr(2)
                : res.Phone;
            BillingManager.setPhoneNumber(phoneNum);
            BillingManager.setIsPhoneNumberValidated(res.IsPhoneValidated);
            BillingManager.setPaymentMethods(res);
            BillingManager.setPaymentMethodData(res);
          }

          if (
            !Utility.isEmpty(res.CashfreeCardNumber) &&
            res.CashfreeStatus === "ACTIVE"
          ) {
            BillingManager.setCardAsAdded(true);
          }

          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static restructureResponse = (res) => {
    return {
      BillingEmail: res.BillingEmail,
      DefaultPaymentMethodID: res.DefaultPaymentMethodID,
      PaymentGateway: res.PaymentGateway,
      Cards: [
        {
          Last4: res.CashfreeCardNumber.substr(
            res.CashfreeCardNumber.length - 4,
            4
          ),
          PaymentMethodID: res.DefaultPaymentMethodID,
          CardBrand: res.CardBrand,
        },
      ],
      CashfreeStatus: res.CashfreeStatus,
    };
  };

  static sendPaymentDetails(params) {
    showLoader("Adding card...");
    return http
      .post(ApiConstants.URL.SUBSCRIPTION.POST__ADYEN_PAYMENT_DETAILS, params)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  // static getCashfreePaymentMethod(params) {
  //   return http
  //     .get(ApiConstants.URL.SUBSCRIPTION.GET__CASHFREE_PAYMENT_METHOD, {
  //       params: params,
  //     })
  //     .then(
  //       (res) => {
  //         if (res.PaymentGateway.toLowerCase() === "cashfree") {
  //           BillingManager.setPaymentMethods(this.restructureResponse(res));
  //         } else {
  //           BillingManager.setPaymentMethods(res);
  //         }

  //         if (
  //           !Utility.isEmpty(res.CashfreeCardNumber) &&
  //           res.CashfreeStatus === "ACTIVE"
  //         ) {
  //           BillingManager.setCardAsAdded(true);
  //         }

  //         return Promise.resolve(res);
  //       },
  //       (err) => {
  //         return Promise.reject(err);
  //       }
  //     );
  // }

  static requestCashfreePaymentLink(params) {
    showLoader("Redirecting you...");
    return http
      .post(ApiConstants.URL.SUBSCRIPTION.POST__CASHFREE_REQUEST_PAGE, params)
      .then(
        (res) => {
          if (res.Result) {
            return Promise.resolve(res);
          }
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static getPrepaidCodeDetails(params, tenantID = null, userID = null) {
    showLoader("Getting details...");
    let url = ApiConstants.URL.SUBSCRIPTION.GET_PREPAID_CODE_DETAILS;

    if (tenantID !== null && userID !== null) {
      url = url + "?UserID=" + userID + "&TenantID=" + tenantID;
    }

    return http.post(url, params).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }
  static getDiscountCodeDetails(params) {
    showLoader("Getting details...");
    return http
      .post(ApiConstants.URL.SUBSCRIPTION.GET_DISCOUNT_CODE_DETAILS, params)
      .then(
        (res) => {
          removeLoader();
          return Promise.resolve(res);
        },
        (err) => {
          removeLoader();
          return Promise.reject(err);
        }
      );
  }

  static applyPrepaidCode(
    prepaid_code,
    forceUpdate = false,
    currency,
    tenantID = null,
    userID = null
  ) {
    showLoader("Activating coupon...");
    let url = `${ApiConstants.URL.SUBSCRIPTION.APPLY_PREPAID_CODE}${prepaid_code}&ForceUpdate=${forceUpdate}&Currency=${currency}`;
    if (tenantID !== null && userID !== null) {
      url = url + "&UserID=" + userID + "&TenantID=" + tenantID;
    }
    return http.put(url, {}).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }

  static applyDiscountCode(discount_code) {
    showLoader("Applying code...");
    let url = `${ApiConstants.URL.SUBSCRIPTION.APPLY_DISCOUNT_CODE}`;
    return http.post(url, { Code: discount_code }).then(
      (res) => {
        removeLoader();
        return Promise.resolve(res);
      },
      (err) => {
        removeLoader();
        return Promise.reject(err);
      }
    );
  }

  static getPlanUpdatedAmount(params) {
    return http
      .post(ApiConstants.URL.SUBSCRIPTION.GET_PLAN_UPDATED_AMOUNT, params)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
  static getFailedInvoice(params) {
    return http
      .get(ApiConstants.URL.SUBSCRIPTION.GET_FAILED_INVOICE, {
        params: params,
      })
      .then(
        (res) => {
          SubscriptionManager.setInvoices(res, false);
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
  static getPaidInvoice(params, isPaid) {
    return http
      .get(ApiConstants.URL.SUBSCRIPTION.GET_PAID_INVOICE, {
        params: params,
      })
      .then(
        (res) => {
          SubscriptionManager.setInvoices(res, (isPaid = true));
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static cancelSubscription(planID, reason = "Other") {
    showLoader("Cancelling subscription...");
    return http
      .post(ApiConstants.URL.SUBSCRIPTION.CANCEL_SUBSCRIPTION, {
        PlanID: planID,
        Reason: reason,
      })
      .then(
        (res) => {
          removeLoader();
          this.pushToMixPanel(planID, false, true);
          return Promise.resolve(res);
        },
        (err) => {
          removeLoader();
          return Promise.reject(err);
        }
      );
  }
  static setBillingAddress(params) {
    return http
      .post(ApiConstants.URL.SUBSCRIPTION.ADD_BILLING_ADDRESS, params)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
  static getBillingAddress() {
    return http.get(ApiConstants.URL.SUBSCRIPTION.GET_BILLING_ADDRESS).then(
      (res) => {
        if (res && Array.isArray(res) && res.length > 0)
          BillingManager.setBillingAddress(res[0]);
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static getCountryFromRequest() {
    return http.get(ApiConstants.URL.SUBSCRIPTION.LOCATION).then(
      (res) => {
        SubscriptionManager.currentLocation = res?.country?.iso_code;
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static isProfessionalPlan(product) {
    if (Utility.isEmpty(product)) product = PRODUCTS.AIO;
    return SubscriptionManager.getParsedCurrentSubscription().some(
      (data) =>
        data.plan.includes(PLANS.PROFESSIONAL) && !Utility.isEmpty(data.planID)
    );
  }

  static printInvoice = (params) => {
    return http
      .post(ApiConstants.URL.SUBSCRIPTION.PRINT, params, {
        observe: "response",
        responseType: "blob",
      })
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  };

  static getAddUserAmount(params) {
    showLoader("Getting Updated User Amount...");
    return http
      .get(ApiConstants.URL.SUBSCRIPTION.GET_ADD_USER_AMOUNT, { params })
      .then(
        (res) => {
          removeLoader();
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static pushToMixPanel = (planID, isTrial, isCancel = false) => {
    const currentSubscription =
      SubscriptionManager.getParsedCurrentSubscription();
    let planInAction = currentSubscription.find((sub) => sub.planID === planID);
    if (!planInAction) {
      return;
    }
    let dataToSend = {
      plan_name: planInAction.plan,
      type: planInAction.interval,
      product: planInAction.product,
    };
    if (isCancel) {
      MixPanelManager.planCancel(dataToSend);
    } else {
      isTrial
        ? MixPanelManager.trialStarted(dataToSend)
        : MixPanelManager.planBuy(dataToSend);
    }
  };

  static getInvoice = (params) => {
    return http.get(ApiConstants.URL.SUBSCRIPTION.GET_INVOICE, { params }).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  };

  static addPhoneNumber = (params) => {
    return http
      .post(ApiConstants.URL.SUBSCRIPTION.PHONE_NUMBER, {
        Phone: `${PHONE_NUMBER_PREFIX_VAL}${params}`,
      })
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  };

  static getOtpForCashfree = (params) => {
    return http
      .post(ApiConstants.URL.SUBSCRIPTION.PHONE_OTP, {
        PhoneOTP: params,
      })
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  };
}
