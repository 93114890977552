import React, { Component } from "react";

import { DKLabel, DKButton, showAlert, DKIcon } from "deskera-ui-library";

import ProductIcon from "./ProductIcon";
import StatusBadge from "./StatusBadge";
import PlanManager from "../../managers/PlanManager";
import {
  SUBSCRIPTION_STATUS,
  INTERVALS,
  TRACK_EVENT,
  PLANS,
  PRODUCTS,
} from "../../constants/Enum";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import Subscription from "../../services/subscription";

import ic_more from "../../assets/icons/ic_more.png";
import ic_info from "../../assets/icons/ic_info.png";
import AppManager from "../../managers/AppManager";
import PriceLabel from "./PriceLabel";
import CouponsManager from "../../managers/CouponsManager";
import Utility from "../../utility/Utility";
import SubscriptionManager from "../../managers/SubscriptionManager";
import { trackEventAC } from "../../managers/ActiveCampaignWrapper";
import UserManager from "../../managers/UserManager";
import DataParser from "../../Helper/DataParser";
import { isMobileAppWebView } from "../../utility/ViewportSizeUtils";
import { t } from "i18next";
import { planDetails } from "../../constants/Plans";
import { TALK_TO_SALES_URL_MRP } from "../../constants/Constant";

/*
- product
- plan
- planInterval
- onDataChange
*/
class BillingProductCardForERP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: this.props.product,
      plan: this.getPlanInState(this.props),
      planInterval: this.props.planInterval
        ? this.props.planInterval
        : PlanManager.getDefaultInterval(),
      currency: !Utility.isEmpty(this.props.currency)
        ? this.props.currency
        : SubscriptionManager.getCurrencySameAsActivePlanCurrency(),
      status: this.props.status,
      remainingTrialDays: this.props.remainingTrialDays,
      needCancelButton: false,
      mouseOverCancelButton: false,
      nextChargedDate: DataParser.getBillDateFormat(
        new Date(this.props.nextChargedDate)
      ),
      activeTill: this.props.activeTill,
    };

    setTimeout(() => {
      // alert(this.props.planInterval);
    }, 2000);
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      product: nextProp.product,
      plan: this.getPlanInState(nextProp),
      planInterval: nextProp.planInterval
        ? nextProp.planInterval
        : PlanManager.getDefaultInterval(),
      currency: !Utility.isEmpty(nextProp.currency)
        ? nextProp.currency
        : SubscriptionManager.getCurrencySameAsActivePlanCurrency(),
      status: nextProp.status,
      remainingTrialDays: nextProp.remainingTrialDays,
      nextChargedDate: DataParser.getBillDateFormat(
        new Date(nextProp.nextChargedDate)
      ),
      activeTill: nextProp.activeTill,
    });
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside);

    Subscription.getDetails().then((res) => {
      if (res?.AIOPlan?.Name === PLANS.FREEMIUM) {
        PlanManager.isFreemium = true;
      } else {
        PlanManager.isFreemium = false;
      }
    });
  }
  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
  }

  getPlanInState(paramProps) {
    if (!paramProps.plan) {
      return PlanManager.getDefaultPlanForERP();
    } else {
      return paramProps.plan;
    }
  }

  handleClickOutside = (event) => {
    if (!this.state.mouseOverCancelButton) {
      this.setState({ needCancelButton: false });
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div
        className={`main-holder-p-horizontal main-holder-p-vertical mt-m border-s position-relative border-radius-s bg-white row row-responsive justify-content-between border-box ${
          isMobileAppWebView() ? " border-radius-r " : ""
        }`}
        style={{ borderColor: "rgb(230, 230, 230)", flexWrap: "wrap" }}
      >
        {(this.state.status === SUBSCRIPTION_STATUS.ACTIVE ||
          this.state.status === SUBSCRIPTION_STATUS.IN_TRIAL) &&
          this.getMoreOptions()}
        {this.getProductDetails()}
        {this.getPriceSection()}
        <div className="column mobile-parent-width align-items-center border-box mt-s padding-top-mobile">
          {this.getActionButton(this.state.status)}
          {/* {(PlanManager.isFreemium ||
            this.state.status !== SUBSCRIPTION_STATUS.ACTIVE) && (
            <DKButton
              title={t("CHANGE_PLAN")}
              className={`text-dark-gray mobile-width-70 justify-content-center parent-width text-wrap-none padding-empty-mobile ${
                isMobileAppWebView() ? " bg-gray1 border-m fw-m mt-m" : ""
              }`}
              onClick={this.gotoPlanSelection}
            />
          )} */}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getMoreOptions() {
    return (
      <div
        className="position-absolute column align-items-end"
        style={{ top: 0, right: 5 }}
      >
        <DKButton
          icon={ic_more}
          onClick={this.changeVisibilityOfCancelSubscription}
          style={{ opacity: 0.2 }}
        />
        {this.state.needCancelButton && (
          <div
            onMouseOver={() => {
              this.setState({ mouseOverCancelButton: true });
            }}
            onMouseOut={() => {
              this.setState({ mouseOverCancelButton: false });
            }}
          >
            <DKButton
              title="Cancel Subscription"
              className="text-red fw-m text-wrap-none bg-white shadow-m"
              style={{ marginTop: -5 }}
              onClick={this.showCancelSubscriptionAlert}
            />
          </div>
        )}
      </div>
    );
  }

  changeVisibilityOfCancelSubscription = () => {
    this.setState({ needCancelButton: !this.state.needCancelButton });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getProductDetails() {
    return (
      <div className="column border-box">
        <div className="row align-items-start">
          <ProductIcon product={PRODUCTS.ERP_V2} className="ic-m mr-m" />

          <div className="column">
            <DKLabel
              text={
                "Deskera " + PlanManager.getProductDisplayName(PRODUCTS.ERP_V2)
              }
              className="fw-m fs-l"
            />
            <div>
              <div className="row mt-s">
                <DKLabel
                  text={
                    PlanManager.getPlanDisplayName(this.state.plan, true) +
                    " Plan"
                  }
                  className="fs-m text-gray"
                />
                <div
                  style={{ opacity: 0.5 }}
                  className="cursor-hand display-only-web"
                  onClick={this.gotoPlanSelection}
                >
                  <DKIcon src={ic_info} className="ic-s ml-r" />
                </div>
              </div>
            </div>
            {/* <DKLabel text="View details" className="text-blue fw-m mt-r" /> */}
            {this.state.status === SUBSCRIPTION_STATUS.ACTIVE && (
              <div className="mt-s">
                {this.state.plan === PLANS.PROFESSIONAL_I ||
                this.state.plan === PLANS.PROFESSIONAL_A ? (
                  <div>
                    Active for
                    <span className="fw-b"> Lifetime</span>
                  </div>
                ) : (
                  !PlanManager.isFreemium && (
                    <div className="fs-s">
                      Active until
                      <span className="fw-b"> {this.state.activeTill}</span>
                    </div>
                  )
                )}
              </div>
            )}
            {!PlanManager.isFreemium &&
              this.state.status !== SUBSCRIPTION_STATUS.TRIAL_PENDING && (
                <StatusBadge className="mt-m" status={this.state.status} />
              )}
          </div>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getPriceSection() {
    let planPrice = PlanManager.getPlanPriceByCurrency(
      PRODUCTS.ERP_V2,
      this.state.plan,
      INTERVALS.YEARLY,
      this.state.currency
    );
    const planDetailed =
      planDetails[PRODUCTS.ERP_V2][PlanManager.getParsedPlan(this.state.plan)];
    const country = SubscriptionManager.getIndiaOrGlobalLocation();
    return (
      <div
        className="column border-box padding-top-mobile align-web-items-center"
        style={{ minWidth: "150px" }}
      >
        <div className="parent-width flex row justify-content-center">
          <div>
            <PriceLabel
              price={planPrice}
              currency={this.state.currency}
              discountedPrice={CouponsManager.getDiscountedPrice(
                planPrice,
                this.state.product,
                this.state.planInterval
              )}
            />
          </div>
        </div>
        <DKLabel
          className="parent-width flex justify-content-center text-gray text-align-center"
          text={`${planDetailed?.billingInfo} (${
            planDetailed?.users?.[country] ?? 0
          } user/s)`}
        />
        {this.state.status === SUBSCRIPTION_STATUS.IN_TRIAL && (
          <div className="parent-width flex justify-content-center">
            <DKLabel
              className="mt-m fw-m text-align-center"
              text={
                this.state.remainingTrialDays
                  ? `${this.state.remainingTrialDays} - days trial remaining`
                  : `Trial Over`
              }
            />
          </div>
        )}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getActionButton(status) {
    const isActive =
      status === SUBSCRIPTION_STATUS.ACTIVE ||
      status === SUBSCRIPTION_STATUS.IN_TRIAL;
    return (
      <div className="column">
        {!isActive && (
          <DKButton
            title={"Talk to Sales"}
            className={`mobile-width-70 justify-content-center text-white fw-m bg-blue`}
            onClick={() => {
              window?.open(TALK_TO_SALES_URL_MRP, "_blank");
            }}
          />
        )}
        {isActive && (
          <DKButton
            title={t("CHANGE_PLAN")}
            className="bg-gray1 mobile-width-70 justify-content-center border-m fw-m"
            onClick={() => {
              this.gotoPlanSelection();
            }}
          />
        )}
      </div>
    );
    // switch (status) {
    //   case SUBSCRIPTION_STATUS.TRIAL_PENDING:
    //     if (PlanManager.isFreemium) {
    //       return (
    //         <DKButton
    //           title={t("BUY_NOW")}
    //           className={`mobile-width-70 justify-content-center text-white fw-m ${
    //             isMobileAppWebView() ? " bg-purple" : " bg-blue"
    //           }`}
    //           onClick={this.buyPlan}
    //         />
    //       );
    //     } else {
    //       return (
    //         <DKButton
    //           title={t("START_TRIAL")}
    //           className={`mobile-width-70 justify-content-center text-white fw-m text-wrap-none ${
    //             isMobileAppWebView() ? " bg-purple" : " bg-blue"
    //           }`}
    //           onClick={() =>
    //             AppManager.showTrialActivationWarning(this.startTrial)
    //           }
    //         />
    //       );
    //     }
    //   case SUBSCRIPTION_STATUS.IN_TRIAL:
    //   case SUBSCRIPTION_STATUS.TRIAL_ENDED:
    //   case SUBSCRIPTION_STATUS.EXPIRED:
    //   case SUBSCRIPTION_STATUS.CANCELLED:
    //     return (
    //       <DKButton
    //         title={t("BUY_NOW")}
    //         className={`mobile-width-70 justify-content-center text-white fw-m ${
    //           isMobileAppWebView() ? " bg-purple" : " bg-blue"
    //         }`}
    //         onClick={this.buyPlan}
    //       />
    //     );
    //   case SUBSCRIPTION_STATUS.ACTIVE:
    //     if (PlanManager.isFreemium) {
    //       return (
    //         <DKButton
    //           title={t("BUY_NOW")}
    //           className={`mobile-width-70 justify-content-center text-white fw-m ${
    //             isMobileAppWebView() ? " bg-purple" : " bg-blue"
    //           }`}
    //           onClick={this.buyPlan}
    //         />
    //       );
    //     } else {
    //       return (
    //         <DKButton
    //           title={t("CHANGE_PLAN")}
    //           className="bg-gray1 mobile-width-70 justify-content-center border-m fw-m"
    //           onClick={this.gotoPlanSelection}
    //         />
    //       );
    //     }
    //   default:
    //     return (
    //       <DKButton
    //         title={t("Try now")}
    //         className={`mobile-width-70 justify-content-center text-white fw-m ${
    //           isMobileAppWebView() ? " bg-purple" : " bg-blue"
    //         }`}
    //         onClick={() => {
    //           showAlert(
    //             "Try now!",
    //             `${this.state.product} - ${this.state.plan} - ${this.state.planInterval}`
    //           );
    //         }}
    //       />
    //     );
    // }
  }

  startTrial = () => {
    const product = this.state.product;
    const plan = this.state.plan;
    let planID = PlanManager.getPlanID(
      PRODUCTS.ERP_V2,
      this.state.plan,
      INTERVALS.YEARLY
    );
    Subscription.activatePlan({
      PlanID: planID,
      Currency:
        SubscriptionManager.getCurrencySameAsActivePlanCurrency().toLowerCase(),
    }).then((res) => {
      trackEventAC(
        {
          event: TRACK_EVENT.START_TRIAL,
          eventData: `Trial started successfully for product ERP and plan ${plan.toLowerCase()}`,
          email: UserManager.getUserEmail(),
        },
        () => {}
      );
      this.setState({});
      this.props.onDataChange();
    });
  };
  buyPlan = () => {
    RouteManager.gotoCheckout(
      PRODUCTS.ERP_V2,
      this.state.plan,
      INTERVALS.YEARLY
    );
  };

  gotoPlanSelection = () => {
    RouteManager.navigateToPage(
      PAGE_ROUTES.PLAN_SELECTION,
      "product=" + this.state.product + "&interval=" + this.state.planInterval
    );
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  showCancelSubscriptionAlert = () => {
    this.changeVisibilityOfCancelSubscription();
    let buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 fw-m border-m",
        onClick: () => {},
      },
      {
        title: "Proceed",
        className: "bg-red fw-m text-white ml-r",
        onClick: () => {
          this.cancelSubscription();
        },
      },
    ];
    showAlert(
      "Cancel subscription",
      "Are you sure want to cancel this subscription?",
      buttons
    );
  };

  cancelSubscription = () => {
    const isProductActive = SubscriptionManager.isProductActive(
      this.state.product
    );
    const product = this.state.product;
    const plan = this.state.plan;
    let planID = PlanManager.getPlanID(
      PRODUCTS.ERP_V2,
      this.state.plan,
      INTERVALS.YEARLY
    );

    Subscription.cancelSubscription(planID).then((res) => {
      Subscription.getDetails()
        .then((res) => {
          this.props.onDataChange();

          trackEventAC(
            {
              event: isProductActive
                ? TRACK_EVENT.CANCEL_SUBSCRIPTION
                : TRACK_EVENT.CANCEL_TRIAL,
              eventData: `Cancelled ${
                isProductActive ? "subscription" : "trial"
              } successfully for product ${product.toUpperCase()} and plan ${plan.toLowerCase()}`,
              email: UserManager.getUserEmail(),
            },
            () => {}
          );

          showAlert(
            "Subscription canceled!",
            "Your subscription has been canceled successfully."
          );
        })
        .catch((err) => {});
    });
  };
}

export default BillingProductCardForERP;
