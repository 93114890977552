import ApiConstants from "../constants/ApiConstants";

class Activecampaign {
  getApiRequestOptions(method, body = null) {
    let requestOptions = {
      method: method,
      credentials: "include",
      withCredentials: true,
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        accept: "*/*",
      },
    };
    if (method === "POST" || method === "PUT") {
      body = JSON.stringify(body);
      requestOptions = { ...requestOptions, body };
    }
    return requestOptions;
  }

  eventTracking(params, callback) {
    const trackData = {
      event: params.event,
      eventdata: params.eventData,
      visit: JSON.stringify({
        email: params.email,
      }),
    };

    fetch(
      ApiConstants.URL.BASE + ApiConstants.URL.ACTIVE_CAMPAIGN.EVENT_TRACKING,
      this.getApiRequestOptions("POST", trackData)
    )
      .then((response) => response.json())
      .then((data) => {
        callback(true);
      })
      .catch((error) => {
        callback(false);
      });
  }

  createOrUpdateContact(params) {
    var contactData = {
      contact: {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
        phone: params.phone,
        fieldValues: params.fieldValues,
      },
    };

    fetch(
      ApiConstants.URL.BASE +
        ApiConstants.URL.ACTIVE_CAMPAIGN.CREATE_UPDATE_CONTACT,
      this.getApiRequestOptions("POST", contactData)
    )
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {});
  }
}

export default Activecampaign;
