import React, { Component } from "react";
import RouteManager from "../../managers/RouteManager";

import {
  DKLabel,
  DKButton,
  showLoader,
  removeLoader,
  DKLine,
  showAlert,
  DKInput,
  DKTextField,
  INPUT_VIEW_DIRECTION,
} from "deskera-ui-library";

import { MAX_SECTION_WIDTH } from "../../constants/Constant";

import ic_delete from "../../assets/icons/white/ic_delete.png";
import ic_repeat from "../../assets/icons/white/ic_repeat.png";
import UserManager from "../../managers/UserManager";
import CompanyDetailManager from "../../managers/CompanyDetailManager";
import Tenant from "../../services/tenant";
import { ACTION_KEY, DELETE_OPTIONS, WEBSITE_TYPE } from "../../constants/Enum";
import AppManager from "../../managers/AppManager";
import User from "../../services/user";
import DeleteAccountPopup from "../common/DeleteAccountPopup";
import IAM from "../../services/iam";
import Utility, { Cookies, getCapitalized } from "../../utility/Utility";
import Subscription from "../../services/subscription";
import CRM from "../../services/crm";
import { REGEX } from "../../constants/Constant";
import ApiConstants from "../../constants/ApiConstants";
import AddOrgPopup from "../common/AddOrgPopup";
import OrgList from "../common/OrgList";
import PermissionManager from "../../managers/PermissionManager";
import { localizationText } from "../../utility/Localization";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import http from "../../http";
import { Analytics } from "../../utility/GTMEvents";
class SettingsDashboard extends Component {
  ////////////////////////////////////////////////////////////////

  constructor(props) {
    super(props);

    this.state = {
      companyName: CompanyDetailManager.getCompanyName(),
      showActionIcons: false,
      showConfimationPopup: false,
      deleteAccState: this.getDeleteAccInitialState(),
      helpCenterDetails: null,
      builderDetails: null,
      customDomainHC: "",
      isCustomDomainHCValid: true,
      customDomainWB: "",
      isCustomDomainWBValid: true,
      needAddNewOrgPopup: false,
      needOrgList: false,
      tenantIds: [],
      allOrgs: [],
      allTenantResReceived: false,
      switchOrgButtonTapped: false,
      saveTapped: false,
      disableDeleteCompanyBtn: false,
      organisationName: "",
      deleteAccount: "",
      resetAccount: "",
      password: "",
      selectedDeleteOption: 0,
      deleteConrifmed: false,
      iamUser: undefined,
    };
  }

  getDeleteAccInitialState() {
    return {
      showAccountDeletePopup: false,
      selectedDeleteReason: "",
      isValidAccDeleteReason: false,
      otherDeleteReason: "",
      showError: false,
    };
  }

  componentDidMount() {
    RouteManager.setPresenter(this);
    if (!PermissionManager.isSettingsAccessible()) {
      RouteManager.navigateToHome();
      return;
    }
    if (Subscription.isProfessionalPlan()) {
      this.getCustomDomainStatus();
    }
    this.getIAMUserDetails();
    if (CompanyDetailManager.getAllOrgs().length === 1) {
      this.setState({
        disableDeleteCompanyBtn: true,
      });
    }
  }

  getIAMUserDetails() {
    http
      .get(
        ApiConstants.URL.IAM.GET_PROFILE(UserManager?.getUserDetails()?.imID)
      )
      .then((res) => {
        this.setState({
          iamUser: res,
        });
      });
  }

  ////////////////////////////////////////////////////////////////

  onSaveClick = () => {
    this.setState({ saveTapped: true });
    if (this.state.companyName.trim() !== "") {
      Tenant.setCompanyName({
        id: UserManager.getUserTenantID(),
        name: this.state.companyName,
      }).then(() => {
        this.setState({
          showActionIcons: false,
        });
      });
    }
  };

  deleteTapped = (key) => {
    if (key === ACTION_KEY.DELETE_COMAPANY) {
      IAM.deleteCompany(
        { id: UserManager.getUserTenantID(), password: this.state.password },
        this.companyDeleted
      );
    } else if (key === ACTION_KEY.RESET_COMPANY) {
      IAM.resetCompany({ password: this.state.password }).then(
        (res) => {
          showAlert(
            t("COMPANY_RESET"),
            t("YOUR_COMPANY_HAS_BEEN_RESET_SUCCESSFULLY"),
            [
              {
                title: "Ok",
                className: "bg-blue text-white",
                onClick: () => {
                  AppManager.refreshApp();
                },
              },
            ]
          );
        },
        (err) => {}
      );
    }
  };

  showCompanyDeletedAlert = (value) => {
    this.hideOrgList();
    let isOrgAvailableToSwitch = CompanyDetailManager.getAllOrgs().length > 1;
    if (!isOrgAvailableToSwitch && value) {
      IAM.logOut().then((res) => {
        AppManager.gotoLoginPage();
      });
      return;
    }

    if (value) {
      let buttons = [];
      if (isOrgAvailableToSwitch) {
        buttons.push({
          title: t("SWITCH_ORG"),
          className: "bg-blue text-white mr-r",
          onClick: () => {
            this.onSwitchOrgClick();
          },
        });
      }

      // buttons.push({
      //   title: "+ Add Org",
      //   className: "bg-blue text-white ",
      //   onClick: () => {
      //     this.addOrgTapped();
      //   },
      // });

      showAlert(
        `${getCapitalized(t("ORGANISATION"))} deleted!`,
        `Your ${t(
          "ORGANISATION"
        )} has been successfully deleted. Choose from below options to continue.`,
        buttons
      );
    }
  };
  companyDeleted = (value = true) => {
    //If last org
    if (CompanyDetailManager.getAllOrgs().length > 1) {
      this.getUpdatedOrgList();
    }

    this.showCompanyDeletedAlert(value);
  };

  deleteAccCallback = (value) => {
    if (value) {
      this.setState(
        {
          deleteAccState: this.getDeleteAccInitialState(),
        },
        () => {
          IAM.logOut().then(
            (res) => {
              AppManager.gotoLoginPage();
            },
            (err) => {}
          );
        }
      );
    }
  };

  continueAccDeletion = () => {
    const reason =
      this.state.deleteAccState.selectedDeleteReason === DELETE_OPTIONS[4].label
        ? this.state.deleteAccState.otherDeleteReason
        : this.state.deleteAccState.selectedDeleteReason;
    User.deleteAccountReason(
      {
        accountDeleteReason: reason,
        password: this.state.password,
        isSocialLogin: this.state.iamUser.socialAuth !== undefined,
      },
      this.deleteAccCallback
    ).catch(() => {});
  };

  ////////////////////////////////////////////////////////////////
  onCompanynameChange = (value) => {
    this.setState({
      companyName: value,
      showActionIcons: true,
    });
  };

  onCancelClick = () => {
    this.setState({
      showActionIcons: false,
      companyName: CompanyDetailManager.getCompanyName(),
    });
  };

  cancelTapped = () => {
    this.setState((prevState) => ({
      showConfimationPopup: false,
      deleteAccState: this.getDeleteAccInitialState(),
    }));
  };

  deleteActionClick = (key) => {
    showAlert(this.getTitle(key), this.getDescription(key), [
      {
        title: t("CANCEL"),
        className: "bg-gray1 fw-m border-m",
        onClick: this.cancelTapped,
      },
      {
        title: this.getButtonTitle(key),
        className: "bg-red ml-r text-white fw-m",
        onClick: () => {
          this.deleteTapped(key);
        },
      },
    ]);
  };

  setAccountDeletePopup = () => {
    this.setState((prevState) => ({
      deleteAccState: {
        ...prevState.deleteAccState,
        showAccountDeletePopup:
          !prevState.deleteAccState.showAccountDeletePopup,
      },
    }));
  };

  deleteAccount = () => {
    const isValidReason = this.state.deleteAccState.isValidAccDeleteReason;
    const isSelectedReasonOther =
      this.state.deleteAccState.selectedDeleteReason ===
      DELETE_OPTIONS[4].label;
    const isOtherReasonEmpty =
      this.state.deleteAccState.otherDeleteReason.trim() !== "";

    if (isValidReason) {
      if (isSelectedReasonOther && isOtherReasonEmpty) {
        this.setState(
          (prevState) => ({
            deleteAccState: {
              ...prevState.deleteAccState,
              showError: false,
            },
          }),
          () => {
            this.continueAccDeletion();
          }
        );
      } else if (!isSelectedReasonOther) {
        this.setState(
          (prevState) => ({
            deleteAccState: {
              ...prevState.deleteAccState,
              showError: false,
            },
          }),
          () => {
            this.continueAccDeletion();
          }
        );
      } else {
        this.setState((prevState) => ({
          deleteAccState: {
            ...prevState.deleteAccState,
            showError: true,
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        deleteAccState: {
          ...prevState.deleteAccState,
          showError: true,
        },
      }));
    }
  };

  onDeleteReasonSelect = (value) => {
    if (value) {
      this.setState((prevState) => ({
        deleteAccState: {
          ...prevState.deleteAccState,
          selectedDeleteReason: value,
          otherDeleteReason: "",
          isValidAccDeleteReason: !prevState.isValidAccDeleteReason,
          showError: false,
        },
      }));
    }
  };

  onDeleteReasonValueChange = (value) => {
    this.setState((prevState) => ({
      deleteAccState: {
        ...prevState.deleteAccState,
        otherDeleteReason: value,
        showError: false,
      },
    }));
  };

  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    const { t } = this.props;
    let detailsEdited =
      this.state.companyName !== CompanyDetailManager.getCompanyName();
    return (
      <div>
        <div className="row fw-m fs-l">Settings</div>
        <div className="row row-responsive mt-l align-items-start">
          <div className="column" style={{ flex: 1 }}>
            <div
              className="column parent-width"
              style={{ maxWidth: MAX_SECTION_WIDTH }}
            >
              {this.getCompanyDetailsView(detailsEdited)}
              {/* {Subscription.isProfessionalPlan() && this.getHelpCenterView()}
              {Subscription.isProfessionalPlan() &&
                this.getWebsiteBuilderView()} */}
              {this.getCompanySettingsView()}
              {this.state.deleteAccState.showAccountDeletePopup
                ? this.deleteAccountPopup()
                : null}
            </div>
          </div>
        </div>
        {this.state.needAddNewOrgPopup && this.getAddNewOrgPopup()}
        {this.state.needOrgList && this.getOrgList()}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getCompanyDetailsView(detailsEdited) {
    return (
      <div className="pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width column ">
        <div className="row justify-content-between">
          <div>
            <DKLabel text={t("COMPANY_DETAILS")} className="fw-m fs-m" />
            <DKLabel
              text={`You can update or modify your ${t(
                "ORGANISATION"
              )} details here`}
              className="text-gray mt-xs"
            />
          </div>
        </div>
        <DKLabel className="mt-xl fs-m fw-m" text={t("COMPANY_NAME")} />

        <div style={{ width: "98%", maxWidth: 570 }}>
          <DKInput
            needHeader={false}
            className="mt-m"
            name={t("COMPANY_NAME")}
            placeholder="Company name"
            required
            value={this.state.companyName}
            onChange={this.onCompanynameChange}
            canValidate={this.state.saveTapped}
            invalid={this.state.companyName.trim() === ""}
          />

          {detailsEdited && this.state.showActionIcons ? (
            <div className="row mt-l">
              <DKButton
                className="bg-gray1 border-m fw-m"
                onClick={this.onCancelClick}
                title={t("CANCEL")}
              />
              <DKButton
                className="bg-blue text-white fw-m ml-r"
                onClick={this.onSaveClick}
                title={t("SAVE")}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getCompanySettingsView() {
    return (
      <div className="pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width column mt-l mb-xl">
        <div>
          <DKLabel text={t("COMPANY_SETTINGS")} className="fw-m fs-m" />
          <DKLabel
            text={`You can delete company or delete account or reset your ${t(
              "ORGANISATION"
            )} here`}
            className="text-gray mt-xs"
          />
          {this.getDeleteCompanyView()}
          {this.getResetCompanyView()}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getDeleteCompanyView() {
    return (
      <div className="column bg-gray1 border-radius-s border-m pt-l pb-l pl-l pr-l parent-width mt-xxl border-box">
        <div className="row row-responsive">
          <div className="column">
            <DKLabel text={t("DELETE_COMPANY")} className="fw-m fs-m" />
            <DKLabel
              text={t("YOU_CAN_USE_THIS_FORM_TO_DELETE")}
              className="text-gray mt-xs"
            />
          </div>
          {this.state.selectedDeleteOption !== 1 && (
            <div className="mt-m display-block ">
              <DKButton
                title={t("DELETE_COMPANY")}
                className="bg-red text-white fw-m text-wrap-none ml-xl"
                style={{
                  opacity: this.state.disableDeleteCompanyBtn ? "20%" : "",
                  cursor: this.state.disableDeleteCompanyBtn
                    ? "default"
                    : "pointer",
                }}
                icon={ic_delete}
                onClick={() => {
                  if (!this.state.disableDeleteCompanyBtn) {
                    this.setState({
                      selectedDeleteOption: 1,
                      deleteConrifmed: false,
                      resetAccount: "",
                      deleteAccount: "",
                      organisationName: "",
                      password: "",
                    });
                  }
                }}
              />
            </div>
          )}
        </div>
        {this.state.selectedDeleteOption === 1 && (
          <div className="row parent-width align-items-start">
            <div style={{ width: "98%", maxWidth: 570 }}>
              <DKInput
                valueStyle={{ backgroundColor: "white" }}
                title={`Please enter your current ${t(
                  "ORGANISATION"
                )} name. <b>(${CompanyDetailManager.getCompanyName()}) </b>`}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                className="mt-m"
                required={true}
                value={this.state.organisationName}
                onChange={(orgName) => {
                  this.setState({ organisationName: orgName });
                }}
                canValidate={true}
                validator={(value) => {
                  return this.state.deleteConrifmed &&
                    CompanyDetailManager.getCompanyName() !==
                      this.state.organisationName.trim()
                    ? false
                    : true;
                }}
                errorMessage={`Please enter valid organisation name. <b>(${CompanyDetailManager.getCompanyName()}) </b>`}
              />
              <DKInput
                direction={"vertical"}
                title={"Enter your login password "}
                valueStyle={{ backgroundColor: "white" }}
                needHeader={false}
                name={"password"}
                placeholder={""}
                required={true}
                type="password"
                className={"mb-l mt-l"}
                value={this.state.password}
                onChange={(value) => {
                  this.setState({ password: value });
                }}
                canValidate={true}
                validator={(value) => {
                  return this.state.deleteConrifmed &&
                    this.state.password === ""
                    ? false
                    : true;
                }}
                errorMessage={`Please enter your password in the field above`}
              />
            </div>
            <DKButton
              className="bg-white text-black border-m fw-m ml-m"
              onClick={() => {
                this.setState({
                  selectedDeleteOption: 0,
                  deleteAccount: "",
                  organisationName: "",
                  resetAccount: "",
                  deleteConrifmed: false,
                  password: "",
                });
              }}
              title={t("CANCEL")}
              style={{ marginTop: 32 }}
            />
            <DKButton
              className="bg-red text-white fw-m ml-m"
              onClick={() => {
                this.setState({
                  deleteConrifmed: true,
                });
                if (
                  CompanyDetailManager.getCompanyName() ===
                    this.state.organisationName.trim() &&
                  this.state.password !== ""
                ) {
                  this.deleteActionClick(ACTION_KEY.DELETE_COMAPANY);
                }
              }}
              title={t("DELETE")}
              style={{ marginTop: 32 }}
            />
          </div>
        )}
        <DKLine className="bg-line-2 mt-xxl mb-xl" />
        {this.getDeleteAccountView()}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getDeleteAccountView() {
    return (
      <div className="row row-responsive">
        <div className="column">
          <DKLabel text="Delete account" className="fw-m fs-m" />
          <DKLabel
            text="You can use this to delete your Account. This operation is irreversible and once the account is deleted, all companies and their associated data/ subscription for ALL Products (ERP, CRM +, People + and others) will be removed."
            className="text-gray mt-xs"
          />
          {this.state.selectedDeleteOption === 2 && (
            <div className="row parent-width align-items-start">
              <div style={{ width: "98%", maxWidth: 570 }}>
                <DKInput
                  direction={"vertical"}
                  title={
                    "To confirm deletion, type <i>delete</i> in the field below"
                  }
                  valueStyle={{
                    backgroundColor: "white",
                  }}
                  className={
                    this.state.deleteConrifmed &&
                    "delete" !== this.state.deleteAccount.trim()
                      ? "mb-m mt-m"
                      : "mt-m"
                  }
                  needHeader={false}
                  name={"delete"}
                  placeholder={"delete"}
                  required={true}
                  value={this.state.deleteAccount}
                  onChange={(value) => {
                    this.setState({ deleteAccount: value });
                  }}
                  canValidate={true}
                  validator={(value) => {
                    return this.state.deleteConrifmed &&
                      "delete" !== this.state.deleteAccount.trim()
                      ? false
                      : true;
                  }}
                  errorMessage="Please enter 'delete' in above field"
                />
                {this.state.iamUser.socialAuth === null && (
                  <DKInput
                    direction={"vertical"}
                    title={"Enter your login password "}
                    valueStyle={{ backgroundColor: "white" }}
                    needHeader={false}
                    className={"mb-l mt-l"}
                    name={"password"}
                    placeholder={"password"}
                    required={true}
                    type="password"
                    value={this.state.password}
                    onChange={(value) => {
                      this.setState({ password: value });
                    }}
                    canValidate={true}
                    validator={(value) => {
                      return this.state.deleteConrifmed &&
                        this.state.password === ""
                        ? false
                        : true;
                    }}
                    errorMessage={`Please enter your password in the field above`}
                  />
                )}
              </div>
              <DKButton
                className="bg-white text-black border-m fw-m ml-m"
                onClick={() => {
                  this.setState({
                    selectedDeleteOption: 0,
                    deleteAccount: "",
                    organisationName: "",
                    resetAccount: "",
                    password: "",
                    deleteConrifmed: false,
                  });
                }}
                title="Cancel"
                style={{ marginTop: 32 }}
              />
              <DKButton
                className="bg-orange text-white fw-m ml-m mt-5"
                onClick={() => {
                  this.setState({
                    deleteConrifmed: true,
                  });
                  if (
                    this.state.deleteAccount.trim() === "delete" &&
                    (this.state.password !== "" ||
                      this.state.iamUser.socialAuth)
                  ) {
                    this.setAccountDeletePopup();
                  }
                }}
                title="Delete"
                style={{ marginTop: 32 }}
              />
            </div>
          )}
        </div>
        {this.state.selectedDeleteOption !== 2 && (
          <div className="mt-m display-block ">
            <DKButton
              title="Delete account"
              className="bg-orange text-white fw-m text-wrap-none ml-xl"
              icon={ic_delete}
              onClick={() =>
                this.setState({
                  selectedDeleteOption: 2,
                  resetAccount: "",
                  deleteAccount: "",
                  organisationName: "",
                  password: "",
                  deleteConrifmed: false,
                })
              }
            />
          </div>
        )}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getResetCompanyView() {
    return (
      <div className="column parent-width bg-gray1 border-radius-s border-m pt-l pb-l pl-l pr-l parent-width mt-xxl mb-m border-box ">
        <div className="row row-responsive">
          <div className="column">
            <DKLabel text={t("RESET_COMPANY")} className="fw-m fs-m" />
            <DKLabel
              text={t("YOU_CAN_USE_THIS_FORM_TO_RESET")}
              className="text-gray mt-xs"
            />
          </div>
          {this.state.selectedDeleteOption !== 3 && (
            <div className="mt-m display-block ">
              <DKButton
                title={t("RESET_COMPANY")}
                className="bg-blue text-white fw-m text-wrap-none ml-xl"
                icon={ic_repeat}
                onClick={() => {
                  this.setState({
                    selectedDeleteOption: 3,
                    resetAccount: "",
                    deleteAccount: "",
                    organisationName: "",
                    password: "",
                    deleteConrifmed: false,
                  });
                }}
              />
            </div>
          )}
        </div>
        {this.state.selectedDeleteOption === 3 && (
          <div className="row parent-width align-items-start">
            <div style={{ width: "98%", maxWidth: 570 }}>
              <DKInput
                direction={"vertical"}
                title={
                  "To confirm resetting, type <i>reset</i> in the field below."
                }
                valueStyle={{ backgroundColor: "white" }}
                needHeader={false}
                className={
                  this.state.deleteConrifmed &&
                  "reset" !== this.state.resetAccount.trim()
                    ? "mb-m mt-m"
                    : "mt-m"
                }
                name={"reset"}
                placeholder={"reset"}
                required={true}
                value={this.state.resetAccount}
                onChange={(value) => {
                  this.setState({ resetAccount: value });
                }}
                canValidate={true}
                validator={(value) => {
                  return this.state.deleteConrifmed &&
                    "reset" !== this.state.resetAccount.trim()
                    ? false
                    : true;
                }}
                errorMessage={`Please enter 'reset' in the field above`}
              />
              <DKInput
                direction={"vertical"}
                title={"Enter your login password "}
                valueStyle={{ backgroundColor: "white" }}
                needHeader={false}
                className={"mb-l mt-l"}
                name={"reset-password"}
                placeholder={"password"}
                required={true}
                type="password"
                value={this.state.password}
                onChange={(value) => {
                  this.setState({ password: value });
                }}
                canValidate={true}
                validator={(value) => {
                  return this.state.deleteConrifmed &&
                    this.state.password === ""
                    ? false
                    : true;
                }}
                errorMessage={`Please enter your password in the field above`}
              />
            </div>
            <DKButton
              className="bg-white text-black border-m fw-m ml-m"
              onClick={() => {
                this.setState({
                  selectedDeleteOption: 0,
                  resetAccount: "",
                  deleteAccount: "",
                  organisationName: "",
                  deleteConrifmed: false,
                  password: "",
                });
              }}
              title="Cancel"
              style={{ marginTop: 32 }}
            />
            <DKButton
              className="bg-blue text-white fw-m ml-m"
              onClick={() => {
                this.setState({
                  deleteConrifmed: true,
                });
                if (
                  this.state.resetAccount.trim() === "reset" &&
                  this.state.password !== ""
                ) {
                  this.deleteActionClick(ACTION_KEY.RESET_COMPANY);
                }
              }}
              title="Reset"
              style={{ marginTop: 32 }}
            />
          </div>
        )}
      </div>
    );
  }

  getTitle = (key) => {
    if (key === ACTION_KEY.DELETE_COMAPANY) {
      return "Delete Company?";
    } else if (key === ACTION_KEY.RESET_COMPANY) {
      return "Reset Company?";
    }
  };

  getDescription = (key) => {
    if (key === ACTION_KEY.DELETE_COMAPANY) {
      return t("THIS_WILL_DELETE_YOUR_COMPANY");
    } else if (key === ACTION_KEY.RESET_COMPANY) {
      return t("THIS_WILL_RESET_YOUR_COMPANY");
    }
  };

  getButtonTitle = (key) => {
    if (key === ACTION_KEY.DELETE_COMAPANY) {
      return t("DELETE");
    } else if (key === ACTION_KEY.RESET_COMPANY) {
      return t("RESET");
    }
  };

  deleteAccountPopup = () => {
    return (
      <DeleteAccountPopup
        onCancel={this.cancelTapped}
        continueTapped={this.deleteAccount}
        onSelect={this.onDeleteReasonSelect}
        deleteAccState={this.state.deleteAccState}
        onInputValueChange={this.onDeleteReasonValueChange}
      />
    );
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getCustomDomainStatus = () => {
    CRM.getCustomDomainStatus(WEBSITE_TYPE.HELP_DESK).then(
      (data) => {
        if (!Utility.isEmpty(data)) {
          this.setState({ helpCenterDetails: data });
        }
      },
      (res) => {
        if (res.code === 404) {
          this.setState({ helpCenterDetails: null });
        }
      }
    );
    CRM.getCustomDomainStatus(WEBSITE_TYPE.BUILDER).then(
      (data) => {
        if (!Utility.isEmpty(data)) {
          this.setState({ builderDetails: data });
        }
      },
      (res) => {
        if (res.code === 404) {
          this.setState({ builderDetails: null });
        }
      }
    );
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getHelpCenterView() {
    const isHelpCenterSetUp = !Utility.isEmpty(this.state.helpCenterDetails);
    const isCustomDomainAvailable =
      isHelpCenterSetUp &&
      !Utility.isEmpty(this.state.helpCenterDetails.domain);
    return (
      <div className="pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width column mt-l">
        {this.getHeader(WEBSITE_TYPE.HELP_DESK)}
        {!isHelpCenterSetUp && this.getEmptyState(WEBSITE_TYPE.HELP_DESK)}
        {isHelpCenterSetUp &&
          !isCustomDomainAvailable &&
          this.getCustomDomainInput(
            this.state.customDomainHC,
            this.onHelpCenterDomainChange,
            this.saveHelpCenterDomain,
            this.state.isCustomDomainHCValid
          )}
        {isCustomDomainAvailable &&
          !this.state.helpCenterDetails.is_verified &&
          this.getMappingWarningView(this.state.helpCenterDetails.domain)}
        {isCustomDomainAvailable &&
          this.state.helpCenterDetails.is_verified &&
          this.getSetUpConfirmView(this.state.helpCenterDetails.domain)}
      </div>
    );
  }
  onHelpCenterDomainChange = (data) => {
    this.setState({
      customDomainHC: data,
      isCustomDomainHCValid: this.validateDomainName(data),
    });
  };
  getWebsiteBuilderView() {
    const isWebsiteBuilderSetUp = !Utility.isEmpty(this.state.builderDetails);
    const isCustomDomainAvailable =
      isWebsiteBuilderSetUp &&
      !Utility.isEmpty(this.state.builderDetails.domain);
    return (
      <div className="pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width column mt-l">
        {this.getHeader(WEBSITE_TYPE.BUILDER)}
        {!isWebsiteBuilderSetUp && this.getEmptyState(WEBSITE_TYPE.BUILDER)}
        {isWebsiteBuilderSetUp &&
          !isCustomDomainAvailable &&
          this.getCustomDomainInput(
            this.state.customDomainWB,
            this.onWebsiteBuilderDomainChange,
            this.saveBuilderCustomDomain,
            this.state.isCustomDomainWBValid
          )}
        {isCustomDomainAvailable &&
          !this.state.builderDetails.is_verified &&
          this.getMappingWarningView(this.state.builderDetails.domain)}
        {isCustomDomainAvailable &&
          this.state.builderDetails.is_verified &&
          this.getSetUpConfirmView(this.state.builderDetails.domain)}
      </div>
    );
  }
  onWebsiteBuilderDomainChange = (data) => {
    this.setState({
      customDomainWB: data,
      isCustomDomainWBValid: this.validateDomainName(data),
    });
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getMappingWarningView(domain) {
    return (
      <div className=" bg-deskera-secondary border-radius-s border-m pt-l pb-l pl-l pr-l mt-xxl border-box row row-responsive">
        <DKLabel
          style={{ width: "80%" }}
          text={`If you have not done the setup already, go to your Domain's DNS control panel and add a CNAME record for <b>${domain}</b> pointing to <b>${"helpcenter1.deskera.com"}</b> with TTL of minimum 300 seconds. The DNS changes may take a few minutes to 4-6 hours to reflect. Please check again after some time for the status.`}
        />
      </div>
    );
  }
  getSetUpConfirmView(domain) {
    return (
      <div className="bg-gray1 border-radius-s border-m pt-l pb-l pl-l pr-l mt-xxl border-box row row-responsive">
        <DKLabel
          text={`Your custom domain setup is ready!.<a target="_blank" href="https://${domain}">Click here</a> to check.`}
        />
      </div>
    );
  }
  getCustomDomainInput(value, onChange, onSave, validFlag) {
    return (
      <>
        <DKLabel className="mt-xl fs-m fw-m" text="Custom Domain" />
        <div className="row parent-width align-items-baseline">
          <div className="row parent-width">
            <div style={{ width: "98%", maxWidth: 570 }}>
              <DKInput
                needHeader={false}
                className="mt-m"
                name="www.yourdomain.com"
                placeholder="www.yourdomain.com"
                required
                value={value}
                onChange={(data) => onChange(data)}
                canValidate={!validFlag}
                invalid={!validFlag}
                validator={(text) => {
                  return this.validateDomainName(text);
                }}
              />
            </div>
            <div className="mt-m">
              <DKButton
                className="bg-blue text-white border-m fw-m ml-xl"
                onClick={onSave}
                title="Save"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
  getHeader(domainType) {
    if (domainType === WEBSITE_TYPE.HELP_DESK) {
      return (
        <div className="row justify-content-between">
          <div>
            <DKLabel
              text={t("CUSTOM_DOMAIN_SETUP") + " - HelpCenter"}
              className="fw-m fs-m"
            />
            <DKLabel
              text={t("MAP_YOUR_CUSTOM_DOMAIN")}
              className="text-gray mt-xs"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="row justify-content-between">
          <div>
            <DKLabel
              text={t("CUSTOM_DOMAIN_SETUP") + " - Website"}
              className="fw-m fs-m"
            />
            <DKLabel
              text={t("MAP_YOUR_CUSTOM_DOMAIN_TEXT")}
              className="text-gray mt-xs"
            />
          </div>
        </div>
      );
    }
  }
  getEmptyState(domainType) {
    if (domainType === WEBSITE_TYPE.HELP_DESK) {
      return (
        <div className="bg-gray1 border-radius-s border-m pt-l pb-l pl-l pr-l mt-xl border-box row row-responsive">
          <DKLabel
            text={`You have not published a helpcenter domain yet. <a href=${ApiConstants.PRODUCT_URL_CRM_PLUS} target="_blank">Click here</a> to create your helpcenter domain.`}
          />
        </div>
      );
    } else {
      return (
        <div className="bg-gray1 border-radius-s border-m pt-l pb-l pl-l pr-l mt-xl border-box row row-responsive">
          <DKLabel
            text={`You have not published a website yet. <a href=${ApiConstants.PRODUCT_URL_WEBSITE_BUILDER} target="_blank">Click here</a> to create your website and publish.`}
          />
        </div>
      );
    }
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  saveHelpCenterDomain = () => {
    if (!this.validateDomainName(this.state.customDomainHC)) {
      this.setState({ isCustomDomainHCValid: false });
      return;
    }
    let dataToSave = {
      ...this.state.helpCenterDetails,
      domain: this.state.customDomainHC,
    };
    CRM.saveCustomDomain(WEBSITE_TYPE.HELP_DESK, dataToSave)
      .then((res) => {
        this.setState({ helpCenterDetails: dataToSave });
      })
      .catch((err) => {});
  };
  saveBuilderCustomDomain = () => {
    if (!this.validateDomainName(this.state.customDomainWB)) {
      this.setState({ isCustomDomainWBValid: false });
      return;
    }
    let dataToSave = {
      ...this.state.builderDetails,
      domain: this.state.customDomainWB,
    };
    CRM.saveCustomDomain(WEBSITE_TYPE.BUILDER, dataToSave).then(
      (data) => {
        this.setState({ builderDetails: dataToSave });
      },
      (err) => {}
    );
  };
  validateDomainName = (value) => {
    return (
      !Utility.isEmpty(value) &&
      Utility.validateAgainstPattern(value, REGEX.WEBSITE_DOMAIN)
    );
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  addOrgTapped = () => {
    this.setState({
      needAddNewOrgPopup: true,
    });
    this.hideOrgList();
  };

  orgCancelTapped = () => {
    this.setState({
      needAddNewOrgPopup: false,
    });
    this.showCompanyDeletedAlert();
  };

  getAddNewOrgPopup() {
    return <AddOrgPopup onCancel={this.orgCancelTapped} />;
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  onSwitchOrgClick = () => {
    this.setState({
      switchOrgButtonTapped: true,
    });
    this.getUpdatedOrgList();
  };

  getUpdatedOrgList = () => {
    if (this.state.allTenantResReceived) {
      this.showOrgList();
      return;
    }

    if (this.state.switchOrgButtonTapped) {
      showLoader(`Fetching ${t("ORGANISATIONS")}...`);
    }

    Tenant.getUsertenants().then(
      (res) => {
        removeLoader();
        let orgNames = [],
          ids = [];
        let orgs = CompanyDetailManager.getAllOrgs();
        for (let index = 0; index < orgs.length; index++) {
          orgNames.push(orgs[index].tenantName);
          ids.push(orgs[index].tenantId);
        }

        this.setState(
          {
            allOrgs: orgNames,
            tenantIds: ids,
            allTenantResReceived: true,
          },
          () => {
            if (this.state.switchOrgButtonTapped) {
              this.showOrgList();
            }
          }
        );
      },
      (err) => {
        removeLoader();
      }
    );
  };

  showOrgList = () => {
    this.setState({ needOrgList: true });
  };
  hideOrgList = () => {
    this.setState({ needOrgList: false });
  };

  getOrgList() {
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ padding: 0, width: 280 }}>
          <OrgList
            allOrgs={this.state.allOrgs}
            tenantIds={this.state.tenantIds}
            // onAddOrgClick={this.addOrgTapped}
            // onClose={this.showCompanyDeletedAlert}
          />
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
}
export default withTranslation()(SettingsDashboard);
