import React, { Component } from 'react';

import {
  DKListPicker
} from "deskera-ui-library";

import CurrencyManager from '../../managers/CurrencyManager';

/*
PROPS:
- onAddOrgClick
- onClose
- allOrgs
- tenantIds
*/

class CurrencyPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
            Currencies : CurrencyManager.getAllCurrencies(),
      CurrencySelected : props.CurrencySelected
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      CurrencySelected : nextProps.CurrencySelected
    });
  }
  render() {
    return (
      <div>
        <DKListPicker
          title="Currency"
          data={this.state.Currencies}
          onSelect={(index, value) => {
            this.state.CurrencySelected(value);
          }}
          onClose={this.props.onClose}
          needIcon={true}
        />
      </div>
    );
  }
}

export default CurrencyPicker;
