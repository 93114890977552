import TableDataParser from "../Helper/TableDataParser";
export const TABLES = {
  PIPELINE: "pipeline",
  STAGE: "stage",
  CONTACT: "contact",
  DEAL: "deal",
  SEGMENT: "segment",
  ACTIVITY: "activity"
};
export const COLUMN_CODE = {
  CONTACT: {
    NAME: `name`,
    EMAIL: `email`,
    TYPE: `type`,
    PHONE: `phone`,
    ORGANISATION: `organization`,
    ADDRESS: `address`,
    SEGMENT: `segment`,
    STATUS: `status`,
    LABEL: `label`,
    PROFILE_PIC: `profile_pic`,
    ATTACHMENT: `attachment`,
    SOURCE: `source`,
    BOOKS_CONTACT_ID: `books_contact_id`,
    SOURCE_ID: `source_id`,
    OWNER_ID: `owner_id`,
    SUB_OWNER_ID: `sub_owner`,
    ACCOUNT: 'account_id',
    CREATED_AT: `created_at`,
    NOTE: `note`
  },
  PIPELINE: {
    NAME: `name`,
    STAGE_ID_S: `stage_id_s`,
    IS_DEFAULT: `is_default`,
  },
  STAGES: {
    NAME: `name`,
    ORDER: `order`,
    WON_STAGE: `won_stage`,
    LOST_STAGE: `lost_stage`,
    HELP_TEXT: `help_text`,
  },
  DEAL: {
    NAME: `name`,
    CLOSING_DATE: `closing_date`,
    STAGE_ID: `stage_id`,
    PIPELINE_ID: `pipeline_id`,
    AMOUNT: `amount`,
    CONTACT_ID: `contact_id`,
    DEAL_DATE: `deal_date`,
    OWNER_ID: `owner_id`,
    SUB_OWNER_ID: `sub_owner`,
    VISIBILITY: `visibility`,
    ORGANIZATION_ID: `organization_id`,
    STATUS: `status`,
    REASON: `reason`,
    SOURCE: 'source',
    SOURCE_ID: 'source_id',
    NOTE: `note`,
    ATTACHMENT: `attachment`,
    CREATED_AT: `created_at`
  },
};
export const TABLE_DISPLAY_NAME = {
  [TABLES.DEAL]: `Deals`,
  [TABLES.PIPELINE]: 'Pipeline',
  [TABLES.STAGE]: 'Stage'
};
export class TableManger {
  static tableMap = new Map();
  static tableIdNameMap = new Map();
  static reportTablesData = null;
  static getAllReportsTableData() {
    return TableManger.reportTablesData;
  }
  static setAllReportsTableData(data) {
    TableManger.reportTablesData = data;
  }
  static populateViewMap(data) {
    data.forEach((item) => {
      const parsedTableData = TableDataParser.parseTableData(item);
      this.tableIdNameMap.set(item._id, item.name);
      this.setTableMap(parsedTableData, item.name);
    });

  }
  static setTableMap(parsedTableData, tableName) {
    this.tableMap.set(tableName, parsedTableData);

    if (tableName === TABLES.CONTACT) {
      this.tableMap.set(TABLES.SEGMENT, parsedTableData);
    } else if (tableName === TABLES.SEGMENT) {
      this.tableMap.set(TABLES.CONTACT, parsedTableData);
    }
  }
  static getTableId(tableName) {
    return this.tableMap?.get(tableName)?.id;
  }
  static getColumnId(tableName, columnCode) {
    let column = this.getColumn(tableName, columnCode);
    return column ? column.id : undefined;
  }
  static getColumn(tableName, columnCode) {
    return this.getTableColumns(tableName)?.find(
      (column) => column.columnCode === columnCode
    );
  }
  static getTableColumns(tableName) {
    return this.tableMap.get(tableName)?.columns;
  }
  
}