import { useEffect, useState } from 'react';
import { DKLabel, DKIcon, DKLine, DKButton } from 'deskera-ui-library';
import ic_erp_fill_bg from '../../assets/productIcons/new/ic-erp-fill-bg.png';
import ic_more from '../../assets/icons/ic_more.png';
import ic_coupon from '../../assets/icons/ic_coupon.png';
import BillingHistory from './BillingHistory';
import CreditCodePopup from './CreditCodePopup';
import PrepaidCodePopup from "../common/PrepaidCodePopup";
import RouteManager, { PAGE_ROUTES } from '../../managers/RouteManager';

const PlanSection = ({
	creditData,
	totalAvailableCredits,
	nextChargeDate,
	onCodeApplied,
	routeInfo
}) => {
	const [showCodePopup, setShowCodePopup] = useState(false);
	const [showPrepaidCodePopup, setShowPrepaidCodePopup] = useState(false);
	const [resetRoute, setResetRoute] = useState(false);

	useEffect(() => {
		if (routeInfo?.path ===  PAGE_ROUTES.BILLING_WITH_ACTION && routeInfo?.isExact) {
			if (routeInfo?.params?.action === 'add-credit') {
				setShowCodePopup(true);
				setResetRoute(true);
			}
		}
	}, [routeInfo])
	

	let nextChargeOn = '-';

	try {
		if (nextChargeDate) {
			nextChargeOn = new Date(nextChargeDate)?.toDateString();
		}
	} catch (error) {
		nextChargeOn = '-';
	}
	const sortCreditData = () => {
		if (creditData?.length > 0) {
			try {
				return creditData?.sort(
					(a, b) => new Date(b.AppliedDate) - new Date(a.AppliedDate)
				);
			} catch (error) {
				return creditData;
			}
		}
		return [];
	};
	const currentAppliedCode = sortCreditData(creditData)?.[0];
	const renderCards = () => {
		return (
			<div
				className="parent-width row align-items-stretch"
				style={{ columnGap: 18 }}
			>
				<div
					className="column bg-white p-xxl border-m border-radius-m"
					style={{ width: '50%' }}
				>
					<div className="row justify-content-between">
						<DKLabel text="Active Plan" className="row fs-xl" />
						{/* <DKIcon
							src={ic_more}
							onClick={() => {}}
							className="cursor-hand ic-s"
							style={{ opacity: 0.5 }}
						/> */}
					</div>
					<div className="row align-items-start mt-l justify-content-between">
						<div className="column">
							<div className="row">
								<div className="row">
									<DKIcon
										src={ic_erp_fill_bg}
										className=" mr-r"
										onClick={() => {}}
									/>
									<DKLabel
										text="Deskera ERP"
										className="row fs-l fw-m"
									/>
								</div>
								<div className="column"></div>
							</div>
							<div className="column mt-l">
								<div className="row fs-m">Total Credits</div>
								<div className="row mt-r fw-b fs-l">
									{totalAvailableCredits} Credits
								</div>
							</div>
						</div>
						<DKLine style={{ height: '100%', width: 2 }} />
						<div className="column">
							<DKLabel
								text="Billing Period"
								className="row justify-content-end fs-m fw-m"
							/>
							<div className="column mt-s parent-width">
								<div className="row">
									<DKLabel
										text="Billed Monthly"
										className="row fs-m text-gray justify-content-end"
									/>
								</div>
								<DKLine className="mt-s" />
							</div>
							{creditData?.length > 0 && <div className="column mt-s">
								<DKLabel
									text="Next Charge On"
									className="row justify-content-end fs-m fw-m"
								/>
								<DKLabel
									text={nextChargeOn}
									className="row fs-m text-gray justify-content-end mt-s"
								/>
							</div>}
						</div>
					</div>
					<div className="row mt-m justify-content-between">
						{/* <DKButton
							title={'Manage Plan'}
							className={'bg-blue p-s text-white border-radius-l'}
							onClick={() => {}}
						/> */}
						{/* <div className="text-gray">Payment ID: #90322</div> */}
					</div>
				</div>
				<div
					className="bg-white column p-xxl border-m border-radius-m"
					style={{ width: '50%' }}
				>
					<div className="row ">
						<DKIcon src={ic_coupon} className="mr-s ic-s-2" />
						<DKLabel
							text={'Applied credits'}
							className=" fs-m fw-m"
						/>
					</div>
					<div className="row ">
						<DKLabel
							text={'See your recent credit history'}
							className=" text-gray mt-xs mb-xl ml-xl"
						/>
					</div>

					<>
						<div className="column parent-width flex-1 justify-content-center border-box pl-xl parent-width pr-xl">
							{creditData?.length !== 0 && (
								<>
									<DKLabel
										text={`Code: ${currentAppliedCode?.Code}`}
										className="fs-m fw-m"
									/>
									<DKLabel
										text={`Credits Received ${currentAppliedCode?.CreditsAvailable} credits`}
										className="text-gray mt-xs "
									/>
									<DKLabel
										text={`Applied on: ${new Date(
											currentAppliedCode?.AppliedDate
										)?.toDateString()}`}
										className="text-gray mt-xs "
									/>
								</>
							)}
							{creditData?.length === 0 && (
								<DKLabel
									text={'No code found'}
									className="text-gray mt-xs parent-width text-align-center"
								/>
							)}
						</div>
					</>
					<div className="row justify-content-between mt-l">
						<DKButton
							className="column bg-white text-blue cursor-hand fw-m"
							title={'+ Redeem credit code'}
							onClick={() => {
								setShowCodePopup(true);
							}}
						/>
						<DKButton
							className="column bg-white text-blue cursor-hand fw-m"
							title={'+ Redeem prepaid code'}
							onClick={() => {
								setShowPrepaidCodePopup(true);
							}}
						/>
					</div>
				</div>
			</div>
		);
	};
	return (
		<div className="column parent-size pb-xl">
			{renderCards()}
			<BillingHistory creditData={sortCreditData(creditData)} />
			{showCodePopup && (
				<CreditCodePopup
					onClose={() => {
						setShowCodePopup(false);
						if(resetRoute) {
							RouteManager.navigateToPage(PAGE_ROUTES.BILLING);
						}
					}}
					onCodeApplied={(response) => {
						setShowCodePopup(false);
						onCodeApplied(response);
						if(resetRoute) {
							RouteManager.navigateToPage(PAGE_ROUTES.BILLING);
						}
					}}
				/>
			)}
			{showPrepaidCodePopup && (
				<PrepaidCodePopup
					onClose={() => setShowPrepaidCodePopup(false)}
					onCouponApply={() => {}}
				/>
			)}
		</div>
	);
};

export default PlanSection;
