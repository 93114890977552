export const color_yellow = "rgb(255, 198, 73)";
export const color_yellow_opaque = "rgba(255, 198, 73, 0.6)";
export const color_blue = "rgb(66, 133, 245)";
export const color_yellow_light = "rgb(254, 246, 225)"; //'rgb(255, 231, 195)'; //
export const color_gray_light = "rgb(240, 240, 240)";
export const color_gray_line = "rgb(220, 220, 220)";
export const color_green = "rgb(133, 197, 36)";

export const COLORS = {
  deskera: "rgb(255, 198, 74)",
  deskera_secondary: "rgb(254, 246, 225)",
  primary: "black",
  secondary: "white",
  text_primary: "#343a40",
  text_secondary: "white",
  white: "white",
  black: "black",
  gray: "rgb(160,160,160)",
  dark_gray: "rgb(60, 60, 60)",
  maroon: "rgb(174, 47, 2)",
  orange: "rgb(240, 126, 37)",
  erp: "rgb(46 75 198)",
  erp_secondary: "rgb(241, 243, 255)",
  crm: "rgb(82 0 82)",
  crm_secondary: "rgb(230, 247, 238)",
  people: "rgb(26 175 93)",
  people_secondary: "rgb(254, 247, 242)",
  aio: "rgb(8, 18, 90)",
  aio_secondary: "rgb(240, 243, 255)",
  mrp: "rgb(240 125 37)",
  pricing: "rgb(255, 198, 74)",
  blue: "rgb(28, 115, 232)",
  charts: {
    bar_vertical: "rgb(38, 144, 240)",
    bar_horizontal: "rgb(52, 195, 161)",
    line: {
      green: "rgb(52, 195, 161)",
      red: "rgb(240, 100, 111)",
      yellow: "rgb(245, 200, 60)",
    },
    pie: [
      "rgb(130, 112, 234)",
      "rgb(250, 200, 85)",
      "rgb(225, 105, 130)",
      "rgb(52, 195, 161)",
      "rgb(143, 232, 236)",
      "rgb(38, 144, 240)",
      "rgb(240, 100, 111)",
      "rgb(245, 200, 60)",
      "rgb(160,160,160)",
    ],
  },
};
