import ApiConstants from '../constants/ApiConstants';
import ApiManager from '../managers/ApiManager';
import { API_STATUS } from '../constants/Enum';

export default class CRMFormAPIs extends ApiManager {
    // ApiConstants.URL.BASE + ApiConstants.URL.CRM_FORM + FormManager.getFormID(FORM_TYPE.BOOK_A_DEMO),
    // "https://api-staging.deskera.xyz/v1/crm/forms/submit/" + FormManager.getFormID(FORM_TYPE.BOOK_A_DEMO),
    submitCRMForm(formID, formData, success, failed) {
       return fetch(ApiConstants.URL.BASE + ApiConstants.URL.CRM.CRM_FORM + formID, ApiManager.getApiRequestOptions('POST', formData))
            .then((response) => {
                if (response.status === API_STATUS.SUCCESS_200 || response.status === API_STATUS.SUCCESS_201) {
                    return { success: true };
                } else {
                    return { success: false };
                }
            })
            .then((data) => {
                if (data.success) {
                    success(data);
                } else {
                    failed({ error: 'Something went wrong, please try again later' });
                }
            })
            .catch((error) => {
                failed('Unexpected error, please try again!');
            });
    }
}
