import React, { Component } from "react";

import {
  DKLabel,
  BarGraph,
  HorizontalBar,
  LineChart,
  PieChart,
  COLORS,
  DKSpinner,
  DKIcon
} from "deskera-ui-library";

import { CHART_TYPE, CHART_API_SOURCES } from "../../../constants/Enum";

import chart_bar_horizontal from "../../../assets/charts/bar_horizontal.png";
import chart_bar_vertical from "../../../assets/charts/bar_vertical.png";
import chart_line from "../../../assets/charts/line.png";
import chart_pie from "../../../assets/charts/pie.png";
import Reports from "../../../services/reports";
import DataParser from "../../../Helper/DataParser";
import ReportManager from "../../../managers/ReportManager";
import Utility from "../../../utility/Utility";

import ic_down_arrow from "../../../assets/icons/ic_arrow_down2.png";
import ic_up_arrow from "../../../assets/icons/ic_arrow_up2.png";

import SubscriptionManager from "../../../managers/SubscriptionManager";
import { numberFormatter } from "../../common/CommonUtils";
import getSymbolFromCurrency from "currency-symbol-map";
import CompanyDetailManager from "../../../managers/CompanyDetailManager";
import { CHART_INTERVALS } from "../../../constants/Constant";
import PermissionManager from "../../../managers/PermissionManager";
import UserManager from "../../../managers/UserManager";

/*
PROPS
- title
- type
- apiSource
- isLastInRow
*/
class ChartHolder extends Component {
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      apiResponseReceived: false,
      needFilterOptions: false,
      selectedFilterIndex: 0,
      width: this.getWidth(),
      height: this.getHeight(),
    };
  }

  setSize = () => {
    this.setState({
      width: this.getWidth(),
      height: this.getHeight(),
    });
  };

  getWidth() {
    return (window.innerWidth - 340) / 3;
  }
  getHeight() {
    return this.getWidth() / 1.88;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  componentDidMount() {
    let filter;
    if(this.getFilterValues()&& this.getFilterValues()[this.state.selectedFilterIndex]) {
      filter = {value:this.getFilterValues()[this.state.selectedFilterIndex],index:this.state.selectedFilterIndex}
    }
    this.getDataFromAPI(filter);
    window.addEventListener("resize", this.setSize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.setSize);
  }

  getDataFromAPI(filterOption = null) {
    this.setState({ apiResponseReceived: false });
    switch (this.props.apiSource) {
      case CHART_API_SOURCES.INVOICES:
        this.getInvoicesReport();
        break;
      case CHART_API_SOURCES.AGE_RECEIVABLE:
        this.getAgeReceivable();
        break;
      case CHART_API_SOURCES.BANK_ACCOUNTS:
        this.getBankAccounts(filterOption);
        break;
      case CHART_API_SOURCES.EXPENSES_CLAIMED:
        this.getExpenseClaimed(filterOption);
        break;
      case CHART_API_SOURCES.PAYROLL:
        this.getPayroll(filterOption);
        break;
      case CHART_API_SOURCES.LEAVES_PER_MONTH:
        this.getLeavesPerMonth(filterOption);
        break;
      case CHART_API_SOURCES.PIPELINES:
        this.getPipelineViewData(filterOption);
        break;
      case CHART_API_SOURCES.CONTACTS:
        this.getContactsReport(filterOption);
        break;
      case CHART_API_SOURCES.WON_AND_LOST_DEALS:
        this.getDealsData(filterOption);
        break;
      case CHART_API_SOURCES.MY_CATEGORY_BREAKDOWN:
        this.getExpenseBreakDown();
        break;
      default:
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  setData = (data) => {
    if(data) {
      this.setState({
        data: data,
        apiResponseReceived: true,
      });
    }
    
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  getColumnData = () => {
    const type = this.props.type;
    const title = this.props.title;

    let columnData = [
      {
        name: "value",
        color: COLORS.charts.bar_vertical,
        title: title,
      },
    ];
    let columnData_horizontal = [
      {
        name: "value",
        color: COLORS.charts.bar_horizontal,
        title: title,
      },
    ];
    let columnData_line = [
      {
        name: "value",
        color: COLORS.charts.line.green,
      },
      {
        name: "value2",
        color: COLORS.charts.line.red,
      },
    ];

    if (this.props.apiSource === CHART_API_SOURCES.AGE_RECEIVABLE) {
      columnData_horizontal = [
        {
          name: "value",
          color: COLORS.orange,
        },
      ];
    }

    if (
      this.props.apiSource === CHART_API_SOURCES.BANK_ACCOUNTS ||
      this.props.apiSource === CHART_API_SOURCES.EXPENSES_CLAIMED
    ) {
      columnData_line = [
        {
          name: "value",
          color: COLORS.charts.line.yellow,
        },
      ];
    }

    if (type === CHART_TYPE.BAR_HORIZONTAL) {
      columnData = columnData_horizontal;
    }
    if (type === CHART_TYPE.LINE) {
      columnData = columnData_line;
    }

    return columnData;
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    const dataAvailable = !Utility.isEmpty(this.state.data);
    const isLastInRow = this.props.isLastInRow;

    let width = this.state.width;
    let height = this.state.height;

    return (
      <div className="chart-width">
      <div
        className={
          "column pl-r pr-r pt-r pb-r bg-white border-m shadow-ss border-radius-s position-relative " +
          (isLastInRow ? "" : "")
        }
        /*
        width: "32%",
          minWidth: CHART_WIDTH,
          height: CHART_HEIGHT,
        */
        style={{
          height: height,
          minWidth: 290,
          minHeight: 270,
          margin: "15px 15px 15px 0px"
        }}
      >
        {this.getHeader()}

        <div className="column parent-width parent-height align-items-center justify-content-center">
        {dataAvailable && this.getChartHeader()}
          {dataAvailable && this.getGraph()}
          {!dataAvailable && (
            <img
              className={dataAvailable ? "" : "blur"}
              src={this.getChartImage()}
              alt=""
              style={{ height: "80%", width: "80%" }}
            />
          )}
        </div>
        {(!dataAvailable || !this.state.apiResponseReceived) && (
          <div
            className="column parent-width parent-height align-items-center justify-content-center position-absolute"
            style={{ top: 0, left: 0 }}
          >
            <div>
              <div className="row pt-s pb-s pl-s pr-s bg-white border-radius-m shadow-m border-s">
                {!this.state.apiResponseReceived && (
                  <DKSpinner className="mr-r" />
                )}
                <DKLabel
                  text={
                    this.state.apiResponseReceived
                      ? "No data found"
                      : "Getting data..."
                  }
                  className=" text-wrap-none"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  getHeader() {
    return (
      <div className="row justify-content-between z-index-2">
        <DKLabel text={this.props.title} className="fw-m fs-m" />
        {!Utility.isEmpty(this.getFilterValues()) && (
          <div className="bg-white border-s border-radius-s pl-s pt-xs pb-xs pr-s position-relative">
            <div
              className="cursor-hand row" style={{justifyContent:'flex-end'}}
              onClick={this.changeFilterOptionsVisibility}
            >
              <DKLabel
                className="unselectable"
                text={
                  this.getFilterValues()[this.state.selectedFilterIndex].name
                }
              />
              <DKIcon
                src={this.state.needFilterOptions ? ic_up_arrow : ic_down_arrow}
                className="ic-xs-2 ml-xs"
              />
            </div>
            {this.state.needFilterOptions && (
              <div
                className="position-absolute columne bg-white border-s shadow-m pt-m pl-r pr-r z-index-2 border-box"
                style={{ right: 0, top: 26, width:'max-content' }}
              >
                {this.getFilterOptionsView()}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  changeFilterOptionsVisibility = () => {
    this.setState({
      needFilterOptions: !this.state.needFilterOptions,
    });
  };
  showFilterOptions = () => {
    this.setState({
      needFilterOptions: true,
    });
  };
  hideFilterOptions = () => {
    this.setState({
      needFilterOptions: false,
    });
  };

  getFilterOptionsView() {
    let options = this.getFilterValues();
    let arr = [];

    options.forEach((obj, index) => {
      arr.push(
        <div
          className="pb-m bg-white  cursor-hand"
          onClick={() => {
            this.hideFilterOptions();
            this.getDataFromAPI({ value: obj, index: index });
          }}
        >
          <DKLabel text={obj.name} className="text-wrap-none" />
        </div>
      );
    });

    return arr;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  getChartImage() {
    switch (this.props.type) {
      case CHART_TYPE.BAR_VERTICAL:
        return chart_bar_vertical;
      case CHART_TYPE.BAR_HORIZONTAL:
        return chart_bar_horizontal;
      case CHART_TYPE.LINE:
        return chart_line;
      case CHART_TYPE.PIE:
        return chart_pie;
      case CHART_TYPE.DONUT:
        return chart_pie;
      default:
        return chart_bar_vertical;
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  getGraph() {
    const type = this.props.type;
    const data = this.state.data;
    let currency = getSymbolFromCurrency(CompanyDetailManager.getCurrencyCode());
    currency = currency ? currency:'';
    if (type === CHART_TYPE.BAR_VERTICAL) {
      return <BarGraph
        data={data}
        height={160}
        tooltipValueFormatter={(value)=> this.props.title === "Leaves Per Month" ? value : currency+ " "+ numberFormatter(value,2)}
      />
    }

    if (type === CHART_TYPE.BAR_HORIZONTAL) {
      return <HorizontalBar
        data={data}
        height={160}
        tooltipValueFormatter={(value)=> this.props.title === "Pipeline View" ? value : currency+ " "+ numberFormatter(value,2)}
      />
    }

    if (type === CHART_TYPE.LINE ) {
      return <LineChart
        data={data}
        height={160}
        showLegend={true}
        displayValueFormatter={(value) => (this.props.title === "Bank Account" || this.props.title === "Expense Claimed" || this.props.title ==="Payroll") ? numberFormatter(value,2) : value}
        tooltipValueFormatter={(value)=> (this.props.title === "Bank Account" || this.props.title === "Expense Claimed" || this.props.title ==="Payroll") ? currency+ " "+ numberFormatter(value,2) : value}
      />
    }

    if (type === CHART_TYPE.PIE ) {
      return <PieChart
        chartConfig={{
          arcWidth: 0.7,
          size: 160
        }}
        showLegend={true}
        dataSet={data}
        displayValueFormatter={(value) => (this.props.title === "Payroll"|| this.props.title === "Aged Receivable" || this.props.title === "Expense Claims") ? currency+ " "+ numberFormatter(value,2) : value}
        tooltipValueFormatter={(value)=> (this.props.title === "Payroll" || this.props.title === "Aged Receivable") ?  currency+ " "+ numberFormatter(value,2) : (this.props.title === "Expense Claims" ? `${ ((value*100)/(ReportManager.getExpenseBreakDown()?.totalClaimAmount)).toFixed(2) }%`  :value)}
      />
    }

    return <div />;
  }
  
  getChartHeader() {
    let currency = getSymbolFromCurrency(CompanyDetailManager.getCurrencyCode());
    currency = currency ? currency:'';
    if(this.props.title === "Expense Claimed") {
      return (<div className="row justify-content-between mt-m">
          <div>
          <DKLabel text="Total Claim Amount"/>
          <DKLabel className="fw-m" text={`${currency} ${ReportManager.getExpenseClaimAmount().totalClaimAmount}`}/>
          </div>
          <div>
          <DKLabel text="Highest Claim Amount"/>
          <DKLabel className="fw-m" text={`${currency} ${ReportManager.getExpenseClaimAmount().maxClaimAmount}`}/>
          </div>

      </div>)
    } else {
      return 
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  getFilterValues() {
    switch (this.props.apiSource) {
      case CHART_API_SOURCES.LEAVES_PER_MONTH:
        return this.getDataForLPM();
      case CHART_API_SOURCES.PIPELINES:
        return ReportManager.getPipelineFilterData().map((pipeline) => {
          return {
            name: pipeline.name,
            value: pipeline.id,
          };
        });
      case CHART_API_SOURCES.BANK_ACCOUNTS:
        return ReportManager.getBankNames();
      case CHART_API_SOURCES.EXPENSES_CLAIMED:
        return [
          {
            name: "Year",
            value: "Year",
          },
          {
            name: "Month",
            value: "Month",
          },
          {
            name: "Quarter",
            value: "Quarter",
          },
          {
            name: "Week",
            value: "Week",
          },
        ];
      case CHART_API_SOURCES.CONTACTS:
      case CHART_API_SOURCES.WON_AND_LOST_DEALS:
        return CHART_INTERVALS;
      case CHART_API_SOURCES.PAYROLL:
        if(PermissionManager.isUserManagementAccessible()) {
          return [
            {
              name: new Date().getFullYear(),
              value: new Date().getFullYear(),
            },
            {
              name: new Date().getFullYear() - 1,
              value: new Date().getFullYear() - 1,
            },
            {
              name: new Date().getFullYear() - 2,
              value: new Date().getFullYear() - 2,
            }
          ]
        }
      default:
        return null;
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  getPipelineViewData(filterOption) {
    let color = [
      COLORS.chart.blue, 
      COLORS.chart.blue2, 
      COLORS.chart.lightBlue, 
      COLORS.chart.purple, 
      COLORS.chart.green, 
      COLORS.chart.yellow, 
      COLORS.chart.red,
      COLORS.chart.yellow
    ]
    if (!Utility.isEmpty(filterOption)) {
      let data = ReportManager.getPipelineFilterData().find(
        (pipeline) => pipeline.id === filterOption.value.value
      );
      this.setState({ selectedFilterIndex: filterOption.index });
      if (!Utility.isEmpty(data)) {
        this.setData(this.formatDataTable(color, data));
      } else {
        this.setData([]);
      }
    } else {
      Reports.getPipelines().then((data) => {
        if (!Utility.isEmpty(data)) {
          if (ReportManager.getPipelineViewData().length > 0 ) {
            this.setData(
              !Utility.isEmpty(ReportManager.getPipelineViewData())
                ? this.setData(this.formatDataTable(color, ReportManager.getPipelineViewData()))
                : []
            );
          } else {
            this.setData([])
          }
        }
      },error=>{this.setData([])});
    }
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  getContactsReport = (filterOption) => {
    let interval = filterOption ? filterOption.value.interval : "monthly";
    this.setState({
      selectedFilterIndex: filterOption ? filterOption.index : 0,
    });
    const [startDate, endDate] = Reports.getDefaultStartEndDate(interval);
    Reports.getContacts({ startDate:startDate,endDate:endDate,interval:interval }).then((data) => {
      this.setData(ReportManager.getContactData().length > 0 ? this.formatDataTable(null, ReportManager.getContactData()) : [])
    },
    error=>{
      this.setData([])
    });
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  getDealsData = (filterOption) => {
    let interval = filterOption ? filterOption.value.interval : "monthly";
    this.setState({
      selectedFilterIndex: filterOption ? filterOption.index : 0,
    });
    const [startDate, endDate] = Reports.getDefaultStartEndDate(interval);
    Reports.getWonAndLostDeals({ startDate:startDate,endDate:endDate,interval:interval,timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}).then((data) => {
      this.getDealsChart(ReportManager.getDealsData());
    });
  };

  getDealsChart(data) {
    let lineData =[];
    for (var i = 0; i < data.length; i++) {
      if (data[i].wonDeal) {
        lineData.push({
          title: "Won Deal",
          color: COLORS.chart.green,
          points : data[i].wonDeal
        })
      }
      if (data[i].lostDeal) {
        lineData.push({
          title: "Lost Deal",
          color: COLORS.chart.red,
          points : data[i].lostDeal
        })
      }
    }
    this.setData(lineData);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  getInvoicesReport = () => {
    Reports.getInvoices().then((data) => {
      let invoiceData = ReportManager.getInvoiceData();
      if(invoiceData.length > 0 ) {
        this.invoiceChart(ReportManager.getInvoiceData());
      } else {
        this.setData([])
      }
    });
  };

  invoiceChart = (data) => {
    let color = [
      COLORS.chart.blue, 
      COLORS.chart.blue2, 
      COLORS.chart.lightBlue, 
      COLORS.chart.purple, 
      COLORS.chart.green, 
      COLORS.chart.yellow, 
      COLORS.chart.red,
      COLORS.chart.yellow,
      COLORS.chart.blue, 
      COLORS.chart.blue2, 
      COLORS.chart.lightBlue, 
      COLORS.chart.purple
    ]

    this.setData(this.formatDataTable(color.slice(0, data.length), data));
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  getAgeReceivable = () => {
    Reports.getAgeReceivable({
      type: "RECEIVABLE",
      "var-start_date": DataParser.formatDate(new Date()),
    }).then((data) => {
      ReportManager.setAgeReceivable(data)
      let agedReceivableData = ReportManager.getAgeReceivable();
      if (agedReceivableData.length > 0) {
        this.agedReceivableData(ReportManager.getAgeReceivable());
      } else {
        this.setData([])
      }
    });
  };

  agedReceivableData(data) {
    let color = [
      COLORS.chart.blue, 
      COLORS.chart.blue2, 
      COLORS.chart.lightBlue, 
      COLORS.chart.purple, 
      COLORS.chart.green
    ]
    this.setData(this.formatDataTable(color, data));
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  getBankAccounts = (filterOption) => {
    let tableData
    if (filterOption) {
      this.setState({ selectedFilterIndex: filterOption.index });
      Reports.getReportByBankAccount({
        accountCode: filterOption.value.value,
      }).then(
        (data) => {
          ReportManager.setBankAccountDetails(data);
          this.bankAccCallback(true)
        },
        (err) => {}
      );
    } else {
      tableData = Reports.getBankAccount(null, this.bankAccCallback);
    }
  };

  bankAccCallback = (isDataAvailable) => {
    if (isDataAvailable) {
      this.bankAccData(ReportManager.getBankAccountDetails());
    }
  };

  bankAccData = (data) => {
    if (data.length > 0) {
      
      let newDataArray = data.map((item) => {
        return {
          title: item.name,
          value: Math.round(item.value),
          color: COLORS.chart.blue
        }
      })
  
      let lineData = {
        title: "Bank Account",
        color: COLORS.chart.blue,
        points: newDataArray.length > 0 ? newDataArray : []
      }
      this.setData([lineData]);
    } else {
      this.setData([]);
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  getExpenseClaimed = (filterOption) => {
    let period = filterOption ? filterOption.value.value.toUpperCase() : "YEAR";
    this.setState({
      selectedFilterIndex: filterOption ? filterOption.index : 0,
    });
    Reports.getExpenseClaimed({ trend_view: period })
      .then(() => {
        this.addColorToData(ReportManager.getExpenseClaimed());
      })
      .catch(() => {
        this.setData([])
      });
  };

  getExpenseBreakDown = ()=>{
    const color = [
      "rgb(130, 112, 234)",
        "rgb(26, 175, 93)",
        "rgb(225, 105, 130)",
    ]
    const role = this.getLoggedInUserRole();
    Reports.getExpenseBreakDown({ type: "CATEGORY", view: role})
    .then(() => {
      this.setData(this.formatDataTable(color,ReportManager.getExpenseBreakDown()?.data))
    })
    .catch(() => {
      this.setData([])
    });
  };

  
  
  addColorToData(data) {
    let newDataArray = data.map((item) => {
      return {
        title: item.name,
        value: Math.round(item.value),
        color: COLORS.chart.orange
      }
    })

    let lineData = {
      title: "Expenses Claimed",
      color: COLORS.chart.orange,
      points: newDataArray.length > 0 ? newDataArray : []
    }

    this.setData([lineData]);
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  getPayroll = (filterOption) => {
    let color = [
      COLORS.chart.purple,
      COLORS.chart.green,
      COLORS.chart.yellow
    ]

    if(PermissionManager.isUserManagementAccessible()) {
      let period = filterOption ? (filterOption.value.value) : (new Date().getFullYear());
      
      this.setState({
        selectedFilterIndex: filterOption ? filterOption.index : 0,
      });
      Reports.getPayRoll(period)
      .then(() => {
        let payroll = ReportManager.getPayRoll();
        if (payroll.length > 0) {
          this.getPeopleChart( ReportManager.getPayRoll())          
        } else {
          this.setData([])
        }
      })
      .catch(() => {
        this.setData(this.formatDataTable(color, ReportManager.getPayRoll()));
      });
    } else {
      Reports.getEmployeePaystub().then(() => {
        let payroll = ReportManager.getEmloyeePaystub();
        if (payroll.length > 0) {
          this.setData(this.formatDataTable(color, ReportManager.getEmloyeePaystub()));
        } else {
          this.setData([])
        }
      })
      .catch(() => {
        this.setData(this.formatDataTable(color, ReportManager.getEmloyeePaystub()));
      });
    }
  };
  getPeopleChart(data) {
    let lineData =[];
    for (var i = 0; i < data.length; i++) {
      if (data[i].payrollCost) {
        lineData.push({
          title: "Payroll Cost",
          color: COLORS.chart.yellow,
          points : data[i].payrollCost
        })
      }
      if (data[i].totalEarnings) {
        lineData.push({
          title: "Total Earning",
          color: COLORS.chart.green,
          points : data[i].totalEarnings
        })
      }
      if (data[i].totalDeductions) {
        lineData.push({
          title: "Total Deduction",
          color: COLORS.chart.red,
          points : data[i].totalDeductions
        })
      }
    }
    this.setData(lineData);
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  getLeavesPerMonth = (filterOption) => {
    let period = filterOption
      ? filterOption.value.value
      : new Date().getFullYear();
    let color = [
      COLORS.chart.blue, 
      COLORS.chart.blue2, 
      COLORS.chart.lightBlue, 
      COLORS.chart.purple, 
      COLORS.chart.green, 
      COLORS.chart.yellow, 
      COLORS.chart.red,
      COLORS.chart.yellow,
      COLORS.chart.blue, 
      COLORS.chart.blue2, 
      COLORS.chart.lightBlue, 
      COLORS.chart.purple
    ]
    this.setState({
      selectedFilterIndex: filterOption ? filterOption.index : 0,
    });
    if(UserManager.isUserAdmin() || UserManager.isUserOwner()) {
        Reports.getAllLeavesPerMonths({ year: period }).then(() => {
            let leavesData = ReportManager.getLeavesPerMonth();
            if (leavesData.length > 0) {
              this.setData(this.formatDataTable(color, ReportManager.getLeavesPerMonth()));
            } else {
              this.setData([]);
            }
          });
    } else {
          Reports.getLeavesPerMonths({ year: period }).then(() => {
            let leavesData = ReportManager.getLeavesPerMonth();
            if (leavesData.length > 0) {
              this.setData(this.formatDataTable(color, ReportManager.getLeavesPerMonth()));
            } else {
              this.setData([]);
            }
          });
    }
  };
  getDataForLPM = () => {
    let data = [];
    for (let i = 0; i < 3; i++) {
      data.push({
        name: new Date().getFullYear() - i,
        value: new Date().getFullYear() - i,
      });
    }
    return data;
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  formatDataTable(color, data) {
    let addColorToArray = []
    if(!color) {
      color = [
        "rgb(130, 112, 234)",
        "rgb(250, 200, 85)",
        "rgb(225, 105, 130)",
        "rgb(52, 195, 161)",
        "rgb(143, 232, 236)",
        "rgb(38, 144, 240)",
        "rgb(240, 100, 111)",
        "rgb(245, 200, 60)",
        "rgb(160,160,160)",
      ]
      const numChartColors = color.length;
      
     addColorToArray = data.map((value, index) => {
      return {...data[index], color: color[index % numChartColors]}
    })
    } else {
      addColorToArray = color.map((value, index) => {
        return {...data[index], color: value}
      })
    }
    addColorToArray = addColorToArray.filter(a=>!Utility.isEmpty(a.name))
    let newData = addColorToArray.map((item) => {
      
        return {
          title: item.name,
          value: item.value,
          color: item.color
        }
      
    })

    return newData;
  }

  getLoggedInUserRole() {
    if(UserManager.isUserOwner() || UserManager.isUserAdmin()) {
      return "ADMIN";
    } else if(UserManager.isUserManager()) {
      return "MANAGER";
    } else {
      return "EMPLOYEE";
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
}

export default ChartHolder;
