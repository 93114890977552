import { MONTHS, DAY_INTERVAlS } from "../constants/Constant";
import Utility from "../utility/Utility";
import {COLORS} from "deskera-ui-library";
import moment from "moment";
import PermissionManager from "../managers/PermissionManager";
import { deepClone } from "../components/common/CommonUtils";

class ChartParser {
  static invoiceReportParser = (data) => {
    let allMonths = MONTHS.slice();
    let monthsArray = allMonths.splice(0, new Date().getMonth() + 1);

    let parsedData = monthsArray.map((month, index) => ({
      name: month,
      value: data[index].invoiceAmount,
    }));

    parsedData = parsedData.every((obj) => obj.value === 0) ? [] : parsedData;

    return parsedData;
  };
  static payrollParser = (data) => {
    if (Utility.isEmpty(data.payruns)) {
      return [];
    }
    if(!PermissionManager.isUserManagementAccessible()) {
      data.payruns.sort((a, b) => new Date(b.processedAt) - new Date(a.processedAt));
      let dataToUse = data.payruns[0];
      const parsedData = [
        {
          name: "Total Cost",
          value: dataToUse ? dataToUse.totalCost : 0,
        },
        {
          name: "Total Earnings",
          value: dataToUse ? dataToUse.totalEarnings : 0,
        },
        {
          name: "Total Deduction",
          value: dataToUse ? dataToUse.totalDeductions : 0,
        },
      ];
      return parsedData;
    } else {
      let parsedData = [];
    let payrollCost = [];
    let totalEarningsArr = [];
    let totalDeductionsArr = [];
      let templatePayrunYearly = deepClone(PayrollYearlyTemplate);
        let totalCost = 0; 
        const totalMargin = 0; 
        let totalDeductions = 0; 
        let totalEarnings = 0; 
        for (const payrun of data.payruns) {
            const index = payrun['month'] - 1;
            const cost = payrun['totalCost'];
            const earning = payrun['totalEarnings'];
            const deduction = payrun['totalCost'] - payrun['totalEarnings'];

            templatePayrunYearly['monthWiseData'][index]['Payroll Cost'] = templatePayrunYearly['monthWiseData'][index]['Payroll Cost'] + parseFloat(cost.toFixed(2));
            templatePayrunYearly['monthWiseData'][index]['Total Earnings'] = templatePayrunYearly['monthWiseData'][index]['Total Earnings'] + earning;
            templatePayrunYearly['monthWiseData'][index]['Total Deductions'] = templatePayrunYearly['monthWiseData'][index]['Total Deductions'] + deduction;
            totalCost = totalCost + parseFloat(cost.toFixed(2));
            totalEarnings = totalEarnings + earning;
            totalDeductions = totalDeductions + deduction;
        }
        templatePayrunYearly.monthWiseData.forEach((value)=>{
          payrollCost.push({
            value: value['Payroll Cost'],
            title: value.month,
            color: COLORS.chart.yellow
          })
          totalEarningsArr.push({
            value: value['Total Earnings'],
            title: value.month,
            color: COLORS.chart.green
          })
          totalDeductionsArr.push({
            value: value['Total Deductions'],
            title: value.month,
            color: COLORS.chart.red
          })
        })
        parsedData.push({payrollCost:payrollCost},{totalEarnings:totalEarningsArr},{totalDeductions:totalDeductionsArr})
      return parsedData
    }
    
    
  };

  static employeePaystub = (data) =>{
    if (Utility.isEmpty(data.paystubs)) {
      return [];
    }
    data.paystubs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    let recentPaystub = data.paystubs[0];
    const parsedData = [
      {
        name: "Gross Pay",
        value: recentPaystub ? (recentPaystub['basicPayrun'] ? recentPaystub['basicPayrun'] : 0) + (recentPaystub['basicPayrun'] ? recentPaystub['earnings'] : 0) : 0,
      },
      {
        name: "Taxes",
        value: recentPaystub && recentPaystub['netTaxes'] ? recentPaystub['netTaxes'] : 0,
      },
      {
        name: "Deduction",
        value: (recentPaystub && recentPaystub['deductions'] ? recentPaystub['deductions'] : 0) - (recentPaystub && recentPaystub['netTaxes'] ? recentPaystub['netTaxes'] : 0),
      },
    ];
    return parsedData;
  }

  static getIntervalValue = (interval, data) => {
    switch (interval) {
      case DAY_INTERVAlS[0]:
        return data.current;
      case DAY_INTERVAlS[1]:
        return data.lessThanOneMonth;
      case DAY_INTERVAlS[2]:
        return data.lessThanTwoMonth;
      case DAY_INTERVAlS[3]:
        return data.lessThanThreeMonth;
      case DAY_INTERVAlS[4]:
        return data.older;
      default:
        return 0;
    }
  };

  static ageReceivableParser = (data) => {
    let parsedData = DAY_INTERVAlS.map((day_interval) => ({
      name: day_interval,
      value: this.getIntervalValue(day_interval, data),
    }));

    return parsedData;
  };

  static leavePerMonthParser = (data) => {
    let parsedData = data.map((obj) => ({
      name: MONTHS[obj.month - 1],
      value: obj.leaveDetails ? obj.leaveDetails[0].leavesTaken : 0,
    }));

    parsedData = parsedData.every((obj) => obj.value === 0) ? [] : parsedData;

    return parsedData;
  };

  static expenseBreakDown(data) {
    let parsedData = [];
    let totalClaimAmount = 0;
    data.forEach((item) => {
      totalClaimAmount += item.totalAmount
      parsedData.push({
        name: item.status,
        value: item.totalAmount,
      });
    });
    return {data:parsedData,totalClaimAmount: totalClaimAmount};
  }

  static wonAndLossDealData = (data, period) => {
    let parsedData = [];
    let wonDeal = [];
    let lostDeal = [];
    
      data.won.forEach((month,index) => {

        wonDeal.push(this.getParsedContactObj({
          title: period === "monthly" ? moment(month.title).format("D") : month.title,
          value: month.value,
          color: COLORS.chart.green
        }))

        lostDeal.push(this.getParsedContactObj({
          title:  period === "monthly" ? moment(month.title).format("D") : month.title,
          value: month.value,
          color: COLORS.chart.red
        }))

      });
    
    parsedData.push({wonDeal:wonDeal},{lostDeal:lostDeal});
    return parsedData;
  };
  static getYearlyParsedContactObj(data) {
    let yearlyArray = [
      {wonDeal:[{
        title: data.name,
        value: data.won_deal,
        color:  COLORS.chart.green
      }]}, 
      {lostDeal:[{
        title: data.name,
        value: data.lost_deal,
        color:  COLORS.chart.red
      }]}
    ]
    return yearlyArray;
  }
  static getParsedContactObj(data) {
    return {
      title: data.title,
      value: !Utility.isEmpty(data) && data.value ? data.value : 0,
      color: data.color,
    };
  }
  static stagesByPipeline(pipelines) {
    let parsedData = [];
    pipelines.data.forEach((pipeline) => {
      if(!Utility.isEmpty(pipeline.title)) {
        let pipelinedData = {
          name: pipeline.title,
          value: pipeline.value,
        };
      parsedData.push(pipelinedData);
      }
    });
    return parsedData;
  }
  static contactReports(data) {
    let parsedData = [];
    data.data.forEach((item) => {
      parsedData.push({
        name: item.title,
        value: item.value,
      });
    });
    return parsedData;
  }
  static expensesClaimed(data,params) {
    let parsedData = [];
    data.forEach((expense) => {
      parsedData.push({
        name: ChartParser.getExpenseNameOnXaxis(params,expense),
        value: expense.amount,
      });
    });
    parsedData = parsedData.every((obj) => obj.value === 0) ? [] : parsedData;
    return parsedData;
  }
  static getExpenseNameOnXaxis(params,expense) {
    switch(params?.trend_view) {
      case "MONTH":
        return (moment(moment(expense.axisValue),"DD MMM YYYY")).format("D");
      case "WEEK":
        return (moment(moment(expense.axisValue),"DD MMM YYYY")).format("ddd");
      default: 
      return (expense.axisValue?.replace(new Date().getFullYear(),'')?.trim() || expense.axisValue)
    }
  }
  static bankAccountReport(data) {
    let parsedData = data.monthlyBalances.map((data) => {
      return {
        name: data.month.slice(0, 3),
        value: data.amount,
      };
    });
    parsedData = parsedData.every((obj) => obj.value === 0) ? [] : parsedData;
    return parsedData.reverse();
  }

  static allLeavePerMonthParser = (data) => {
    let parsedData = data.map((obj) => ({
      name: MONTHS[obj.month - 1],
      value: obj.leaveCount ? obj.leaveCount : 0,
    }));

    parsedData = parsedData.every((obj) => obj.value === 0) ? [] : parsedData;

    return parsedData;
  };
}

export default ChartParser;

export const PayrollYearlyTemplate = {
  year: '',
  totalCost: 0,
  totalMargin: 0,
  totalEarnings: 0,
  totalDeductions: 0,
  monthWiseData: [
      {
          month: 'Jan',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
      {
          month: 'Feb',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
      {
          month: 'Mar',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
      {
          month: 'Apr',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
      {
          month: 'May',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
      {
          month: 'Jun',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
      {
          month: 'Jul',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
      {
          month: 'Aug',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
      {
          month: 'Sep',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
      {
          month: 'Oct',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
      {
          month: 'Nov',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
      {
          month: 'Dec',
          'Payroll Cost': 0,
          'Total Earnings': 0,
          'Total Deductions': 0,
      },
  ],
};