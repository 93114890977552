import React from "react";

import {
  DKButton,
  DKSpinner,
  DKLabel,
  DKIcon,
  removeLoader
} from "deskera-ui-library";

import BillingManager from "../../../managers/BillingManager";
import Subscription from "../../../services/subscription";
import Utility from "../../../utility/Utility";
import ic_edit from "../../../assets/icons/ic_edit.png";
import ic_cards from "../../../assets/icons/ic_cards.png";
import AcceptPhoneNumberPopup from "./AcceptPhoneNumberPopup";
import VerifyMobileOtp from "./VerifyMobileOtp";
import { CASHFREE_PAYMENT_STATUS } from "../../../constants/Enum";

class CashfreeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCardAdded: false,
      didAddNewCardTapped: false,
      cardResponseReceived: false,
      cardDetails: BillingManager.getDefaultPaymentCard(),
      showMobileNumberPopup: false,
      showEditDetailsConfirmationPopup: false,
    };
  }

  componentDidMount() {
    this.getCardDetails();
  }

  getCardDetails(isNewCardAdded = false) {
    if (
      isNewCardAdded ||
      Utility.isEmpty(BillingManager.getDefaultPaymentCard())
    ) {
      Subscription.getPaymentMethods()
        .then((res) => {
          this.setCardValues();
          if (isNewCardAdded) {
            this.setState({
              isCardAdded: true,
              didAddNewCardTapped: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ cardResponseReceived: true });
        });
    } else {
      this.setCardValues();
    }
  }

  setCardValues() {
    let cardInfo = BillingManager.getDefaultPaymentCard();
    this.setState({
      cardDetails: cardInfo,
      isCardAdded:
        !Utility.isEmpty(cardInfo && cardInfo.Last4) ||
        BillingManager.getPaymentMethodData().CashfreeStatus.toLowerCase() ===
          CASHFREE_PAYMENT_STATUS.ACTIVE.toLowerCase(),
      cardResponseReceived: true,
    });
    if (cardInfo && cardInfo.Last4 && this.props.onCardAdded) {
      this.props.onCardAdded();
    }
  }

  render() {
    const phoneNumber = BillingManager.getPhoneNumber();
    const isNewAuthLinkRequired =
      !Utility.isEmpty(BillingManager.getPaymentMethodData()) &&
      BillingManager.getPaymentMethodData().CashfreeStatus === "INITIALIZED" &&
      BillingManager.getPaymentMethodData().IsPhoneValidated;
    const isBankApprovalPending =
      !Utility.isEmpty(BillingManager.getPaymentMethodData()) &&
      BillingManager.getPaymentMethodData().CashfreeStatus ===
        "BANK_APPROVAL_PENDING";
    return (
      <div className="pl-l pr-l pt-l pb-l mb-l border-radius-s shadow-s bg-white column parent-width">
        <div className="row">
          <DKIcon src={ic_cards} className="mr-s" />
          <DKLabel
            text={
              this.state.isCardAdded
                ? "Saved billing details"
                : "Billing Details"
            }
            className="fw-m fs-m"
          />
        </div>

        {!isBankApprovalPending && (
          <DKLabel
            text={
              this.state.isCardAdded
                ? "Amount will be charged on subscription date"
                : isNewAuthLinkRequired
                ? "Complete adding your payment details"
                : "Add the required details to initiate the payment"
            }
            className="text-gray mt-xs ml-xxl"
          />
        )}

        {isBankApprovalPending && (
          <DKLabel
            text={"E-Mandate registration pending at Bank."}
            className="text-gray mt-xs ml-xxl"
          />
        )}

        {this.state.isCardAdded &&
          !this.state.didAddNewCardTapped &&
          BillingManager.isMobileNumberVerified() && (
            <div className="parent-width mt-l">
              <DKLabel
                className="mb-s text-gray"
                text={`You have successfully added your billing details, and your added phone number is +91 ${[
                  phoneNumber.slice(0, 5),
                  " ",
                  phoneNumber.slice(5, phoneNumber.length),
                ].join("")}`}
              />
            </div>
          )}

        {this.state.showMobileNumberPopup && (
          <VerifyMobileOtp
            onClose={() => {
              this.setState({
                showMobileNumberPopup: false,
              });
            }}
            onSave={() => {
              this.fetchCashfreePage();
            }}
          />
        )}

        {(!this.state.isCardAdded || this.state.didAddNewCardTapped) &&
          this.state.cardResponseReceived &&
          !isBankApprovalPending && (
            <div className="row ">
              <DKButton
                title={isNewAuthLinkRequired ? "Continue" : "Add Details"}
                className={" bg-blue text-white mt-l fw-m text-wrap-none "}
                onClick={() => {
                  if (!BillingManager.isMobileNumberVerified()) {
                    this.setState({
                      showMobileNumberPopup: true,
                    });
                  } else {
                    this.fetchCashfreePage();
                  }
                }}
              />

              {this.state.didAddNewCardTapped && (
                <DKButton
                  title="Cancel"
                  className="bg-gray1 border-m ml-m mt-l"
                  onClick={() => {
                    this.setState({
                      didAddNewCardTapped: false,
                    });
                  }}
                />
              )}

              {this.props.isPopup && (
                <DKButton
                  title="Cancel"
                  className="bg-gray1 border-m ml-m"
                  onClick={() => {
                    this.props.onCancel();
                  }}
                />
              )}
            </div>
          )}

        {this.state.isCardAdded &&
          !this.state.didAddNewCardTapped &&
          !Utility.isEmpty(
            this.state.cardDetails && this.state.cardDetails.Last4
          ) && (
            <div className="column parent-width mt-l bg-gray1 border-m border-radius-s pt-r pb-r pl-r pr-r">
              <DKLabel
                text={
                  (this.state.cardDetails && this.state.cardDetails.CardBrand
                    ? this.state.cardDetails.CardBrand.toUpperCase()
                    : "") +
                  " ******* " +
                  (this.state.cardDetails && this.state.cardDetails.Last4
                    ? this.state.cardDetails.Last4
                    : "")
                }
              />
            </div>
          )}

        {(isBankApprovalPending ||
          (this.state.isCardAdded && !this.state.didAddNewCardTapped)) && (
          <DKButton
            title="Edit Details"
            className="bg-gray1 border-m text-whites fw-m mt-l "
            icon={ic_edit}
            onClick={() => {
              this.setState({
                showEditDetailsConfirmationPopup: true,
              });
            }}
          />
        )}

        {this.state.showEditDetailsConfirmationPopup &&
          this.geteditDetailsViewPopup()}

        {!this.state.cardResponseReceived && (
          <DKSpinner title="Fetching card details" className="mt-xl mb-l" />
        )}
      </div>
    );
  }

  geteditDetailsViewPopup = () => {
    return (
      <div className=" transparent-background">
        <div className="popup-window">
          <div className="row justify-content-between">
            <DKLabel text="Do you want to continue?" className="fw-m fs-l" />
            <div>
              <div className="row">
                <DKButton
                  title="No"
                  className="bg-gray1 border-m fw-m"
                  onClick={() => {
                    this.setState({
                      showEditDetailsConfirmationPopup: false,
                    });
                  }}
                />
                <DKButton
                  title="Yes"
                  className="bg-blue ml-r text-white fw-m"
                  onClick={() => {
                    if (!BillingManager.isMobileNumberVerified()) {
                      this.setState({
                        showMobileNumberPopup: true,
                        showEditDetailsConfirmationPopup: false,
                      });
                    } else {
                      this.setState({
                        showEditDetailsConfirmationPopup: false,
                      });
                      this.fetchCashfreePage();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="column mt-l">
            <div className="row" style={{ alignItems: "flex-start" }}>
              <DKLabel
                text="Your currently saved billing details will be lost. Do you want to continue?"
                className="fs-m"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////

  fetchCashfreePage = () => {
    let prevAuthLink = "";
    if (
      !Utility.isEmpty(BillingManager.getPaymentMethodData()) &&
      BillingManager.getPaymentMethodData().CashfreeStatus === "INITIALIZED" &&
      BillingManager.getPaymentMethodData().IsPhoneValidated
    ) {
      prevAuthLink = BillingManager.getPaymentMethodData().CashfreeAuthlink;
    }

    if (!Utility.isEmpty(prevAuthLink)) {
      Utility.openTab(prevAuthLink, "_self");
    } else {
      Subscription.requestCashfreePaymentLink({})
        .then(
          (res) => {
            removeLoader();

            if (!Utility.isEmpty(res.AuthLink)) {
              Utility.openTab(res.AuthLink, "_self");
            }
          },
          (err) => {
            this.setState({
              showMobileNumberPopup: true,
            });
            removeLoader();
          }
        )
        .catch((error) => {
          removeLoader();
        });
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////
}

export default CashfreeView;
