import React, { Component } from "react";

import {
  DKIcon
} from "deskera-ui-library";

import { PLANS } from "../../constants/Enum";

import PlanManager from "../../managers/PlanManager";

import ic_startup from "../../assets/pricingSection/startup.svg";
import ic_essential from "../../assets/pricingSection/essential.svg";
import ic_professional from "../../assets/pricingSection/professional.svg";

/*
PROPS: 
- className
- plan
*/

class PlanIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: this.props.plan ? this.props.plan : PlanManager.getDefaultPlan(),
    };
  }
  componentWillReceiveProps(nextProp) {
    this.setState({
      plan: nextProp.plan ? nextProp.plan : PlanManager.getDefaultPlan(),
    });
  }

  render() {
    return (
      <DKIcon
        src={this.getIconSrc(this.state.plan)}
        className={this.props.className}
      />
    );
  }

  getIconSrc(plan) {
    switch (plan.toLowerCase()) {
      case PLANS.STARTUP:
        return ic_startup;
      case PLANS.ESSENTIAL:
        return ic_essential;
      case PLANS.PROFESSIONAL:
        return ic_professional;
      default:
        return ic_professional;
    }
  }
}

export default PlanIcon;
