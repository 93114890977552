import React, { Component } from "react";

import { DKLabel, DKButton } from "deskera-ui-library";

import PlanManager from "../../managers/PlanManager";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";

import StatusBadge from "./StatusBadge";
import {
  SUBSCRIPTION_STATUS,
  INTERVALS,
  PRODUCTS,
  PLANS,
} from "../../constants/Enum";
import Subscription from "../../services/subscription";
import PlanIcon from "./PlanIcon";
import PlanExpiryBadge from "./PlanExpiryBadge";
import PriceLabel from "./PriceLabel";
import UserManager from "../../managers/UserManager";
import AppManager from "../../managers/AppManager";
import ProductIcon from "./ProductIcon";
import { COLORS } from "../../constants/Colors";
import CouponsManager from "../../managers/CouponsManager";
import PermissionManager from "../../managers/PermissionManager";
import SubscriptionManager from "../../managers/SubscriptionManager";

/*
- product
- plan
- planInterval
- status
- remainingTrialDays
- onTrialActivate
*/

class PlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: this.props.product
        ? this.props.product
        : PlanManager.getDefaultProduct(),
      plan: this.props.plan ? this.props.plan : PlanManager.getDefaultPlan(),
      planInterval: this.props.planInterval
        ? this.props.planInterval
        : PlanManager.getDefaultInterval(),
      currency: this.props.currency,
      status: this.props.status ? this.props.status : null,
      remainingTrialDays: this.props.remainingTrialDays
        ? this.props.remainingTrialDays
        : null,
      nextChargeDate: this.props.nextChargeDate,
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      product: nextProp.product
        ? nextProp.product
        : PlanManager.getDefaultProduct(),
      plan: nextProp.plan ? nextProp.plan : PlanManager.getDefaultPlan(),
      planInterval: nextProp.planInterval
        ? nextProp.planInterval
        : PlanManager.getDefaultInterval(),
      currency: nextProp.currency,
      status: nextProp.status ? nextProp.status : null,
      remainingTrialDays: nextProp.remainingTrialDays
        ? nextProp.remainingTrialDays
        : null,
      nextChargeDate: nextProp.nextChargeDate,
    });
  }

  render() {
    return (
      <div
        className={
          " mb-l pl-r pr-r pt-r border-m border-radius-s parent-width column shadow-ms bg-deskera-secondary"
        }
        style={{ paddingBottom: 26 }}
      >
        {/* <div className="row justify-content-between ">
          <DKLabel text="Subscription status" className="fw-m" />
          {this.state.status && <StatusBadge status={this.state.status} />}
        </div> */}

        {this.getProductPlanDetails()}
        {this.getPlanDescription()}
        {!PlanManager.isFreemium &&
          this.props.status === SUBSCRIPTION_STATUS.ACTIVE &&
          (UserManager.isUserOwner() || UserManager.isUserAdmin()) &&
          this.getPriceSection()}

        {((!PlanManager.isFreemium &&
          this.props.status === SUBSCRIPTION_STATUS.IN_TRIAL) ||
          this.props.status === SUBSCRIPTION_STATUS.TRIAL_ENDED) && (
          <PlanExpiryBadge
            remainingTrialDays={
              this.state.remainingTrialDays ? this.state.remainingTrialDays : 0
            }
          />
        )}
        {PermissionManager.isSubscriptionAccessible() ? (
          this.getActionButtons()
        ) : (
          <DKLabel
            className="text-red mt-s mb-s"
            text="No permissions provided, please contact owner/admin"
          />
        )}

        {!PlanManager.isFreemium &&
          this.props.status === SUBSCRIPTION_STATUS.ACTIVE && (
            <div className="row mt-s">
              <DKLabel
                className="mt-m fs-s"
                text={`Next Charge Date &nbsp;${
                  this.state.nextChargeDate === "Lifetime" ? "- " : ": "
                }`}
              />
              <DKLabel
                className="mt-m ml-s fw-b"
                text={
                  this.state.nextChargeDate === "Lifetime"
                    ? "NA"
                    : this.state.nextChargeDate
                }
              />
              <DKButton
                className="text-blue pt-l"
                title="Billing Details"
                onClick={() => {
                  RouteManager.navigateToPage(PAGE_ROUTES.BILLING);
                }}
                style={{ textDecoration: "underline" }}
              />
            </div>
          )}

        {/* {this.props.status === SUBSCRIPTION_STATUS.ACTIVE && (
          <DKLabel text="✓ Currently active plan" className="mt-m mb-r" />
        )} */}
      </div>
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////

  getProductPlanDetails() {
    return (
      <div className="row mt-r justify-content-between">
        <div className="row">
          <div>
            <div
              className="row border-radius-s fs-r fw-m cursor-hand text-wrap-none"
              style={{
                padding: 3,
                paddingRight: 8,
                backgroundColor:
                  this.state.product === PRODUCTS.AIO
                    ? COLORS.deskera
                    : PlanManager.getProductColor(this.state.product),
                color:
                  this.state.product === PRODUCTS.AIO
                    ? COLORS.black
                    : COLORS.white,
              }}
              onClick={
                PermissionManager.isSubscriptionAccessible()
                  ? this.gotoChangePlanScreen
                  : null
              }
            >
              <ProductIcon
                product={this.state.product}
                className={`mr- ${
                  this.state.product === PRODUCTS.MRP ? "mr-xs" : ""
                }`}
              />

              {this.state.product
                ? PlanManager.getProductDisplayName(this.state.product)
                : "-"}
            </div>
          </div>
          <div
            className="fs-m ml-s fw-b cursor-hand text-wrap-none"
            onClick={
              PermissionManager.isSubscriptionAccessible()
                ? this.gotoChangePlanScreen
                : null
            }
          >
            {PlanManager.isFreemium && this.state.product === PRODUCTS.AIO && (
              <div>{PLANS.FREEMIUM}</div>
            )}
            {!(PlanManager.isFreemium && this.state.product === PRODUCTS.AIO) &&
            this.state.plan
              ? PlanManager.getPlanDisplayName(this.state.plan, false)
              : "-"}{" "}
            Plan
          </div>
          <PlanIcon
            className="ml-xs ic-s-2"
            plan={this.state.plan}
            onClick={this.gotoChangePlanScreen}
          />
        </div>
        {!PlanManager.isFreemium &&
          this.state.status &&
          this.state.status !== SUBSCRIPTION_STATUS.TRIAL_PENDING && (
            <StatusBadge status={this.state.status} />
          )}
      </div>
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////

  getPlanDescription() {
    if (!PlanManager.isFreemium) {
      return (
        <DKLabel
          className="mt-m"
          style={{ minHeight: 42 }}
          text={
            this.state.product && this.state.plan
              ? PlanManager.getPlanDescription(
                  this.state.product,
                  this.state.plan
                )
              : "-"
          }
        />
      );
    } else {
      return (
        <DKLabel
          className="mt-m"
          style={{ minHeight: 42 }}
          text={"Free for life"}
        />
      );
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////

  getPriceSection() {
    let planPrice = PlanManager.getPlanPriceByCurrency(
      this.state.product,
      this.state.plan,
      this.state.planInterval,
      this.state.currency
    );

    return (
      <div className="mt-r">
        <PriceLabel
          price={planPrice}
          currency={this.state.currency}
          discountedPrice={CouponsManager.getDiscountedPrice(
            planPrice,
            this.state.product,
            this.state.planInterval
          )}
        />
        <div className="row">
          <DKLabel className="" text={`per user / month`} />
          <DKLabel
            className="fw-m ml-s"
            text={
              this.state.planInterval === INTERVALS.YEARLY
                ? "(billed annually)"
                : "(billed monthly)"
            }
          />
        </div>
      </div>
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////

  getActionButtons() {
    return (
      <div className="row mt-l">
        {this.props.status === SUBSCRIPTION_STATUS.ACTIVE && (
          <>
            {/* <DKButton
              title="View details"
              className="bg-white border-m shadow-s fw-m mr-s"
              onClick={this.gotoChangePlanScreen}
            /> */}
            {PlanManager.isFreemium && (
              <DKButton
                title="Upgrade Plan"
                className="bg-white border-m shadow-s fw-m mr-s"
                onClick={this.gotoMySubscriptionScreen}
              />
            )}
          </>
        )}
        {this.props.status === SUBSCRIPTION_STATUS.ACTIVE && (
          <DKButton
            title="Change plan"
            className="bg-white border-m shadow-s fw-m mr-s"
            onClick={this.gotoChangePlanScreen}
          />
        )}
        {this.props.status !== SUBSCRIPTION_STATUS.ACTIVE &&
          this.props.status !== SUBSCRIPTION_STATUS.TRIAL_PENDING && (
            <DKButton
              title="Buy now"
              className="bg-blue text-white border-m fw-m"
              onClick={() => {
                RouteManager.gotoCheckout(
                  this.state.product,
                  this.state.plan,
                  this.state.planInterval
                );
              }}
            />
          )}
        {PlanManager.isFreemium &&
          this.props.status === SUBSCRIPTION_STATUS.TRIAL_PENDING && (
            <DKButton
              title="Upgrade Plan"
              className="bg-white border-m shadow-s fw-m mr-s"
              onClick={this.gotoMySubscriptionScreen}
            />
          )}
        {!PlanManager.isFreemium &&
          this.props.status === SUBSCRIPTION_STATUS.TRIAL_PENDING && (
            <DKButton
              title="Start 15 days trial"
              className="bg-blue bg-dark-gray text-white border-m fw-m"
              onClick={() =>
                AppManager.showTrialActivationWarning(this.startTrial)
              }
            />
          )}
      </div>
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////

  gotoChangePlanScreen = () => {
    RouteManager.navigateToPage(
      PAGE_ROUTES.PLAN_SELECTION,
      "product=" + this.state.product + "&interval=" + this.state.planInterval
    );
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////

  startTrial = () => {
    let planID = PlanManager.getPlanID(
      this.state.product,
      this.state.plan,
      this.state.planInterval
    );
    Subscription.activatePlan(
      {
        PlanID: planID,
        Currency:
          SubscriptionManager.getCurrencySameAsActivePlanCurrency().toLowerCase(),
      },
      true
    ).then((res) => this.props.onTrialActivate());
  };
}

export default PlanDetails;
