import React, { Component } from "react";

import { DKListPicker, TOAST_TYPE, showToast } from "deskera-ui-library";

import UserManager from "../../managers/UserManager";
import IAM from "../../services/iam";
import AppManager from "../../managers/AppManager";
import { localizationText } from "../../utility/Localization";
import { getCapitalized } from "../../utility/Utility";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
/*
PROPS:
- onAddOrgClick
- onClose
- allOrgs
- tenantIds
*/

class OrgList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrgs: this.props.allOrgs,
      tenantIds: this.props.tenantIds,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      allOrgs: nextProps.allOrgs,
      tenantIds: nextProps.tenantIds,
    });
  }
  render() {
    const { t } = this.props;
    return (
      <div className="parent-width">
        <DKListPicker
          title={`My ${getCapitalized(t("ORGANISATIONS"))}`}
          data={this.state.allOrgs}
          onSelect={(index, value) => {
            this.onSwitchOrgClick(index, value);
          }}
          onClose={this.props.onClose}
          needIcon={true}
          buttonTitle={
            this.props.onAddOrgClick ? `+ Add ${t("ORGANISATION")}` : null
          }
          buttonOnClick={
            this.props.onAddOrgClick
              ? () => {
                  this.props.onAddOrgClick();
                }
              : null
          }
        />
      </div>
    );
  }

  onSwitchOrgClick = (index, value) => {
    if (
      this.state.allOrgs[index] === value &&
      this.state.allOrgs[index] !== 0
    ) {
      let listTenantID = this.state.tenantIds[index];
      if (listTenantID !== UserManager.getUserTenantID()) {
        IAM.switchTenant({ tenantId: listTenantID }).then(
          (res) => {
            AppManager.refreshApp();
          },
          (err) => {}
        );
      } else {
        this.setState({ needOrgListPicker: false });
        showToast(`Current ${t("ORGANISATION")}`, TOAST_TYPE.SUCCESS);
      }
    }
  };
}

export default withTranslation()(OrgList);
