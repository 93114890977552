import TagManager from "react-gtm-module";

export const Analytics = {
  initGoogleGTM,
  PushGoogleGTMDataLayer,
  OnboardingSignup,
  OnSocialSignup,
};

function initGoogleGTM() {
  const pathname = window.location.pathname ? window.location.pathname : "";
  const search = window.location.search ? window.location.search : "";
  const webURL = process.env.REACT_APP_GO_UI_URL + pathname + search;
  const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GTM_TRACKING_ID}`,
    dataLayer: {
      pagePath: webURL,
    },
  };
  const tagManagerArgsSecond = {
    gtmId: `${process.env.REACT_APP_GTM_TRACKING_ID_SECOND}`,
    dataLayer: {
      pagePath: webURL,
    },
  };
  TagManager.initialize(tagManagerArgs);
  TagManager.initialize(tagManagerArgsSecond);
}

function PushGoogleGTMDataLayer(args) {
  const { userId, tenantId, asPath } = args;
  const webURL = process.env.GATSBY_WEBSITE_URL + asPath;
  const tagManagerArgsSecond = {
    gtmId: `${process.env.REACT_APP_GTM_TRACKING_ID_SECOND}`,
    dataLayer: {
      userId: userId ? userId : "",
      tenantId: tenantId ? tenantId : "",
      pagePath: webURL,
    },
  };
  const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GTM_TRACKING_ID}`,
    dataLayer: {
      userId: userId ? userId : "",
      tenantId: tenantId ? tenantId : "",
      pagePath: webURL,
    },
  };

  TagManager.initialize(tagManagerArgs);
  TagManager.initialize(tagManagerArgsSecond);
}
function OnboardingSignup(args) {
  let data = args;
  const webURL = typeof window === undefined ? "" : window.location.href;
  const event = "free_trial";

  const tagManagerArgsSecond = {
    gtmId: `${process.env.REACT_APP_GTM_TRACKING_ID_SECOND}`,
    dataLayer: {
      event: event,
      data: data,
    },
  };
  const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GTM_TRACKING_ID}`,
    dataLayer: {
      event: event,
      data: data,
    },
  };

  TagManager.initialize(tagManagerArgs);
  TagManager.initialize(tagManagerArgsSecond);
}
function OnSocialSignup(args) {
  let data = args;
  const webURL = typeof window === undefined ? "" : window.location.href;
  const event = "free_trial_sso";

  const tagManagerArgsSecond = {
    gtmId: `${process.env.REACT_APP_GTM_TRACKING_ID_SECOND}`,
    dataLayer: {
      event: event,
      data: data,
    },
  };
  const tagManagerArgs = {
    gtmId: `${process.env.REACT_APP_GTM_TRACKING_ID}`,
    dataLayer: {
      event: event,
      data: data,
    },
  };

  TagManager.initialize(tagManagerArgs);
  TagManager.initialize(tagManagerArgsSecond);
}
