class TableDataParser {
    static parseTableData(data) {
        let tableData = {};
        tableData["id"] = data._id;
        tableData["name"] = data.name;
        tableData["columns"] = this.parseColumnData(data.columnsMetaData);
        return tableData;
      }
      static parseColumnData(data) {
        let columnsData = [];
        data.forEach((column) => {
          let data = { ...column };
          data["key"] = column.id;
          data["type"] = column.type.toLowerCase();
          data["options"] = column.options ? column.options : [];
          data["required"] = column.required ? column.required : false;
          data["width"] = column.width ? column.width : 200;
          columnsData.push(data);
        });
        return columnsData;
      }
}
export default TableDataParser;