export const SVGICON = {
  MENU: {
    ERP: {
      ICON: `ic-erp`,
    },
    MRP: {
      ICON: `ic-mrp`,
    },
  },
};
