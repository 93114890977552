import React, { Component } from "react";
import RouteManager from "../../managers/RouteManager";
import ProductIcon from "../common/ProductIcon";
import {
  PRODUCTS,
  CHART_TYPE,
  CHART_API_SOURCES,
  USER_ROLE,
} from "../../constants/Enum";
import PlanManager from "../../managers/PlanManager";
import ic_arrow_right from "../../assets/icons/ic_arrow_right2.png";
import ChartHolder from "./Charts/ChartHolder";
import ApiConstants from "../../constants/ApiConstants";
import UserManager from "../../managers/UserManager";
import PermissionManager from "../../managers/PermissionManager";
import User from "../../services/user";
import { t } from "i18next";
import {
  DKLabel,
  DKButton,
  DKIcon,
  removeLoader,
  showLoader,
  BarGraph,
} from "deskera-ui-library";
import Reports from "../../services/reports";
import ReportManager from "../../managers/ReportManager";
import { isComplianceCountry } from "../common/CommonUtils";
import SubscriptionManager from "../../managers/SubscriptionManager";
import { withTranslation } from "react-i18next";
class ReportsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceBarData: null,
      showCustomizeReport: false,
    };
  }

  componentDidMount() {
    RouteManager.setPresenter(this);
  }
  getAllProducts() {
    return (
      <div>
        {
          <div className="column" style={{ flex: 1 }}>
            {PermissionManager.isGraphVisible(PRODUCTS.ERP)
              ? this.getBooksCharts()
              : null}

            {PermissionManager.isGraphVisible(PRODUCTS.CRM)
              ? this.getCRMCharts()
              : null}

            {PermissionManager.isGraphVisible(PRODUCTS.PEOPLE)
              ? this.getPeopleCharts()
              : null}
          </div>
        }
      </div>
    );
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        {(SubscriptionManager.isAnyProductActive() ||
          SubscriptionManager.isAnyProductInTrial() ||
          SubscriptionManager.isAnyProductsTrialOver()) && (
          <div className="column" style={{ flex: 1 }}>
            {(SubscriptionManager.isProductInTrial(PRODUCTS.ERP) ||
              SubscriptionManager.isProductActive(PRODUCTS.ERP) ||
              SubscriptionManager.isProductTrialOver(PRODUCTS.ERP)) &&
            PermissionManager.isGraphVisible(PRODUCTS.ERP)
              ? this.getBooksCharts()
              : null}

            {(SubscriptionManager.isProductInTrial(PRODUCTS.CRM) ||
              SubscriptionManager.isProductActive(PRODUCTS.CRM) ||
              SubscriptionManager.isProductTrialOver(PRODUCTS.CRM)) &&
            PermissionManager.isGraphVisible(PRODUCTS.CRM)
              ? this.getCRMCharts()
              : null}

            {(SubscriptionManager.isProductInTrial(PRODUCTS.PEOPLE) ||
              SubscriptionManager.isProductActive(PRODUCTS.PEOPLE) ||
              SubscriptionManager.isProductTrialOver(PRODUCTS.PEOPLE)) &&
            PermissionManager.isGraphVisible(PRODUCTS.PEOPLE)
              ? this.getPeopleCharts()
              : null}
          </div>
        )}
        {!(
          SubscriptionManager.isAnyProductActive() ||
          SubscriptionManager.isAnyProductInTrial() ||
          SubscriptionManager.isAnyProductsTrialOver()
        ) && this.getAllProducts()}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  getProductNameRow(product) {
    return (
      <div className="row justify-content-between">
        <div
          className="cursor-hand"
          onClick={() => this.gotoProductURL(product)}
        >
          <div className="row">
            <ProductIcon product={product} />
            <DKLabel
              text={`Deskera ${PlanManager.getProductDisplayName(product)}`}
              className="fw-m fs-l ml-r"
            />
            <DKIcon
              className="ic-s"
              src={ic_arrow_right}
              style={{ opacity: 0.7 }}
            />
          </div>
        </div>
        {this.state.showCustomizeReport && (
          <div>
            <DKButton
              title={t("CUSTOMIZE_REPORT")}
              className="border-m bg-gray1 fw-m ml-r"
              onClick={() => this.gotoProductURL(PRODUCTS.REPORT_BUILDER)}
            />
          </div>
        )}
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  getBooksCharts() {
    return (
      <div className="parent-width">
        {this.getProductNameRow(PRODUCTS.BOOKS_PLUS)}
        <div className="row row-responsive flex-wrap mt-s">
          {this.getChart(
            t("INVOICES"),
            CHART_TYPE.BAR_VERTICAL,
            CHART_API_SOURCES.INVOICES,
            (value) => {
              return [value.toFixed(2), "Amount"];
            }
          )}
          {this.getChart(
            t("AGED_RECEIVABLE"),
            CHART_TYPE.PIE,
            CHART_API_SOURCES.AGE_RECEIVABLE,
            (value) => {
              return [value.toFixed(2), "Amount"];
            }
          )}
          {this.getChart(
            t("BANK_ACCOUNT"),
            CHART_TYPE.LINE,
            CHART_API_SOURCES.BANK_ACCOUNTS,
            (value) => {
              return [value, "Bank Account"];
            },
            true
          )}
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////

  getCRMCharts() {
    return (
      <div className="mt-xl parent-width">
        {this.getProductNameRow(PRODUCTS.CRM)}
        <div className="row row-responsive flex-wrap mt-s">
          {this.getChart(
            t("WON_&_LOST_DEALS"),
            CHART_TYPE.LINE,
            CHART_API_SOURCES.WON_AND_LOST_DEALS,
            (value, name, props) => {
              return [value, name === "value" ? "Won" : "Lost"];
            }
          )}
          {this.getChart(
            t("PIPELINE_VIEW"),
            CHART_TYPE.BAR_HORIZONTAL,
            CHART_API_SOURCES.PIPELINES,
            (value) => {
              return [value, "Deal count"];
            }
          )}
          {this.getChart(
            t("CONTACTS_REPORT"),
            CHART_TYPE.PIE,
            CHART_API_SOURCES.CONTACTS,
            (value, name) => {
              return [value, name + " count"];
            },
            true
          )}
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////

  getPeopleCharts() {
    return (
      <div className="mt-xl parent-width" style={{ marginBottom: 80 }}>
        {this.getProductNameRow(PRODUCTS.PEOPLE)}
        <div className="row row-responsive flex-wrap mt-s">
          {this.getChart(
            t("EXPENSE_CLAIMES"),
            CHART_TYPE.PIE,
            CHART_API_SOURCES.MY_CATEGORY_BREAKDOWN,
            (value) => {
              return [value.toFixed(2), "Amount"];
            }
          )}
          {PermissionManager.isUserManagementAccessible() &&
            this.getChart(
              t("PAYROLL"),
              CHART_TYPE.LINE,
              CHART_API_SOURCES.PAYROLL,
              (value) => {
                return [value.toFixed(2), "Payroll"];
              }
            )}
          {!PermissionManager.isUserManagementAccessible() &&
            this.getChart(
              t("PAYROLL"),
              CHART_TYPE.PIE,
              CHART_API_SOURCES.PAYROLL,
              (value) => {
                return [value.toFixed(2), "Payroll"];
              }
            )}
          {this.getChart(
            t("LEAVES_PER_MONTH"),
            CHART_TYPE.BAR_VERTICAL,
            CHART_API_SOURCES.LEAVES_PER_MONTH,
            (value) => {
              return [value, "Leaves"];
            },
            true
          )}
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  getChart(title, type, apiSource, tooltipFormatter, isLastInRow = false) {
    return (
      <ChartHolder
        title={title}
        type={type}
        apiSource={apiSource}
        tooltipFormatter={tooltipFormatter}
        isLastInRow={isLastInRow}
      />
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  gotoProductURL = (product) => {
    let url = null;
    switch (product) {
      case PRODUCTS.ERP:
        url = isComplianceCountry(product)
          ? ApiConstants.PRODUCT_URL_ERP_PLUS
          : ApiConstants.PRODUCT_URL_ERP;
        break;
      case PRODUCTS.CRM:
        url = ApiConstants.PRODUCT_URL_CRM_PLUS;
        break;
      case PRODUCTS.PEOPLE:
        url = isComplianceCountry(product)
          ? ApiConstants.PRODUCT_URL_PEOPLE_PLUS
          : ApiConstants.PRODUCT_URL_PEOPLE;
        break;
      case PRODUCTS.REPORT_BUILDER:
        url = ApiConstants.PRODUCT_URL_REPORT_BUILDER;
        break;
      default:
        url = null;
    }

    if (url) {
      window.open(url, "_blank");
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
}

export default withTranslation()(ReportsDashboard);
