class CompanyDetailManager {
  static companyDetails = null;
  static allOrgs = [];

  static isCompanyDataAvailable() {
    return this.companyDetails !== null && this.companyDetails !== undefined;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static setCompanyDetails = (data) => {
    CompanyDetailManager.companyDetails = {...data,tenantName: data.name};
  };

  static getCompanyDetails = () => {
    return this.companyDetails;
  };

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getCompanyName = () => {
    return CompanyDetailManager.companyDetails ? CompanyDetailManager.companyDetails.tenantName : null;
  };
  static updateCompanyName = (name) => {
    CompanyDetailManager.setCompanyDetails({ ...CompanyDetailManager.companyDetails, name: name, tenantName: name })
  };

  static getCompanyLocation = () => {
    return this.companyDetails ? this.companyDetails.country : null;
  };

  static getCompanyStatus = () => {
    return this.companyDetails ? this.companyDetails.status : null;
  };
  static getCountryCode = () =>{
    return this.companyDetails ? this.companyDetails.country : null;
  }
  static getCurrencyCode = () =>{
    return this.companyDetails ? this.companyDetails.currency : null;
  }
  static setCountry = (country) =>{
    this.companyDetails.country = country;
  }
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static setAllOrgs = (data) => {
    this.allOrgs = data;
  };

  static getAllOrgs = () => {
    return this.allOrgs;
  };
}

export default CompanyDetailManager;
