import React, { Component } from "react";
import RouteManager from "../../managers/RouteManager";

import {
  DKLabel,
  DKButton,
  DKInput,
  showToast,
  TOAST_TYPE,
  showAlert,
} from "deskera-ui-library";

import { MAX_SECTION_WIDTH } from "../../constants/Constant";

import ic_key from "../../assets/icons/ic_key_2.png";
import ic_lock from "../../assets/icons/white/ic_lock.png";
import ic_settings from "../../assets/icons/ic_settings.png";
import IAM from "../../services/iam";
import IAMManager from "../../managers/IAMManager";
import ChangePasswordPopup from "../../components/common/ChangePasswordPopup";
import DisableTFA from "../common/DisableTFA";
import Utility from "../../utility/Utility";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import ApiConstants from "../../constants/ApiConstants";
import TimeoutSettings from "./TimeoutSettings";
import PasswordPolicyPopup from "./PasswordPolicyPopup";
import UserManager from "../../managers/UserManager";

class SecurityDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTwoFactorAuthEnabled: false,
      showQRCode: false,
      recoveryCodes: [],
      showRecoveryCodePopup: false,
      hasUserCopiedCodes: false,
      qrCodeDetails: {
        codeDetails: null,
        otpCode: "",
        isValidOtp: true,
        isValidOtpTapped: false,
      },
      showDisableTFA: false,
      showChangePasswordView: false,
      showPasswordPolicyView: false,
    };
  }

  componentDidMount() {
    RouteManager.setPresenter(this);
    this.getTFAStatus();
  }

  getTFAStatus() {
    IAM.getTwoFactorAuthStatus().then((res) => {
      this.setState({
        isTwoFactorAuthEnabled: IAMManager.getTFAStatus(),
      });
    });
  }

  handleEnableTwoFactorAuthClick = () => {
    if (!this.state.isTwoFactorAuthEnabled) {
      IAM.enableTwoFactorAuthStatus().then(
        (res) => {
          this.setState((prevState) => ({
            showQRCode: !prevState.showQRCode,
            qrCodeDetails: {
              ...prevState.qrCodeDetails,
              codeDetails: IAMManager.getTwoFactorAuthCode(),
            },
          }));
        },
        (err) => {}
      );
    } else {
      this.setState({ showDisableTFA: true });
    }
  };

  onOtpSubmitContinue = () => {
    this.setState(
      (prevState) => ({
        qrCodeDetails: {
          ...prevState.qrCodeDetails,
          isValidOtpTapped: true,
        },
      }),
      () => {
        if (this.validateOtp()) {
          IAM.registerTwoFactorAuthStatusApp({
            mfaCode: this.state.qrCodeDetails.otpCode,
          }).then(
            (res) => {
              this.setState((prevState) => ({
                isTwoFactorAuthEnabled: IAMManager.getTFAStatus(),
                recoveryCodes: res.recoveryCodes,
                showRecoveryCodePopup: !prevState.showRecoveryCodePopup,
                showQRCode: !prevState.showQRCode,
              }));
            },
            (err) => {
              this.setState((prevState) => ({
                isTwoFactorAuthEnabled: IAMManager.getTFAStatus(),
                qrCodeDetails: {
                  ...prevState.qrCodeDetails,
                  isValidOtp: !prevState.qrCodeDetails.isValidOtp,
                },
              }));
            }
          );
        }
      }
    );
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onOtpInputChange = (value) => {
    this.setState((prevState) => ({
      qrCodeDetails: {
        ...prevState.qrCodeDetails,
        otpCode: value,
      },
    }));
  };

  validateOtp = () => {
    if (
      this.state.qrCodeDetails.otpCode.trim() === "" ||
      isNaN(this.state.qrCodeDetails.otpCode)
    ) {
      return false;
    } else {
      return true;
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="row fw-m fs-l">Security</div>
        <div className="row row-responsive mt-l align-items-start">
          <div className="column" style={{ flex: 1 }}>
            <div
              className="column parent-width"
              style={{ maxWidth: MAX_SECTION_WIDTH }}
            >
              {this.get2FASettingsView()}
              {this.state.showRecoveryCodePopup && this.recoveryCodesView()}
              {this.getPasswordView()}
              {UserManager.isUserOwner() && this.getPasswordPolicyView()}
              {this.state.showChangePasswordView ? (
                <ChangePasswordPopup
                  onCancel={() =>
                    this.setState({ showChangePasswordView: false })
                  }
                />
              ) : null}
              {this.state.showPasswordPolicyView ? (
                <PasswordPolicyPopup
                  onCancel={() =>
                    this.setState({ showPasswordPolicyView: false })
                  }
                />
              ) : null}
              {this.state.showDisableTFA ? (
                <DisableTFA
                  onConfirm={(password) => this.disableTFA(password)}
                  onCancel={() => this.setState({ showDisableTFA: false })}
                />
              ) : null}
              {ApiConstants.TIMEOUT_ENABLED && <TimeoutSettings />}
            </div>
          </div>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  get2FASettingsView() {
    const { isTwoFactorAuthEnabled } = this.state;
    return (
      <div className="pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width column  mb-xl">
        <div className="parent-width">
          <div className="row">
            <DKLabel
              text={t("TWO_FACTOR_AUTHENTICATION")}
              className="fw-m fs-m"
            />
            <DKLabel
              className={`fw-m pl-s pr-s pt-xs pb-xs ml-r border-radius-m ${
                isTwoFactorAuthEnabled
                  ? "text-green bg-chip-green"
                  : "text-red bg-chip-red"
              }`}
              text={`${isTwoFactorAuthEnabled ? t("ENABLED") : t("DISABLED")}`}
            />
          </div>
          <DKLabel
            text={t("TWO_FACTOR_AUTHENTICATION_TEXT")}
            className="text-gray mt-xs"
          />
          {isTwoFactorAuthEnabled
            ? this.getDisableTFAView()
            : this.getEnable2FAView()}
          {this.getFooter()}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getEnable2FAView() {
    return (
      <div className="bg-gray1 border-radius-s border-m pt-l pb-l pl-l pr-l  mt-xxl border-box row">
        <div className="column parent-width">
          <DKLabel text={t("2FA_SETTINGS")} className="fw-m fs-m" />
          <DKLabel
            text={t("SCAN_THE_CODE_USING_MOBILE")}
            className="text-gray mt-xs"
          />
          {!this.state.showQRCode ? (
            <DKButton
              title={t("ENABLE_2FA")}
              className="bg-blue text-white fw-m text-wrap-none mt-l"
              icon={ic_lock}
              onClick={this.handleEnableTwoFactorAuthClick}
            />
          ) : null}
          {this.state.showQRCode ? this.qrCodeView() : null}
        </div>
      </div>
    );
  }
  getDisableTFAView() {
    let { isTwoFactorAuthEnabled } = this.state;
    return (
      <div className="column parent-width">
        <DKButton
          title={`${
            isTwoFactorAuthEnabled ? t("DISABLE_2FA") : t("ENABLE_2FA")
          }`}
          className={`text-white fw-m text-wrap-none mt-l ${
            isTwoFactorAuthEnabled ? "bg-red" : "bg-blue"
          }`}
          icon={ic_lock}
          onClick={this.handleEnableTwoFactorAuthClick}
        />
      </div>
    );
  }

  qrCodeView = () => {
    const { qrCodeDetails } = this.state;
    return (
      <div className="column align-items-start">
        {/* TODO: REVIEW THIS SRC CODE */}
        <img
          className="mt-xl shadow-m"
          src={`data:image/jpg;base64,${qrCodeDetails.codeDetails.qrCodeBytes}`}
          alt=""
        />
        <DKLabel text={t("SCAN_THE_CODE")} className=" fs-r mt-xxl" />
        <DKInput
          needHeader={false}
          name="OTP code *"
          placeholder="OTP Code *"
          value={this.state.qrCodeDetails.otpCode}
          onChange={(value) => this.onOtpInputChange(value)}
          required
          className="mt-r"
          canValidate={this.state.qrCodeDetails.isValidOtpTapped}
          invalid={
            this.state.qrCodeDetails.isValidOtpTapped && !this.validateOtp()
          }
        />
        <DKButton
          title={t("REGISTER_WITH_2FA")}
          className="bg-blue text-white mt-l fw-m display-block pt-s pr-s pb-s pl-s"
          onClick={this.onOtpSubmitContinue}
        />
      </div>
    );
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getFooter() {
    return (
      <div className="bg-chip-green border-radius-s pt-r pb-r pl-r pr-r parent-width mt-xxl border-box row">
        <div className="column">
          {this.state.isTwoFactorAuthEnabled ? (
            <DKLabel text={t("2FA_ENABLED")} className="text-green fw-m" />
          ) : (
            <DKLabel text={t("2FA_REQUIRED")} className="text-green fw-m" />
          )}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getPasswordView() {
    return (
      <div className="pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width column  mb-xl">
        <div className="parent-width">
          <DKLabel text={t("CHANGE_PASSWORD")} className="fw-m fs-m" />
          <DKLabel
            text="You can change or modify your password here"
            className="text-gray mt-xs"
          />
          <div className="row mt-l">
            <DKButton
              title={t("CHANGE_ACCOUNT_PASSWORD")}
              className="bg-gray2 border-m text-whites fw-m"
              icon={ic_key}
              onClick={() => {
                this.setState({ showChangePasswordView: true });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
  disableTFA(password) {
    IAM.disableTwoFactorAuthStatusApp({ password }).then(
      (data) => {
        this.setState({ showDisableTFA: false });
        this.getTFAStatus();
      },
      (err) => {}
    );
  }
  recoveryCodesView = () => {
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ maxWidth: 550 }}>
          <div className="row justify-content-between">
            <div className="row">
              <DKLabel text={t(`2FA_RECOVERY`)} className="fw-m fs-l" />
            </div>
            <div>
              <div className="row">
                <DKButton
                  title="Finish"
                  className="bg-gray1 border-m fw-m"
                  onClick={() => this.onFinish()}
                />
                <DKButton
                  title="Copy"
                  className="bg-blue ml-r text-white fw-m"
                  onClick={() => this.copyRecoveryCodes()}
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-between mt-s">
            <div className="row">
              <DKLabel
                text={t(`SHOULD_YOU_EVER_LOSE_YOUR_PHONE`)}
                className="text-gray mt-xs"
              />
            </div>
          </div>
          <div className="row justify-content-between mt-m">
            <div className="row">
              <DKLabel
                text={t(`PLEASE_SAVE_THEM`)}
                className="text-gray mt-xs"
              />
            </div>
          </div>
          <div lassName="row">
            <ul id="recoveryCodeList">
              {this.state.recoveryCodes.map((code) => (
                <li className="mb-l text-align-left">{code}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  copyRecoveryCodes = () => {
    Utility.copyToClipboard(this.state.recoveryCodes.toString());
    this.setState({ hasUserCopiedCodes: true });
    showToast(t("RECOVERY_CODES_COPIED_TO_CLIPBOARD"), TOAST_TYPE.SUCCESS);
  };
  onFinish() {
    if (!this.state.hasUserCopiedCodes) {
      let buttons = [
        {
          title: "Close",
          className: "bg-gray2",
          onClick: () => {
            this.closeRecoveryCodesPopup();
          },
        },
        {
          title: "Copy & Close",
          className: "bg-blue text-white ml-r",
          onClick: () => {
            this.copyRecoveryCodes();
            this.closeRecoveryCodesPopup();
          },
        },
      ];
      showAlert(
        t("NOT_COPIED_RECOVERY_CODE"),
        t("CANNOT_BE_RETRIEVED_LATER"),
        buttons
      );
    } else {
      this.closeRecoveryCodesPopup();
    }
  }
  closeRecoveryCodesPopup() {
    this.setState({
      hasUserCopiedCodes: false,
      showRecoveryCodePopup: false,
      recoveryCodes: [],
    });
  }

  getPasswordPolicyView() {
    return (
      <div className="pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s bg-white parent-width column  mb-xl">
        <div className="parent-width">
          <DKLabel text={t("PASSWORD_POLICY")} className="fw-m fs-m" />
          <DKLabel
            text="You can setup password policy for users here"
            className="text-gray mt-xs"
          />
          <div className="row mt-l">
            <DKButton
              title={t("SETUP_PASSWORD_POLICY")}
              className="bg-gray2 border-m text-whites fw-m"
              icon={ic_settings}
              onClick={() => {
                this.setState({ showPasswordPolicyView: true });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(SecurityDashboard);
