import React from "react";

import {
  DKButton,
  DKIcon,
  DKLabel,
  DKLine,
  showLoader,
  removeLoader,
} from "deskera-ui-library";

import DataParser from "../../Helper/DataParser";
import Subscription from "../../services/subscription";
import { toCurrencyFormat } from "../../utility/Utility";
import ProductIcon from "./ProductIcon";
import { CODE_TYPE } from "../../constants/Enum";

import ic_document from "../../assets/icons/ic_document.png";
import ic_print from "../../assets/icons/ic_printer.png";
import ic_coupon from "../../assets/icons/ic_coupon.png";

import MixPanelManager from "../../managers/common/MIxpanelManger";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
class InvoiceDetailPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onPrintClick = () => {
    showLoader(t("PREPARING"));
    Subscription.printInvoice({
      InvoiceID: this.props.invoiceDetailsData.InvoiceID,
    }).then(
      (res) => {
        removeLoader();
        const responseBlob = new Blob([res], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(responseBlob);
        window.open(fileURL, "_blank");
        // this.props.onClose();
      },
      (err) => {
        removeLoader();
      }
    );
  };
  componentDidMount() {
    MixPanelManager.invoiceView({
      doc_type: "INVOICE_VIEW",
      doc_id: this.props.invoiceDetailsData.InvoiceID,
    });
  }
  render() {
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ maxWidth: 520 }}>
          {this.getHeader()}
          {this.getDetails()}
        </div>
      </div>
    );
  }

  cancelTapped = () => {
    this.props.onClose();
  };

  getHeader() {
    const { invoiceDetailsData } = this.props;
    return (
      <div className="row justify-content-between mt-s">
        <div>
          <div className="row align-items-start">
            <DKIcon src={ic_document} className="mr-s ic-s-2" />
            <div className="column">
              <DKLabel text="Invoice details" className="fw-m fs-l" />
              <DKLabel
                text={`Date: ${invoiceDetailsData.ChargeDate}`}
                className="text-gray mt-xs"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <DKButton
              title={t("PRINT")}
              onClick={this.onPrintClick}
              className="bg-gray1 border-m fw-m mr-r"
              icon={ic_print}
            />
            <DKButton
              title={t("CLOSE")}
              className="bg-gray1 border-m fw-m"
              onClick={this.cancelTapped}
            />
          </div>
        </div>
      </div>
    );
  }

  getDetails = () => {
    const { invoiceDetailsData } = this.props;
    return (
      <div className="column parent-width mt-l">
        {invoiceDetailsData.Items && invoiceDetailsData.Items.length !== 0
          ? invoiceDetailsData.Items.map((obj, index) => {
              let isCiscountCode =
                obj.Type === CODE_TYPE.COUPON_CODE ||
                obj.Type === CODE_TYPE.PROMO_CODE;

              return (
                <div className="column parent-width">
                  <div
                    className="row justify-content-between border-box pt-r pb-r "
                    key={index}
                  >
                    <div>
                      <div className="row align-items-start">
                        {!isCiscountCode && (
                          <ProductIcon
                            product={
                              obj.Type === "plan"
                                ? invoiceDetailsData.Product
                                : null
                            }
                            className="mr-r"
                          />
                        )}
                        {isCiscountCode && (
                          <DKIcon
                            src={ic_coupon}
                            className="bg-gray1 border-m border-radius-m ic-s mr-r"
                            style={{ padding: 4 }}
                          />
                        )}
                        <div className="column">
                          <DKLabel text={obj.Name} className="fw-b" />
                          <DKLabel
                            text={obj.Description}
                            className="text-gray mt-xs"
                          />
                          {obj.type === "prepaid" && (
                            <DKLabel
                              text={`Valid upto: ${DataParser.getDateFormatted(
                                new Date(obj.ExpiryDate)
                              )}`}
                              className="mt-xs"
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <DKLabel
                      className={
                        "text-align-left fs-l fw-m text-wrap-none ml-l " +
                        (isCiscountCode ? "text-red" : "")
                      }
                      text={`${isCiscountCode ? "- " : ""}${toCurrencyFormat(
                        obj.Amount,
                        invoiceDetailsData.Currency.toUpperCase()
                      )}`}
                    />
                  </div>
                  <DKLine />
                </div>
              );
            })
          : null}

        <DKLabel
          className=" fs-xl fw-m align-self-end mt-xl text-wrap-none mb-m pl-s pr-s pt-s pb-s bg-gray1 border-radius-s"
          text={`Total: ${toCurrencyFormat(
            invoiceDetailsData.TotalAmount,
            invoiceDetailsData.Currency.toUpperCase()
          )}`}
        />
      </div>
    );
  };
}

export default withTranslation()(InvoiceDetailPopup);
