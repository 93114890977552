import React, { Component, Fragment } from "react";

import ic_home from "../../assets/icons/white/ic_home.png";
import ic_dashboard from "../../assets/icons/white/ic_dashboard.png";
import ic_user from "../../assets/icons/white/ic_user.png";
import ic_cards from "../../assets/icons/white/ic_cards.png";
import ic_settings from "../../assets/icons/white/ic_settings.png";
import ic_key from "../../assets/icons/white/ic_key.png";

import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import AppManager from "../../managers/AppManager";
import UserManager from "../../managers/UserManager";
import PermissionManager from "../../managers/PermissionManager";

import Utility from "../../utility/Utility";
import { URL_PARAMS } from "../../constants/Enum";

import {
  DKSidebar,
  showLoader,
  removeLoader,
  showToast,
  TOAST_TYPE,
} from "deskera-ui-library";

import { BrowserRouter as Router } from "react-router-dom";

import SideBarService from "../../services/sidebar";
import {
  isMobileAppWebView,
  isViewportLarge,
} from "../../utility/ViewportSizeUtils";
import Tenant from "../../services/tenant";
import CompanyDetailManager from "../../managers/CompanyDetailManager";
import IAM from "../../services/iam";
import AddNewTenant from "../common/AddNewTenant";
import Popup from "../common/Popup";
import { t } from "i18next";

export default class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuExpanded: SideBarService.isSideBarExpanded,
      selectedTenantDetails: {},
      tenantList: [],
      isBookkeeperFlow: false,
    };
  }
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let userID = !Utility.isEmpty(urlParams.get(URL_PARAMS.USER_ID))
      ? urlParams.get(URL_PARAMS.USER_ID)
      : "";
    let tenantID = !Utility.isEmpty(urlParams.get(URL_PARAMS.TENANT_ID))
      ? urlParams.get(URL_PARAMS.TENANT_ID)
      : "";

    if (userID !== "" && tenantID !== "") {
      this.setState({
        isBookkeeperFlow: true,
      });
    }

    showLoader();
    Tenant.getUsertenants().then(
      (res) => {
        let userDetail = UserManager?.getUserDetails();
        if (userDetail) {
          this.setState({
            selectedTenantDetails: {
              tenantName: userDetail.tenantName,
              tenantId: userDetail.tenantID,
            },
            tenantList: CompanyDetailManager.getAllOrgs(),
          });
          removeLoader();
        }
      },
      (err) => {}
    );

    window.addEventListener("onSideBarChange", this.handleSidebarToggle);
    window.addEventListener("resize", this.handleSidebarOnResize);
  }

  componentWillUnmount() {
    window.removeEventListener("onSideBarChange", this.handleSidebarToggle);
    window.removeEventListener("resize", this.handleSidebarOnResize);
  }

  handleSidebarOnResize() {
    SideBarService.setIsSideBarExpanded();
    SideBarService.triggerSidebarChangeEvent();
  }

  handleSidebarToggle = () => {
    this.setState({ isMenuExpanded: SideBarService.isSideBarExpanded });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  onMenuItemClick = (route) => {
    RouteManager.navigateToPage(route);
    if (!isViewportLarge()) {
      SideBarService.toggleSideBar();
    }
  };

  initialSideBarList() {
    let sidebarList = [];
    if (!this.state.isBookkeeperFlow) {
      sidebarList = [
        ...sidebarList,
        {
          name: t("HOME"),
          icon: ic_home,
          route: PAGE_ROUTES.HOME,
          onClick: () => {
            this.onMenuItemClick(PAGE_ROUTES.HOME);
          },
        },
        {
          name: t("DASHBOARD"),
          icon: ic_dashboard,
          route: PAGE_ROUTES.REPORTS,
          onClick: () => {
            this.onMenuItemClick(PAGE_ROUTES.REPORTS);
          },
        },
        {
          name: t("SECURITY"),
          icon: ic_key,
          route: PAGE_ROUTES.SECURITY,
          onClick: () => {
            this.onMenuItemClick(PAGE_ROUTES.SECURITY);
          },
        }
      ];

      if (PermissionManager.isUserManagementAccessible()) {
        sidebarList.splice(2, 0, {
          name: t("USER_MANAGEMENT"),
          icon: ic_user,
          route: PAGE_ROUTES.USERS,
          onClick: () => {
            this.onMenuItemClick(PAGE_ROUTES.USERS);
          },
        });
        sidebarList.splice(3, 0, {
          name: t("MY_SUBSCRIPTIONS"),
          icon: ic_cards,
          route: PAGE_ROUTES.BILLING,
          onClick: () => {
            this.onMenuItemClick(PAGE_ROUTES.BILLING);
          },
        });
        sidebarList.splice(5, 0, {
          name: t("SETTINGS"),
          icon: ic_settings,
          route: PAGE_ROUTES.SETTINGS,
          onClick: () => {
            this.onMenuItemClick(PAGE_ROUTES.SETTINGS);
          },
        });
      }
    }

    return sidebarList;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  onSelectTenant = (newSelectedTenantDetails) => {
    if (
      newSelectedTenantDetails.tenantId !==
      this.state.selectedTenantDetails.tenantId
    ) {
      IAM.switchTenant({ tenantId: newSelectedTenantDetails.tenantId }).then(
        (res) => {
          AppManager.refreshApp();
        },
        (err) => {}
      );
    } else {
      showToast(t("CURRENT_ORGANISATION"), TOAST_TYPE.SUCCESS);
    }
  };

  onSaveNewTenantTapped = (newTenantName) => {
    Tenant.getOrgWithoutSetup({
      tenantName: newTenantName,
      isBookkeeper: false,
      refreshToken: "refreshToken",
    }).then((res) => {
      IAM.switchTenant({ tenantId: res.tenantId }).then((res) => {
        window.location.reload();
      });
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <Fragment>
        <div
          className={`transparent-background parent-height parent-width position-absolute ${
            this.state.isMenuExpanded
              ? "display-only-mobile z-index-5"
              : "display-none z-index-0"
          }`}
          onClick={() => SideBarService.toggleSideBar()}
        ></div>
        {!this.state.isBookkeeperFlow && (
          <Router>
            <DKSidebar
              className="z-index-6"
              collapsedWidth={0}
              expandedWidth={230}
              menuItemList={this.initialSideBarList()}
              tenantList={this.state.tenantList}
              allowTenantSelection={!isMobileAppWebView()}
              isMenuExpanded={this.state.isMenuExpanded}
              selectedTenantDetails={this.state.selectedTenantDetails}
              onTenantSelect={(newTenantDetails) =>
                this.onSelectTenant(newTenantDetails)
              }
              onAddNewTenantTapped={(data) =>
                this.setState({ showAddTenantPopup: true })
              }
              onExpandCollapse={() => SideBarService.toggleSideBar()}
            />
            {this.state.showAddTenantPopup && (
              <Popup popupWindowStyles={{ overflow: "visible" }}>
                <AddNewTenant
                  onClose={() => this.setState({ showAddTenantPopup: false })}
                  onSave={(data) =>
                    this.setState({ showAddTenantPopup: false })
                  }
                />
              </Popup>
            )}
          </Router>
        )}
      </Fragment>
    );
  }
}
