import React, { Component } from "react";
import {
    DKLabel,
    DKButton,
    setPersistentValue
} from "deskera-ui-library";
import { WALKTHROUGH_STATUS } from "../../constants/Constant";

import RouteManager from "../../managers/RouteManager";

class WalkthroughTooltip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            displayWalkthrough: false,
            walkthroughStep: 0,
        };
    }

    componentWillReceiveProps(nextProp) {
        this.setState({
            data: nextProp.data,
        });
    }

    render() {
        return <div>
            {this.state.displayWalkthrough &&
                <div className="transparent-background-light">
                    {this.getTooltip(this.state.data[this.state.walkthroughStep])}
                </div>
            }
        </div>
    }

    getTooltip(item) {
        return (
            <div id="tooltip" className={` ${item.arrowPosition} on`} style={{left: item.tooltipPosition.left, top: item.tooltipPosition.top}}>
                <div className="tooltip-arrow"></div>
                <div className="tooltip-label mr-s">
                    <div className="p-v-s p-h-s">
                        {item.title.length !== 0 &&
                            <DKLabel text={item.title} className="col fw-m " />
                        }
                        <DKLabel text={item.msg} className="pt-m"/>
                    </div>
                    <div 
                        className="row pb-s flex justify-content-end"
                        style={{
                            position: "relative",
                        }}
                    >
                        {(item.title.length === 0 || item.title === "Start Tour") &&
                            <DKButton
                                title="Skip"
                                className="text-underline text-blue"
                                onClick={() => {
                                    this.setState({displayWalkthrough : false}, () => setPersistentValue("skip", WALKTHROUGH_STATUS))
                                }}
                            />
                        }
                        <DKButton
                            title={item.title.length !== 0 ? item.title === "Start Tour" ? "Begin" : "Let's begin" : "Next"}
                            className="bg-blue text-white"
                            onClick={() =>{
                                if(item.title === "Finished Tour") {
                                    this.setState({displayWalkthrough : false}, () => setPersistentValue("done", WALKTHROUGH_STATUS))
                                } else {
                                    this.setState({
                                        walkthroughStep : this.state.walkthroughStep + 1
                                    })
                                }
                                if(item.navigateToPage !== "") {
                                    RouteManager.navigateToPage(item.navigateToPage)
                                } 
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default WalkthroughTooltip;