export const ENV = {
  DEV: "DEV",
  QA: "QA",
  QA2: "QA2",
  STAGING: "STAGING",
  PROD: "PROD",
};
export const PRODUCTS = {
  AIO: "aio",
  ERP: "erp",
  MRP: "mrp",
  BOOKS_PLUS: "books_plus",
  CRM: "crm",
  CRM_PLUS: "crm_plus",
  PEOPLE: "people",
  PEOPLE_PLUS: "people_plus",
  WEBSITE_BUILDER: "website builder",
  SHOP: "shop",
  REPORT_BUILDER: "report_builder",
  DOCUMENT_BUILDER: "document builder",
  DOCUMENT_SCANNER: "document scanner",
  ORGANISATION: "organisation",
  CONSOLE: "console",
  WORKFLOW_ENGINE: "workflow_engine",
  ATTENDANCE: "attendance",
  EXPENSE: "expense",
  CHAT: "chat",
  EM: "em", //Expense
  DS: "ds", //Shop
  EL: "el", //Leave Management,
  ERP_V2: "erp_v2",
  AI: "ai"
};

export const PLANS = {
  STARTUP: "startup",
  ESSENTIAL: "essential",
  INDIVIDUAL: "individual",
  PROFESSIONAL: "professional",
  PROFESSIONAL_I: "Professional-I",
  PROFESSIONAL_A: "Professional-A",
  FREEMIUM: "Professional-Freemium",
  SMALL_BUSINESS: "small business",
  GROWTH: "growth",
  MID_MARKET: "mid market",
};

export const INTERVALS = {
  YEARLY: "yearly",
  MONTHLY: "monthly",
};

export const ALIGNMENT = {
  LEFT: "left",
  RIGHT: "right",
  CENTER: "center",
  JUSTIFY: "justify",
};

export const FORM_TYPE = {
  BOOK_A_DEMO: "BOOK_A_DEMO",
  SIGN_UP_LEAD_GENERATION: "SIGN_UP_LEAD_GENERATION",
  CONTACT_US: "CONTACT_US",
};

export const API_STATUS = {
  SUCCESS_200: 200,
  SUCCESS_201: 201,
};

export const USER_ROLE = {
  OWNER: "Owner",
  ADMIN: "Admin",
  MANAGER: "Manager",
  MEMBER: "User",
};
export const USER_TYPE = {
  GUEST: "Guest",
  PAID: "Paid",
};
export const USER_STATUS = {
  ACTIVE: "Active",
  DISABLED: "Disabled",
  PENDING: "Pending",
};

export const SUBSCRIPTION_STATUS = {
  IN_TRIAL: "In trial",
  TRIAL_PENDING: "Trial available",
  EXPIRED: "Expired",
  ACTIVE: "Active",
  TRIAL_ENDED: "Trial over",
  CANCELLED: "Cancelled",
};

export const ACTION_KEY = {
  DELETE_COMAPANY: "delete_company",
  RESET_COMPANY: "reset_company",
};

export const DELETE_OPTIONS = [
  {
    label: "It's too costly",
    value: "It's too costly",
  },
  {
    label: "I found another product that fulfills my needs.",
    value: "I found another product that fulfills my needs.",
  },
  {
    label: "I don't use it enough.",
    value: "I don't use it enough.",
  },
  {
    label: "Some features I need are missing",
    value: "Some features I need are missing",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const PASSWORD_OBJ = {
  OLD_PASSWORD: "old password",
  NEW_PASSWORD: "new password",
  CONFIRM_NEW_PASSWORD: "confirm new password",
};

export const INPUT_TYPE = {
  TEXT: "text",
  PASSWORD: "password",
  DROPDOWN: "dropdown",
  NUMBER: "number",
};

export const PASSWORD_SETTING = {
  SHOW: "show",
  HIDE: "hide",
};

export const PASSWORD_ERROR = {
  ERROR_API: "error: api",
  ERROR_BLANK: "error: blank",
  ERROR_NOT_MATCHED: "error: mismatched",
  ERROR_NOT_MATCHED_FIELD: "error: mismatched other field",
  ERROR_LENGTH: "error: length invalid",
};

export const PLAN_UPDATE_TYPE = {
  TRIAL_START: "TrialStart",
  PLAN_START: "PlanStart",
  UPGRADE: "Upgrade",
  DOWNGRADE: "Downgrade",
};
export const INVOICE_STATUS = {
  PAID: "paid",
  FAILED: "failed",
};

export const CODE_TYPE = {
  PREPAID_CODE: "prepaid",
  PROMO_CODE: "promo / discount",
  PLAN_CODE: "plan",
  ADDON_CODE: "addon",
  COUPON_CODE: "coupon",
};

export const WEBSITE_TYPE = {
  HELP_DESK: "helpdesk",
  BUILDER: "builder",
};

export const CHART_TYPE = {
  BAR_VERTICAL: "Bar Vertical Graph",
  MULTI_BAR_VERTICAL: "Multi Vertical Bar Graph",
  BAR_HORIZONTAL: "Bar Horizontal Graph",
  MULTI_BAR_HORIZONTAL: "Multi Bar Horizontal Graph",
  LINE: "Line Chart",
  PIE: "Pie Chart",
  DONUT: "DONUT",
};

export const CHART_API_SOURCES = {
  INVOICES: "INVOICES",
  AGE_RECEIVABLE: "AGE_RECEIVABLE",
  BANK_ACCOUNTS: "BANK_ACCOUNTS",
  WON_AND_LOST_DEALS: "WON_AND_LOST_DEALS",
  PIPELINES: "PIPELINES",
  CONTACTS: "CONTACTS",
  EXPENSES_CLAIMED: "EXPENSES_CLAIMED",
  PAYROLL: "PAYROLL",
  LEAVES_PER_MONTH: "LEAVES_PER_MONTH",
  MY_CATEGORY_BREAKDOWN: "MY_CATEGORY_BREAKDOWN",
};

export const PERMISSIONS = {
  CONSOLE: [
    "org_manage_subscription_rwd",
    "org_manage_role_rwd",
    "manage_user_rwd",
    "manage_user_r",
  ],
};

export const CURRENCYLIST = {
  USD: "USD",
  INR: "INR",
  IDR: "IDR",
  MYR: "MYR",
};

export const COUNTRY_CODE = {
  IN: "IN",
  SG: "SG",
};

export const TRACK_EVENT = {
  DELETE_COMPANY: "GO_DELETE_COMPANY",
  ADD_CARD: "GO_ADD_CARD",
  CODE_APPLIED: "GO_CODE_APPLIED",
  CANCEL_SUBSCRIPTION: "GO_CANCEL_SUBSCRIPTION",
  CANCEL_TRIAL: "GO_CANCEL_TRIAL",
  START_TRIAL: "GO_START_TRIAL",
  PLAN_CHANGED: "GO_PLAN_CHANGED",
  ACTIVATE_PLAN: "GO_ACTIVATE_PLAN",
};

export const CASHFREE_PAYMENT_STATUS = {
  ACTIVE: "active",
};

export const BOOKKEEPER_FLOW = {
  TENANT_ID: "tenantID",
  USER_ID: "userID",
  IS_EDIT: "isEdit",
};

export const URL_PARAMS = {
  USER_ID: "UserID",
  TENANT_ID: "TenantID",
  IS_EDIT: "isEdit",
};
export const COMPLIANCE_COUNTRIES = ["US", "MY", "SG", "IN", "ID"];
export const COMPLIANCE_COUNTRIES_FOR_PEOPLE_PLUS = ["US", "MY", "SG", "IN", "TH", "UK", "GB", "IL"];
export const COMPLIANCE_COUNTRIES_FOR_OLD_BOOKS = [ "AE", "CA", "DE", "AU", "BE", "NL", "NZ", "PH", "SA"];
export const PLANS_NAME_V2 = {
  ERP: `ERP`,
  ERP_PLUS: `ERP+`,
  MRP_PLUS: `MRP+`,
};
export const PLANS_V2 = {
  ERP: `erp`,
  ERP_PLUS: `erp_plus`,
  MRP_PLUS: `mrp_plus`,
};
export const PRICING_PLANS_NAME_V2 = {
  STANDARD_PLAN: `Standard Plan`,
  BUSINESS_PLAN: `Business Plan`,
  PROFESSIONAL_PLAN: `Professional Plan`,
  SMALL_BUSINESS: `Small Business`,
  GROWTH: `Growth`,
  MID_MARKET: `Mid Market`,
  ENTERPRISE: `Enterprise`,
};
export const NEW_PLAN_TO_OLD_PLAN = {
	[PLANS.SMALL_BUSINESS]: PLANS.STARTUP,
	[PLANS.GROWTH]: PLANS.ESSENTIAL,
	[PLANS.MID_MARKET]: PLANS.PROFESSIONAL,
};
export const OLD_PLAN_TO_NEW_PLAN = {
	[PLANS.STARTUP]: PLANS.SMALL_BUSINESS,
	[PLANS.ESSENTIAL]: PLANS.GROWTH,
	[PLANS.PROFESSIONAL]: PLANS.MID_MARKET,
};
export const CTA_TITLES = {
    BUY_NOW : 'Buy Now',
    TALK_TO_SALE : 'Talk to Sales',
    START_TRIAL : 'Start Trial',
    CURRENT_PLAN : 'Current Plan',
}

export const PLAN_DETAILS_TABS = {
  PLAN_DETAILS: "Plan Details",
  BILLING: "Billing",
  CURRENT_USAGE: "Current Usage",
  PAYMENT_METHODS: "Payment Methods",
}