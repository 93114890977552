import { isComplianceCountry } from "../components/common/CommonUtils";
import ApiConstants from "../constants/ApiConstants";
import { CHART_INTERVALS } from "../constants/Constant";
import ChartParser from "../Helper/ChartParser";
import http from "../http";
import ReportManager from "../managers/ReportManager";
import Utility from "../utility/Utility";
import { COLUMN_CODE, TableManger, TABLES } from "../managers/TableManager";
import InitialApiManager from '../managers/InitialApiManager';
import UserManager from "../managers/UserManager";
import PermissionManager from "../managers/PermissionManager";
import User from "./user";
import UserDetails from "../components/common/UserDetails";
const INVOICE_EMPTY_RESPONSE = {
  initialStageCount: 0,
  initialStageAmount: 0.0,
  invoiceCount: 0,
  invoiceAmount: 0.0,
  closedDocumentCount: 0,
  closedDocumentAmount: 0.0,
};
class Reports {
  static getPipelines(params) {
    //later use 'https://api-dev.deskera.xyz/v1/crm/core/dashboard/stage-deal-count?pipelineId=637b460572a9a70013fbcfca&startDate=2020-12-28T05:16:17.476Z&endDate=2022-12-28T05:16:17.476Z'
    return http.get(ApiConstants.URL.REPORTS.PIPELINES, { params }).then(
      (response) => {
        if (!Utility.isEmpty(response)) {
          ReportManager.setPipelineViewData(response);
        }
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static getDealWonLostCount({ pipelineId = null, startDate = null, endDate = null, interval = 'monthly' }) {
    if (!startDate || endDate) [startDate, endDate] = this.getDefaultStartEndDate(interval);
    return http.get(ApiConstants.URL.DASHBOARD.DEAL_WON_LOST, {
        params: {
            startDate,
            pipelineId,
            endDate,
            interval,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
    });
}
static getDefaultStartEndDate(interval) {
  const currentDate = new Date();
  let endDate = new Date();
  let startDate = new Date();
  switch (interval) {
      case CHART_INTERVALS[0].interval:
          startDate.setDate(currentDate.getDate() - 6);
          endDate.setDate(currentDate.getDate())
          break;
      case CHART_INTERVALS[1].interval:
          startDate.setMonth(currentDate.getMonth() - 1);
          endDate.setDate(currentDate.getDate() + 1)
          break;
      case CHART_INTERVALS[2].interval:
          startDate.setFullYear(currentDate.getFullYear() - 1);
          startDate.setMonth(currentDate.getMonth() - 11);
          endDate.setMonth(currentDate.getMonth() + 1)
          break;
  }

  return [startDate, endDate];
}
  static getWonAndLostDeals(params) {
    
    return http
      .get(ApiConstants.URL.REPORTS.WON_AND_LOST_DEALS, { params })
      .then(
        (response) => {
          if (!Utility.isEmpty(response)) {
            ReportManager.setDealsData(response, params.interval);
          }
          return Promise.resolve(response);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((err) => {});
  }
  static async getContacts(params) {
    if(TableManger.tableMap.size !== 0) {
      return Reports.getContactAPI(params);
    } else {
      await InitialApiManager.getTableData().then((data) => {
        return Reports.getContactAPI(params);
      },error=>{
        return Promise.reject(error)
      })
    }
  }
  static getContactAPI(params) {
    const contactTableId = TableManger.getTableId(TABLES.CONTACT);
      const labelColumnId = TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.LABEL
      );
    return http.get(ApiConstants.URL.DASHBOARD.RECORD_COUNT_BY_COLUMN_ID(contactTableId,labelColumnId), { params }).then(
      (response) => {
        if (!Utility.isEmpty(response)) {
          ReportManager.setContactData(response);
        }
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static getAgeReceivable(params) {
    const url = isComplianceCountry() ? ApiConstants.URL.REPORTS.COMPLIANCE_BASED_AGE_RECEIVABLE : ApiConstants.URL.REPORTS.AGE_RECEIVABLE
    return http
      .get(url, { params: params })
      .then(
        (response) => {
          ReportManager.setAgeReceivable(response);
          return Promise.resolve(response);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
  static getInvoices() {
    const url = isComplianceCountry()? ApiConstants.URL.REPORTS.COMPLIANCE_BASED_INVOICES: ApiConstants.URL.REPORTS.INVOICES
    const params = this.getParamsForInvoices();
    const apiArray = [];
    params.forEach((data, index) => {
      apiArray.push(
        http
          .get(url, { params: data })
          .then(this.handleInvoiceResponse, this.handleInvoiceError)
      );
    });
    return Promise.all(apiArray).then(
      (response) => {
        ReportManager.setInvoicesData(response);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.resolve(err);
      }
    );
  }
  static handleInvoiceResponse = (res) => Promise.resolve(res);
  static handleInvoiceError = (err) => Promise.resolve(INVOICE_EMPTY_RESPONSE);
  static getBankAccount(params, callback) {
    return http
      .get(ApiConstants.URL.REPORTS.BANK_ACCOUNTS, params)
      .then(
        (response) => {
          if (response && response.content.length === 0) {
            callback(true);
          } else {
            ReportManager.setBankNames(response);
          }

          if (
            !Utility.isEmpty(response) &&
            !Utility.isEmpty(response.content)
          ) {
            Reports.getReportByBankAccount({
              accountCode: response.content[0].code,
            }).then((data) => {
              ReportManager.setBankAccountDetails(data);
              callback(true);
              return Promise.resolve(response);
            });
          }
          return Promise.resolve(response);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((err) => {});
  }

  static getExpenseClaimed(params) {
    return http
      .get(ApiConstants.URL.REPORTS.EXPENSES_CLAIMED, { params: params })
      .then(
        (response) => {
          ReportManager.setExpenseClaimed(response,params);
          return Promise.resolve(response);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
  static getExpenseBreakDown(params) {
    return http
      .get(ApiConstants.URL.REPORTS.EXPENSE_BREAKDOWN, { params: params })
      .then(
        (response) => {
          ReportManager.setExpenseBreakDown(response,params);
          return Promise.resolve(response);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
  static async getEmployeePaystub(params) {
    if(UserManager.getPeopleUser()?.user?.userId) {
      return await this.getPaystusById(UserManager.getPeopleUser()?.user?.userId)
    } else {
      await User.getPeopleUserInfo().then(res=>{
        return this.getPaystusById(res?.user?.userId)
      })
    }
    
  }
  static getPaystusById(id) {
    let url = ApiConstants.URL.REPORTS.PAYSTUB_EMPLOYEE(id)
    return http.get(url).then(
      (response) => {
        ReportManager.setEmloyeePaystub(response);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static getPayRoll(params) {
    return http.get(ApiConstants.URL.REPORTS.PAYROLL + `/${params}`).then(
      (response) => {
        ReportManager.setPayRoll(response);
        return Promise.resolve(response);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static getLeavesPerMonths(params) {
    return http
      .get(ApiConstants.URL.REPORTS.LEAVES_PER_MONTH, { params: params })
      .then(
        (response) => {
          if (
            response.errorCode === 6009 &&
            response.errorMessage === "mongo: no documents in result"
          ) {
            ReportManager.setLeavesPerMonth([]);
            return Promise.resolve([]);
          } else {
            ReportManager.setLeavesPerMonth(response);
            return Promise.resolve(response);
          }
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static getReportByBankAccount(params) {
    params["SKIP_REQUEST_INTERCEPTOR"] = true;
    return http
      .get(ApiConstants.URL.REPORTS.GET_BANK_ACCOUNT_REPORT, { params })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
  static getParamsForInvoices() {
    let params = [];
    for (let i = 0; i <= new Date().getMonth(); i++) {
      let date = Utility.getFirstAndLastDayByMonth(i);
      params.push({
        fromDate: date[0],
        toDate: date[1],
        query: "module=SALES_INVOICE",
        eType: "IC"
      });
    }
    return params;
  }

  static getAllLeavesPerMonths(params) {
    return http
      .get(ApiConstants.URL.REPORTS.ALL_LEAVES_PER_MONTH, { params: params })
      .then(
        (response) => {
          if (
            response.errorCode === 6009 &&
            response.errorMessage === "mongo: no documents in result"
          ) {
            ReportManager.setAllLeavesPerMonth([]);
            return Promise.resolve([]);
          } else {
            ReportManager.setAllLeavesPerMonth(response);
            return Promise.resolve(response);
          }
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
}

export default Reports;
