import React, { Component } from "react";
import  {
    DKLabel
} from "deskera-ui-library";

export default class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minutes: props.time,
            seconds: 0,
            anyTimeLeft: props.isAnytimeLeft
        }
    }

    componentDidMount() {
        this.timer()
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    componentWillReceiveProps(nextprops) {
        if(nextprops.time > 0 && !nextprops.isAnytimeLeft) {
            this.state = { 
                minutes: nextprops.time,
                seconds: 0, 
                anyTimeLeft: nextprops.isAnytimeLeft
            }
            this.timer();
        }
    }
    

    timer() {
        if (this.state.minutes > 0) {
            this.myInterval = setInterval(() => {
                const { seconds, minutes } = this.state
    
                if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                        seconds: seconds - 1
                    }))
                }
                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(this.myInterval);
                        this.props.onTimerEnds()
                        this.setState({
                            seconds: 0
                        })
                    } else {
                        this.setState(({ minutes }) => ({
                            minutes: minutes - 1,
                            seconds: 59
                        }))
                    }
                } 
                this.props.timeLeft({seconds: seconds, minutes: minutes});
            }, 1000)
        } else {

        }
    }

    render() {
        return (
            <div style={{display: "flex"}} className="mb-l mt-s">
                <DKLabel text="Resend OTP available in " />
                <DKLabel text={"&nbsp;0"+this.state.minutes + " : " + (this.state.seconds < 10 ? "0" +this.state.seconds : this.state.seconds)} className="fw-b"/>
            </div>
        )
    }
}