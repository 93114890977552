import ApiConstants from '../constants/ApiConstants';
import { WEBSITE_TYPE } from '../constants/Enum';
import Payload from '../constants/Payload';
import http from '../http';
import CRMManager from '../managers/CRMManager';

import {
    removeLoader, 
    showLoader 
} from "deskera-ui-library";

class CRM {
    static getCrmUsers(params) {
        return (
            http
                .get(ApiConstants.URL.CRM.USERS, { params })
                .then(res => {
                    CRMManager.setCrmusers(res);
                    return Promise.resolve(res);
                }, (err) => {
                    return Promise.reject(err);
                })
            );
    }

    static uploadCrmGuide(params) {
        return (
            http
                .put(ApiConstants.URL.CRM.GUIDE, { params })
                .then(res => {
                    return Promise.resolve(res);
                }, (err) => {
                    return Promise.reject(err);
                })
            );
    }
    static getCustomDomainStatus(webSiteType) {
        if (!webSiteType) webSiteType = WEBSITE_TYPE.HELP_DESK;
        const params = { [Payload.COMMON.WEBSITE_TYPE]: webSiteType };
        return http.get(ApiConstants.URL.CRM.GUIDE, { params: params })
            .then(response => Promise.resolve(response), err => Promise.reject(err));
    }
    static saveCustomDomain(webSiteType, params) {
        showLoader("Updating Custom Domain Settings...")
        return http.put(`${ApiConstants.URL.CRM.GUIDE}?${Payload.COMMON.WEBSITE_TYPE}=${webSiteType}`, params)
            .then(response => {
                removeLoader();
                Promise.resolve(response);
            }, err => {
                removeLoader();
                Promise.reject(err);
        });
    }
}

export default CRM;