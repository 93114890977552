import React, { Component } from "react";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import CheckoutPlanDetails from "../common/CheckoutPlanDetails";
import ic_coupon from "../../assets/icons/white/ic_coupon.png";
import ic_cards from "../../assets/icons/white/ic_cards.png";
import PlanManager from "../../managers/PlanManager";

import {
  DKLabel,
  DKButton,
  DKIcon,
  showAlert,
  DKInput,
  removeLoader,
} from "deskera-ui-library";

import ic_location from "../../assets/icons/ic_location.png";

import CreditCardView from "../common/CreditCardView";
import Utility, { getCapitalized } from "../../utility/Utility";
import SubscriptionManager from "../../managers/SubscriptionManager";
import Subscription from "../../services/subscription";
import PrepaidCodePopup from "../common/PrepaidCodePopup";
import DiscountCodePopup from "../common/DiscountCodePopup";
import UserManager from "../../managers/UserManager";
import Payload from "../../constants/Payload";
import BillingManager from "../../managers/BillingManager";
import {
  CURRENCYLIST,
  INTERVALS,
  PLANS,
  PRODUCTS,
  TRACK_EVENT,
} from "../../constants/Enum";
import CouponsManager from "../../managers/CouponsManager";
import { trackEventAC } from "../../managers/ActiveCampaignWrapper";
import CurrencyManager from "../../managers/CurrencyManager";
import AdyenCardView from "../common/AddAdyenCardUI/AdyenCardView";
import CashfreeView from "../common/CashfreeUI/CashfreeView";
import { MOBILE_APP_ACTIONS } from "../../constants/Constant";
import { isMobileAppWebView } from "../../utility/ViewportSizeUtils";

export default class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: PlanManager.getDefaultProduct(),
      plan: PlanManager.getDefaultPlan(),
      planInterval: PlanManager.getDefaultInterval(),
      isValidCard: false,
      billingAddress: {
        line1: "",
        line2: "",
        zipcode: "",
        city: "",
        state: "",
        country: "",
      },
      needPrepaidPopup: false,
      needDiscountPopup: false,
      discountCodeApplied: false,
      currency: this.getUpdatedCurrency(PlanManager.getDefaultProduct()),
    };
  }
  componentDidMount() {
    RouteManager.setPresenter(this);
    if (!UserManager.isUserOwner() && !UserManager.isUserAdmin()) {
      RouteManager.navigateToHome();
      return;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let para_product =
      urlParams.get("product") !== null &&
      urlParams.get("product") !== undefined
        ? urlParams.get("product")
        : PlanManager.getDefaultProduct();

    let para_plan =
      urlParams.get("plan") !== null && urlParams.get("plan") !== undefined
        ? urlParams.get("plan")
        : PlanManager.getDefaultPlan();

    let para_interval =
      urlParams.get("interval") !== null &&
      urlParams.get("interval") !== undefined
        ? urlParams.get("interval")
        : PlanManager.getDefaultInterval();

    if (
      para_product.toLowerCase() !== PRODUCTS.ERP_V2 ||
      para_interval.toLowerCase() !== INTERVALS.YEARLY
    ) {
      RouteManager.gotoCheckout(
        PRODUCTS.ERP_V2,
        PLANS.STARTUP,
        INTERVALS.YEARLY
      );
      window.location.reload();
      return;
    }

    this.setState({
      product: para_product,
      plan: para_plan,
      planInterval: para_interval,
      isValidCard: !Utility.isEmpty(BillingManager.getDefaultPaymentCard()),
      currency: this.getUpdatedCurrency(para_product),
    });
    this.getAddress();
  }

  getUpdatedCurrency = (productProp) => {
    let currency = SubscriptionManager.getCurrencySameAsActivePlanCurrency();
    if (productProp === PRODUCTS.ERP_V2 || productProp === PRODUCTS.MRP) {
      let currencyList = [CURRENCYLIST.INR, CURRENCYLIST.USD];
      if (!currencyList.includes(currency)) {
        currency = CURRENCYLIST.USD;
      }
    }

    return currency;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div>
        <div className="row fw-m fs-l">Checkout</div>
        <div className="row row-responsive mt-l align-items-start">
          <div className="column pr-r-only-web" style={{ flex: 1 }}>
            <div
              className="column parent-width"
              // style={{ maxWidth: MAX_SECTION_WIDTH }}
            >
              {!CouponsManager.isVendorPrepaidCouponApplied() &&
                (BillingManager.showStripePayment() ||
                  !BillingManager.isDefaultPaymentGateway()) &&
                this.getCreditCardDetailsView()}
              {!CouponsManager.isVendorPrepaidCouponApplied() &&
                BillingManager.showAdyenPayment() &&
                this.getAdyenCardView()}
              {!CouponsManager.isVendorPrepaidCouponApplied() &&
                BillingManager.showCashfreePayment() &&
                this.getCashfreeView()}
              {this.getBillingInfoView()}
            </div>
          </div>
          <div className="column right-section-1">
            {this.getPlanDetailsView()}
            <DKButton
              title="Do you have a prepaid code? "
              className={`text-white mt-l fw-m ${
                isMobileAppWebView()
                  ? " p-v-r bg-purple justify-content-center align-self-center justify-content-center mobile-width-90 "
                  : " bg-blue"
              }`}
              style={{ width: "100%" }}
              icon={ic_cards}
              onClick={this.showPrepaidPopup}
            />
            <DKButton
              title="Do you have a discount / promo code? "
              className={`bg-green text-white mt-l fw-m mb-l ${
                isMobileAppWebView()
                  ? " p-v-r justify-content-center align-self-center justify-content-center mobile-width-90"
                  : ""
              }`}
              style={{ width: "100%" }}
              icon={ic_coupon}
              onClick={this.showDiscountPopup}
            />
          </div>
        </div>
        {this.state.needPrepaidPopup && (
          <PrepaidCodePopup
            onClose={this.closePrepaidPopup}
            onCouponApply={this.prepaidCouponApplied}
          />
        )}
        {this.state.needDiscountPopup && (
          <DiscountCodePopup
            onClose={this.closeDiscountPopup}
            onCouponApply={this.discountCouponApplied}
          />
        )}
      </div>
    );
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  prepaidCouponApplied = () => {
    this.setState({ needPrepaidPopup: false });
    RouteManager.navigateToPage(PAGE_ROUTES.BILLING);
  };

  closePrepaidPopup = () => {
    this.setState({ needPrepaidPopup: false });
  };
  showPrepaidPopup = (code_type) => {
    this.setState({
      needPrepaidPopup: true,
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  discountCouponApplied = () => {
    this.setState(
      { needDiscountPopup: false, discountCodeApplied: true },
      () => {
        setTimeout(() => {
          this.setState({
            discountCodeApplied: false,
          });
        }, 10);
      }
    );
  };

  closeDiscountPopup = () => {
    this.setState({ needDiscountPopup: false });
  };
  showDiscountPopup = (code_type) => {
    this.setState({
      needDiscountPopup: true,
    });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getCreditCardDetailsView() {
    return (
      <CreditCardView
        onCardAdded={() => {
          this.setState({ isValidCard: true });
        }}
      />
    );
  }

  getAdyenCardView = () => {
    return (
      <AdyenCardView
        onCardAdded={() => {
          BillingManager.setCardAsAdded(true);
          this.setState({ isValidCard: true });
        }}
      />
    );
  };

  getCashfreeView = () => {
    return <CashfreeView />;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getBillingInfoView() {
    return (
      <div
        className={` pl-l pr-l pt-l pb-l border-m border-radius-s shadow-s  parent-width column mb-xl bg-white ${
          isMobileAppWebView() ? " border-radius-r " : ""
        }`}
        // style={{ backgroundColor: "rgb(245, 245, 245)" }}
      >
        <div className="row">
          <DKIcon src={ic_location} className="mr-s ic-r" />
          <DKLabel text="Billing Address" className="fw-m fs-m" />
        </div>
        <div className="row">
          <DKIcon
            src={ic_location}
            className="mr-s ic-r"
            style={{ opacity: 0 }}
          />
          <DKLabel
            text="We use this address to verify the use of card"
            className="text-gray mt-xs mb-s"
          />
        </div>

        {this.getAddressForm()}
        {this.getFooterNotes()}
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getAddressForm() {
    return (
      <div className="column parent-width mt-l">
        <div className="row">
          {this.getTextField(
            "Address line 1",
            this.state.billingAddress.line1,
            "line1"
          )}
          {this.getTextField(
            "Address line 2",
            this.state.billingAddress.line2,
            "line2"
          )}
        </div>

        <div className="row mt-l">
          {this.getTextField(
            "Zipcode",
            this.state.billingAddress.zipcode,
            "zipcode"
          )}
          {this.getTextField("City", this.state.billingAddress.city, "city")}
        </div>

        <div className="row mt-l">
          {this.getTextField("State", this.state.billingAddress.state, "state")}
          {this.getTextField(
            "Country",
            this.state.billingAddress.country,
            "country"
          )}
        </div>
        <div
          className={` row mt-xl ml-r ${
            isMobileAppWebView() ? " justify-content-center " : ""
          }`}
        >
          <DKButton
            title="Checkout"
            className={` fw-m text-white mr-m ${
              isMobileAppWebView() ? " bg-purple " : " bg-blue"
            }`}
            onClick={() => {
              if (
                SubscriptionManager.isAIOProductActive() &&
                this.state.product !== PRODUCTS.AIO &&
                !PlanManager.isFreemium
              ) {
                this.showAIOWarning();
              } else if (CouponsManager.isPrepaidCouponApplied()) {
                this.showPrepaidWarning();
              } else {
                this.checkoutTapped();
              }
            }}
          />
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m fw-m "
            onClick={() => RouteManager.navigateToPage(PAGE_ROUTES.BILLING)}
          />
        </div>
      </div>
    );
  }
  getTextField(placeholder, value, key) {
    return (
      <DKInput
        placeholder={placeholder}
        name={placeholder}
        className="ml-s mr-s"
        value={value}
        onChange={(newValue) => {
          this.setState({
            billingAddress: {
              ...this.state.billingAddress,
              [key]: getCapitalized(newValue),
            },
          });
        }}
      />
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getPlanDetailsView() {
    const productName = SubscriptionManager.isNewERPPlan()
      ? PRODUCTS.ERP_V2
      : this.state.product;
    const planInterval = SubscriptionManager.isNewERPPlan()
      ? INTERVALS.YEARLY
      : this.state.planInterval;

    return (
      <CheckoutPlanDetails
        product={productName}
        plan={this.state.plan}
        planInterval={planInterval}
        discountCodeApplied={this.state.discountCodeApplied}
        currentCurrency={this.state.currency}
        onCheckout={() => {
          if (
            SubscriptionManager.isAIOProductActive() &&
            this.state.product !== PRODUCTS.AIO &&
            !PlanManager.isFreemium
          ) {
            this.showAIOWarning();
          } else if (CouponsManager.isPrepaidCouponApplied()) {
            this.showPrepaidWarning();
          } else {
            this.checkoutTapped();
          }
        }}
      />
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getFooterNotes() {
    return (
      <DKLabel
        text="By clicking Checkout, I understand that I am enrolling to a Deskera Paid Subscription plan and I authorize Deskera to charge my credit card or debit card on file at the discounted amount, if any, specified above and thereafter based on the subscription plan(s) and number of users I have chosen/added, till it is canceled."
        className="text-gray mt-l ml-r"
      />
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  showAIOWarning = () => {
    showAlert(
      "All in One already active!",
      "Your All in One subscription is already active and it has access to multiple products, you will loose those if subscribing to any other plans.",
      [
        {
          title: "Cancel",
          className: "bg-gray1 fw-m border-m",
          onClick: () => {},
        },
        {
          title: "Continue",
          className: "bg-blue fw-m text-white ml-r",
          onClick: () => {
            this.checkoutTapped();
          },
        },
      ]
    );
  };

  showPrepaidWarning = () => {
    showAlert(
      "Important Warning!",
      "This account has prepaid code applied to it. You will loss access to current products, as you are subscribing to another product. ",
      [
        {
          title: "Cancel",
          className: "bg-gray1 fw-m border-m",
          onClick: () => {},
        },
        {
          title: "Continue",
          className: "bg-blue fw-m text-white ml-r",
          onClick: () => {
            this.checkoutTapped();
          },
        },
      ]
    );
  };

  /////////////////////////////////////////
  fetchCashfreePage = () => {
    let prevAuthLink = "";
    if (
      !Utility.isEmpty(BillingManager.getPaymentMethodData()) &&
      BillingManager.getPaymentMethodData().CashfreeStatus === "INITIALIZED" &&
      BillingManager.getPaymentMethodData().IsPhoneValidated
    ) {
      prevAuthLink = BillingManager.getPaymentMethodData().CashfreeAuthlink;
    }

    if (!Utility.isEmpty(prevAuthLink)) {
      Utility.openTab(prevAuthLink, "_self");
    } else {
      Subscription.requestCashfreePaymentLink({})
        .then(
          (res) => {
            removeLoader();

            if (!Utility.isEmpty(res.AuthLink)) {
              Utility.openTab(res.AuthLink, "_self");
            }
          },
          (err) => {
            this.setState({
              showMobileNumberPopup: true,
            });
            removeLoader();
          }
        )
        .catch((error) => {
          removeLoader();
        });
    }
  };
  /////////////////////////////////////////

  checkoutTapped = () => {
    const productName = SubscriptionManager.isNewERPPlan()
      ? PRODUCTS.ERP_V2
      : this.state.product;
    const planInterval = SubscriptionManager.isNewERPPlan()
      ? INTERVALS.YEARLY
      : this.state.planInterval;
    if (CouponsManager.isVendorPrepaidCouponApplied()) {
      this.showPrepaidPopup();
    } else if (!this.state.isValidCard) {
      const paymentMethods = BillingManager.getPaymentMethods();
      const cashfreeFlowWithNoEmandate =
        !Utility.isEmpty(paymentMethods) &&
        paymentMethods.PaymentGateway.toLowerCase() === "cashfree" &&
        paymentMethods.CashfreeStatus.toUpperCase() === "ACTIVE" &&
        paymentMethods.DefaultPaymentMethodID === "";
      if (cashfreeFlowWithNoEmandate) {
        showAlert(
          "Incorrect payment details",
          "There was some problem while adding the card details, please add your details again using E-Mandate.",
          [
            {
              title: "Ok",
              className: "bg-blue fw-m text-white",
              onClick: () => {
                this.fetchCashfreePage();
              },
            },
          ]
        );
      } else {
        showAlert(
          "No credit card added!",
          "Please enter valid card details and add your card."
        );
      }
    } else {
      const isProductActive = SubscriptionManager.isProductActive(productName);
      const product = productName;
      const plan = this.state.plan;
      let planID = PlanManager.getPlanID(
        productName,
        this.state.plan,
        planInterval
      );
      Subscription.activatePlan(
        {
          PlanID: planID,
          Currency:
            SubscriptionManager.getCurrencySameAsActivePlanCurrency().toLowerCase(),
        },
        false
      ).then((res) => {
        trackEventAC(
          {
            event: isProductActive
              ? TRACK_EVENT.PLAN_CHANGED
              : TRACK_EVENT.ACTIVATE_PLAN,
            eventData: `Plan ${
              isProductActive ? "changed" : "activated"
            } successfully to product ${product.toUpperCase()} and plan ${plan.toLowerCase()}`,
            email: UserManager.getUserEmail(),
          },
          () => {}
        );
        this.saveAddress();
        this.sendSubscriptionSuccessEvent();
      });
    }
  };

  saveAddress() {
    if (
      Object.keys(this.state.billingAddress).some(
        (key) => !Utility.isEmpty(this.state.billingAddress[key])
      )
    ) {
      let addressToSave = {};
      addressToSave[Payload.ADDRESS.ADDRESS1] = this.state.billingAddress.line1;
      addressToSave[Payload.ADDRESS.ADDRESS2] = this.state.billingAddress.line2;
      addressToSave[Payload.ADDRESS.CITY] = this.state.billingAddress.city;
      addressToSave[Payload.ADDRESS.PIN_CODE] =
        this.state.billingAddress.zipcode;
      addressToSave[Payload.ADDRESS.STATE] = this.state.billingAddress.state;
      addressToSave[Payload.ADDRESS.COUNTRY] =
        this.state.billingAddress.country;
      Subscription.setBillingAddress(addressToSave).then(
        (res) => {
          if (res) {
            BillingManager.setBillingAddress(addressToSave);
            this.routeToBilling();
          }
        },
        (err) => {}
      );
    } else {
      this.routeToBilling();
    }
  }
  routeToBilling() {
    RouteManager.navigateToPage(PAGE_ROUTES.BILLING);
  }
  getAddress() {
    if (BillingManager.getBillingAddress()) {
      this.setAddressToState();
    } else {
      Subscription.getBillingAddress()
        .then((res) => {
          if (res) this.setAddressToState();
        })
        .catch((error) => {
          this.setState({
            billingAddress: {
              line1: "",
              line2: "",
              zipcode: "",
              city: "",
              state: "",
              country: "",
            },
          });
        });
    }
  }
  setAddressToState() {
    let address = BillingManager.getBillingAddress();
    if (Utility.isEmpty(address)) {
      return;
    }
    this.setState({
      billingAddress: {
        line1: address[Payload.ADDRESS.ADDRESS1]
          ? address[Payload.ADDRESS.ADDRESS1]
          : "",
        line2: address[Payload.ADDRESS.ADDRESS2]
          ? address[Payload.ADDRESS.ADDRESS2]
          : "",
        zipcode: address[Payload.ADDRESS.PIN_CODE]
          ? address[Payload.ADDRESS.PIN_CODE]
          : "",
        city: address[Payload.ADDRESS.CITY]
          ? address[Payload.ADDRESS.CITY]
          : "",
        state: address[Payload.ADDRESS.STATE]
          ? address[Payload.ADDRESS.STATE]
          : "",
        country: address[Payload.ADDRESS.COUNTRY]
          ? address[Payload.ADDRESS.COUNTRY]
          : "",
      },
    });
  }

  sendSubscriptionSuccessEvent() {
    Utility.postMobileAppActions(MOBILE_APP_ACTIONS.SUBSCRIPTION_SUCCESS);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
}
