import ApiConstants from "../constants/ApiConstants";
import http from "../http";
import UserManager from "../managers/UserManager";

import {
  showAlert,
  showLoader,
  removeLoader,
  showToast,
  TOAST_TYPE,
} from "deskera-ui-library";

import MixPanelManager from "../managers/common/MIxpanelManger";
import Utility from "../utility/Utility";
import { PRODUCTS } from "../constants/Enum";

class User {
  static getRoles(params) {
    return http
      .get(ApiConstants.URL.USER.ROLES, { params })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  static deleteAccountReason(params, callback) {
    showLoader("Deleting account...");
    return http
      .delete(
        ApiConstants.URL.USER.DELETE_REASON +
          "?accountDeleteReason=" +
          params.accountDeleteReason +
          "&password=" +
          btoa(params.password)
      +"&isSocialLogin=" +
          params.isSocialLogin
      )
      .then(
        (res) => {
          removeLoader();
          showAlert(
            "Action completed",
            "Your account has been successfully deleted",
            [
              {
                title: "Ok",
                onClick: () => {
                  callback(true);
                },
                className: "bg-blue ml-r text-white fw-m",
              },
            ]
          );
        },
        (err) => {
          removeLoader();
          return Promise.reject(err);
        }
      );
  }
  static getUserTenantDetail(params) {
    return http
      .get(ApiConstants.URL.USER.DETAIL, { params })
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch(() => {});
  }
  static getUserInfo(params) {
    return http
      .get(ApiConstants.URL.USER.USER_INFO_BY_ID + params.id, {})
      .then(
        (res) => {
          console.log("res", res);
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  static getUsersList(pageNo, params, needLoader = true) {
    if (needLoader) {
      showLoader("Updating user list...");
    }
    return http
      .get(
        ApiConstants.URL.USER.USER_INFO +
          "?limit=100&page=" +
          pageNo ,
        { params: params }
      )
      .then(
        (res) => {
          if (needLoader) {
            removeLoader();
          }
          UserManager.setUsersList(res.content);
          return Promise.resolve(res);
        },
        (err) => {
          if (needLoader) {
            removeLoader();
          }
          return Promise.reject(err);
        }
      )
      .catch(() => {});
  }
  static deleteUserFromList(params) {
    showLoader("Deleting user...");
    return http
      .delete(ApiConstants.URL.USER.DELETE_USER, { data: params })
      .then(
        (res) => {
          removeLoader();
          showToast("User deleted!", TOAST_TYPE.FAILURE);
          MixPanelManager.userDelete({ userid: params.userId });
          return Promise.resolve(res);
        },
        (err) => {
          removeLoader();
          return Promise.reject(err);
        }
      )
      .catch(() => {});
  }
  static addUserToList(user) {
    showLoader("Adding user...");
    return http
      .post(ApiConstants.URL.IAM.ADD_USER, user)
      .then(
        (res) => {
          removeLoader();
          showToast("User added successfully!", TOAST_TYPE.SUCCESS);
          UserManager.addUser(res);
          MixPanelManager.userAdd(user);
          return Promise.resolve(res);
        },
        (err) => {
          removeLoader();
          return Promise.reject(err);
        }
      )
      .catch(() => {});
  }
  static updateUser(user) {
    showLoader("Updating user...");
    return http
      .post(ApiConstants.URL.IAM.UPDATE_USER, user)
      .then(
        (res) => {
          removeLoader();
          showToast("User updated successfully!", TOAST_TYPE.SUCCESS);
          MixPanelManager.userUpdate(user);
          return Promise.resolve(res);
        },
        (err) => {
          removeLoader();
          showToast("Error while updating", TOAST_TYPE.FAILURE);
          return Promise.reject(err);
        }
      )
      .catch(() => {});
  }
  static getRoleGroups(params) {
    return http
      .get(ApiConstants.URL.ROLE_GROUP.GET, { params })
      .then((res) => {
        UserManager.setUserRoleGroup(res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  /**
   *
   * @param {string[]} array of string(appNames)
   * @returns {Promise} returns a promise
   */
  static getMetadata(params, forceUpdate = false) {
    if (!params)
      params = [
        PRODUCTS.ERP.toUpperCase(),
        PRODUCTS.CRM.toUpperCase(),
        PRODUCTS.CRM_PLUS.toUpperCase(),
        PRODUCTS.PEOPLE.toUpperCase(),
        PRODUCTS.REPORT_BUILDER.toUpperCase(),
        PRODUCTS.SHOP.toUpperCase(),
        PRODUCTS.WORKFLOW_ENGINE.toUpperCase(),
        PRODUCTS.CONSOLE.toUpperCase(),
      ];
    if (forceUpdate) {
      return http
        .post(ApiConstants.URL.TENANT.GET_METADATA, params)
        .then((res) => {
          UserManager.setMetaData(res);
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    } else {
      if (!Utility.isEmpty(UserManager.getMetadata())) {
        return Promise.resolve(UserManager.getMetadata());
      } else {
        return this.getMetadata(params, true);
      }
    }
  }
  /**
   *
   * @param {*} params
   * @returns {Promise} returns a promise
   */
  static getLoggedInUserPermissions(params, forceUpdate = false) {
    if (forceUpdate) {
      return http
        .get(ApiConstants.URL.USER.GET_LOGGED_IN_USER_PERMISSIONS, { params })
        .then((res) => {
          UserManager.setCurrentUserPermission(res);
          return Promise.resolve(res);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    } else {
      if (!Utility.isEmpty(UserManager.getCurrentUserPermission())) {
        return Promise.resolve(UserManager.getCurrentUserPermission());
      } else {
        this.getLoggedInUserPermissions(params, true);
      }
    }
  }
  /**
   *
   * @param {number[]} params array of users id
   * @returns {Promise} returns a promise
   */
  static getUsersPermissions(params) {
    showLoader("Fetching user details...");
    return http
      .post(ApiConstants.URL.TENANT.GET_PERMISSIONS, params)
      .then((res) => {
        removeLoader();
        return Promise.resolve(res);
      })
      .catch((err) => {
        removeLoader();
        return Promise.reject(err);
      });
  }

  static getIntercomHash(params) {
    return http
      .get(ApiConstants.URL.IAM.INTERCOM_HASH, { params })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  static getPeopleUserInfo() {
    return http
      .get(ApiConstants.URL.USER.PEOPLE_USER_INFO)
      .then((res) => {
        UserManager.setPeopleUser(res);
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  static setPassword(payload) {
    return http.post(ApiConstants.URL.IAM.SET_PASSWORD, payload)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  }

  static resetPassword(params) {
    return http.get(ApiConstants.URL.IAM.RESET_PASSWORD, { params: params })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  }
}

export default User;
