export default class Payload {
    static COMMON = {
        VERSION: 'Version',
        WEBSITE_TYPE:'website_type'
    }
    static PLAN = {
        CRM: 'CRMPlan',
        AIO: 'AIOPlan',
        CUSTOM: 'CustomPlan',
        ERP: 'ERPPlan',
        PEOPLE: 'PeoplePlan',
        IS_BOOKKEEPER: 'IsBookkeeper',
        PREPAID_COUPONS: 'PrepaidCoupons',
        PRODUCT_LIMITS: 'ProductLimits',
        STATUS: 'Status',
        CARD_AVAILABLE: 'CardAvailable'
    }
    static PLAN_DETAILS = {
        IN_TRIAL: 'InTrial',
        INTERVAL: 'Interval',
        PRODUCT: 'Product',
        NAME: 'Name',
        NEXT_CHARGE_DATE: 'NextChargeDate',
        PLAN_ID: 'PlanID',
        PREV_PLAN_ID: 'PrevPlanID',
        TRIAL_DAYS: 'TrialDays',
        TRIAL_OVER: 'TrialOver',
        CURRENCY : 'Currency',
        DisplayName: 'DisplayName'
    }
    static PAYMENT = {
        CARDS: 'Cards',
        CARD_BRAND: 'CardBrand',
        CARD_COUNTRY: 'CardCountry',
        EXP_MONTH: 'ExpMonth',
        EXP_YEAR: 'ExpYear',
        LAST4: 'Last4',
        PAYMENT_METHOD_ID: 'PaymentMethodID',
        DEFAULT_PAYMENT_METHOD_ID: 'DefaultPaymentMethodID',
    }
    static ADDRESS = {
        ADDRESS1: "Address1",
        ADDRESS2: "Address2",
        CITY: "City",
        PIN_CODE: "PinCode",
        STATE: "State",
        COUNTRY: "Country",
    }
}