import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import MixPanelManager from "../../managers/common/MIxpanelManger";

import StripeApiManager from "../../managers/StripeApiManager";

/*
PROPS:
- onPaymentInitialise
- onPaymentFail
- onPaymentSetup
*/

export default function StripeForm(props) {
  const [error, setError] = useState("");

  useEffect(() => {
    if (props.checkoutTapped === true) {
      handleSubmit();
    }
  }, [props.checkoutTapped]);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "14px",
        "::placeholder": {},
      },
      invalid: {
        color: "#FFC7EE",
      },
    },
    hidePostalCode: true,
  };

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    getStripeIntent();
  };

  const getStripeIntent = async (event) => {
    StripeApiManager.getIntent().then(
      (res) => {
        if (res.ClientSecret) {
          props.onPaymentInitialize();
          callStripePayment(res.ClientSecret);
        } else {
          props.onPaymentFail("error");
        }
      },
      (err) => {
        props.onPaymentFail(err);
      }
    );
  };

  const callStripePayment = async (secret) => {
    if(!stripe) {
      return
    }
    const result = await stripe?.confirmCardSetup(secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: props.user.organizationName,
          email: props.user.emailId,
        },
      },
    });

    if (result?.error) {
      // Show error to your customer (e.g., insufficient funds)
      props.onPaymentFail(result.error.message);
    } else {
      // The payment has been processed!
      if (result?.setupIntent.status === "succeeded") {
        StripeApiManager.setPaymentMethod(
          result.setupIntent.payment_method,
          props.tenantID,
          props.userID
        ).then(
          (res) => {
            if(res.result) {
              props.onPaymentSetup({
                ...res,
                payment_method: result.setupIntent.payment_method,
              });
            }
            if(res.errorMessage){
              props.onPaymentFail("Adding card failed.");
            }
          },
          (err) => {
            props.onPaymentFail("Payment failed due to some internal error.");
          }
        );
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        MixPanelManager.cardSave({doc_type:'Card Added'})
      } else {
        props.onPaymentFail("Payment failed due to some internal error.");
      }
    }
  };

  return (
    <div className="parent-width column align-items-start">
      <div
        className="border-m border-radius-s border-box parent-width bg-gray1"
        style={{ padding: 15 }}
      >
        <CardElement
          id="CardElement"
          options={CARD_ELEMENT_OPTIONS}
          onChange={(e) => {
            if (e.error) {
              setError(e.error);
              props.onCardDetailsEnter(false);
            } else if (e.complete) {
              setError(null);
              props.onCardDetailsEnter(true);
            }
          }}
        ></CardElement>
      </div>
      {error && (
        <div className="text-red fw-m mt-s mb-s" role="alert">
          {error.message}
        </div>
      )}
    </div>
  );
}
