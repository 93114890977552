import Payload from "../constants/Payload";
import {
  PAYMENT_GATEWAY,
  DEFAULT_PAYMENT_GATEWAY,
} from "../constants/PaymentGateway";
import Utility from "../utility/Utility";
import UserManager from "../managers/UserManager";
import ApiConstants from "../constants/ApiConstants";

class BillingManager {
  static paymentMethods = null;
  static defaultPaymentCard = null;
  billingAddress = null;
  static isCreditCardDetailsAdded = false;
  static paymentGatewayMethod = "";
  static phoneNumber = "";
  static isPhoneNumberValidated = false;
  static paymentMethodData = null;

  static getDefaultPaymentCard() {
    return BillingManager.defaultPaymentCard;
  }
  static setDefaultPaymentCard(data) {
    if (!Utility.isEmpty(data)) BillingManager.defaultPaymentCard = data;
  }
  static getPaymentMethods() {
    return BillingManager.paymentMethods;
  }
  static setPaymentMethods(data) {
    if (!Utility.isEmpty(data)) {
      BillingManager.paymentMethods = data;
      if (
        !Utility.isEmpty(data[Payload.PAYMENT.CARDS]) &&
        !Utility.isEmpty(data[Payload.PAYMENT.DEFAULT_PAYMENT_METHOD_ID])
      )
        BillingManager.setDefaultPaymentCard(
          data.Cards.find(
            (card) =>
              card[Payload.PAYMENT.PAYMENT_METHOD_ID] ===
              data[Payload.PAYMENT.DEFAULT_PAYMENT_METHOD_ID]
          )
        );
    }
  }
  static getBillingAddress() {
    return this.billingAddress;
  }
  static setBillingAddress(data) {
    this.billingAddress = data;
  }

  static setCardAsAdded(value = false) {
    this.isCreditCardDetailsAdded = value;
  }

  static isCardDetailsAdded() {
    return this.isCreditCardDetailsAdded;
  }

  static showStripePayment() {
    return BillingManager.getPaymentGateway() === DEFAULT_PAYMENT_GATEWAY;
  }

  static showAdyenPayment() {
    return BillingManager.getPaymentGateway() === PAYMENT_GATEWAY.SG;
  }

  static showCashfreePayment() {
    let isCashfreeGloballyEnabled = ApiConstants.IS_CASHFREE_GLOBALLY_ENABLED;

    if (isCashfreeGloballyEnabled === "false") {
      isCashfreeGloballyEnabled =
        UserManager.getUserEmail().includes("deskera.org") ||
        UserManager.getUserEmail().includes("deskera.com");
    }

    return (
      BillingManager.getPaymentGateway() === PAYMENT_GATEWAY.IN &&
      isCashfreeGloballyEnabled
    );
  }

  static isDefaultPaymentGateway = () => {
    return (
      BillingManager.showStripePayment() ||
      BillingManager.showAdyenPayment() ||
      BillingManager.showCashfreePayment()
    );
  };

  static setPaymentGateway(method) {
    BillingManager.paymentGatewayMethod = method;
  }

  static getPaymentGateway() {
    return BillingManager.paymentGatewayMethod;
  }

  static setPhoneNumber = (phNumber) => {
    BillingManager.phoneNumber = phNumber;
  };

  static getPhoneNumber = () => {
    return BillingManager.phoneNumber;
  };

  static setIsPhoneNumberValidated = (isValid) => {
    BillingManager.isPhoneNumberValidated = isValid;
  };

  static isMobileNumberVerified = () => {
    return BillingManager.isPhoneNumberValidated;
  };

  static setPaymentMethodData = (data) => {
    BillingManager.paymentMethodData = data;
  };

  static getPaymentMethodData = () => {
    return BillingManager.paymentMethodData;
  };
}

export default BillingManager;
