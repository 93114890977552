import { PRODUCTS, USER_ROLE } from "../constants/Enum";
import UserManager from "./UserManager";

class PermissionManager {
  static isSubscriptionAccessible() {
    return (
      UserManager?.isUserOwner() ||
      (UserManager?.isUserAdmin() &&
        UserManager?.getUsersPermission() &&
        UserManager?.getUsersPermission().apps.some((permission) =>
          permission.modules.some(
            (role) =>
              role.appName === PRODUCTS.CONSOLE.toUpperCase() &&
              role.code === "m_console_subscription"
          )
        ))
    );
  }

  static isUserManagementAccessible() {
    return (
      UserManager?.isUserOwner() ||
      UserManager?.isUserManager() ||
      (UserManager?.isUserAdmin() &&
        UserManager?.getUsersPermission() &&
        UserManager?.getUsersPermission().apps.some((permission) =>
          permission.modules.some(
            (role) =>
              role.appName === PRODUCTS.CONSOLE.toUpperCase() &&
              role.code === "m_console_role"
          )
        ))
    );
  }

  static isSettingsAccessible() {
    return UserManager?.isUserAdmin() || UserManager?.isUserOwner();
  }

  static canAddOrg() {
    return UserManager?.isUserAdmin() || UserManager?.isUserOwner();
  }

  static isGraphVisible(plan) {
    if(UserManager?.getUserDetails().roleShortInfo !== undefined) {
      return (
        UserManager?.isUserOwner() ||
        (UserManager?.getUserDetails() &&
          UserManager?.getUserDetails().roleShortInfo.some(
            (role) => role.appName.toLowerCase() === plan
          ))
      );
    } else {
      return true;
    }
  }

  static canPerformUserMgmtActions() {
    return (
      !UserManager?.isUserOwner() &&
      UserManager?.isUserManager() &&
      UserManager?.getUsersPermission() &&
      UserManager?.getUsersPermission().apps.some(
        (permission) =>
          permission.appName === PRODUCTS.CONSOLE.toUpperCase() &&
          permission.rgShortCode === USER_ROLE.MANAGER.toLowerCase() &&
          permission.modules.some(
            (role) =>
              role.appName === PRODUCTS.CONSOLE.toUpperCase() &&
              role.code === "m_console_user_r"
          )
      )
    );
  }

  static showProducts(product) {
    if (UserManager?.isUserOwner()) {
      return true;
    } else {
      return (
        !UserManager?.isUserOwner() &&
        UserManager?.getUserDetails().roleShortInfo.some(
          (role) => role.appName === product.toUpperCase()
        )
      );
    }
  }

  static showPlanDetailsSection() {
    return (
      !UserManager?.isUserOwner() &&
      UserManager?.getUsersPermission() &&
      UserManager?.getUsersPermission().apps.some(
        (role) =>
          (role.appName === PRODUCTS.CONSOLE.toUpperCase() &&
            role.rgShortCode === USER_ROLE.MANAGER.toLowerCase()) ||
          (role.appName === PRODUCTS.CONSOLE.toUpperCase() &&
            role.rgShortCode === USER_ROLE.MEMBER.toLowerCase())
      )
    );
  }
}

export default PermissionManager;
