/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import tick from "../../assets/website/icons/tick.svg";
import { DKIcon } from "deskera-ui-library";
import { PRICING_PLANS_NAME_V2 } from "../../constants/Enum";
import SVGIcon from "../../constants/SVGIcon";
import "../../css/pagesCommon.css";
const PlanSpecification = ({ pricingSpecificationData, activeTab }) => {
  useEffect(() => {
    window.addEventListener(`scroll`, function () {
      const position = window.scrollY;
      const sidebarSticky = document.getElementById(
        `pricing-table-header-sticky`
      );
      const sidebarNonSticky = document.getElementById(
        `pricing-table-header-non-sticky`
      );
      const headerMenu = document.getElementById(
        `header-menu-with-sales-header`
      );
      const pricingBanner = document.getElementById(`pricing-banner-wrapper`);
      const pricingTabs = document.getElementById(`pricing-tabs-wrapper`);
      const pricingCards = document.getElementById(
        `pricing-table-card-wrapper`
      );
      const pricingTabsContent = document.getElementById(
        `pricing-table-tab-content-wrapper`
      );

      const menuHeight = 110;
      if (
        sidebarSticky !== null &&
        typeof headerMenu !== `undefined` &&
        typeof pricingBanner !== `undefined` &&
        typeof pricingTabs !== `undefined` &&
        typeof pricingCards !== `undefined` &&
        typeof pricingTabsContent !== `undefined` &&
        typeof sidebarNonSticky !== `undefined`
      ) {
        const headerMenuWrapperHeight = 58;
        const pricingBannerWrapperHeight =
          pricingBanner?.getBoundingClientRect()?.height;
        const pricingTabsWrapperHeight =
          pricingTabs?.getBoundingClientRect()?.height;
        const pricingCardsWrapperHeight =
          pricingCards?.getBoundingClientRect()?.height;
        const pricingTabsContentWrapperHeight =
          pricingTabsContent?.getBoundingClientRect()?.height;
        const startPostion = 895;
        const endPostion = 5000;
        const top = headerMenuWrapperHeight;
        if (position > startPostion && sidebarSticky) {
          sidebarSticky.classList.remove(`display-none`);
          sidebarSticky.classList.add(`pricing-table-header-sticky`);
          sidebarSticky.style.top = top + `px`;
          sidebarNonSticky.classList.add(`opacityZero`);
        }
        if (position > endPostion || position < startPostion) {
          sidebarSticky.classList.remove(`pricing-table-header-sticky`);
          sidebarSticky.classList.add(`display-none`);
          sidebarNonSticky.classList.remove(`opacityZero`);
        }
      }
    });
  });
  return (
    <section
      id={pricingSpecificationData.id}
      className="PlanSpecificationChart column align-items-center parent-width text-align-center pt-xl scroll-header-margin"
    >
      <table className={pricingSpecificationData?.className}>
        <tr
          id="pricing-table-header-sticky"
          style={{
            lineHeight: 1,
            background:
              activeTab == 1 ? "rgb(255, 250, 247)" : "rgb(249, 252, 255)",
            alignItems: "center",
          }}
          className={`display-none ${
            activeTab == 1 ? "mrp-blank-heading" : "mrp-blank-heading"
          }`}
        >
          <th
            className={`price-heading header-neue ${
              activeTab == 1 ? "" : "erp-svg"
            }`}
            style={{
              fontWeight: 700,
              border: `none`,
              color: pricingSpecificationData.color
                ? pricingSpecificationData.color
                : "#181818",
            }}
          >
            <div className="prod-heading">
              <SVGIcon
                name={pricingSpecificationData.icon}
                className=" ic-l"
                style={{
                  height: 20,
                }}
              />
              {pricingSpecificationData.title}
            </div>
          </th>
          {/* {pricingSpecificationData.plan_available.small_business ? (
            <th style={{ border: `none` }}>
              <div style={{ height: 40 }}>
                <SVGIcon
                  name={`ic-small-business`}
                  className=" ic-l"
                  style={{
                    height: 20,
                  }}
                />
              </div>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.SMALL_BUSINESS}
              </div>
            </th>
          ) : // (
          //     <th style={{ border: `none`, opacity: 0 }}>
          //         <div className="fs-l fw-b mt-xxl plan-name">{PRICING_PLANS_NAME_V2.SMALL_BUSINESS}</div>
          //     </th>
          // )
          null} */}
          {pricingSpecificationData.plan_available.growth ? (
            <th style={{ border: `none` }}>
              <div style={{ height: 40 }}>
                <SVGIcon
                  name={`ic-growth`}
                  className=" ic-l"
                  style={{
                    height: 20,
                  }}
                />
              </div>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.GROWTH}
              </div>
            </th>
          ) : null}
          {pricingSpecificationData.plan_available.mid_market ? (
            <th style={{ border: `none` }}>
              <div style={{ height: 40 }}>
                <SVGIcon
                  name={`ic-mid-market`}
                  className=" ic-l"
                  style={{
                    height: 20,
                  }}
                />
              </div>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.MID_MARKET}
              </div>
            </th>
          ) : (
            <th style={{ border: `none`, opacity: 0 }}>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.MID_MARKET}
              </div>
            </th>
          )}
          {pricingSpecificationData.plan_available.enterprise ? (
            <th style={{ border: `none` }}>
              <div style={{ height: 40 }}>
                <SVGIcon
                  name={`ic-enterprise`}
                  className=" ic-l"
                  style={{
                    height: 20,
                  }}
                />
              </div>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.ENTERPRISE}
              </div>
            </th>
          ) : (
            <th style={{ border: `none`, opacity: 0 }}>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.ENTERPRISE}
              </div>
            </th>
          )}
        </tr>
        <tr
          id="pricing-table-header-non-sticky"
          style={{
            lineHeight: 1,
            background:
              activeTab == 1 ? "rgb(255, 250, 247)" : "rgb(249, 252, 255)",
          }}
          className={`${activeTab == 1 ? "" : "erp-svg"}`}
        >
          <th
            className={`price-heading header-neue `}
            style={{
              fontWeight: 700,
              border: `none`,
              color: pricingSpecificationData.color
                ? pricingSpecificationData.color
                : "#181818",
            }}
          >
            <div className="prod-heading">
              <SVGIcon
                name={pricingSpecificationData.icon}
                className=" ic-l"
                style={{
                  height: 20,
                }}
              />
              {pricingSpecificationData.title}
            </div>
          </th>
          {/* {pricingSpecificationData.plan_available.small_business ? (
            <th style={{ border: `none` }}>
              <div style={{ height: 40 }}>
                <SVGIcon
                  name={`ic-small-business`}
                  className=" ic-l"
                  style={{
                    height: 20,
                  }}
                />
              </div>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.SMALL_BUSINESS}
              </div>
            </th>
          ) : // (
          //     <th style={{ border: `none`, opacity: 0 }}>
          //         <div className="fs-l fw-b mt-xxl plan-name">{PRICING_PLANS_NAME_V2.SMALL_BUSINESS}</div>
          //     </th>
          // )
          null} */}
          {pricingSpecificationData.plan_available.growth ? (
            <th style={{ border: `none` }}>
              <div style={{ height: 40 }}>
                <SVGIcon
                  name={`ic-growth`}
                  className=" ic-l"
                  style={{
                    height: 20,
                  }}
                />
              </div>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.GROWTH}
              </div>
            </th>
          ) : null}
          {pricingSpecificationData.plan_available.mid_market ? (
            <th style={{ border: `none` }}>
              <div style={{ height: 40 }}>
                <SVGIcon
                  name={`ic-mid-market`}
                  className=" ic-l"
                  style={{
                    height: 20,
                  }}
                />
              </div>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.MID_MARKET}
              </div>
            </th>
          ) : (
            <th style={{ border: `none`, opacity: 0 }}>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.MID_MARKET}
              </div>
            </th>
          )}
          {pricingSpecificationData.plan_available.enterprise ? (
            <th style={{ border: `none` }}>
              <div style={{ height: 40 }}>
                <SVGIcon
                  name={`ic-enterprise`}
                  className=" ic-l"
                  style={{
                    height: 20,
                  }}
                />
              </div>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.ENTERPRISE}
              </div>
            </th>
          ) : (
            <th style={{ border: `none`, opacity: 0 }}>
              <div className="fs-l fw-b mt-xxl plan-name">
                {PRICING_PLANS_NAME_V2.ENTERPRISE}
              </div>
            </th>
          )}
        </tr>
        <tr
          className="pt-s"
          style={{
            lineHeight: 1,
          }}
        >
          <th
            className="fs-r"
            style={{
              width: `50%`,
              border: `none`,
            }}
          >
            {pricingSpecificationData.subtitle}
          </th>
          {pricingSpecificationData?.plan_values &&
            pricingSpecificationData?.plan_values?.us.map((price, index) => (
              <th style={{ border: `none`, height: 60 }} key={index}>
                <div
                  className="price-heading header-neue"
                  style={{
                    fontWeight: 700,
                    border: `none`,
                    color: `#181818`,
                  }}
                >
                  {price && (
                    <>
                      <sup style={{ fontSize: 18 }}>$</sup>
                      {price}
                    </>
                  )}
                </div>
              </th>
            ))}
        </tr>
        {pricingSpecificationData?.features &&
          pricingSpecificationData?.features?.map((feature, index) => (
            <tr key={index}>
              <td>{feature.features_name}</td>
              {/* {pricingSpecificationData.plan_available.small_business && (
                <td
                  className={feature.small_business ? `tick` : ``}
                  style={{ borderLeft: "1px dashed #D6D3D1" }}
                >
                  {feature.small_business ? (
                    feature.isOptional ? (
                      `Optional`
                    ) : (
                      <DKIcon src={tick} />
                    )
                  ) : null}
                </td>
              )} */}
              {pricingSpecificationData.plan_available.growth && (
                <td
                  className={feature.growth ? `tick` : ``}
                  style={{ borderLeft: "1px dashed #D6D3D1" }}
                >
                  {feature.growth ? (
                    feature.isOptional ? (
                      `Optional`
                    ) : (
                      <DKIcon src={tick} />
                    )
                  ) : null}
                </td>
              )}
              <td
                className={feature.mid_market ? `tick` : ``}
                style={{ borderLeft: "1px dashed #D6D3D1" }}
              >
                {feature.mid_market ? (
                  feature.isOptional ? (
                    `Optional`
                  ) : (
                    <DKIcon src={tick} />
                  )
                ) : null}
              </td>
              <td
                className={feature.enterprise ? `tick` : ``}
                style={{ borderLeft: "1px dashed #D6D3D1" }}
              >
                {feature.enterprise ? (
                  feature.isOptional ? (
                    `Optional`
                  ) : (
                    <DKIcon src={tick} />
                  )
                ) : null}
              </td>
            </tr>
          ))}
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {/* {pricingSpecificationData.plan_available.small_business && <td></td>} */}
        </tr>
      </table>
      {/* <Link
            className="parent-width"
            to={pricingSpecificationData.talk_to_expert_button ? pricingSpecificationData.talk_to_expert_button.link : ``}
            style={{ textDecoration: `none` }}
        >
            <ButtonView
                className="mt-l fs-s"
                align={ALIGNMENT.LEFT}
                buttonTitle={pricingSpecificationData.talk_to_expert_button.name ? pricingSpecificationData.talk_to_expert_button.name : `More`}
                buttonColor={`#3f3f3f`}
                textColor={COLORS.text_secondary}
            // buttonIcon={right_arrow}
            />
        </Link> */}
    </section>
  );
};

export default PlanSpecification;
