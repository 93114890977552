import React, { Component } from "react";

import {
  DKLabel,
  DKButton,
  DKIcon,
  showAlert,
  DKInput
} from "deskera-ui-library";

import Subscription from "../../services/subscription";

import ic_coupons from "../../assets/icons/ic_coupon.png";
import { CODE_TYPE, TRACK_EVENT } from "../../constants/Enum";
import { COLORS } from "../../constants/Colors";
import { trackEventAC } from "../../managers/ActiveCampaignWrapper";
import UserManager from "../../managers/UserManager";

/*
- onSave
- onClose
- onCouponApply
*/

class DiscountCodePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      checkTapped: false,
      couponDetails: null,
      reason: null,
    };
  }
  render() {
    return (
      <div className=" transparent-background">
        <div className="popup-window" style={{ maxWidth: 450 }}>
          {this.getHeader()}
          {this.getCodeForm()}
          {this.state.couponDetails && this.getCouponDetails()}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getHeader() {
    return (
      <div className="row justify-content-between">
        <div>
          <div className="row">
            <DKIcon src={ic_coupons} className="mr-s ic-s-2" />
            <DKLabel
              text={`Redeem Discount / Promo code`}
              className="fw-m fs-l"
            />
          </div>
        </div>
        <div>
          <div className="row">
            <DKButton
              title="Close"
              className="bg-gray1 border-m fw-m"
              onClick={this.cancelTapped}
            />
          </div>
        </div>
      </div>
    );
  }

  cancelTapped = () => {
    this.props.onClose();
  };
  saveTapped = () => {};

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getCodeForm() {
    return (
      <div className="column mt-xl mb-l parent-width">
        <DKLabel text={`Enter discount / promo code below and validate`} />
        <div className="row mt-m" style={{ alignItems: "flex-start" }}>
          <DKInput
            needHeader={false}
            name={"Discount / Promo code"}
            placeholder={"discount / promo code"}
            required={true}
            value={this.state.code}
            onChange={(value) => {
              this.setState({
                code: value.toUpperCase(),
                couponDetails: null,
              });
            }}
            canValidate={this.state.checkTapped}
            invalid={this.state.checkTapped && this.state.code.trim() === ""}
          />
          <DKButton
            className="text-wrap-none text-white bg-green fw-m ml-m mt-xs"
            title="Validate"
            onClick={this.getDiscountCodeDetails}
          />
        </div>
      </div>
    );
  }

  getDiscountCodeDetails = () => {
    this.setState({
      checkTapped: true,
      couponDetails: null,
    });
    if (this.state.code.trim() !== "") {
      Subscription.getDiscountCodeDetails({
        Code: this.state.code.trim(),
      }).then((res) => {
        if (res.CanApply === false && res.CanOverride === false) {
          showAlert("Error occured!", res.Reason);
        } else {
          if (res.Type !== CODE_TYPE.PREPAID_CODE) {
            this.setState({
              couponDetails: res,
            });
          } else {
            showAlert(
              "Invalid discount code!",
              "Please enter a valid discount / promo code and try again."
            );
          }
        }
      });
    }
  };

  getCouponDetails() {
    let data = this.state.couponDetails;
    return (
      <div className="column parent-width mt-xxl mb-l">
        <DKLabel text="Coupon details" className="fw-m fs-m text-gray mb-s" />

        <div
          className="mt-m parent-width column align-items-center bg-white border-radius-s border-m"
          style={{
            borderStyle: "dotted",
            borderWidth: 3,
            borderColor: COLORS.blue,
          }}
        >
          <DKLabel
            text={`${data.PercentOff}% FREE`}
            className="fs-xxxl fw-b text-blue mt-l"
          />
          <DKLabel text={data.Description} className="fs-l mt-s" />
          <div className="row justify-content-center bg-gray1 pl-l pt-l pb-l pr-l mt-l">
            <DKLabel text={`Promo code: `} className="fs-l mt-s" />
            <DKLabel
              text={this.state.code}
              className="fs-xxl mt-s bg-blue pt-s pb-s pl-m pr-m text-white fw-m ml-m"
            />
          </div>
        </div>

        <div className="row mt-xxl">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m fw-m mr-r"
            onClick={this.cancelTapped}
          />
          <DKButton
            title="Apply this coupon"
            className="bg-blue  fw-m text-white"
            onClick={this.applyDiscountCode}
          />
        </div>
      </div>
    );
  }

  applyDiscountCode = () => {
    const code_ac = this.state.code.trim();
    Subscription.applyDiscountCode(this.state.code.trim()).then((res) => {
      trackEventAC(
        {
          event: TRACK_EVENT.CODE_APPLIED,
          eventData: `${code_ac} code applied successfully`,
          email: UserManager.getUserEmail(),
        },
        () => {}
      );

      this.props.onCouponApply();
      showAlert(
        "Discount applied!",
        "Discount code has been applied successfully applied. Your final payable amount will be updated."
      );
    });
  };
}

export default DiscountCodePopup;
