import { LANGUAGES, LANGUAGES_CODE } from "../../../constants/Constant";
import united_states from "../../../assets/flags/united-states.svg";
import uk from "../../../assets/flags/uk.svg";
import india from "../../../assets/flags/india.svg";

export const LanguageMenuList = [
  {
    title: LANGUAGES.ENGLISH_US,
    code: LANGUAGES_CODE.ENGLISH_US,
    icon: united_states,
  },
  {
    title: LANGUAGES.ENGLISH_UK,
    code: LANGUAGES_CODE.ENGLISH_UK,
    icon: uk,
  },
  {
    title: LANGUAGES.ENGLISH_IN,
    code: LANGUAGES_CODE.ENGLISH_IN,
    icon: india,
  },
];
