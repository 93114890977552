import Utility from "../utility/Utility";

class IAMManager {
    static TFAStatus = null;    // TODO: REVIEW IF THIS SHOULD BE FALSE
    static codeDetails = null; 

    static getTFAStatus() {
        return IAMManager.TFAStatus;
    }
    static setTFAStatus(data) {
        if (!Utility.isEmpty(data)) IAMManager.TFAStatus = data.enableStatus;
    }
    static deleteTFA() {
        IAMManager.TFAStatus = null;
    }

    static setTwoFactorAuthCode = (data) => {
        this.codeDetails = data;
    }

    static getTwoFactorAuthCode = () => {
        return this.codeDetails;
    }

    static setRegisterTwoFactorAuthStatusApp = (data) => {
        this.TFAStatus = data.enableStatus;
    }
}

export default IAMManager;